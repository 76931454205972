import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../shared/reducers';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Input, Row, Table } from 'reactstrap';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faEye,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  fetchCustomerList,
  fetchCustomerPet,
} from '../../reducers/user.reducer';
import './styles.scss';
import SaveButton from '../components/button';
import filterSvg from '../customers/svg/filterSvg.svg';
import searchSvg from '../customers/svg/searchSvg.svg';
import { CUSTOMER_PETS_FILTER } from '../../config/constants';
import { Cascader } from 'antd';
import 'antd/dist/antd.css';
import { getPetTypes, getPetBreed } from '../../reducers/master.reducer';
import moment from 'moment-timezone';
import { ITEMS_PER_PAGE } from '../../shared/util/pagination.constants';
import Pagination from '@mui/material/Pagination';



export interface ICustomerListProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ url: string }> {}

export const CustomerPets = (props: any) => {
  const { customerPets, loading, petTypes, petBreeds,totalCount } = props;
  const customerId = props.customerId;
  const [filterPets, setFilterPets] = useState([]);
  const [selectedOption, setSelectedOption] = useState([['', 0]]);
  const [selectedPetIds, setSelectedPetIds] = useState('');
  const [selectedBreedIds, setSelectedBreedIds] = useState('');
  const [searchForm, setSearchForm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);

  useEffect(() => {
    props.fetchCustomerPet({ customerId });
    props.getPetTypes({});
    props.getPetBreed({});
  }, []);

  useEffect(() => {
    props.fetchCustomerPet({
      customerId,
      page: currentPage - 1,
      size: ITEMS_PER_PAGE,
      petTypeIds: selectedPetIds,
      breedIds: selectedBreedIds,
      search: searchForm,
    });
  }, [currentPage]);

  useEffect(() => {
    setNumberOfPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
  }, [customerPets]);

  const onPaginationChange = (e, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const data = [{ value: '0', label: 'All', children: [] as any }];
    CUSTOMER_PETS_FILTER.forEach(element =>
      data.push({ value: element, label: element, children: [] }),
    );
    petTypes.map((petType, i) => {
      data[1].children.push({ value: petType.id, label: petType.name });
    });
    petBreeds.map((breed, i) => {
      data[2].children.push({ value: breed.id, label: breed.name });
    });
    setFilterPets(data as any);
  }, [petTypes]);

  useEffect(() => {
    let petIds = '' as any;
    let breedIds = '' as any;
    selectedOption.forEach(element => {
      if (element[0] === CUSTOMER_PETS_FILTER[0]) {
        petIds.length === 0
          ? (petIds = element[1])
          : (petIds = petIds + ',' + element[1]);
      }
      if (element[0] === CUSTOMER_PETS_FILTER[1]) {
        breedIds.length === 0
          ? (breedIds = element[1])
          : (breedIds = breedIds + ',' + element[1]);
      }
    });
    setSelectedBreedIds(breedIds);
    setSelectedPetIds(petIds);
  }, [selectedOption]);

  useEffect(() => {
    if (selectedPetIds === CUSTOMER_PETS_FILTER[0]) {
      setSelectedPetIds('');
    }
    if (selectedBreedIds === CUSTOMER_PETS_FILTER[1]) {
      setSelectedBreedIds('');
    }
    setCurrentPage(1);
    props.fetchCustomerPet({
      customerId: customerId,
      petTypeIds: selectedPetIds,
      breedIds: selectedBreedIds,
      search: searchForm,
      page: 0,
      size: ITEMS_PER_PAGE,
    });
  }, [selectedPetIds, selectedBreedIds, searchForm]);

  const searchHandler = event => {
    event.persist();
    setSearchForm(event.target.value);
  };

  return (
    <div>
      <Row className="d-flex align-items-right mb-4 mt-4">
        <Col md={6}></Col>
        <Col md={3}>
          <div className="fa-search position-relative ant-d-style">
            <Cascader
              multiple
              options={filterPets}
              onChange={data => setSelectedOption(data)}
              placeholder="Please select"
              maxTagCount="responsive"
              style={{ width: '100%' }}
            />
            <img
              src={filterSvg}
              alt=""
              className="position-absolute pr-3"
              style={{ top: 10, left: 6 }}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="fa-search position-relative">
            <Input
              className="form-control input-lg search-style"
              type="text"
              value={searchForm}
              onChange={searchHandler}
              placeholder="Pet Name"></Input>
            <img
              src={searchSvg}
              alt=""
              className="position-absolute"
              style={{ top: 6, left: 6 }}
            />
          </div>
        </Col>
        <Col md={1}></Col>
      </Row>
      <Row>
        {customerPets && customerPets?.length ? (
          <Col md={12} className="border-2 shadow-sm mt-3 ml-2 mr-2">
            <Table responsive className="mb-0 ">
              <thead className="border-0 border-bottom border-1">
                <tr>
                  <th className="border-0 ps-2 pe-2 py-3">
                    {translate(`customer_tab.customer_pets.patient_id`)}
                  </th>
                  <th className="border-0 ps-2 pe-2 py-3">
                    {translate(`customer_tab.customer_pets.pet_name`)}
                  </th>
                  <th className="border-0 px-2 py-3">
                    {translate(`customer_tab.customer_pets.pet_type`)}
                  </th>
                  <th className="border-0 px-2 py-3">
                    {translate(`customer_tab.customer_pets.breed`)}
                  </th>
                  <th className="border-0 px-2 py-3">
                    {translate(`customer_tab.customer_pets.age`)}
                  </th>
                  <th className="border-0 px-2 py-3">
                    {translate(`customer_tab.customer_pets.gender`)}
                  </th>
                  <th className="border-0 px-2 py-3">
                    {translate(`customer_tab.customer_pets.weight`)}
                  </th>
                </tr>
              </thead>
              <tbody className="border-top-0">
                {customerPets?.map((pet, i) => (
                  <tr
                    className="border-0 border-bottom border-1"
                    key={`entity-${i}`}
                    data-cy="entityTable">
                    <td className="border-0 px-2 py-4">{pet?.patientIds}</td>
                    <td className="border-0 px-2 py-4">{pet?.petName}</td>
                    <td className="border-0 px-2 py-4">{pet?.petType}</td>
                    <td className="border-0 px-2 py-4">{pet?.breed}</td>
                    <td className="border-0 px-2 py-4">
                      {pet?.dob
                        ? moment().diff(
                            moment(pet?.dob, 'YYYY-MM-DD'),
                            'months',
                          )
                        : 0}{' '}
                      {translate(`customer_tab.customer_pets.months`)}
                    </td>
                    <td className="border-0 px-2 py-4">{pet?.sex}</td>
                    <td className="border-0 px-2 py-4">{pet?.weight}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        ) : (
          !loading && (
            <div
              className="alert alert-warning"
              style={{ display: 'flex', justifyContent: 'center' }}>
              {translate(`customer_tab.customer_pets.no_pet_found`)}
            </div>
          )
        )}
        {totalCount && customerPets?.length ? (
        <Pagination
          count={numberOfPages}
          onChange={onPaginationChange}
          variant="outlined"
          shape="rounded"
          className="d-flex justify-content-center mb-4 mt-4"
        />
      ) : null}
      </Row>
    </div>
  );
};

const mapStateToProps = ({ vendorUser, user, master }: IRootState) => ({
  customerPets: user.customerPets,
  loading: vendorUser.loading,
  petTypes: master.petTypes,
  petBreeds: master.petBreeds,
  totalCount: user.totalCount
});

const mapDispatchToProps = {
  fetchCustomerPet,
  getPetTypes,
  getPetBreed,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPets);
