import { Button, Col, Container, Label, Row } from "reactstrap";
import LogoImage from "../../svg/login-logo.svg";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { translate, Translate } from "react-jhipster";
import "./style.scss";
import { connect } from "react-redux";
import { createNewPassword } from "../../reducers/user.reducer";
import { IRootState } from "../../shared/reducers";
import { Colors, HTTP_CREATED_RESPONSE } from "../../config/constants";
import { RouteComponentProps } from "react-router";
import { useEffect, useState } from "react";
import { sendResetPassword } from "../../reducers/user.reducer";
import { AppLogo } from "../login/components/app-logo";
import { AppLogoHeader } from "../login/components/app-logo-header";

export interface IPageProps
  extends StateProps,
  DispatchProps,
  RouteComponentProps<{ id: string }> { }

export const ForgotPassword = (props: IPageProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [password, setPassword] = useState("");
  const updatePassword = (event) => setPassword(event.target.value);
  let { sendResetPasswordResponse } = props

  const clickHandler = (event, values) => {
    let payload = {
      email: values?.email,
    };
    setFormLoading(true);
    props.sendResetPassword(payload);
  };

  useEffect(() => {
    if (formLoading) {
      if (props?.createNewPasswordResponse?.status === HTTP_CREATED_RESPONSE) {
        props.history.push("/login");
      }
      setFormLoading(false);
    }
  }, [props?.createNewPasswordResponse]);

  return (
    <Container id={"login-form"} fluid className="p-0">
      <Row className="m-0">
        <Col
          md={7}
          className="column p-0 bg-light position-relative">
         <div className="d-flex justify-content-center align-items-start w-100">
            <div style={{  zIndex: 1000, marginTop:"2rem" }}>
              <AppLogoHeader />
            </div>
            <img
              className="position-absolute w-100"
              style={{ left: 0, bottom: 0 }}
              src={LogoImage}
              alt=""
            />
          </div>.
        </Col>
        <Col
          style={{ backgroundColor: Colors.pageBackground }}
          fluid
          className="px-0 px-lg-5 "
        >
          <Col className="p-4 mt-4"></Col>
          <Col className="p-5 mt-5">
            <p className="login-head-text">
              {translate("forgot_password.forgot_password_head")}
            </p>
            <div className="message">
              {translate("forgot_password.forgot_password_message")}
            </div>
            <AvForm model={{}} onValidSubmit={clickHandler}>
              <Col className="mt-4" md={12}>
                <Label className="label" for="email">
                  <Translate contentKey="forgot_password.enter_email">
                    Enter E Mail
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  className="p-3"
                  name="email"
                  id="email"
                  type={Text}
                  validate={{
                    required: {
                      errorMessage: translate(
                        "validation_business_info.email_error_message"
                      ),
                    },
                    email: {
                      errorMessage: translate(
                        "validation_business_info.invalid_email_error_message"
                      ),
                    },
                  }}
                />
              </Col>
              <Col md={12}>
                <Button
                  type="submit"
                  style={{ width: "100%" }}
                  color="danger"
                  className="pb-0 pt-1 p-5 m-0 mt-5 text-light"
                >
                  <p className="text-center button-label mb-0 mt-0 pt-2 pb-2">
                    {translate("new_password.continue")}
                  </p>
                </Button>
              </Col>
            </AvForm>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ authentication, plan, user }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  services: plan.services,
  loading: plan.loading,
  loginResponse: user.loginResponse,
  createNewPasswordResponse: user.createNewPasswordResponse,
  sendResetPasswordResponse: user.sendResetPasswordResponse,
});

const mapDispatchToProps = {
  sendResetPassword,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
