import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { IRootState } from '../../shared/reducers';
import './style.scss';
import { getServicesMenus } from '../../reducers/plan.reducer';
import preRegistrationSuccessSvg from '../../svg/pre-registration-success.svg';
import { Translate } from 'react-jhipster';
import { Container, Row, Col } from 'reactstrap';
import { AppLogo } from '../login/components/app-logo';
import { useEffect } from 'react';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export const PreRegistrationSuccess = (props: IPageProps) => {

  useEffect(() => {
    let timer = setTimeout(() => {
      props.history.push('/landing-page');
    }, 10000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  
  return (
    <div className="background-whole-page" style={{ overflow: 'hidden' }}>
      <div className="background">
        <Col>
          <div className="d-flex align-items-center flex-column">
            <img
              src={preRegistrationSuccessSvg}
              alt=" "
              style={{ marginTop: 80 }}
            />
            <div style={{ marginTop: 10 }} className="logo-container">
              <AppLogo />
            </div>
            <Row
              style={{ marginTop: '-20px' }}
              md={2}
              className="message-container">
              <div>
                <span className="welcome-message">
                  <Translate contentKey="pre_registration.welcome">
                    Welcome to PeditoH
                  </Translate>
                  <br />
                </span>
                <Translate contentKey="pre_registration.success">
                  pre-registration Success
                </Translate>
              </div>
            </Row>
          </div>
        </Col>
      </div>
    </div>
  );
};

const mapStateToProps = ({ authentication, plan }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  services: plan.services,
  loading: plan.loading,
});

const mapDispatchToProps = { getServicesMenus };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreRegistrationSuccess);
