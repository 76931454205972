import React, { useEffect, useState } from 'react';
import './style.scss';
import { Row, Col, Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { translate } from 'react-jhipster';
import {
  getMedicineList,
  getPrescriptionByAppointment,
  createPrescription,
  updatePrescription,
} from '../../../reducers/prescription.reducer';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { toast } from 'react-toastify';
import {
  HTTP_CREATED_RESPONSE,
  Colors,
  MEDICINE_DIRECTION,
  MEDICINE_TIME,
  UNASSIGNED,
} from '../../../config/constants';
import { IoMdAdd, AiOutlineDelete, GrClose } from 'react-icons/all';
import CreatableSelect from 'react-select/creatable';
import Tooltip from '@mui/material/Tooltip';

const inPatient = 'In Patient';
const filter = createFilterOptions({ matchFrom: 'any' });

const PrescriptionEntry = props => {
  const {
    appointmentId,
    prescriptionsId,
    isAddPrescription,
    onCloseModal,
    doctor,
    consultationDetails,
    isShowConfirmationModal,
    setIsShowConfirmationModal,
    consultationType,
  } = props;
  const dispatch = useDispatch();
  const medicines = useSelector(
    (state: IRootState) => state.prescription.medicines,
  );
  const loading = useSelector(
    (state: IRootState) => state.prescription.loading,
  );
  const prescriptionResponse = useSelector(
    (state: IRootState) => state.prescription.prescription,
  );
  const updatePrescriptionResponse = useSelector(
    (state: IRootState) => state.prescription.updatePrescriptionResponse,
  );
  const createPrescriptionResponse = useSelector(
    (state: IRootState) => state.prescription.createPrescriptionResponse,
  );

  const [prescriptionItems, setPrescriptionItems] = useState([] as any[]);
  const [errorMessage, setErrorMessage] = useState(null as any);
  const [diagnosis, setDiagnosis] = useState('');
  const [prognosis, setPrognosis] = useState('');
  const [adviceGiven, setAdviceGiven] = useState('');
  const [treatmentNotes, setTreatmentNotes] = useState('');
  const [prescriptionId, setPrescriptionId] = useState(null as any);
  const [errorRows, setErrorRows] = useState([] as any[]);
  const [prescriptionLoading, setPrescriptionLoading] = useState(false);
  const [prescription, setPrescription] = useState({}) as any;
  const [doctorName, setDoctorName] = useState('');
  const [doctorQualification, setDoctorQualification] = useState('');

  useEffect(() => {
    setErrorMessage(null);
    setErrorRows([]);
  }, [prescriptionItems]);

  useEffect(() => {
    dispatch(getMedicineList());
    dispatch(getPrescriptionByAppointment(appointmentId));
    setPrescriptionLoading(true);
  }, []);


  useEffect(() => {
    if (!isAddPrescription && prescriptionResponse?.prescriptions?.length) {
      if (prescriptionsId) {
        setPrescription(
          prescriptionResponse?.prescriptions?.find(
            prescription => prescription?.id === prescriptionsId,
          ),
        );
      } else {
        setPrescription(prescriptionResponse?.prescriptions[0]);
      }
    } else {
      setPrescription({});
    }
  }, [prescriptionResponse]);

  useEffect(() => {
    if (prescription?.id && prescriptionLoading) {
      setPrescriptionId(prescription?.id);
      setDiagnosis(prescription?.diagnosis ?? '');
      setPrognosis(prescription?.prognosis ?? '');
      setAdviceGiven(prescription?.adviceGiven ?? '');
      setTreatmentNotes(prescription?.treatmentNotes ?? '');
      setPrescriptionItems([...prescription.prescriptionItems]);
      setDoctorName(prescription?.doctorName);
      setDoctorQualification(prescription?.qualifications)
      setPrescriptionLoading(false);
    }
  }, [prescription]);

  useEffect(() => {
    if (
      !loading &&
      updatePrescriptionResponse?.status === HTTP_CREATED_RESPONSE
    ) {
      toast.success(translate('prescription_entry.saved'));
      dispatch(getMedicineList());
      dispatch(getPrescriptionByAppointment(appointmentId));
      onCloseModal();
    }
  }, [updatePrescriptionResponse]);

  useEffect(() => {
    if (
      !loading &&
      createPrescriptionResponse?.status === HTTP_CREATED_RESPONSE
    ) {
      dispatch(getMedicineList());
      dispatch(getPrescriptionByAppointment(appointmentId));
      toast.success(translate('prescription_entry.saved'));
      onCloseModal();
    }
  }, [createPrescriptionResponse]);

  const onChangeMedicine = (options, index) => {
    const prescriptions = [...prescriptionItems];
    prescriptions[index].medicineName = options?.name;
    prescriptions[index].medicineId = options?.id ?? null;
    setPrescriptionItems(prescriptions);
  };

  const onChangeTime = (value, index) => {
    const prescriptions = [...prescriptionItems];
    prescriptions[index].dosage = value || MEDICINE_TIME[0];
    setPrescriptionItems(prescriptions);
  };

  const onChangeDirections = (e, index) => {
    const prescriptions = [...prescriptionItems];
    prescriptions[index].directions = e?.value;
    setPrescriptionItems(prescriptions);
  };

  const onChangeDuration = (e, index) => {
    const prescriptions = [...prescriptionItems];
    prescriptions[index].durationInDays = e?.target?.value;
    setPrescriptionItems(prescriptions);
  };

  const addPrescriptionItem = () => {
    const prescriptions = [...prescriptionItems];
    prescriptions.push({
      medicineName: '',
      dosage: MEDICINE_TIME[0],
      durationInDays: '',
    });
    setPrescriptionItems(prescriptions);
  };

  const removePrescriptionItem = index => {
    const prescriptions = [...prescriptionItems];
    prescriptions.splice(index, 1);
    setPrescriptionItems(prescriptions);
  };

  const handleValidSubmit = (event, errors, values) => {
    let medicineItems = [...prescriptionItems];

    if (
      !medicineItems?.length &&
      !values.treatmentNotes &&
      !values.adviceGiven &&
      !values.prognosis &&
      !values.diagnosis
    ) {
      return toast.error('Add details before saving');
    }

    //commented for future
    // if (!medicineItems?.length) {
    //   setErrorRows([0]);
    //   return setErrorMessage(translate('prescription_entry.add_at_least_one'));
    // }

    let invalidIndexes = [] as any[];
    medicineItems.forEach((item, index) => {
      (!item.medicineName || !item.dosage) && invalidIndexes.push(index);
    });

    if (invalidIndexes.length) {
      setErrorRows(invalidIndexes);
      return setErrorMessage(
        translate('prescription_entry.please_check_the_details'),
      );
    }

    //commented for future
    // if (errors?.length) return;

    let prescription = {
      diagnosis: values?.diagnosis,
      adviceGiven: values?.adviceGiven,
      prognosis: values?.prognosis,
      treatmentNotes: values?.treatmentNotes,
      prescriptionItems: medicineItems,
    };

    if (prescriptionId) {
      prescription['id'] = prescriptionId;
      dispatch(updatePrescription({ appointmentId, prescription }));
      confirmationModal();
      return;
    }

    dispatch(createPrescription({ appointmentId, prescription }));
    confirmationModal();
  };

  const confirmationModal = () => {
    setIsShowConfirmationModal(true);
  };

  const handleKeyPress = e => {
    if (e.key === '-') {
      e.preventDefault();
    }
  };

  return (
    <Row className="prescription-entry">
      <Col className="inner-container">
        <Row className="header-container">
          {consultationType != inPatient ? <Col className="p-0">
            <h5 className="header-texts">
              {doctor ?? UNASSIGNED}
              <br />
              {consultationDetails?.qualification ?? ''}
            </h5>
          </Col> : <Col className="p-0">
            <h5 className="header-texts">
              {doctorName ?? ''}
              <br />
              {doctorQualification ?? ''}
            </h5>
          </Col>}
          <Col className="d-flex justify-content-end p-2">
            <GrClose onClick={onCloseModal} role="button" />
          </Col>
        </Row>

        <Row className="bottom-container">
          <Row className="table-item">
            <Col md={1} className="title justify-content-center">
              {translate('prescription_entry.serial_number')}
            </Col>
            <Col md={3} className="title">
              {translate('prescription_entry.medicine_name')}
            </Col>
            <Col md={2} className="title">
              {translate('prescription_entry.time')}
            </Col>
            <Col md={2} className="title">
              {translate('prescription_entry.duration')}
            </Col>
            <Col md={3} className="title">
              {translate('prescription_entry.direction')}
            </Col>
          </Row>

          {prescriptionItems.map((prescriptionItem, index) => (
            <Row
              key={index}
              className={`table-item mt-2 bg-white ${errorRows.includes(index) && 'highlight-error'
                } `}>
              <Col
                md={1}
                className="title-lab-report justify-content-center d-flex table-text">
                {index + 1}
              </Col>
              <Col md={3} className="title-lab-report d-block">
                <Tooltip
                  title={
                    prescriptionItem.medicineName
                      ? prescriptionItem.medicineName
                      : ''
                  }>
                  <Autocomplete
                  style={{width:'350px'}}
                    value={prescriptionItem?.medicineName}
                    size={'small'}
                    options={medicines}
                    getOptionLabel={option => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option?.inputValue;
                      }
                      return option?.name;
                    }}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        onChangeMedicine({ name: newValue }, index);
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        onChangeMedicine({ name: newValue.inputValue }, index);
                      } else {
                        onChangeMedicine(newValue, index);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        option => inputValue === option?.name,
                      );
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          name: inputValue,
                        });
                      }
                      return filtered;
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        style={{
                          width: '85%',
                        }}
                        placeholder={translate('prescription_entry.medicine')}
                      />
                    )}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                  />
                </Tooltip>
              </Col>
              <Col md={2} className="title-lab-report d-block">
                <select
                  value={prescriptionItem?.dosage}
                  placeholder="Select"
                  onChange={event => onChangeTime(event?.target?.value, index)}
                  className="input-box-lab-report">
                  <option value={0} disabled>
                    {translate('entity.action.select')}
                  </option>
                  {MEDICINE_TIME?.map((time, i) => (
                    <option key={i} value={time}>
                      {time}
                    </option>
                  ))}
                </select>
              </Col>
              <Col md={2} className="title-lab-report auto-width">
                <Col className="h-100">
                  <input
                    value={prescriptionItem?.durationInDays}
                    className="input-box-lab-report"
                    type="number"
                    min={1}
                    style={{ width: '50%' }}
                    onChange={e => onChangeDuration(e, index)}
                    onKeyPress={e => handleKeyPress(e)}
                    onWheel={e => e.currentTarget.blur()}
                  />
                  <span className="table-text m-2">
                    {translate('prescription_entry.days')}
                  </span>
                </Col>
              </Col>

              <Col md={2} className="title-lab-report">
                <CreatableSelect
                  className="style-text-box my-1"
                  isClearable
                  value={{
                    value: prescriptionItem?.directions,
                    label: prescriptionItem?.directions,
                  }}
                  options={MEDICINE_DIRECTION}
                  onChange={e => onChangeDirections(e, index)}
                />
              </Col>
              <Col className="d-flex justify-content-end">
                <div
                  onClick={() => removePrescriptionItem(index)}
                  className="add-item-button bg-white align-items-center d-flex justify-content-center pt-2 pb-2">
                  <AiOutlineDelete size={20} color={Colors.geraldine} />
                </div>
              </Col>
            </Row>
          ))}

          <Row className={`table-item mt-2 bg-white`}>
            <Col className="justify-content-end d-flex">
              <div
                onClick={addPrescriptionItem}
                className="add-item-button align-items-center d-flex justify-content-center pt-2 pb-2 pr-2">
                <IoMdAdd className="text-light" />
              </div>
            </Col>
          </Row>

          {errorMessage && (
            <Row className="pl-0 ml-0">
              <span className="text-danger error-text mt-2 mb-2">
                {errorMessage}
              </span>
            </Row>
          )}

          <AvForm onSubmit={handleValidSubmit}>
            <Row>
              <Col className="pt-1 p-0">
                <span>{translate('prescription_entry.prognosis')}</span>
                <AvField
                  value={prognosis}
                  className="p-3 my-2 border-0 text-area"
                  name={'prognosis'}
                  type="textarea"
                />
                <span>{translate('prescription_entry.diagnosis')}</span>
                <AvField
                  value={diagnosis}
                  className="p-3 my-2 border-0 text-area"
                  name={'diagnosis'}
                  type="textarea"
                />
                <span>{translate('prescription_entry.advice_given')}</span>
                <AvField
                  value={adviceGiven}
                  className="p-3 my-2 border-0 text-area"
                  name={'adviceGiven'}
                  type="textarea"
                />
                <span>{translate('prescription_entry.treatment_notes')}</span>
                <AvField
                  value={treatmentNotes}
                  className="p-3 my-2 border-0 text-area"
                  name={'treatmentNotes'}
                  type="textarea"
                />
              </Col>
            </Row>
            <Row className="d-grid justify-content-center mt-3">
              <Button
                disabled={loading}
                type="submit"
                color="danger"
                className="save-button">
                <span className="text-center title">
                  {translate('placeholders.save')}
                </span>
              </Button>
            </Row>
          </AvForm>
        </Row>
      </Col>
    </Row>
  );
};

export default PrescriptionEntry;
