import {
    ClientConfig,
    createClient,
    IAgoraRTCClient
} from 'agora-rtc-react';
import { translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import { io, Socket } from "socket.io-client";
import { CUSTOMER_CALL_STATUS, SOCKET_URL } from '../../config/constants';

interface Props {
    doctorId: string,
    appointmentId: string,
}
const config: ClientConfig = {
    mode: 'rtc',
    codec: 'vp8',
};

const RANDOM_USER_ID = Math.floor(Math.random() * 100000);

class VideoCallService {
    private useClient = createClient(config);
    public client: IAgoraRTCClient;

    private doctorId: string;
    private appointmentId: string;
    public callUUID: string = "";
    private socket: Socket;
    private setCallStatus: (payload) => void;
    constructor({ doctorId, appointmentId, updateCallStatus }) {
        this.doctorId = doctorId;
        this.appointmentId = appointmentId;
        this.setCallStatus = updateCallStatus;
        this.socket = io(SOCKET_URL, { autoConnect: false });
        this.client = this.useClient();
    }

    resetClient() {
        this.client = this.useClient();
    }

    releaseAllResources() {
        console.log('Releasing all resources');
        this.client.leave();
        this.client.removeAllListeners();
        this.socket.removeAllListeners();
        this.socket.disconnect();
    }


    setCallUID(callUUID: string) {
        this.callUUID = callUUID;
    }

    async initiateClientMessaging(): Promise<void> {
        console.log('[SOCKET] Initiating client messaging');
        this.socket.connect();

        this.socket.on("connect", () => {
            console.log("[SOCKET] Socket connected: " + this.socket.id);
        });

        this.socket.on("disconnect", () => {
            console.log("[SOCKET] Socket disconnected: " + this.socket.id);
        });

        this.socket.on("customer:message", (data: any) => {
            console.log("[SOCKET] Received message: ", { data });
            if (data && data.source == "customer" && data.appointmentId == this.appointmentId) {
                this.handleCallStatus(data.type);
            }
        });
    };

    handleCallStatus(message: string) {
        if (
            [
                CUSTOMER_CALL_STATUS.CUSTOMER_CALL_ANSWERED,
                CUSTOMER_CALL_STATUS.CUSTOMER_CALL_ON_HOLD,
                CUSTOMER_CALL_STATUS.CUSTOMER_CALL_DECLINED,
                CUSTOMER_CALL_STATUS.CUSTOMER_CALL_DISCONNECTED,
                CUSTOMER_CALL_STATUS.CUSTOMER_CALL_MISSED,
                CUSTOMER_CALL_STATUS.CUSTOMER_CALL_NOTIFICATION_RECEIVED,
            ].includes(message)
        ) {
            this.showNotification(message);
            this.setCallStatus(message);
        }
    }

    showNotification(callStatus: string) {
        switch (callStatus) {
            case CUSTOMER_CALL_STATUS.CUSTOMER_CALL_NOTIFICATION_RECEIVED:
                toast.info(translate(`customer_call_status.${callStatus}`));
                break;
            case CUSTOMER_CALL_STATUS.CUSTOMER_CALL_ANSWERED:
                toast.success(translate(`customer_call_status.${callStatus}`));
                break;
            case CUSTOMER_CALL_STATUS.CUSTOMER_CALL_ON_HOLD:
            case CUSTOMER_CALL_STATUS.CUSTOMER_CALL_DECLINED:
            case CUSTOMER_CALL_STATUS.CUSTOMER_CALL_DISCONNECTED:
                toast.error(translate(`customer_call_status.${callStatus}`));
                break;
            case CUSTOMER_CALL_STATUS.CUSTOMER_CALL_MISSED:
                toast.warning(translate(`customer_call_status.${callStatus}`));
                break;
            default:
                break;
        }
    }

    async sendChannelMessage(text: string) {
        this.socket.emit("vendor:server:message", {
            source: "vendor",
            appointmentId: this.appointmentId,
            type: text,
            callUUID: this.callUUID
        });
    };

    async notifyPeerAboutVideoDisconnection() {
        return this
            .sendChannelMessage(CUSTOMER_CALL_STATUS.VENDOR_CALL_DISCONNECTED)
            .then(() => { })
            .catch((err: any) => {
                console.log(`Could not send message : ${err}`);
            });
    };
};

export default VideoCallService;
