import { API_URL } from './../config/constants';
import axios from 'axios';
import { getToken } from '../config/constants';
import { IPlan } from '../model/plans.model';
import { IService } from '../model/services.model';
import { ISubMenu } from '../model/user-menu';

import { REQUEST, SUCCESS, FAILURE } from '../shared/reducers/action-type.util';
import { IAdditionalCharges } from '../model/additional-charge-model';

export const ACTION_TYPES = {
  GET_PLANS: 'plan/GET_PLANS',
  GET_SERVICES: 'services/GET_SERVICES',
  GET_SERVICE_LINKS: 'services/GET_SERVICE_LINKS',
  GET_PHARMACY_CHARGE: '/GET_PHARMACY_CHARGE',
};

const initialState = {
  loading: false,
  plans: [] as IPlan[],
  services: [] as IService[],
  servicesLinks: [] as ISubMenu[],
  pharmacyCharge: {} as IAdditionalCharges,
};

export type PlanState = Readonly<typeof initialState>;

export default (state: PlanState = initialState, action): PlanState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_PLANS):
    case REQUEST(ACTION_TYPES.GET_SERVICES):
    case REQUEST(ACTION_TYPES.GET_PHARMACY_CHARGE):
    case REQUEST(ACTION_TYPES.GET_SERVICE_LINKS): {
      return {
        ...state,
        loading: true,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_PLANS): {
      return {
        ...state,
        plans: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_SERVICES): {
      return {
        ...state,
        services: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_SERVICE_LINKS): {
      return {
        ...state,
        servicesLinks: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_PHARMACY_CHARGE): {
      return {
        ...state,
        pharmacyCharge: action.payload.data,
        loading: false,
      };
    }
    case FAILURE(ACTION_TYPES.GET_PLANS):
    case FAILURE(ACTION_TYPES.GET_SERVICES):
    case FAILURE(ACTION_TYPES.GET_PHARMACY_CHARGE):
    case FAILURE(ACTION_TYPES.GET_SERVICE_LINKS): {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

export const getPlans: () => void = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_PLANS,
    payload: axios.get(`${API_URL}/v1/services?page=0&size=20`, config),
  });
  return result;
};

export const getPharmacyCharge: () => void = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_PHARMACY_CHARGE,
    payload: axios.get(`${API_URL}/v1/services/additional-charges`, config),
  });
  return result;
};

export const getServices: () => void = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_SERVICES,
    payload: axios.get(`${API_URL}/v1/vendor/services?page=0&size=20`, config),
  });
  return result;
};

export const getServicesMenus: any = (id?: number) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_SERVICE_LINKS,
    payload: axios.get(`/data/service-links.json`),
  });
  return result;
};
