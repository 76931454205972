import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
} from 'reactstrap';
import { translate } from 'react-jhipster';
import { FaUserMd, FaPlus, FaPencilAlt } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import {
  fetchDoctorsBySlotId,
  assignDoctorForAppointment,
} from '../../../reducers/video-consultation-reducer';
import { HTTP_OK_RESPONSE, Colors } from '../../../config/constants';
import { toast } from 'react-toastify';
import { getRandomIconColor } from '../../../shared/util/utility-functions';
import { PERMISSION_ACTIONS } from '../../../config/permission-constants';
import { IsPermittedAction } from '../../../shared/util/permission-utils';

const UN_ASSIGNED_LIST_KEY = -1;
const UN_ASSIGN = 'Unassign';

const AppointmentCard = ({
  id,
  missedCall,
  customerName,
  petType,
  fromTime,
  toTime,
  onPressAppointmentCard,
  onClickEdit,
  slotId,
  loading,
  refreshAppointments,
  isAssigned,
  canAssignDoctors = true,
  petName,
  mode,
  speciality,
  doctor,
}: any) => {
  const dispatch = useDispatch();
  const doctorsBySlot = useSelector(
    (state: IRootState) => state.videoConsultation.doctorsBySlot,
  );
  const assignDoctorResponse = useSelector(
    (state: IRootState) => state.videoConsultation.assignDoctorResponse,
  );
  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );
  const [assignModalVisible, setAssignModalVisible] = useState(false);
  const [doctors, setDoctors] = useState([] as any);
  const togglePopup = () => setAssignModalVisible(!assignModalVisible);

  const onClickUserIcon = e => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(fetchDoctorsBySlotId(slotId));
    togglePopup();
  };

  useEffect(() => {
    const data = doctorsBySlot;
    if (
      data &&
      isAssigned !== UN_ASSIGNED_LIST_KEY &&
      !data?.filter(doctor => doctor?.id === UN_ASSIGNED_LIST_KEY).length
    ) {
      data.push({ name: UN_ASSIGN, id: UN_ASSIGNED_LIST_KEY });
      setDoctors(data);
    } else {
      setDoctors(doctorsBySlot);
    }
  }, [doctorsBySlot]);

  useEffect(() => {
    if (
      assignDoctorResponse?.status === HTTP_OK_RESPONSE &&
      assignModalVisible
    ) {
      toast.success(translate('video_consultation.doctor_assigned'));
      setAssignModalVisible(false);
      refreshAppointments();
    }
  }, [assignDoctorResponse]);

  const onSelectDoctorToAssign = doctorId => {
    dispatch(
      assignDoctorForAppointment({
        doctorId,
        appointmentId: id,
        unassign: doctorId === UN_ASSIGNED_LIST_KEY ? 'true' : 'false',
      }),
    );
  };

  const doctorAssignPopup = () => (
    <UncontrolledPopover
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
      target={`Popover${id?.toString()}`}
      isOpen={assignModalVisible}
      toggle={togglePopup}
      className="custom-popover assign-popup"
      trigger="legacy"
      placement={'right-end'}>
      <PopoverHeader>{translate('video_consultation.assign_to')}</PopoverHeader>

      <PopoverBody
        style={{
          overflow: 'auto',
          maxHeight: '170px',
          zIndex: 1,
        }}>
        {loading ? (
          <div className="text-info my-2 justify-content-center d-flex">
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        ) : doctors?.length ? (
          doctors.map((doctor: any) => (
            <Row
              onClick={() => onSelectDoctorToAssign(doctor?.id)}
              className="doctor-row">
              <Col md={1} className="p-0"></Col>
              <Col md={2} className="p-0">
                <div
                  className="icon-wrapper"
                  style={{ backgroundColor: getRandomIconColor() }}>
                  <FaUserMd className="icon" />
                </div>
              </Col>
              <Col md={9}>
                <h6 className="doctor-name">{doctor?.name}</h6>
              </Col>
            </Row>
          ))
        ) : (
          <h4 className="my-4 text-muted empty-list-text">
            {translate('video_consultation.no_doctors')}
          </h4>
        )}
      </PopoverBody>
    </UncontrolledPopover>
  );

  return (
    <Col onClick={e => onPressAppointmentCard(id)} key={id} md={6}>
      <Card className="card-content mt-2 mb-2">
        <CardBody>
          <Row>
            <Row>
              <Col md={4}>
                <p className="pet-type-text">
                  {translate('video_consultation.parent_name')}
                </p>
              </Col>
              <Col>
                <p className="pet-name">{customerName}</p>
              </Col>
              {canAssignDoctors && (
                <Col className="d-flex justify-content-end" md={2}>
                  <div
                    id={`Popover${id?.toString()}`}
                    onClick={onClickUserIcon}
                    className={`icon-wrapper ${!isAssigned && 'add-icon'}`}>
                    {isAssigned ? (
                      <FaUserMd className="icon" />
                    ) : (
                      <FaPlus className="icon" />
                    )}
                  </div>
                </Col>
              )}
              {mode === 'OFFLINE' &&
                IsPermittedAction(
                  PERMISSION_ACTIONS.EDIT_OFFLINE_APPOINTMENT,
                  authorities,
                  permissionList,
                ) &&
                IsPermittedAction(
                  PERMISSION_ACTIONS.GET_OFFLINE_APPOINTMENT,
                  authorities,
                  permissionList,
                ) && (
                  <Col className="d-flex justify-content-end" md={1}>
                    <div
                      onClick={e => {
                        e.stopPropagation();
                        onClickEdit(id);
                      }}>
                      <FaPencilAlt className="edit-style-pencil" />
                    </div>
                  </Col>
                )}
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col md={4}>
                    <p className="pet-type-text">
                      {translate('video_consultation.pet_name')}
                    </p>
                  </Col>
                  <Col>
                    <p className="pet-type">
                      {petName}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <p className="pet-type-text">
                      {translate('video_consultation.pet_type')}
                    </p>
                  </Col>
                  <Col>
                    <p className="pet-type">{petType}</p>
                  </Col>
                </Row>
                {speciality && (
                  <Row>
                    <Col md={4}>
                      <p className="pet-type-text">Speciality</p>
                    </Col>
                    <Col>
                      <p className="pet-type">
                        {speciality}
                      </p>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md={4}>
                    <p className="appointment-type">Slot</p>
                  </Col>
                  <Col>
                    <p className="time">
                      {`${fromTime} - ${toTime}`}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <p className="appointment-type">Mode</p>
                  </Col>
                  <Col>
                    <p className="appointment-type">
                      {mode}
                    </p>
                  </Col>
                </Row>
                {doctor && (
                  <Row>
                    <Col md={4}>
                      <p className="appointment-type">Doctor</p>
                    </Col>
                    <Col>
                      <p className="appointment-type">
                        {doctor}
                      </p>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Row>
        </CardBody>
      </Card>
      {assignModalVisible && doctorAssignPopup()}
    </Col>
  );
};

export default AppointmentCard;
