import { Container } from "reactstrap";
import SubHeader from "../../shared/layout/sub-header";

export const Home = () => {
  return (
    <div>
      <Container fluid>
        <br/>
        <h3>Welcome to MyPetZone!</h3>
        <br/>
        <p>
          MyPetZone is an online application that helps you treat your pets
          through online vet consultation. We not only provide medicinal
          consultation, but we also help with grooming and other facilities for
          your pets. Through this app, you can reduce the burden of carrying
          your pets to the nearest vet hospitals.
        </p>
      </Container>
    </div>
  );
};

export default Home;
