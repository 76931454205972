import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Card, Col, Container, Label, Row } from 'reactstrap';
import { IRootState } from '../../shared/reducers';
import {
  AvForm,
  AvField,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';
import './style.scss';
import { translate, Translate } from 'react-jhipster';
import {
  getPreRegisteredUser,
  setRegisteredUser,
  updatePostRegistrationState,
  resetToDefault,
} from '../../reducers/user.reducer';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Steps from '../components/steps';
import leftBgImg from '../../assets/images/left-container-bg.png';

import {
  fetchCities,
  fetchCountries,
  fetchDesignations,
  fetchStates,
} from '../../reducers/master.reducer';
import Button from '../components/button';
import { AppLogo } from '../login/components/app-logo';
import { PHONE_NUMBER_LENGTH, PIN_CODE_LENGTH } from '../../config/constants';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export const PostRegistration = (props: IPageProps) => {
  const { user, loading, postRegistrationDetails } = props;
  const [countries, setCountries] = useState([] as any[]);
  const [states, setStates] = useState([] as any[]);
  const [cities, setCities] = useState([] as any[]);
  const [designations, setDesignations] = useState([] as any[]);

  useEffect(() => {
    props.getPreRegisteredUser();
    props?.fetchCountries({});
    props?.fetchDesignations({});
    props.resetToDefault();
  }, []);

  useEffect(() => {
    if (Array.isArray(props?.designations)) {
      setDesignations(props?.designations);
    }
  }, [props?.designations]);

  useEffect(() => {
    if (Array.isArray(props?.countries)) {
      setCountries(props?.countries);
    }
  }, [props?.countries]);

  useEffect(() => {
    if (Array.isArray(props?.states)) {
      setStates(props?.states);
    }
  }, [props?.states]);

  useEffect(() => {
    if (Array.isArray(props?.cities)) {
      setCities(props?.cities);
    }
  }, [props?.cities]);

  useEffect(() => {
    if (postRegistrationDetails?.countryId) {
      props?.fetchStates({
        countryId: postRegistrationDetails?.countryId,
      });
    }
  }, [postRegistrationDetails?.countryId]);

  useEffect(() => {
    if (postRegistrationDetails?.stateId) {
      props?.fetchCities({
        stateId: postRegistrationDetails?.stateId,
      });
    }
  }, [postRegistrationDetails?.stateId]);

  const handleValidSubmit = (event, values) => {
    const entity = {
      ...values,
      id: postRegistrationDetails?.id,
      step: 2,
    };
    props.updatePostRegistrationState(entity);
    props.setRegisteredUser(entity);
  };

  useEffect(() => {
    if (user?.step == 2) {
      props.history.push('service-subscription');
    }
  }, [user]);

  return (
    <Row className="w-100 mx-0" style={{ height: '100vh' }}>
      <Col xs="12" md="4" className="px-0 h-100 sm-h-20">
        <div className="left-container">
          <div className="d-flex flex-column h-100 justify-content-center align-items-center">
            <div className="logo-container">
              <AppLogo />
            </div>
            <div>
              <h2 className="text-white mb-2">
                <Translate contentKey="pages.vendor_registration">
                  Vendor Registration
                </Translate>
              </h2>
              <h6 className="text-white">
                <Translate contentKey="pages.registration_take_couple_of_minute">
                  It will take a couple of minutes
                </Translate>
              </h6>
            </div>
          </div>
          <img src={leftBgImg} alt="left-bg"></img>
        </div>
      </Col>
      <Col xs="12" md="8" className="right-container">
        <div className="d-none d-sm-block">
          <h2>
            <Translate contentKey="pages.vendor_registration">
              Vendor Registration
            </Translate>
          </h2>
        </div>
        <Steps
          className="mt-sm-4"
          total={3}
          selected={1}
          key={1}
          labels={[
            translate('placeholders.pre_registration'),
            translate('placeholders.post_registration'),
            translate('placeholders.finish'),
          ]}
        />
        {!props.loading ? (
          <AvForm
            model={postRegistrationDetails}
            onValidSubmit={handleValidSubmit}>
            <Row>
              <Col md="12">
                <h5>
                  <Translate contentKey="form.register.basic_info">
                    basic info
                  </Translate>
                </h5>
                <hr />
              </Col>
              <Col md="6">
                <Label for="contact">
                  <Translate contentKey="form.register.name">
                    name / business name
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  name="businessName"
                  id="businessName"
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_registration.name_error_message',
                      ),
                    },
                  }}
                />
              </Col>
              <Col md="6">
                <Label for="buildingName">
                  <Translate contentKey="form.register.building">
                    building
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  name="buildingName"
                  id="buildingName"
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_registration.building_number_error_message',
                      ),
                    },
                  }}
                />
              </Col>
              <Col md="6">
                <Label for="address">
                  <Translate contentKey="form.register.address">
                    address
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  name="address"
                  id="address"
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_registration.address_error_message',
                      ),
                    },
                  }}
                />
              </Col>
              <Col md="6">
                <Label for="streetName">
                  <Translate contentKey="form.register.street">
                    street
                  </Translate>
                </Label>
                <AvField name="streetName" id="streetName" />
              </Col>
              <Col md="6">
                <Label for="country">
                  <Translate contentKey="form.register.country">
                    country
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  onChange={({ target }) => {
                    props?.fetchStates({
                      countryId: target?.value,
                    });
                  }}
                  type="select"
                  name="countryId"
                  id="countryId"
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_registration.country_error_message',
                      ),
                    },
                  }}>
                  <option value="">Select Country</option>
                  {countries?.map((country, index) => {
                    return (
                      <option key={index} value={country?.id}>
                        {country?.name}
                      </option>
                    );
                  })}
                </AvField>
              </Col>
              <Col md="6">
                <Label for="state">
                  <Translate contentKey="form.register.state">state</Translate>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  onChange={({ target }) => {
                    props?.fetchCities({
                      stateId: target?.value,
                    });
                  }}
                  type="select"
                  name="stateId"
                  id="stateId"
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_registration.state_error_message',
                      ),
                    },
                  }}>
                  <option value="">Select State</option>
                  {states?.map((state, index) => {
                    return (
                      <option key={index} value={state?.id}>
                        {state?.name}
                      </option>
                    );
                  })}
                </AvField>
              </Col>
              <Col md="6">
                <Label for="city">
                  <Translate contentKey="form.register.city">city</Translate>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  type="select"
                  name="cityId"
                  id="cityId"
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_registration.city_error_message',
                      ),
                    },
                  }}>
                  <option value="">Select City</option>
                  {cities?.map((city, index) => {
                    return (
                      <option key={index} value={city?.id}>
                        {city?.name}
                      </option>
                    );
                  })}
                </AvField>
              </Col>
              <Col md="6">
                <Label for="pincode">
                  <Translate contentKey="form.register.pincode">
                    pin code
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  name="pincode"
                  type="pincode"
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_registration.pin_error_message',
                      ),
                    },
                    minLength: {
                      value: `${PIN_CODE_LENGTH}`,
                      errorMessage: translate(
                        'validation_registration.invalid_pin_error_message',
                      ),
                    },
                    maxLength: {
                      value: `${PIN_CODE_LENGTH}`,
                      errorMessage: translate(
                        'validation_registration.invalid_pin_error_message',
                      ),
                    },
                  }}
                />
              </Col>
              <Col md="6">
                <Label for="landMark">
                  <Translate contentKey="form.register.landmark">
                    landmark
                  </Translate>
                </Label>
                <AvField name="landMark" id="landMark" />
              </Col>
              <Col md="12" className="mt-3">
                <h5>
                  <Translate contentKey="form.register.authorized_details">
                    authorized details
                  </Translate>
                </h5>
                <hr />
              </Col>
              <Col md="6">
                <Label for="contactPerson">
                  <Translate contentKey="form.register.person_name">
                    personName
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  name="contactPerson"
                  id="contactPerson"
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_registration.name_error_message',
                      ),
                    },
                  }}
                />
              </Col>
              <Col md="6">
                <Label for="contactDesignation">
                  <Translate contentKey="form.register.designation">
                    designation
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  type="select"
                  name="designationId"
                  id="designationId"
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_registration.designation_error_message',
                      ),
                    },
                  }}>
                  <option value="">
                    {translate('placeholders.select_designation')}
                  </option>
                  {designations?.map((designation, index) => {
                    return (
                      <option key={index} value={designation?.id}>
                        {designation?.name}
                      </option>
                    );
                  })}
                </AvField>
              </Col>
              <Col md="6">
                <Label for="mobile">
                  <Translate contentKey="form.register.mobile">
                    mobile number
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  name="mobileNo"
                  id="mobileNo"
                  type="number"
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_registration.number_error_message',
                      ),
                    },
                    minLength: {
                      value: `${PHONE_NUMBER_LENGTH}`,
                      errorMessage: translate(
                        'validation_registration.invalid_number_error_message',
                      ),
                    },
                    maxLength: {
                      value: `${PHONE_NUMBER_LENGTH}`,
                      errorMessage: translate(
                        'validation_registration.invalid_number_error_message',
                      ),
                    },
                  }}
                />
              </Col>
              <Col md="6">
                <Label for="email">
                  <Translate contentKey="form.register.email">
                    email id
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  name="email"
                  type="email"
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_registration.email_error_message',
                      ),
                    },
                    email: {
                      errorMessage: translate(
                        'validation_registration.invalid_email_error_message',
                      ),
                    },
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-5 mb-sm-3 mt-2 mt-sm-5">
              <Col>
                <Button color="px-5 submit-btn">
                  <Translate contentKey="placeholders.next">next</Translate>
                </Button>
              </Col>
            </Row>
          </AvForm>
        ) : (
          <div className="text-info my-5" style={{ fontSize: 32 }}>
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ authentication, user, master }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  user: user.user,
  loading: user.loading,
  postRegistrationDetails: user.postRegistrationDetails,
  countries: master?.countries,
  states: master?.states,
  cities: master?.cities,
  designations: master.designations,
});

const mapDispatchToProps = {
  getPreRegisteredUser,
  setRegisteredUser,
  updatePostRegistrationState,
  fetchCountries,
  fetchStates,
  fetchCities,
  fetchDesignations,
  resetToDefault,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PostRegistration);
