import axios from 'axios';
import { ICrudGetAction, ICrudPutAction, translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import { resourceLimits } from 'worker_threads';
import {
  API_URL,
  HTTP_FAILURE_STATUS_CODE,
  SERVICE_NOT_PAID,
  TOAST_TIMEOUT,
} from '../config/constants';
import { IVendorUser } from '../model/vendor-users.model';
import { REQUEST, SUCCESS, FAILURE } from '../shared/reducers/action-type.util';
import { cleanEntity } from '../shared/util/entity-utils';
import { ITEMS_PER_PAGE } from '../shared/util/pagination.constants';

export const ACTION_TYPES = {
  FETCH_VENDOR_USER: 'vendor/FETCH_VENDOR_USER',
  UPDATE_VENDOR_USER: 'vendor/UPDATE_VENDOR_USER',
  CREATE_VENDOR_USER: 'vendor/CREATE_VENDOR_USER',
  FETCH_VENDOR_USER_LIST: 'vendor/FETCH_VENDOR_USER_LIST',
  DEACTIVATE_VENDOR_USER: 'vendor/DEACTIVATE_VENDOR_USER',
  RESET: 'vendor/RESET',
};

const initialState = {
  loading: false,
  vendorUser: {} as any,
  updateVendorUserResponse: {} as any,
  createVendorUserResponse: {} as any,
  vendorUserList: [] as ReadonlyArray<IVendorUser>,
  updating: false,
  updateSuccess: false,
  deactivateSuccess: false,
  totalCount: 0,
};

export type VendorState = Readonly<typeof initialState>;

export const vendorUserReducer = (
  state: VendorState = initialState,
  action,
): VendorState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_VENDOR_USER):
    case REQUEST(ACTION_TYPES.FETCH_VENDOR_USER_LIST):
    case REQUEST(ACTION_TYPES.DEACTIVATE_VENDOR_USER): {
      return {
        ...state,
        loading: true,
      };
    }

    case REQUEST(ACTION_TYPES.UPDATE_VENDOR_USER):
    case REQUEST(ACTION_TYPES.CREATE_VENDOR_USER): {
      return {
        ...state,
        updating: true,
      };
    }

    case SUCCESS(ACTION_TYPES.FETCH_VENDOR_USER): {
      return {
        ...state,
        vendorUser: action.payload.data,
        updateSuccess: false,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.UPDATE_VENDOR_USER): {
      toast.success(translate('form.create_vendor_user.user_updated_msg'));
      return {
        ...state,
        updateVendorUserResponse: action.payload.data,
        updateSuccess: true,
        updating: false,
      };
    }
    case SUCCESS(ACTION_TYPES.CREATE_VENDOR_USER): {
      toast.success(translate('form.create_vendor_user.user_created_msg'));
      return {
        ...state,
        createVendorUserResponse: action.payload.data,
        updateSuccess: true,
        updating: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_VENDOR_USER_LIST): {
      return {
        ...state,
        vendorUserList: action.payload.data,
        updateSuccess: false,
        totalCount: action.payload.headers['x-total-count'],
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.DEACTIVATE_VENDOR_USER): {
      toast.success(translate('form.create_vendor_user.user_deactivated_msg'));
      return {
        ...state,
        updateSuccess: true,
        loading: false,
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case FAILURE(ACTION_TYPES.FETCH_VENDOR_USER): {
      toast.error(
        action.payload?.response?.data?.errorKey ??
          translate('error.email_exists'),
      );
      return {
        ...state,
        updateSuccess: false,
        loading: false,
      };
    }
    case FAILURE(ACTION_TYPES.UPDATE_VENDOR_USER): {
      if (action.payload?.response?.data?.errorKey === 'email-already-exists') {
        toast.error(translate('error.email_exists'));
      } else {
        toast.error(translate('error.something_went_wrong'));
      }
      return {
        ...state,
        updating: false,
        updateVendorUserResponse: action.payload.data,
      };
    }

    case FAILURE(ACTION_TYPES.FETCH_VENDOR_USER_LIST): {
      return {
        ...state,
        loading: false,
        updateSuccess: false,
      };
    }

    case FAILURE(ACTION_TYPES.DEACTIVATE_VENDOR_USER): {
      toast.error(
        translate('form.create_vendor_user.user_deactivated_failure_msg'),
      );
      return {
        ...state,
        loading: false,
        updateSuccess: false,
      };
    }

    case FAILURE(ACTION_TYPES.CREATE_VENDOR_USER): {
      if (action.payload?.response?.data?.errorKey === 'emailexists') {
        toast.error(translate('error.email_exists'));
      } else if (
        action.payload?.response?.data?.errorKey === 'maximumlimitreached'
      ) {
        toast.error(translate('error.user_limit_reached'));
      } else if (
        action.payload?.response?.data?.status === HTTP_FAILURE_STATUS_CODE &&
        action.payload?.response?.data?.errorKey === SERVICE_NOT_PAID
      ) {
        toast.error(translate('entity.validation.pay_before_adding_users'),{autoClose:TOAST_TIMEOUT});
      } else {
        toast.error(translate('error.something_went_wrong'));
      }
      return {
        ...state,
        updating: false,
        createVendorUserResponse: action.payload.data,
      };
    }
    default:
      return state;
  }
};

export const getVendorUserList = ({ page, size }: any) => ({
  type: ACTION_TYPES.FETCH_VENDOR_USER_LIST,
  payload: axios.get<IVendorUser>(
    `${API_URL}/v1/vendor/users?page=${page ?? 0}&size=${size ?? ITEMS_PER_PAGE}`,
  ),
});

export const fetchVendorUser: any = id => {
  const requestUrl = `${API_URL}/v1/vendor/users/${id}`;
  return {
    type: ACTION_TYPES.FETCH_VENDOR_USER,
    payload: axios.get(requestUrl),
  };
};

export const updateVendorUser: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_VENDOR_USER,
    payload: axios.put(`${API_URL}/v1/vendor/users`, cleanEntity(entity)),
  });
  return result;
};

export const createVendorUser: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_VENDOR_USER,
    payload: axios.post(`${API_URL}/v1/vendor/users`, cleanEntity(entity)),
  });
  dispatch(getVendorUserList({}));
  return result;
};

export const deactivateVendorUser: any = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.DEACTIVATE_VENDOR_USER,
    payload: axios.delete(`${API_URL}/v1/vendor-users/${id}`),
  });
  dispatch(getVendorUserList({}));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export default vendorUserReducer;
