import { translate, Translate } from 'react-jhipster';
import { Col } from 'reactstrap';
import { AppLogo } from '../../login/components/app-logo'
import leftBgImg from '../../../assets/images/left-container-bg.png';

export const DefaultLeftContent = () => {
  return (
    <Col className="p-0 left-content-container" lg={4}>
      <Col className="d-flex flex-column h-100 justify-content-center align-items-center">
            <div className="logo-container">
              <AppLogo />
            </div>
        <div>
          <p className="m-0 text-light text-center vendor-registration-text">
            <b>
              <Translate contentKey="pages.vendor_registration">
                Vendor Registration
              </Translate>
            </b>
          </p>
          <p className="header-bottom-text m-0">
            {translate(
              'placeholders.registration_will_take_minutes_description',
            )}
          </p>
        </div>
      </Col>
      <Col>
        <img width="100%" height="100%" src={leftBgImg} alt=""></img>
      </Col>
    </Col>
  );
};
