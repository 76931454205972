import { connect } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import {
  getPreRegisteredUser,
  postRegister,
  setRegisteredUser,
  updatePostRegistrationState,
  createSubscriptionOrder,
  updatePaymentStatus,
  updatePaymentFailureStatus,
  resetSubscriptionOrderResponse,
} from '../../reducers/user.reducer';
import { IRootState } from '../../shared/reducers';
import { AvForm } from 'availity-reactstrap-validation';
import { getPlans } from '../../reducers/plan.reducer';
import './style.scss';
import { useEffect, useState } from 'react';
import { Translate } from 'react-jhipster';
import {
  HTTP_CREATED_RESPONSE,
  PAYMENT_AMOUNT_MULTIPLIER,
  PAYMENT_CURRENCY,
  PAYMENT_DESCRIPTION,
  PAYMENT_IMAGE,
  PAYMENT_METHOD,
  PAYMENT_NAME,
  RAZORPAY_PAYMENT_TEST_KEY,
  VENDOR_SUBSCRIPTION_RENEWAL,
} from '../../config/constants';
import Button from '../components/button';
import { AppLogo } from '../login/components/app-logo';
import leftBgImg from '../../assets/images/left-container-bg.png';
import { getVendorProfile } from '../../reducers/user.reducer';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export interface IPaymentValues {
  paymentPeriodId: number;
  servicePlanId: number;
  servicesId: number;
  hasPharma: boolean;
  noOfBookingsPerSlot: number;
  startDate: string;
  endDate: string;
  netAmount: number;
  paymentPeriod: String;
  paymentPeriodMonths: number;
  renewalRemainingDays: number;
}

declare global {
  interface Window {
    Razorpay: any;
  }
}

export const ConfirmRenewal = (props: IPageProps) => {
  const { postRegistrationDetails, vendorProfile } = props;

  const location = useLocation<IPaymentValues>();
  const [startDate, setStartDate] = useState('' as any);
  const [endDate, setEndDate] = useState('' as any);
  const [open, setOpen] = useState<boolean>(false);

  const {
    paymentPeriodId,
    servicePlanId,
    servicesId,
    hasPharma,
    noOfBookingsPerSlot,
    paymentPeriod,
    paymentPeriodMonths,
    renewalRemainingDays,
  } = location.state;

  const onPressMakePayment = () => {
    props.resetSubscriptionOrderResponse();

    const renewalServiceDetails = {
      serviceId: servicesId,
      servicePlanId: servicePlanId,
      paymentPeriodId: paymentPeriodId,
      slots: noOfBookingsPerSlot,
      isPharmaRequired: hasPharma,
      type: VENDOR_SUBSCRIPTION_RENEWAL,
    };
    props.createSubscriptionOrder(renewalServiceDetails);
  };

  useEffect(() => {
    props.resetSubscriptionOrderResponse();
    props.getVendorProfile({});

    let date = new Date();

    if (renewalRemainingDays == 0)
      date.setDate(date.getDate() + renewalRemainingDays + 1);

    if (renewalRemainingDays > 0)
      date.setDate(date.getDate() + renewalRemainingDays + 2);

    setStartDate(date.toLocaleDateString().replaceAll('/', '-'));

    if (paymentPeriodMonths) {
      date.setMonth(date.getMonth() + paymentPeriodMonths);
      setEndDate(date.toLocaleDateString().replaceAll('/', '-'));
    }
  }, []);

  useEffect(()=>{
    if(open){
      window.location.reload();
    }
  },[open])

  useEffect(() => {
    if (
      props.subscriptionOrderResponse?.status === HTTP_CREATED_RESPONSE &&
      vendorProfile
    ) {
      const subscriptionOrder = props.subscriptionOrderResponse.data;
      var options = {
        key: RAZORPAY_PAYMENT_TEST_KEY,
        amount: subscriptionOrder.amount * PAYMENT_AMOUNT_MULTIPLIER,
        currency: PAYMENT_CURRENCY,
        name: PAYMENT_NAME,
        description: PAYMENT_DESCRIPTION,
        image: PAYMENT_IMAGE,
        order_id: subscriptionOrder.orderId,
        handler: function (response) {
          const data = {
            paymentId: response.razorpay_payment_id,
            orderId: response.razorpay_order_id,
            signature: response.razorpay_signature,
            type: VENDOR_SUBSCRIPTION_RENEWAL,
          };
          if (data) {
            pay.close();
            props.updatePaymentStatus(data);
            props.history.push({
              pathname: '/renew-success',
              state: { isRenewalPaymentSuccessPage: true },
            });
          }
        },
        prefill: {
          name: vendorProfile.name,
          email: vendorProfile.email,
          contact: vendorProfile.mobile,
        },
        config: {
          display: {
            hide: [
              {
                method: PAYMENT_METHOD.EMI,
              },
              {
                method: PAYMENT_METHOD.WALLET,
              },
              {
                method: PAYMENT_METHOD.PAYLATER,
              },
            ],
            preferences: {
              show_default_blocks: true,
            },
          },
        },
        notes: {
          address: 'Razorpay Corporate office',
        },
        theme: {
          color: '#61dafb',
        },
      };
      var pay = new window.Razorpay(options);
      pay.on('payment.failed', function (response) {
        const data = {
          paymentId: response.error.metadata.payment_id,
          orderId: subscriptionOrder.orderId,
          code: response.error.code,
          description: response.error.description,
          source: response.error.source,
          step: response.error.step,
          reason: response.error.reason,
          type: VENDOR_SUBSCRIPTION_RENEWAL,
        };
        if (data != null) {
          setOpen(true);
          pay.close();
          props.updatePaymentFailureStatus(data);
        }
      });
      pay.open();
    }
  }, [props?.subscriptionOrderResponse]);

  return (
    <Row className="w-100 mx-0" style={{ height: '100vh' }}>
      <Col xs="12" md="4" className="px-0 h-100 sm-h-20">
        <div className="left-container">
          <div className="d-flex flex-column h-100 justify-content-center align-items-center">
            <div className="logo-container">
              <AppLogo />
            </div>
            <h2 className="text-white">
              <Translate contentKey="pages.confirmation">
                confirmation
              </Translate>
            </h2>
          </div>
          <img src={leftBgImg} alt="left-bg"></img>
        </div>
      </Col>

      <Col xs="12" md="8" className="right-container">
        <Col className="added-services-container">
          <div className="m-4">
            <Card key={1} className="mt-3 mb-3 pt-3 pb-3 selected-service-card">
              <CardBody>
                <Col md={12}>
                  <Row>
                    <Col md={6}>
                      <p className="text-dark service-name m-0 ">
                        {'Online Video Consultation'}
                      </p>
                    </Col>
                    <Col>
                      <p className="text-dark plan-added-amount m-0 px-5 inter">
                        ₹{location.state?.netAmount}
                      </p>
                    </Col>
                    <Col></Col>
                  </Row>
                </Col>
              </CardBody>
            </Card>
          </div>
        </Col>

        <Col className="services-container" mb-0>
          <div className="summary-info">
            <div
              className="item renew_item"
              style={{ paddingLeft: '30%', paddingRight: '30%' }}>
              <h6>
                <Translate contentKey="form.service.subscription.start_date">
                  Start Date
                </Translate>
              </h6>
              <h6 className="inter"> {startDate} </h6>
            </div>

            <div
              className="item renew_item"
              style={{ paddingLeft: '30%', paddingRight: '30%' }}>
              <h6>
                <Translate contentKey="form.service.subscription.end_date">
                  End Date
                </Translate>
              </h6>
              <h6 className="inter"> {endDate} </h6>
            </div>
          </div>
        </Col>
        <AvForm model={{}}>
          <Row
            className="services-container "
            md="8"
            style={{ left: 0, marginTop: '15%', height: '400px' }}>
            <div
              className="summary-info "
              style={{ position: 'relative', left: 0, padding: '10%' }}>
              <div className="item renew_item">
                <h6>
                  <Translate contentKey="placeholders.subscription_rate">
                    Subscription rate
                  </Translate>
                </h6>
                <h6 className="inter"> ₹ {location.state.netAmount} </h6>
              </div>
              <div className="item renew_item">
                <h6>
                  <Translate contentKey="placeholders.total">total</Translate>
                </h6>
                <h6 className="inter"> ₹ {location.state.netAmount} </h6>
              </div>
              <div className="btn-renew-confirm">
                <Button
                  onClick={onPressMakePayment}
                  color="primary"
                  className="confirm w-10 py-3 m5 px-4">
                  <Translate contentKey="placeholders.confirmPayment">
                    Confirm and make the payment
                  </Translate>
                </Button>
              </div>
            </div>
          </Row>
        </AvForm>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ authentication, user, plan }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  userMenus: user.menus,
  user: user.user,
  loading: user.loading,
  plans: plan.plans,
  postRegistrationDetails: user.postRegistrationDetails,
  postRegisterResponse: user.postRegisterResponse,
  subscriptionOrderResponse: user.subscriptionOrderResponse,
  vendorProfile: user.getVendorProfileResponse,
});

const mapDispatchToProps = {
  getPreRegisteredUser,
  setRegisteredUser,
  getPlans,
  updatePostRegistrationState,
  postRegister,
  createSubscriptionOrder,
  updatePaymentStatus,
  updatePaymentFailureStatus,
  resetSubscriptionOrderResponse,
  getVendorProfile,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmRenewal);
