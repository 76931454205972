import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  PopoverBody,
  Row,
  Table,
  UncontrolledPopover,
} from 'reactstrap';
import { IPlan, ISubscription } from '../../model/plans.model';
import { getPharmacyCharge, getPlans } from '../../reducers/plan.reducer';
import {
  fetchServicePackages,
  fetchServicePaymentPeriods,
  fetchServiceSlots,
  getPreRegisteredUser,
  postRegister,
  setRegisteredUser,
  updatePostRegistrationState,
} from '../../reducers/user.reducer';
import { IRootState } from '../../shared/reducers';
import { useWindowDimensions } from '../../shared/util/utility-functions';
import Steps from '../components/steps';
import './style.scss';
import { roundedCheckIcon } from './components/rounded-check-icon';
import { deleteIcon } from './components/delete-icon';
import { modalCloseIcon } from './components/close-icon';
import { InfoIcon } from './components/info-icon';
import { DefaultLeftContent } from './components/default-left-content';
import Button from '../components/button';
import { DISCOUNT_PERCENTAGE_BASE } from '../../config/constants';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}
const PERCENT_ICON = 'percent';

export const ServiceSubscription = (props: IPageProps) => {
  const {
    plans,
    servicePackages,
    serviceSlots,
    servicePaymentPeriods,
    postRegistrationDetails,
    pharmacyCharge,
  } = props;

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [planPopup, setPlanPopup] = useState({} as IPlan);
  const [selectedPlan, setSelectedPlan] = useState({} as any);
  const [servicePackageList, setServicePackageList] = useState([] as any);
  const [serviceSlotList, setServiceSlotList] = useState([] as any);
  const [servicePaymentPeriodList, setServicePaymentPeriodList] = useState(
    [] as any,
  );
  const [registerLoading, setRegisterLoading] = useState(false as boolean);

  const [slabToMaximumValue, setSlabToMaximumValue] = useState(0 as number);
  const [slots, setSlots] = useState([] as any);
  const rightContentWidth = useRef(null as any);

  const totalAmount = postRegistrationDetails?.subscriptions?.reduce(
    (sum, obj) => sum + (obj?.total ? obj.total : 0),
    0,
  );

  const discountAmount = totalAmount
    ? Math.round(
        (totalAmount / DISCOUNT_PERCENTAGE_BASE) *
          postRegistrationDetails?.onboardingDiscountPercentage,
      )
    : 0;

  const netTotal =
    totalAmount === 0 ? 0 : (totalAmount ?? 0) - (discountAmount ?? 0);

  useEffect(() => {
    if (Array.isArray(plans) && plans.length === 1) {
      handlePlanClick(plans[0]);
    }
  }, [plans]);

  //On Click Plan Box
  const handlePlanClick = (planDetail: any) => {
    let index = plans.indexOf(planDetail);
    const id = plans[index]?.id;
    props.fetchServiceSlots({ serviceId: plans[index].id });
    props.fetchServicePackages({ serviceId: plans[index].id });
    props.fetchServicePaymentPeriods({ serviceId: plans[index].id });
    const plan = postRegistrationDetails.subscriptions?.find(p => p?.id === id);
    setSelectedPlan(plan);
    setPlanValues({
      ...planValues,
      serviceId: id,
      isPharmacyRequired:
        plan?.isPharmacyRequired !== undefined
          ? plan?.isPharmacyRequired
          : false,
    });
    setPlanPopup(plans[index]);
    setModal(true);
  };

  useEffect(() => {
    props.getPharmacyCharge();
  }, [selectedPlan?.isPharmacyRequired]);

  //On Update Subscription from modal
  const handleAddService = plan => {
    let total = getPlanTotalAmount();
    const index =
      postRegistrationDetails.subscriptions?.findIndex(p => p.id === plan.id) ??
      -1;
    let subscriptionToAdd = {
      id: plan?.id,
      serviceId: planValues?.serviceId,
      packageId: parseInt(planValues?.packageId?.toString() ?? '0'),
      paymentPeriodId: parseInt(planValues?.paymentPeriodId?.toString() ?? '0'),
      isPharmacyRequired: planValues?.isPharmacyRequired,
      bookingPerSlot: parseInt(planValues?.bookingPerSlot?.toString() ?? '0'),
      total,
      name: plan?.name,
    };
    if (index > -1) {
      postRegistrationDetails.subscriptions?.splice(
        index,
        1,
        subscriptionToAdd,
      );
    } else {
      postRegistrationDetails.subscriptions?.push(subscriptionToAdd);
    }
    props.updatePostRegistrationState(postRegistrationDetails);
    setModal(false);
  };

  //On change Drop Down from modal
  const handleFormEdit = (key, obj) => {
    const values = {
      ...planValues,
      [key]: obj,
    };

    setPlanValues(values);
  };

  useEffect(() => {
    props.getPlans();
  }, []);

  const checkIfSelected = id => {
    const index =
      postRegistrationDetails.subscriptions?.findIndex(p => p?.id === id) ?? -1;
    if (index > -1 && postRegistrationDetails.subscriptions) {
      return `active ${
        !postRegistrationDetails.subscriptions[index]?.total ? 'unset' : ''
      }`;
    } else return '';
  };

  const [planValues, setPlanValues] = useState({
    isPharmacyRequired: false,
    packageId: 0,
    paymentPeriodId: 0,
    bookingPerSlot: 0,
    serviceId: 0,
  } as ISubscription);

  const removePlan = plan => {
    let filteredSubscriptions = postRegistrationDetails?.subscriptions?.filter(
      (x, i) => x.id !== plan?.id,
    );
    postRegistrationDetails.subscriptions = filteredSubscriptions;
    props.updatePostRegistrationState(postRegistrationDetails);
  };

  const handleNextClick = () => {
    if (
      postRegistrationDetails.subscriptions &&
      postRegistrationDetails.subscriptions.length > 0
    ) {
      props.history.push('/confirm-registration');
    } else {
      toast.error(translate('form.validation.min_plan'));
    }
  };

  useEffect(() => {
    if (Array.isArray(servicePackages)) {
      setServicePackageList(servicePackages);
      setPlanValues({
        ...planValues,
        packageId:
          (selectedPlan?.packageId > 0 ? selectedPlan?.packageId : null) ??
          (servicePackages.length > 0 ? servicePackages[0]?.id : 0),
      });
    }
  }, [servicePackages]);

  useEffect(() => {
    if (Array.isArray(servicePaymentPeriods)) {
      setServicePaymentPeriodList(servicePaymentPeriods);
      setPlanValues({
        ...planValues,
        paymentPeriodId:
          (selectedPlan?.paymentPeriodId > 0
            ? selectedPlan?.paymentPeriodId
            : null) ??
          (servicePaymentPeriods.length > 0 ? servicePaymentPeriods[0]?.id : 0),
      });
    }
  }, [servicePaymentPeriods]);

  const slotMaximumValue = service => {
    if (service.length) {
      setSlabToMaximumValue(
        Math.max.apply(
          Math,
          service.map(obj => {
            return obj.slabTo;
          }),
        ),
      );
    }
  };

  useEffect(() => {
    setSlots(
      Array.from(new Array(slabToMaximumValue), (value, index) => index),
    );
  }, [slabToMaximumValue]);
  const defaultBookingSlot = 1;
  useEffect(() => {
    if (Array.isArray(serviceSlots)) {
      setServiceSlotList(serviceSlots);
      slotMaximumValue(serviceSlots);
      setPlanValues({
        ...planValues,
        bookingPerSlot:
          (selectedPlan?.bookingPerSlot > 0
            ? selectedPlan?.bookingPerSlot
            : null) ?? (serviceSlots.length > 0 ? defaultBookingSlot : 0),
      });
    }
  }, [serviceSlots]);

  const checkPlanValues = () => {
    let isValid = false;
    if (
      planValues?.packageId &&
      planValues?.paymentPeriodId &&
      planValues?.bookingPerSlot &&
      planValues?.serviceId
    ) {
      isValid = true;
    }
    return isValid;
  };

  const getPlanTotalAmount = () => {
    let total = 0;
    const selectedPackage = servicePackageList.filter(
      x => x.id === planValues?.packageId,
    )[0];
    const selectedPaymentPeriod = servicePaymentPeriodList.filter(
      x =>
        x.id ===
        parseInt(
          planValues?.paymentPeriodId
            ? planValues?.paymentPeriodId.toString()
            : '0',
        ),
    )[0];
    const selectedBookingPerSlot = serviceSlotList.filter(
      x =>
        x.slabFrom <=
          parseInt(
            planValues?.bookingPerSlot
              ? planValues?.bookingPerSlot?.toString()
              : '0',
          ) &&
        x.slabTo >=
          parseInt(
            planValues?.bookingPerSlot
              ? planValues?.bookingPerSlot?.toString()
              : '0',
          ),
    )[0];
    if (selectedPackage && selectedPaymentPeriod && selectedBookingPerSlot) {
      total =
        (selectedPaymentPeriod?.noOfMonths ?? 0) *
        (selectedPackage?.amount +
          selectedBookingPerSlot?.amount +
          (planValues?.isPharmacyRequired
            ? parseFloat(pharmacyCharge.additionalCharges)
            : 0));
    }

    return total;
  };

  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const renderBookingPerSlotInfo = () => {
    return (
      <UncontrolledPopover
        trigger="focus"
        placement="right-start"
        target="bookingPerSlotInfo">
        <PopoverBody className="p-0">
          <Table bordered className="mb-0 border-none">
            <thead>
              <tr>
                <td className="text-start">
                  <Translate contentKey="placeholders.booking_per_slots">
                    No of booking per slots
                  </Translate>
                </td>
                {serviceSlotList?.map((bc, i) => (
                  <td key={i}>
                    {bc?.slabFrom === bc?.slabTo
                      ? `${bc?.slabFrom}`
                      : `${bc?.slabFrom} - ${bc?.slabTo}`}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-start" scope="row">
                  <Translate contentKey="placeholders.additional_charges">
                    Additional Charges
                  </Translate>
                </td>
                {serviceSlotList?.map((bc, i) => (
                  <td key={i}> {bc?.amount} </td>
                ))}
              </tr>
            </tbody>
          </Table>
        </PopoverBody>
      </UncontrolledPopover>
    );
  };

  const renderPaymentPeriodInfo = () => {
    return (
      <UncontrolledPopover
        trigger="focus"
        placement="right-start"
        target="paymentPeriodInfo">
        <PopoverBody className="p-0">
          <Table bordered className="mb-0 border-none">
            <thead>
              <tr>
                <td className="text-start package-text">
                  <Translate contentKey="placeholders.payment_period">
                    Payment Period
                  </Translate>
                </td>
                {servicePaymentPeriods?.map((paymentPeriod, i) => (
                  <td key={i}> {paymentPeriod?.name} </td>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-start" scope="row">
                  <Translate contentKey="placeholders.additional_period">
                    Additional Period
                  </Translate>
                </td>
                {servicePaymentPeriods?.map((paymentPeriod, i) => (
                  <td key={i}>
                    {paymentPeriod?.offerDays
                      ? `${paymentPeriod?.offerDays} ${translate(
                          'placeholders.days',
                        )}`
                      : translate('placeholders.nill_text')}{' '}
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
        </PopoverBody>
      </UncontrolledPopover>
    );
  };

  const renderAmountDetail = () => {
    return (
      <Col className="rate-details pb-3">
        <div className="footer">
          <div className="entry p-4 px-5 pb-0">
            <p className="m-0 checkout-rate-label">
              <Translate contentKey="placeholders.subscription_rate">
                Subscription rate
              </Translate>
            </p>
            <h6 className="inter">₹ {totalAmount}</h6>
          </div>
          <div className="entry p-4 px-5 ">
            <p className="m-0 checkout-rate-label inter">
              <Translate contentKey="placeholders.discount">Discount</Translate>
              &nbsp; ({postRegistrationDetails?.onboardingDiscountPercentage}
              %)
            </p>
            <h6 className="inter">
              ₹ {isNaN(discountAmount) ? 0 : discountAmount}
            </h6>
          </div>
          <div className="entry total p-4 px-5 ">
            <p className="m-0 checkout-rate-label inter">
              <Translate contentKey="placeholders.total">total</Translate>
            </p>
            <h6 className="inter">₹ {isNaN(netTotal) ? 0 : netTotal} </h6>
          </div>
          <div className="p-4 px-5  checkout-button-container">
            <Button
              className="p-3 w-100 px-3"
              color="danger"
              onClick={handleNextClick}>
              <Translate contentKey="placeholders.checkout">Checkout</Translate>
            </Button>
          </div>
        </div>
      </Col>
    );
  };

  const renderLeftContent = () => {
    return (
      <Col className="p-0 left-content-container" lg={4}>
        <Col className="m-4 mb-0 flex-grow-0 text-light">
          <p className="select-service-label m-0 mt-3 mb-3">
            <b>
              <Translate contentKey="placeholders.selected_services">
                Selected services
              </Translate>
            </b>
          </p>
          <p className="m-0 text-light services-selected-text">
            {postRegistrationDetails?.subscriptions?.length}{' '}
            <Translate contentKey="placeholders.services_selected">
              services selected
            </Translate>
          </p>
        </Col>

        {/* Added Services */}
        <Col className="added-services-container">
          <div className="m-4">
            {postRegistrationDetails?.subscriptions?.map((plan, index) => (
              <Card
                key={index}
                className="mt-3 mb-3 pt-3 pb-3 selected-service-card">
                <CardBody>
                  <Col md={12}>
                    <Row>
                      <Col md={9}>
                        <p className="text-dark service-name m-0">
                          {plan?.name}
                        </p>
                      </Col>
                      <Col>
                        <p className="text-dark plan-added-amount m-0 inter">
                          ₹{plan?.total}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </CardBody>
              </Card>
            ))}
          </div>
        </Col>
        {renderAmountDetail()}
      </Col>
    );
  };

  const renderRightContent = () => {
    return (
      <div
        ref={el => {
          if (!el) return;  
          rightContentWidth.current = el.getBoundingClientRect().width - 5;
        }}
        className="right-content-container col-lg-8">
        <div className="m-5">
          <p className="select-service-label m-0 mt-3 mb-3">
            <b>
              <Translate contentKey="placeholders.select_services">
                Select services required
              </Translate>
            </b>
          </p>
          <div></div>
          <Steps
            total={3}
            selected={2}
            key={2}
            labels={[
              translate('placeholders.pre_registration'),
              translate('placeholders.post_registration'),
              translate('placeholders.finish'),
            ]}
          />
          {/* Existing Plans */}
          <Col className="service-list-col">
            <Row className="services-container">
              {plans.map((plan, index) => (
                <Col
                  className="d-flex justify-content-center"
                  lg="6"
                  key={plan.id}>
                  <Card
                    id="service-card"
                    className={`${checkIfSelected(plan?.id)} service-card`}
                    onClick={() => handlePlanClick(plan)}>
                    <h5>
                      {roundedCheckIcon({
                        selected: checkIfSelected(plan.id),
                      })}
                    </h5>
                    <img src={plan.iconUrl} alt="image" />
                    <p className="m-0 name"> {plan.name} </p>
                    <p className="m-0 description"> {plan.serviceType} </p>
                    <p className="m-0 description">{plan.serviceDescription}</p>
                    {checkIfSelected(plan.id).includes('unset') && (
                      <p className="text-danger mb-0 small">
                        <Translate contentKey="form.validation.plan_details">
                          Please click here to select plan details !
                        </Translate>
                      </p>
                    )}
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </div>
      </div>
    );
  };

  const renderAddServiceModal = () => {
    return (
      <Modal
        id="plan-modal"
        style={{ minWidth: rightContentWidth.current }}
        isOpen={modal}
        size="lg"
        className="plan-modal"
        zIndex="1500"
        toggle={toggle}>
        <div className="modal-header p-4 pb-0">
          <span className="ms-3 select-subscription-plan">
            <Translate contentKey="placeholders.select_subscription">
              Select subscription plan
            </Translate>
          </span>
          {modalCloseIcon({ onClick: toggle })}
        </div>
        <ModalBody>
          <Row className="plans px-4">
            {servicePackageList?.map(subscription => (
              <Col className="m-2 mx-0" lg="4" key={subscription.id}>
                <Card
                  className={
                    planValues.packageId === subscription.id ? 'active' : ''
                  }
                  onClick={() => handleFormEdit('packageId', subscription.id)}>
                  <CardBody>
                    <Row className="m-0">
                      <Col md={6} className="d-flex align-items-center">
                        <p className="m-0 package-text">{subscription.name}</p>
                      </Col>
                      <Col
                        md={6}
                        className="d-flex align-items-center justify-content-end">
                        <p className="m-0 package-text inter">
                          &nbsp;₹{subscription.amount}
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row className="px-4 pr-0">
            <hr />
          </Row>
          <Row className="bookings-selection px-4">
            <Col lg="4">
              <p className="m-0 label">
                <Translate contentKey="placeholders.booking_per_slot">
                  No of booking per slot
                </Translate>
                &nbsp;
                <InfoIcon id="bookingPerSlotInfo" />
                {renderBookingPerSlotInfo()}
              </p>
              <div className="form-group">
                <select
                  value={planValues.bookingPerSlot}
                  className="form-control mb-2"
                  onChange={e =>
                    handleFormEdit('bookingPerSlot', e.target.value)
                  }>
                  {slots?.map(index => (
                    <option key={index} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          </Row>
          <Row className="payment-selection px-4">
            <Col lg="4">
              <p className="m-0 label">
                <Translate contentKey="placeholders.payment_period">
                  Payment period
                </Translate>
                &nbsp;
                <InfoIcon id="paymentPeriodInfo" />
                {renderPaymentPeriodInfo()}
              </p>
              <div className="form-group">
                <select
                  value={planValues.paymentPeriodId}
                  className="form-control"
                  onChange={e =>
                    handleFormEdit('paymentPeriodId', e.target.value)
                  }>
                  {servicePaymentPeriodList?.map((period, i) => (
                    <option key={i} value={period?.id}>
                      {period?.name}
                    </option>
                  ))}
                </select>
              </div>
            </Col>

            {planPopup?.isPharmaRequired ? (
              <Col className="p-0 px-5">
                <p
                  className="m-0 mt-4 pharmacy-services-required-text"
                  onClick={() =>
                    handleFormEdit(
                      'isPharmacyRequired',
                      !planValues.isPharmacyRequired,
                    )
                  }>
                  <FontAwesomeIcon
                    size="lg"
                    icon={
                      planValues.isPharmacyRequired ? faCheckSquare : faSquare
                    }
                  />
                  &nbsp; &nbsp;
                  <Translate contentKey="placeholders.pharmacy_service_required">
                    Pharmacy service required
                  </Translate>
                </p>
                <p className="m-0 pharmacy-services-required-text">
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  <span className="label additional-charge-text inter">
                    {`${translate(`placeholders.additional_charges`)} ₹${
                      pharmacyCharge.additionalCharges
                    }`}
                  </span>
                </p>
              </Col>
            ) : null}
          </Row>
          <Row className="px-4">
            <Col>
              <p className="m-0">
                <span className="label">
                  {translate('placeholders.total_amount')}
                </span>
                &nbsp; &nbsp; &nbsp;
                <span className="total-amount inter">
                  ₹ {getPlanTotalAmount()}
                </span>
              </p>
            </Col>
          </Row>
          <Row className="px-4">
            <Col>
              <Button
                disabled={!checkPlanValues()}
                color="danger"
                className="px-4"
                onClick={() => handleAddService(planPopup)}>
                <span className="text-light label">
                  <Translate contentKey="placeholders.update">Update</Translate>
                </span>
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <div>
      <Container id="service-subscription" fluid className="p-0">
        <Row>
          {postRegistrationDetails?.subscriptions?.length ? (
            renderLeftContent()
          ) : (
            <DefaultLeftContent />
          )}
          {renderRightContent()}
        </Row>
        {renderAddServiceModal()}
      </Container>
    </div>
  );
};

const mapStateToProps = ({ authentication, user, plan }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  user: user.user,
  loading: user.loading,
  plans: plan.plans,
  servicePackages: user?.servicePackages,
  serviceSlots: user?.serviceSlots,
  servicePaymentPeriods: user?.servicePaymentPeriods,
  postRegistrationDetails: user.postRegistrationDetails,
  postRegisterResponse: user.postRegisterResponse,
  pharmacyCharge: plan?.pharmacyCharge,
});

const mapDispatchToProps = {
  getPlans,
  getPharmacyCharge,
  setRegisteredUser,
  getPreRegisteredUser,
  fetchServiceSlots,
  fetchServicePackages,
  fetchServicePaymentPeriods,
  updatePostRegistrationState,
  postRegister,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceSubscription);
