import TimePicker from 'rc-time-picker';
import React, { useEffect, useState } from 'react';
import { DATE_TIME_FORMATS, TIME_TYPES } from '../../config/constants';

export const TimePickerWidget = props => {
  const { TWENTY_FOUR_HH_MM, TWELVE_HH_MM_A } = DATE_TIME_FORMATS;

  const [timeStore, setTimeStore] = useState(null as any);
  const [time, setTime] = useState(props.time);
  const initialTime = props.time;

  useEffect(() => {
    stateTimeSet(initialTime);
  }, [props.selectedDay]);

  const stateTimeSet = initialTime => {
    setTime(initialTime);
  };

  return (
    <TimePicker
      use12Hours
      key={props.key}
      showSecond={false}
      format={TWELVE_HH_MM_A}
      onClose={() => {
        if (timeStore) {
          props.onChangeTime(timeStore);
        }
      }}
      onChange={e => {
        props.resetError();
        setTime(null);
        setTime(e);
        setTimeStore({
          time: e?.format(TWENTY_FOUR_HH_MM),
          selectedDay: props.selectedDay,
          hour: props.hour,
          timeType: props.timeType,
          isNew: props.isNew,
          index: props.index,
        });
      }}
      minuteStep={1}
      className="form-control input-height "
      value={props.error ? initialTime : time}
    />
  );
};
