import { useCallback, useEffect, useState } from "react";
import "./side-drawer.scss";
import { IRootState } from "../../../shared/reducers";
import { getSession } from "../../../shared/reducers/authentication";
import { getMenus } from "../../../reducers/user.reducer";
import { connect } from "react-redux";
import { DropDown } from "./drop-down/drop-down";
import { hasAnyAuthority } from "../../../shared/auth/private-route";
import { AUTHORITIES } from "../../../config/constants";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouteComponentProps, useHistory } from "react-router";
import { getPermittedMenus } from '../../../shared/util/permission-utils';

export interface ISideDrawerProps extends StateProps, DispatchProps {
  sideDrawerOpen: boolean;
  drawerToggleClickHandler: any;
  isExpandable?: boolean;
  isLink?: boolean
}

export const SideDrawer = (props: ISideDrawerProps) => {
  const history = useHistory();
  const [currentMenu, setCurrentMenu] = useState({} as any);
  
  useEffect(() => {
    props.getMenus();
  }, []);

  const { userMenus, isExpandable } = props;

  const isRouteActive = ({ defaultLink }) => {
    return defaultLink !== "/"
      ? history.location.pathname.includes(defaultLink)
      : history.location.pathname === defaultLink;
  };

  const onHeaderClick = (props) => {
    if (props.defaultLink) {
      history.push(props.defaultLink);
    } else if (props?.subMenus?.length) {
      history.push(props?.subMenus[0].url);
    }
    setCurrentMenu(props);
  };

  return (
    <div
      className={`side-drawer ${props.sideDrawerOpen ? "open" : ""}`}>
      {userMenus?.map((menu, index) => (
        <DropDown
          key={index}
          isExpandable={menu?.subMenus?.length > 0 && isExpandable}
          defaultLink={menu?.defaultLink}
          title={menu?.name}
          icon={menu?.icon}
          subMenus={menu?.subMenus}
          drawerToggle={props.drawerToggleClickHandler}
          sideDrawerOpen={props.sideDrawerOpen}
          isRouteActive={isRouteActive({ defaultLink: menu?.defaultLink })}
          onHeaderClick={() => onHeaderClick(menu)}
          isLink={menu?.isLink}
        />
      ))}
      <div
        className={`toggle-control ${props.sideDrawerOpen ? "open" : ""}`}
        onClick={props.drawerToggleClickHandler}
      >
        <a className={`toggle-btn`}>
          {props.sideDrawerOpen ? (
            <span>
              <FontAwesomeIcon icon={faAngleDoubleLeft} className="me-1" />
              Collapse sidebar
            </span>
          ) : (
            <span>
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </span>
          )}
        </a>
      </div>
    </div>
  );
};
const mapStateToProps = ({ authentication, user, permissions }: IRootState) => ({
  account: authentication.account,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [
    AUTHORITIES.ADMIN,
  ]),
  isSuperAdmin: hasAnyAuthority(authentication.account.authorities, [
    AUTHORITIES.SUPER_ADMIN,
  ]),
  isAuthenticated: authentication.isAuthenticated,
  userMenus: getPermittedMenus(user.menus, authentication.account.authorities, permissions),
  loading: user.loading,
});

const mapDispatchToProps = {
  getSession,
  getMenus,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SideDrawer);
