import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { IRootState } from '../../shared/reducers';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { getVendorUserList } from '../../reducers/vendor-user.reducer';
import './styles.scss';
import SaveButton from '../components/button';
import { PERMISSION_ACTIONS } from '../../config/permission-constants';
import { IsPermittedAction } from '../../shared/util/permission-utils';
import Pagination from '@mui/material/Pagination';
import { ITEMS_PER_PAGE } from '../../shared/util/pagination.constants';

export interface IVendorUserListProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ url: string }> {}

export const VendorUserList = (props: IVendorUserListProps) => {
  const { getVendorUserList, vendorUserList, loading, totalCount } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );

  useEffect(() => {
    getVendorUserList({
      page: currentPage - 1,
      size: ITEMS_PER_PAGE,
    });
  }, [currentPage]);
  
  useEffect(() => {
    setNumberOfPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
  }, [vendorUserList]);

  const onPaginationChange = (e, page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Row className="ps-4 pe-5 py-2" style={{ backgroundColor: '#E5E5E5' }}>
        <Col md="10">
          <h6 className="text-greyTeal mb-0 px-2 py-3">
            {translate('form.create_vendor_user.total_users')}
          </h6>
        </Col>
        {IsPermittedAction(
          PERMISSION_ACTIONS.CREATE_VENDOR_USERS,
          authorities,
          permissionList,
        ) && (
          <Col
            md="2"
            className="py-1 d-flex justify-content-center align-items-center">
            <Col>
              <SaveButton
                className="text-white text-right py-1"
                tag={Link}
                id="cancel-save"
                to="/user-list/new"
                replace
                color="danger">
                <small className="m-0 p-0">
                  {translate('form.create_vendor_user.add')}
                </small>
              </SaveButton>
            </Col>
          </Col>
        )}
      </Row>
      <div className="table-responsive shadow border border-1 rounded custom-table ms-4 me-4 my-5 pt-2">
        <Table responsive className="mb-0">
          <thead className="border-0 border-bottom border-1">
            <tr>
              <th className="border-0 ps-4 pe-2 py-3">
                {translate('form.create_vendor_user.user_name')}
              </th>
              <th className="border-0 px-2 py-3">
                {translate('form.create_vendor_user.email')}
              </th>
              <th className="border-0 px-2 py-3">
                {translate('form.create_vendor_user.mobile_number')}
              </th>
              <th className="border-0 px-2 py-3">
                {translate('form.create_vendor_user.user_role')}
              </th>
            </tr>
          </thead>
          {!loading ? (
            <tbody className="border-top-0">
              {vendorUserList?.length &&
                vendorUserList.map((vendorUser, i) => (
                  <tr
                    className="border-0 border-bottom border-1"
                    key={`entity-${i}`}
                    data-cy="entityTable">
                    <td className="border-0 ps-4 pe-2 py-4">
                      {vendorUser.name}
                    </td>
                    <td className="border-0 px-2 py-4">{vendorUser.email}</td>
                    <td className="border-0 px-2 py-4">{vendorUser.mobile}</td>
                    <td className="border-0 px-2 py-4">{vendorUser.role}</td>
                    {IsPermittedAction(
                      PERMISSION_ACTIONS.GET_VENDOR_USERS,
                      authorities,
                      permissionList,
                    ) && (
                      <td className="border-0 pa-2 pe-4 py-4">
                        <div>
                          <Button
                            tag={Link}
                            to={`/user-list/${vendorUser.id}/edit`}
                            color="none"
                            size="sm"
                            data-cy="entityDeleteButton">
                            <FontAwesomeIcon
                              className="text-danger"
                              icon={faEye}
                            />
                          </Button>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          ) : (
            !loading && (
              <div className="alert alert-warning">No Users found</div>
            )
          )}
        </Table>
      </div>
      {totalCount && vendorUserList?.length ? (
        <Pagination
          count={numberOfPages}
          onChange={onPaginationChange}
          variant="outlined"
          shape="rounded"
          className="d-flex justify-content-center mb-4"
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = ({ vendorUser }: IRootState) => ({
  vendorUserList: vendorUser.vendorUserList,
  loading: vendorUser.loading,
  totalCount: vendorUser.totalCount,
});

const mapDispatchToProps = {
  getVendorUserList,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VendorUserList);
