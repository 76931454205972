import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { NavLink } from "react-router-dom";
import { Card, Col, Container, Label, Row } from "reactstrap";
import { IRootState } from "../../shared/reducers";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { getServicesMenus, getServices } from "../../reducers/plan.reducer";
import { IMenu, ISubMenu } from "../../model/user-menu";
import SubHeader from "../../shared/layout/sub-header";
import { translate } from "react-jhipster";
import { SERVICE_STATUSES } from "../../config/constants";

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export const Services = (props: IPageProps) => {
  const { services, loading } = props;
  useEffect(() => {
    props.getServices();
  }, []);

  return (
    <div>
      {/* <SubHeader title=></SubHeader> */}
      <Row className="ps-4 pe-5 py-2" style={{ backgroundColor: '#E5E5E5' }}>
        <Col md="10">
          <h6 className="text-greyTeal mb-0 px-2 py-3">
            {translate("placeholders.myServices")}
          </h6>
        </Col>
        <Col md="2" className="py-1 d-flex justify-content-center align-items-center">
        </Col>
      </Row>
      <Container fluid className="px-0 px-lg-3 pb-5 mb-2">
        <Row className="service-container">
          {services?.map((service, index) => (
            <Col md="6" lg="4" key={index}>
              <NavLink to={`/services/${service?.id}/service-tab`}>
                <Card className="service">
                  <div className="image">
                    <img src={service?.imageUrl} alt={service.serviceName} />
                  </div>
                  <div className="details">
                    <div
                      className={`label 
                      ${service?.isPublished ? "active" : ""}
                      ${service?.status === SERVICE_STATUSES.PUBLISHED ? "published"
                          : service?.status === SERVICE_STATUSES.REQUEST_FOR_PUBLISH ?
                            "publish-requested"
                            : "draft"}`
                      }
                    >
                      <span>
                        {service?.status === SERVICE_STATUSES.PUBLISHED ?
                          translate("placeholders.published") :
                          service?.status === SERVICE_STATUSES.REQUEST_FOR_PUBLISH ?
                          translate("placeholders.publish_requested"):
                          service?.status === SERVICE_STATUSES.DEACTIVATED ?
                            translate("placeholders.deactivated"):
                          service?.status === SERVICE_STATUSES.DEACTIVATED_BY_ADMIN ?
                            translate("placeholders.deactivatedAdmin")  
                            : translate("placeholders.draft")}
                      </span>
                    </div>
                    <h5 className="title"> {service.serviceName} </h5>
                  </div>
                </Card>
              </NavLink>
            </Col>
          ))}
          <Col></Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ authentication, plan }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  services: plan.services,
  loading: plan.loading,
});

const mapDispatchToProps = { getServicesMenus, getServices };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Services);
