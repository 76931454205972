import { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import firebase from 'firebase';
import '@firebase/messaging';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  Input,
} from 'reactstrap';
import { IRootState } from '../../shared/reducers';
import 'react-multi-carousel/lib/styles.css';
import './style.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment-timezone';
import { SuccessCheckIcon } from './svg-icons/success-check.icon';
import { PendingCallIcon } from './svg-icons/pending-call-icon';
import { ACTION_TYPES } from '../../reducers/video-consultation-reducer';

import {
  fetchAppointments,
  fetchDoctors,
  fetchWeekDetail,
  fetchWeekDetailOffline,
  createOfflineAppointment,
  getOfflineAppointment,
  editOfflineAppointment,
} from '../../reducers/video-consultation-reducer';
import { editUserDeviceInfo } from '../../reducers/user.reducer';
import { useHistory, useLocation } from 'react-router-dom';
import { Translate, translate } from 'react-jhipster';
import {
  DAY_TEXT_FORMAT,
  DAY_NUMBER_FORMAT,
  APPOINTMENT_STATUS,
  DATE_TIME_FORMATS,
  Colors,
  UN_ASSIGNED_LIST_KEY,
} from '../../config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWindowClose,
  faCaretDown,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import AppointmentCard from './components/appointment-card';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { DatePicker } from 'antd';
import 'react-datepicker/dist/react-datepicker.css';
import { DateIcon } from '../services/components/icons/date-icon';
import { CUSTOMER_APPOINTMENT_STATUS } from '../../config/constants';
import { faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import missCallIconSvg from './svg-icons/missed-call.svg';
import incompleteCallIconSvg from './svg-icons/incomplete.svg';
import { getServices } from '../../reducers/plan.reducer';
import { fetchPetTypeVendor } from '../../reducers/master.reducer';
import {
  fetchCustomerList,
  fetchAllCustomerPets,
} from '../../reducers/user.reducer';
import CreatableSelect from 'react-select/creatable';
import { getServiceDetailsOffline } from '../../reducers/user.reducer';
import { PERMISSION_ACTIONS } from '../../config/permission-constants';
import { IsPermittedAction } from '../../shared/util/permission-utils';
import { CONSULTATION_TYPE } from '../../config/constants';
import Tooltip from '@mui/material/Tooltip';
import { checkIsAdmin } from '../../utils/check-isadmin';
import openIconSvg from './svg-icons/open-icon.svg';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{
      date: string | undefined | any;
      doctor: string | undefined;
      filter: string | any;
    }> {}

export const OnlineVideoConsultation = (props: IPageProps) => {
  const history = useHistory();
  const sevenDaysList = Array.from(Array(7).keys());
  const [doctors, setDoctors] = useState([] as any[]);
  const [weekDayDetail, setWeekDetail] = useState([] as any[]);
  const [weekDayDetailOffline, setWeekDetailOffline] = useState([] as any[]);
  const [appointments, setAppointments] = useState([] as any[]);
  const [appointmentHistory, setAppointmentHistory] = useState([] as any[]);
  const [queryParams, setQueryParams] = useState({
    selectedDate: null,
    selectedDoctorId: null,
    filter: null,
  } as any);
  const [appointmentsLoading, setAppointmentsLoading] = useState(true);
  const [selectedDates, setSelectedDates] = useState([] as any[]);
  const dispatch = useDispatch();
  const [mode, setMode] = useState('ALL');
  const [modalOpen, setModalOpen] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [selectedCustomerId, setSelectedCustomerId] = useState(0);
  const [selectedCustomerMobile, setSelectedCustomerMobile] = useState(
    '' as any,
  );
  const [petSelect, setPetSelect] = useState([] as any);
  const [petName, setPetName] = useState('');
  const [petWeight, setPetWeight] = useState(0 as any);
  const [petId, setPetId] = useState(0);
  const [petType, setPetType] = useState(0 as any);
  const [consultationType, setConsultationType] = useState('');
  const [assignedDoctorId, setAssignedDoctorId] = useState<any>(0);
  const [serviceId, setServiceId] = useState<any>(16);
  const [apointmentType, setApointmentType] = useState('' as any);
  const [fromTime, setFromTime] = useState('' as any);
  const [toTime, setToTime] = useState('' as any);
  const [isPaid, setIsPaid] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAppointmentUpdateRequired, setIsAppointmentUpdateRequired] =
    useState({} as any);
  const [firebaseToken, setFirebaseToken] = useState<String>('');
  const [offlineAppointmentDate, setOfflineAppointmentDate] = useState(
    moment(new Date()).format('YYYY-MM-DD'),
  );
  const [appointmentId, setAppointmentId] = useState(0);
  const toggle = () => setModalOpen(!modalOpen);
  const {
    serviceDetailsOffline,
    customerPets,
    customerList,
    petTypes,
    services,
    offlineAppointmentResponse,
  } = props;
  const [consultationCharge, setConsultationCharge] = useState(
    serviceDetailsOffline?.consultationCharge,
  );
  const [isErrorCustomerNumber, setIsErrorCustomerNumber] = useState(false);
  const [isErrorCustomerPet, setIsErrorCustomerPet] = useState(false);
  const [isErrorConsultationType, setIsErrorConsultationType] = useState(false);
  const [isErrorPetType, setIsErrorPetType] = useState(false);
  const [isErrorAssignTo, setIsErrorAssignTo] = useState(false);
  const [isErrorServiceType, setIsErrorServiceType] = useState(false);
  const [isChangeNumber, setIsChangeNumber] = useState(false);
  const [isChangePetName, setIsChangePetName] = useState(false);
  const [isActive, setIsActive] = useState<boolean>(
    serviceDetailsOffline?.isActive,
  );
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let doctorTemp = [] as any;
  let customerSeachParam = '';
  let customerSelect = [] as any;
  const isAdmin = checkIsAdmin(props?.doctors);
  const [customerSelectDrop, setCustomerSelectDrop] = useState([]);
  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );
  const [dateParam, setDateParam] = useState(searchParams.get('date') as any);
  const [doctor, setDoctor] = useState(searchParams.get('doctor'));
  const [filterStatus, setFilterStatus] = useState(searchParams.get('filter'));
  const [selectedInitialDate, setSelectedInitialDate] = useState(
    dateParam ? moment(dateParam).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
  );
  const [status, setStatus] = useState(filterStatus ? filterStatus : 'ALL');

  const handleValidSubmit = async (event, errors, values) => {
    setIsChangePetName(true);
    const isErrorCheckCustomerNumber = errorCheckCustomerNumber();
    const isErrorCheckCustomerPet = errorCheckCustomerPet();
    const isErrorCheckConsultationType = errorCheckConsultationType();
    const isErrorCheckServiceType = errorCheckServiceType();
    const isErrorCheckPetType = errorCheckPetType();
    const isErrorCheckAssignTo = errorCheckAssignTo();
    const entity = {
      date: offlineAppointmentDate,
      customerName: customerName,
      customerMobile: selectedCustomerMobile,
      petTypeId: petType,
      consultationType: consultationType,
      petName: petName,
      petWeight: petWeight,
      petId: petId ? petId : '',
      assignedDoctorId: assignedDoctorId === '-1' ? null : assignedDoctorId,
      fromTime: fromTime,
      toTime: toTime ? toTime : fromTime,
      isPaid: isPaid,
      consultingCharge: consultationCharge,
      id: isEdit ? appointmentId : '',
      serviceId: serviceId,
    };
    if (
      !isErrorCheckCustomerNumber &&
      !isErrorCheckCustomerPet &&
      !isErrorCheckConsultationType &&
      !isErrorCheckServiceType &&
      !isErrorCheckPetType &&
      !isErrorCheckAssignTo &&
      errors.length === 0
    ) {
      setModalOpen(false);
      isEdit
        ? await props?.editOfflineAppointment(entity)
        : await props?.createOfflineAppointment(entity);
      {
        queryParams?.selectedDoctorId !== null &&
          props.fetchAppointments({
            date: moment(offlineAppointmentDate).format(
              DATE_TIME_FORMATS.YYYY_MM_DD_WITH_HYPHEN,
            ),
            doctorId: queryParams?.selectedDoctorId,
            mode: mode,
          });
      }
      setPetWeight(0);
      setIsEdit(false);
      dispatch({
        type: ACTION_TYPES.RESET_DATA,
      });
      let state = localStorage.getItem('state') || '';
      let resObj = JSON.parse(state);
      resObj.videoConsultation.offlineAppointmentResponse = {};
      localStorage.setItem('state', JSON.stringify(resObj));
    }
  };
  useEffect(() => {
    if (!modalOpen) {
      setSelectedCustomerMobile('');
      setPetType(0);
      setConsultationType('');
      setPetId(0);
      setAssignedDoctorId(0);
      setOfflineAppointmentDate(moment(new Date()).format('YYYY-MM-DD'));
      setFromTime(moment().format('HH:mm'));
      setToTime('');
      setIsPaid(false);
      setPetName('');
      setSelectedCustomerId(0);
      setCustomerName('');
      setIsErrorCustomerPet(false);
      setIsErrorCustomerNumber(false);
      setIsErrorConsultationType(false);
      setIsErrorPetType(false);
      setIsErrorServiceType(false);
      setIsErrorAssignTo(false);
      setConsultationCharge(
        serviceDetailsOffline?.consultationCharge
          ? serviceDetailsOffline?.consultationCharge
          : 0,
      );
    }
  }, [modalOpen]);

  const askForPermissioToReceiveNotifications = async () => {
    try {
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();
      setFirebaseToken(token);
    } catch (error) {
      console.error(error);
    }
  };

  const onMessageListener = async () => {
    try {
      const messaging = firebase.messaging();
      await messaging.onMessage(payload => {
        setIsAppointmentUpdateRequired(payload);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    askForPermissioToReceiveNotifications();
    onMessageListener();
  }, []);

  useEffect(() => {
    if (isAppointmentUpdateRequired) {
      if (queryParams.selectedDate && queryParams.selectedDoctorId !== null) {
        props.fetchAppointments({
          date: moment(queryParams.selectedDate).format(
            DATE_TIME_FORMATS.YYYY_MM_DD_WITH_HYPHEN,
          ),
          doctorId: assignedDoctorId,
          mode: mode,
        });
      }
    }
  }, [isAppointmentUpdateRequired]);

  useEffect(() => {
    if (firebaseToken) {
      props.editUserDeviceInfo({
        os: 'WEB',
        token: firebaseToken,
        type: 'FCM',
      });
    }
  }, [firebaseToken]);

  useEffect(() => {
    props.fetchDoctors();
    dispatch({
      type: ACTION_TYPES.RESET_DATA,
    });
    !dateParam
      ? setQueryParams({
          ...queryParams,
          selectedDate: selectedInitialDate,
        })
      : setQueryParams({
          ...queryParams,
          selectedDate: dateParam,
        });
  }, []);

  useEffect(() => {
    const newLocation = {
      ...location,
      search: `?date=${
        queryParams.selectedDate
          ? moment(queryParams.selectedDate).format(
              DATE_TIME_FORMATS.YYYY_MM_DD_WITH_HYPHEN,
            )
          : moment(new Date()).format(DATE_TIME_FORMATS.YYYY_MM_DD_WITH_HYPHEN)
      }&doctor=${queryParams?.selectedDoctorId}&filter=${status}`,
    };
    history.push(newLocation);
  }, [queryParams]);

  useEffect(() => {
    if (queryParams.selectedDate && queryParams.selectedDoctorId !== null) {
      props.fetchAppointments({
        date: moment(queryParams.selectedDate).format(
          DATE_TIME_FORMATS.YYYY_MM_DD_WITH_HYPHEN,
        ),
        doctorId: assignedDoctorId,
        mode: mode,
      });
      props.fetchWeekDetail({
        date: moment(selectedInitialDate).format(
          DATE_TIME_FORMATS.YYYY_MM_DD_WITH_HYPHEN,
        ),
        mode: 'ONLINE',
      });
      props.fetchWeekDetailOffline({
        date: moment(selectedInitialDate).format(
          DATE_TIME_FORMATS.YYYY_MM_DD_WITH_HYPHEN,
        ),
        mode: 'OFFLINE',
      });
    }
  }, [mode]);

  useEffect(() => {
    customerSelect = [];
    setPetSelect([]);
    setIsErrorCustomerNumber(false);
    setIsErrorCustomerPet(false);
    setIsErrorConsultationType(false);
    setIsErrorServiceType(false);
    setIsErrorPetType(false);
    setIsErrorAssignTo(false);
    if (modalOpen) {
      props.getServices();
      props.fetchCustomerList({
        page: 0,
        size: 5,
      });
    }
  }, [modalOpen]);

  useEffect(() => {
    if (serviceId) props?.fetchPetTypeVendor(serviceId);
  }, [serviceId]);

  useEffect(() => {
    if (customerList && customerList.length) {
      customerSelect = customerList.map(customer => {
        return {
          value: customer?.id,
          label: customer?.mobile,
          name: customer?.name,
        };
      });
    }
  }, [customerList]);

  useEffect(() => {
    customerSelect.length > 0 && setCustomerSelectDrop(customerSelect);
  }, [customerSelect]);

  useEffect(() => {
    setPetSelect([]);
    customerPets?.map(pet => {
    setPetSelect(prevPetSelect => [
      ...prevPetSelect,
      { value: pet?.id, label: pet?.petName }
    ]);
  });
  }, [customerPets]);


  useEffect(() => {
    fetchSlots();
  }, [selectedInitialDate]);

  useEffect(() => {
    props.getServiceDetailsOffline({ serviceId });
  }, [serviceId]);

  useEffect(() => {
    if (serviceDetailsOffline) {
      setConsultationCharge(serviceDetailsOffline?.consultationCharge);
      setIsActive(serviceDetailsOffline?.isActive);
    }
  }, [serviceDetailsOffline]);

  useEffect(() => {
    if (offlineAppointmentResponse.serviceId) {
      setServiceId(offlineAppointmentResponse?.serviceId);
      setOfflineAppointmentDate(offlineAppointmentResponse?.date);
      setFromTime(offlineAppointmentResponse?.fromTime);
      setToTime(offlineAppointmentResponse?.toTime);
      setCustomerName(offlineAppointmentResponse?.customerName);
      setSelectedCustomerMobile(offlineAppointmentResponse?.customerMobile);
      setPetType(offlineAppointmentResponse?.petTypeId);
      setConsultationType(offlineAppointmentResponse?.consultationType);
      setConsultationCharge(offlineAppointmentResponse?.consultingCharge);
      setIsPaid(offlineAppointmentResponse?.isPaid);
      setAssignedDoctorId(offlineAppointmentResponse?.assignedDoctorId);
      setPetName(offlineAppointmentResponse?.petName);
      setPetWeight(offlineAppointmentResponse?.petWeight);
      setPetId(offlineAppointmentResponse?.petId);
      setSelectedCustomerId(offlineAppointmentResponse?.customerId);
      setApointmentType(offlineAppointmentResponse?.type);
      props.fetchAllCustomerPets({
        customerId: offlineAppointmentResponse?.customerId,
        petTypeId: offlineAppointmentResponse?.petTypeId,
      });
    }
  }, [offlineAppointmentResponse]);

  useEffect(() => {
    const pet = customerPets.find(customerPet => customerPet.id === petId);
    setPetWeight(pet?.weight);
  }, [petId,customerPets]);

  const fetchSlots = () => {
    props.fetchWeekDetail({
      date: moment(selectedInitialDate).format(
        DATE_TIME_FORMATS.YYYY_MM_DD_WITH_HYPHEN,
      ),
      mode: 'ONLINE',
    });
    props.fetchWeekDetailOffline({
      date: moment(selectedInitialDate).format(
        DATE_TIME_FORMATS.YYYY_MM_DD_WITH_HYPHEN,
      ),
      mode: 'OFFLINE',
    });
  };

  const deactivateModalClose = () => {
    setIsPaid(false);
    setModalOpen(!modalOpen);
    setPetWeight(0);
  };

  const onClickEdit = id => {
    setAppointmentId(id);
    setIsEdit(true);
    if (id) {
      props?.getOfflineAppointment(id);
    }
    deactivateModal();
  };

  const deactivateModal = () => {
    setModalOpen(!modalOpen);
  };

  const newAppoinments = () => {
    setIsEdit(false);
    deactivateModal();
  };

  useEffect(() => {
    if (Array.isArray(props?.weekDetail)) {
      setWeekDetail(props?.weekDetail);
      if (selectedInitialDate) {
        setQueryParams({
          ...queryParams,
          selectedDate: selectedInitialDate,
        });
      }
    }
  }, [props?.weekDetail]);

  useEffect(() => {
    if (Array.isArray(props?.weekDetailOffline)) {
      setWeekDetailOffline(props?.weekDetailOffline);
      if (props?.weekDetailOffline?.length) {
        setQueryParams({
          ...queryParams,
          selectedDate: selectedInitialDate,
        });
      }
    }
  }, [props?.weekDetailOffline]);

  const doctorArraySet = () => {
    const temp = [...props?.doctors];
    if (isAdmin) {
      const firstElement = { ...temp.shift() };
      doctorTemp = [firstElement, { id: -2, name: 'ALL' }, ...temp];
    } else {
      doctorTemp = [...temp];
    }
    setDoctors(doctorTemp);
  };

  useEffect(() => {
    if (Array.isArray(props?.doctors)) {
      mode === 'ALL'
        ? doctorArraySet()
        : isAdmin
        ? setDoctors(props?.doctors.slice(1, props?.doctors.length))
        : doctorArraySet();
      if (props?.doctors?.length && doctor == null) {
        setQueryParams({
          ...queryParams,
          selectedDoctorId:
            mode === 'ALL'
              ? props?.doctors[0]?.id
              : isAdmin
              ? props?.doctors[1]?.id
              : props?.doctors[0]?.id,
        });
      } else
        setQueryParams({
          ...queryParams,
          selectedDoctorId: doctor,
        });
    }
  }, [props?.doctors, mode]);

  useEffect(() => {
    if (Array.isArray(props?.appointments)) {
      setAppointmentsLoading(false);
      setAppointments(
        props?.appointments.filter(
          item => item.status === APPOINTMENT_STATUS.OPEN,
        ) ?? [],
      );
    }
  }, [props?.appointments]);

  useEffect(() => {
    if (Array.isArray(props?.appointments)) {
      setAppointmentsLoading(false);
      status === 'ALL'
        ? setAppointmentHistory(props?.appointments ?? [])
        : setAppointmentHistory(
            props?.appointments.filter(item => item.status === status) ?? [],
          );
    }
  }, [status, props?.appointments]);

  useEffect(() => {
    setPetSelect([]);
    petType !== props?.offlineAppointmentResponse?.petTypeId &&
      props?.offlineAppointmentResponse?.petTypeId &&
      setPetName('');
    if (selectedCustomerId && petType) {
      props.fetchAllCustomerPets({
        customerId: selectedCustomerId,
        petTypeId: petType,
        page:0,
        size:50
      });
    }
  }, [selectedCustomerId, petType]);

  const getStatusIcon = status => {
    if (status === APPOINTMENT_STATUS.CANCELLED) {
      return (
        <FontAwesomeIcon icon={faWindowClose} color={Colors.darkRedColor} />
      );
    } else if (status === APPOINTMENT_STATUS.COMPLETED) {
      return <SuccessCheckIcon />;
    } else if (status === APPOINTMENT_STATUS.PENDING) {
      return <PendingCallIcon />;
    } else if (status === APPOINTMENT_STATUS.INCOMPLETE) {
      return <img src={incompleteCallIconSvg} alt="" />;
    } else if (status === APPOINTMENT_STATUS.MISSED) {
      return <img src={missCallIconSvg} alt="" />;
    } else if (status === APPOINTMENT_STATUS.OPEN) {
      return <img src={openIconSvg} alt="" />;
    } else {
      return (
        <FontAwesomeIcon icon={faPhoneSlash} color={Colors.darkRedColor} />
      );
    }
  };

  const getAppointmentDetail = (
    selectedDate = queryParams.selectedDate,
    selectedDoctorId = queryParams.selectedDoctorId,
  ) => {
    if (selectedDoctorId !== null && selectedDate && !appointmentsLoading) {
      setAppointmentsLoading(true);
      props.fetchAppointments({
        date: moment(selectedDate).format(
          DATE_TIME_FORMATS.YYYY_MM_DD_WITH_HYPHEN,
        ),
        doctorId:
          selectedDoctorId === UN_ASSIGNED_LIST_KEY
            ? UN_ASSIGNED_LIST_KEY
            : selectedDoctorId,
        mode: mode,
      });
    }
  };

  const appointmentFilter = event => {
    setStatus(event.target.value);
    setQueryParams({ ...queryParams, filter: event.target.value });
  };

  const onPressAppointmentCard = id => {
    if (
      queryParams?.selectedDoctorId &&
      (IsPermittedAction(
        PERMISSION_ACTIONS.GET_CUSTOMER_APPOINTMENT,
        authorities,
        permissionList,
      ) ||
        IsPermittedAction(
          PERMISSION_ACTIONS.GET_MEDICAL_HISTORY,
          authorities,
          permissionList,
        ) ||
        IsPermittedAction(
          PERMISSION_ACTIONS.GET_CUSTOMER_APPOINTMENT_PRESCRIPTIONS,
          authorities,
          permissionList,
        ) ||
        IsPermittedAction(
          PERMISSION_ACTIONS.GET_APPOINTMENT_LAB_REPORT,
          authorities,
          permissionList,
        ))
    )
      history.push(`video-consultation/${id}/consultation-detail`, {
        doctor:
          doctors.find(doctor => doctor.id === queryParams.selectedDoctorId) ??
          {},
      });
  };

  useEffect(() => {
    getAppointmentDetail(
      queryParams?.selectedDate,
      queryParams?.selectedDoctorId,
    );
  }, [queryParams]);

  const handleChangeCustomer = async event => {
    setIsChangeNumber(true);
    setPetName('');
    setPetType(0);
    setSelectedCustomerMobile(event?.label);
    if (event?.value !== event?.label) {
      setSelectedCustomerId(event?.value);
      setCustomerName(event?.name);
    } else {
      setSelectedCustomerId(0);
      setCustomerName('');
    }
  };

  const onkeypress = e => {
    e &&
      props.fetchCustomerList({
        page: 0,
        size: 5,
        search: e,
      });
  };

  const handleChangePet = async event => {
    setIsChangePetName(true);
    setPetName(event?.label);
    if (event?.value !== event?.label) {
      setPetId(event?.value);
    } else {
      setPetId(0);
    }
  };

  useEffect(() => {
    if (isChangeNumber) errorCheckCustomerNumber();
  }, [selectedCustomerMobile]);

  useEffect(() => {
    if (isChangePetName) errorCheckCustomerPet();
  }, [petName]);

  useEffect(() => {
    if (consultationType) errorCheckConsultationType();
  }, [consultationType]);

  useEffect(() => {
    if (serviceId) errorCheckServiceType();
  }, [serviceId]);

  useEffect(() => {
    if (petType) errorCheckPetType();
  }, [petType]);

  useEffect(() => {
    if (assignedDoctorId) errorCheckAssignTo();
  }, [assignedDoctorId]);

  const errorCheckCustomerNumber = () => {
    if (
      !selectedCustomerMobile ||
      selectedCustomerMobile?.toString().length !== 10
    ) {
      setIsErrorCustomerNumber(true);
      return true;
    }
    setIsErrorCustomerNumber(false);
    return false;
  };

  const errorCheckCustomerPet = () => {
    if (!petName) {
      setIsErrorCustomerPet(true);
      return true;
    }
    setIsErrorCustomerPet(false);
    return false;
  };
  const errorCheckConsultationType = () => {
    if (!consultationType) {
      setIsErrorConsultationType(true);
      return true;
    }
    setIsErrorConsultationType(false);
    return false;
  };
  const errorCheckServiceType = () => {
    if (!serviceId) {
      setIsErrorServiceType(true);
      return true;
    }
    setIsErrorServiceType(false);
    return false;
  };

  const errorCheckPetType = () => {
    if (!petType) {
      setIsErrorPetType(true);
      return true;
    }
    setIsErrorPetType(false);
    return false;
  };

  const errorCheckAssignTo = () => {
    if (!assignedDoctorId) {
      setIsErrorAssignTo(true);
      return true;
    }
    setIsErrorAssignTo(false);
    return false;
  };

  const refreshScreen = () => {
    setQueryParams({
      ...queryParams,
      selectedDate: selectedInitialDate,
    });
  };

  const dayCard = ({
    selected,
    date,
    totalNumberOfSlots,
    totalNumberOfAppointments,
    totalNumberOfOfflineAppointments,
  }: any) => {
    return (
      <Col key={date} className="my-2 col-lg-1-5" xs={5} sm={4} md={3}>
        <Card
          onClick={() => setQueryParams({ ...queryParams, selectedDate: date })}
          className="day-content-card">
          <div className="remain-days-container-offline">
            <p className="text-center remain-days-text pt-1 pb-1">{`OFFLINE : ${totalNumberOfOfflineAppointments}`}</p>
          </div>
          <div className={`content ${selected && `selected-container`}`}>
            <p className={`date-text m-0 ${selected && `selected-text`}`}>
              {moment(date).format(DAY_NUMBER_FORMAT)}
            </p>
            <p className={`day-text m-0 ${selected && `selected-text`}`}>
              {moment(date).format(DAY_TEXT_FORMAT)?.toUpperCase()}
            </p>
          </div>
          <div className="remain-days-container">
            <p className="text-center remain-days-text pt-1 pb-1">{`ONLINE : ${totalNumberOfAppointments}/${totalNumberOfSlots}`}</p>
          </div>
        </Card>
      </Col>
    );
  };

  const appointmentHistoryCard = ({
    id,
    customerName,
    petType,
    fromTime,
    toTime,
    status,
    petName,
    mode,
    doctor,
    speciality,
  }) => {
    return (
      <Col onClick={() => onPressAppointmentCard(id)} key={id} md={12}>
        <Card className="card-content mt-2 mb-2">
          <CardBody>
            <Row>
              <Col
                className="d-flex justify-content-center align-items-center"
                md={2}>
                <div className="text-info my-4" style={{ fontSize: '.8rem' }}>
                  {getStatusIcon(status)}
                </div>
              </Col>
              <Col md={10}>
                <p className="pet-name">{customerName}</p>
                <Col>
                  <Row>
                    <Col>
                      <p className="pet-type-text">
                        {translate('video_consultation.pet_name')}
                      </p>
                    </Col>
                    <Col>
                      <p className="pet-type">{petName}</p>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <p className="pet-type-text">
                        {translate('video_consultation.pet_type')}
                      </p>
                    </Col>
                    <Col>
                      <p className="pet-type">{petType}</p>
                    </Col>
                  </Row>
                </Col>
                {speciality ? (
                  <Row>
                    <Col>
                      <p className="pet-type-text">Speciality</p>
                    </Col>
                    <Col>
                      <p className="pet-type">{speciality}</p>
                    </Col>
                  </Row>
                ) : null}
                <Col>
                  <Row>
                    <Col>
                      <Col>
                        <p className="pet-type-text">Slot</p>
                      </Col>
                    </Col>
                    <Col>
                      <p className="time">{`${fromTime} - ${toTime}`}</p>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <Col>
                        <p className="pet-type-text">Mode</p>
                      </Col>
                    </Col>
                    <Col>
                      <p className="pet-type">{mode}</p>
                    </Col>
                  </Row>
                </Col>
                {doctor ? (
                  <Row>
                    <Col>
                      <p className="pet-type-text">Doctor</p>
                    </Col>
                    <Col>
                      <p className="pet-type">{doctor}</p>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  };

  const doctorsTabItem = ({ id, name, index }) => {
    return (
      <Col
        key={index}
        md={2}
        onClick={() => setQueryParams({ ...queryParams, selectedDoctorId: id })}
        className={
          queryParams?.selectedDoctorId == id
            ? `selected-tab m-1`
            : `un-selected-tab m-1`
        }>
        <p className="doctor-text m-0">{name}</p>
      </Col>
    );
  };

  const isExistingCustomer = () => {
    const isFound = customerSelect.find(
      item => item.label === selectedCustomerMobile,
    );
    if (isFound) {
      return true;
    }
    return false;
  };

  const renderEmptyListText = () => (
    <h4 className="my-4 text-muted empty-list-text">
      <Translate contentKey="video_consultation.no_appointments">
        No appointments to list!
      </Translate>
    </h4>
  );

  const renderCustomDatePicker = () => {
    return (
      <div
        className={`today-button custom-button p-2 px-3 ${
          !selectedDateIsToday ? 'today-button-active' : ''
        }`}>
        <div className="d-flex justify-content-between">
          <p className="m-0 today-text">
            {!selectedDateIsToday
              ? moment(selectedInitialDate).format(
                  DATE_TIME_FORMATS.APP_LOCAL_DATE_FORMAT,
                )
              : translate('form.service.appointment_reschedule.custom_text')}
          </p>
          <DateIcon />
        </div>
      </div>
    );
  };

  const selectedDateIsToday =
    moment(selectedInitialDate).format(
      DATE_TIME_FORMATS.APP_LOCAL_DATE_FORMAT,
    ) === moment(new Date()).format(DATE_TIME_FORMATS.APP_LOCAL_DATE_FORMAT);

  const handleTodayButtonClick = () => {
    setSelectedInitialDate(moment().format('YYYY-MM-DD'));
    setQueryParams({
      ...queryParams,
      selectedDate: moment().format('YYYY-MM-DD'),
    });
  };

  return (
    <div id="video-consultation">
      <Row className="ps-4 pe-5 py-2" style={{ backgroundColor: '#E5E5E5' }}>
        <Col md="2">
          <h6 className="text-greyTeal mb-0 px-2 py-3">
            {translate('placeholders.appointments')}
          </h6>
        </Col>
        <Col
          md="2"
          className="py-1 d-flex justify-content-center align-items-center"></Col>
      </Row>
      <Row className="mt-3 mx-0 d-flex align-items-center">
        <Col md={1}>
          <Button
            onClick={() => handleTodayButtonClick()}
            className={`today-button p-2 px-3 ${
              selectedDateIsToday ? 'today-button-active' : ''
            }`}>
            <p className="m-0">
              {translate('form.service.appointment_reschedule.today_text')}
            </p>
          </Button>
        </Col>
        <Col md={2}>
          <DatePicker
            value={moment(selectedInitialDate)}
            onChange={date => {
              setSelectedInitialDate(moment(date).format('YYYY-MM-DD'));
              setQueryParams({
                ...queryParams,
                selectedDate: moment(date).format('YYYY-MM-DD'),
              });
            }}
            format={DATE_TIME_FORMATS.DD_MM_YYYY}
            allowClear={false}
            style={{ height: '39px' }}
          />
        </Col>
        <Col md={2}>
          {isActive &&
            IsPermittedAction(
              PERMISSION_ACTIONS.CREATE_OFFLINE_APPOINTMENT,
              authorities,
              permissionList,
            ) && (
              <Button
                className="button-make-appointment"
                onClick={newAppoinments}>
                {' '}
                New Appointment
              </Button>
            )}
        </Col>

        <Col md={1}>
          <Button
            style={{
              backgroundColor: 'transparent',
              borderColor: 'transparent',
            }}>
            <Tooltip title="Refresh Appointments">
              <FontAwesomeIcon
                className="spin"
                icon={faRefresh}
                color={Colors.geraldine}
                onClick={refreshScreen}
                size="2x"
              />
            </Tooltip>
          </Button>
        </Col>
      </Row>
      <Container fluid className="px-3 d-flex main-container">
        <Row style={{ width: '100%' }}>
          <Col className="p-2" md={8}>
            <Col>
              {queryParams?.selectedDate && (
                <p className="text-center date-header-text mt-3">
                  {moment(queryParams?.selectedDate).format(
                    DATE_TIME_FORMATS.APP_LOCAL_DATE_FORMAT_WITH_COMMA,
                  )}
                </p>
              )}
              <Row className="align-items-center justify-content-center">
                {sevenDaysList.map((item: any) => {
                  let day = moment(selectedInitialDate)
                    .add(item, 'days')
                    .format('YYYY-MM-DD');
                  let found = weekDayDetail.find(week => week.date === day);
                  let oflineFound = weekDayDetailOffline.find(
                    week => week.date === day,
                  );
                  let initialDate = moment(queryParams?.selectedDate).format(
                    'YYYY-MM-DD',
                  );
                  let today = initialDate.toString() === day;
                  return dayCard({
                    selected: today,
                    totalNumberOfAppointments:
                      found == undefined ? 0 : found?.totalNumberOfAppointments,
                    totalNumberOfOfflineAppointments:
                      oflineFound == undefined
                        ? 0
                        : oflineFound?.totalNumberOfAppointments,
                    totalNumberOfSlots:
                      found == undefined ? 0 : found?.totalNumberOfSlots,
                    date: day,
                  });
                })}
              </Row>
            </Col>

            <Col className="my-3 doctor-list-scroll-content">
              <ScrollMenu scrollContainerClassName="p-1 custom-scrollbar">
                {doctors.map((item, index) => {
                  return doctorsTabItem({ ...item, index });
                })}
              </ScrollMenu>
            </Col>

            <Col className="appointment-scrollable">
              <Row>
                {appointments?.length
                  ? appointments.map((appointment: any) => (
                      <AppointmentCard
                        mode={appointment?.type}
                        key={appointment?.id}
                        id={appointment?.id}
                        slotId={appointment.slotId}
                        customerName={appointment?.customer}
                        petType={appointment?.petType}
                        fromTime={appointment?.slotFrom}
                        toTime={appointment?.slotTo}
                        petName={appointment?.petName}
                        onPressAppointmentCard={onPressAppointmentCard}
                        onClickEdit={onClickEdit}
                        missedCall={
                          appointment?.status === APPOINTMENT_STATUS.MISSED
                        }
                        loading={props.consultationLoading}
                        refreshAppointments={getAppointmentDetail}
                        isAssigned={queryParams.selectedDoctorId}
                        canAssignDoctors={
                          doctors?.some(
                            item => item.id === UN_ASSIGNED_LIST_KEY,
                          ) ?? false
                        }
                        speciality={appointment?.speciality}
                        doctor={appointment?.doctorName}
                      />
                    ))
                  : !appointmentsLoading && renderEmptyListText()}
              </Row>
            </Col>
          </Col>

          <Col className="m-0" md={4}>
            <div className="m-3">
              <Card>
                <CardHeader className="text-center appointment-history-text">
                  <p className="m-0 pt-2 pb-2 appointment-history-header">
                    {translate('video_consultation.appointment_history')}
                  </p>
                  <span className="appointment-history-select">
                    <select
                      value={status}
                      placeholder="Select"
                      onChange={event => appointmentFilter(event)}
                      style={{
                        width: '100%',
                      }}>
                      <option value={0} disabled>
                        {translate('appointment_form.select_service')}
                      </option>
                      {CUSTOMER_APPOINTMENT_STATUS?.map((status, i) => (
                        <option key={i} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                  </span>
                </CardHeader>
                <CardBody className="appointment-history-scrollable">
                  {appointmentHistory?.length
                    ? appointmentHistory.map((item: any) => {
                        return appointmentHistoryCard({
                          id: item?.id,
                          customerName: item?.customer,
                          petType: item?.petType,
                          fromTime: item?.slotFrom,
                          toTime: item?.slotTo,
                          status: item?.status,
                          petName: item?.petName,
                          mode: item?.type,
                          doctor: item?.doctorName,
                          speciality: item?.speciality,
                        });
                      })
                    : !appointmentsLoading && renderEmptyListText()}
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
      {modalOpen ? (
        <Modal isOpen={modalOpen} toggle={toggle} backdrop="static">
          <ModalHeader>
            {isEdit ? 'Edit Offline Appointment' : 'New Offline Appointment'}
          </ModalHeader>
          <AvForm
            model={isEdit ? offlineAppointmentResponse : null}
            onSubmit={handleValidSubmit}>
            <ModalBody style={{ maxHeight: '575px', overflow: 'auto' }}>
              <Label>{translate('appointment_form.service')}</Label>
              <Col xs={12} className="mt-1">
                <select
                  value={serviceId}
                  placeholder="Select"
                  onChange={event => setServiceId(event?.target?.value)}
                  style={{
                    width: '100%',
                  }}>
                  <option value={0} disabled>
                    {translate('appointment_form.select_service')}
                  </option>
                  {services?.map((service, index) => (
                    <option key={index} value={service?.id}>
                      {service?.serviceName}
                    </option>
                  ))}
                </select>
                {isErrorServiceType && (
                  <span className="text-danger" style={{ fontSize: '.8rem' }}>
                    {translate(
                      'appointment_form.please_select_a_consultation_type',
                    )}
                  </span>
                )}
                <br />
              </Col>
              <Label className="mt-1">
                {translate('appointment_form.date')}
              </Label>
              <Col xs={12} className="mt-1">
                <DatePicker
                  value={moment(offlineAppointmentDate)}
                  onChange={date => {
                    setOfflineAppointmentDate(moment(date).format('YYYY-MM-DD'));
                  }}
                  format={DATE_TIME_FORMATS.DD_MM_YYYY}
                  allowClear={false}
                  style={{ width: '100%' }}
                  className="p-3"
                />
              </Col>
              <Label className="mt-1">
                {translate('appointment_form.time')}
              </Label>
              <Row>
                <Col xs={6}>
                  <AvField
                    className="style-text-box"
                    name="from"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: translate(
                          'appointment_form.please_select_a_from_time',
                        ),
                      },
                      pattern: {
                        value: '^[0-9:]*$',
                        errorMessage: 'errorMessage--',
                      },
                      minLength: {
                        value: 5,
                        errorMessage: translate(
                          'appointment_form.please_select_a_from_time',
                        ),
                      },
                    }}
                    type="time"
                    placeholder="Select"
                    value={fromTime ? fromTime : moment().format('HH:mm')}
                    onChange={event => {
                      setFromTime(event?.target?.value);
                    }}
                  />
                </Col>
                <Col xs={6}>
                  <AvField
                    className="style-text-box"
                    name="to"
                    type="time"
                    value={toTime}
                    placeholder="Select"
                    onChange={event => {
                      setToTime(event?.target?.value);
                    }}
                  />
                </Col>
              </Row>
              <Label> {translate('appointment_form.consultation_type')}</Label>
              <Col xs={12} className="mt-1">
                <select
                  value={consultationType}
                  onChange={event => setConsultationType(event?.target?.value)}
                  placeholder="Select"
                  style={{
                    width: '100%',
                  }}>
                  <option>Select Consultation Type</option>
                  {CONSULTATION_TYPE?.map((type, index) => {
                    return (
                      <option key={index} value={type?.value}>
                        {type?.label}
                      </option>
                    );
                  })}
                </select>
                {isErrorConsultationType && (
                  <span className="text-danger" style={{ fontSize: '.8rem' }}>
                    {translate(
                      'appointment_form.please_select_a_consultation_type',
                    )}
                  </span>
                )}
                <br />
              </Col>
              <Label>{translate('appointment_form.customer_mobile')}</Label>
              <CreatableSelect
                className="my-1"
                value={{
                  value: selectedCustomerId,
                  label: selectedCustomerMobile,
                  name: customerName,
                }}
                isClearable
                options={customerSelectDrop}
                onChange={value => {
                  handleChangeCustomer(value);
                }}
                onInputChange={event => onkeypress(event)}
              />
              {isErrorCustomerNumber && (
                <span className="text-danger" style={{ fontSize: '.8rem' }}>
                  {translate(
                    'appointment_form.please_enter_select_customer_number',
                  )}
                </span>
              )}
              <br />
              <Label>{translate('appointment_form.customer_name')}</Label>
              <AvField
                className="style-text-box my-1"
                name="customerName"
                type="text"
                disabled={isExistingCustomer()}
                placeholder="Name"
                value={customerName}
                onChange={event => {
                  setCustomerName(event?.target?.value);
                }}
              />
              <Label>{translate('appointment_form.pet_type')}</Label>
              <Col xs={12} className="mt-1">
                <select
                  value={petType}
                  onChange={event => setPetType(event?.target?.value)}
                  placeholder="Select"
                  style={{
                    width: '100%',
                  }}>
                  <option value={0}>Select Pet Type</option>
                  {petTypes?.map((pet, index) => {
                    return (
                      <option key={index} value={pet?.id}>
                        {pet?.name}
                      </option>
                    );
                  })}
                </select>
                {isErrorPetType && (
                  <span className="text-danger" style={{ fontSize: '.8rem' }}>
                    {translate('appointment_form.please_select_a_pet_type')}
                  </span>
                )}
                <br />
              </Col>
              <Label>{translate('appointment_form.pet_name')}</Label>
              <CreatableSelect
                className=" my-1"
                value={{ value: petId, label: petName }}
                isClearable
                options={petSelect}
                onChange={value => {
                  handleChangePet(value);
                }}
              />
              {isErrorCustomerPet && (
                <span className="text-danger" style={{ fontSize: '.8rem' }}>
                  {translate('appointment_form.please_enter_select_pet')}
                </span>
              )}
              <br />
              <Label>{translate('appointment_form.pet_weight')}</Label>
              <AvField
                className="style-text-box my-1"
                name="petWeight"
                value={petWeight}
                validate={{
                  required: {
                    value: true,
                    errorMessage: translate(
                      'appointment_form.please_enter_pet_weight',
                    ),
                  },
                }}
                min="0"
                type="text"
                placeholder="0"
                onChange={event => {
                  setPetWeight(event?.target?.value);
                }}
              />
              <Label>{translate('appointment_form.assign_to')}</Label>  
              <Col xs={12} className="mt-1">
                <select
                  value={assignedDoctorId}
                  onChange={event => setAssignedDoctorId(event?.target?.value)}
                  placeholder="Select"
                  style={{
                    width: '100%',
                  }}>
                  <option>Select Doctor</option>
                  {doctors
                    .filter(doctor => doctor.id !== -2)
                    .map((doctor, index) => {
                      return (
                        <option key={index} value={doctor?.id}>
                          {doctor?.name}
                        </option>
                      );
                    })}
                </select>
                {isErrorAssignTo && (
                  <span className="text-danger" style={{ fontSize: '.8rem' }}>
                    {translate('appointment_form.please_select_a_doctor')}
                  </span>
                )}
                <br />
              </Col>
              <Label>{translate('appointment_form.consultation_charge')}</Label>
              <AvField
                className="style-text-box my-1"
                name="consultationCharge"
                value={consultationCharge}
                validate={{
                  required: {
                    value: true,
                    errorMessage: translate(
                      'appointment_form.please_enter_consultation_charge',
                    ),
                  },
                }}
                min="0"
                type="text"
                placeholder="0"
                onChange={event => {
                  setConsultationCharge(event?.target?.value);
                }}
              />
              <div className="style-ispaid">
                <AvField
                  className="style-checkbox my-1"
                  type="checkbox"
                  name="isPaid"
                  checked={isPaid ? true : false}
                  label="Mark as Paid"
                  onChange={() => {
                    setIsPaid(!isPaid);
                  }}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button className="button-cancel" onClick={deactivateModalClose}>
                {translate('entity.action.cancel')}
              </Button>
              <Button
                className="button-make-appointment"
                color="dark"
                type="submit">
                Save
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      ) : null}
    </div>
  );
};

const mapStateToProps = ({
  authentication,
  user,
  videoConsultation,
  plan,
  master,
}: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loading: user.loading,
  weekDetail: videoConsultation.weekDetail,
  weekDetailOffline: videoConsultation.weekDetailOffline,
  consultationLoading: videoConsultation.loading,
  doctors: videoConsultation.doctors,
  appointments: videoConsultation.appointments,
  account: authentication.account,
  services: plan.services,
  petTypes: master.petTypeVendor,
  customerList: user.customerList,
  customerPets: user.pets,
  serviceDetailsOffline: user.serviceDetailsOffline,
  offlineAppointmentResponse: videoConsultation.offlineAppointmentResponse,
  editOfflineAppointmentResponse:
    videoConsultation.editOfflineAppointmentResponse,
});

const mapDispatchToProps = {
  fetchWeekDetail,
  fetchWeekDetailOffline,
  fetchDoctors,
  fetchAppointments,
  getServices,
  fetchPetTypeVendor,
  fetchCustomerList,
  fetchAllCustomerPets,
  createOfflineAppointment,
  getServiceDetailsOffline,
  getOfflineAppointment,
  editOfflineAppointment,
  editUserDeviceInfo,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OnlineVideoConsultation);
