import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Media,
  Row,
} from 'reactstrap';
import { IRootState } from '../../../shared/reducers';
import 'react-multi-carousel/lib/styles.css';
import Pagination from '@mui/material/Pagination';
import './style.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment-timezone';
import { translate } from 'react-jhipster';
import {
  fetchConsultationDetails,
  fetchMedicalHistory,
  downloadAppointment,
  getReminders,
} from '../../../reducers/video-consultation-reducer';
import {
  APP_CUSTOM_FIELD_INPUT_TYPES,
  APP_DATA_TYPES,
} from '../../../config/constants';
import {
  getPrescriptionByAppointment,
  getLabRecord,
} from '../../../reducers/prescription.reducer';
import { useSelector, useDispatch } from 'react-redux';
import { width } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faCircleDot } from '@fortawesome/free-solid-svg-icons';
import Link from '@mui/material/Link';
import { ITEMS_PER_PAGE } from '../../../shared/util/pagination.constants';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export const MedicalHistory = props => {
  const { appointmentId } = props;
  const page = 0;
  const medicalHistoryResponse = useSelector(
    (state: IRootState) => state.videoConsultation.medicalHistoryResponse,
  );
  const totalCount = useSelector(
    (state: IRootState) => state.videoConsultation.totalCount,
  );
  const consultationDetails = useSelector(
    (state: IRootState) => state.videoConsultation.consultationDetails,
  );
  const prescriptionResponse = useSelector(
    (state: IRootState) => state?.prescription?.prescription,
  );
  const labRecord = useSelector(
    (state: IRootState) => state?.prescription?.labRecord,
  );
  const getRemindersResponse = useSelector(
    (state: IRootState) => state?.videoConsultation.getRemindersResponse,
  );

  const [varyingAppointmentId, setVaryingAppointmentId] = useState(0);
  const [prescriptionItems, setPrescriptionItems] = useState([]);
  const [varyingAppointmentMode, setVaryingAppointmentMode] = useState('');
  const [doctorName, setDoctorName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [date, setDate] = useState(null as any);
  const dispatch = useDispatch();
  const [petId, setPetId] = useState(props.petId);
  const [medicalHistoryResponseLength, setMedicalHistoryResponseLength] =
    useState(0);
  const [selectedCard, setSelectedCard] = useState(0);
  const [age, setAge] = useState('' as any);
  const [prescription, setPrescription] = useState([]) as any;
  const offlineMode = 'OFFLINE';
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);

  useEffect(() => {
    if (petId) {
      dispatch(fetchMedicalHistory(petId, currentPage - 1, ITEMS_PER_PAGE));
    }
  }, [petId, currentPage]);

  const getMedicalHostoryApi = page => {
    dispatch(fetchMedicalHistory(petId, currentPage - 1, ITEMS_PER_PAGE));
  };

  useEffect(() => {
    setNumberOfPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
  }, [medicalHistoryResponse]);

  const onPaginationChange = (e, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (medicalHistoryResponse) {
      setMedicalHistoryResponseLength(medicalHistoryResponse.length);
    }
  }, [medicalHistoryResponse]);

  useEffect(() => {
    if (prescriptionResponse?.prescriptions?.length)
      setPrescription(prescriptionResponse?.prescriptions);
  }, [prescriptionResponse]);

  useEffect(() => {
    if (varyingAppointmentId) {
      dispatch(getReminders(varyingAppointmentId));
    }
  }, [varyingAppointmentId]);

  useEffect(() => {
    if (medicalHistoryResponseLength) {
      setDoctorName(medicalHistoryResponse[0].doctorName);
      setDate(medicalHistoryResponse[0].date);
      setVaryingAppointmentId(medicalHistoryResponse[0].id);
      setVaryingAppointmentMode(medicalHistoryResponse[0].type);
    } else {
      setErrorMessage('No medical history for this appointment');
      setDate(null);
    }
  }, [medicalHistoryResponseLength]);

  useEffect(() => {
    if (varyingAppointmentId) {
      dispatch(
        fetchConsultationDetails({ appointmentId: varyingAppointmentId }),
      );
      dispatch(getPrescriptionByAppointment(varyingAppointmentId));
      dispatch(getLabRecord(varyingAppointmentId));
    }
  }, [varyingAppointmentId]);

  useEffect(() => {
    calculateAge(consultationDetails?.petDetails?.dob);
  }, [consultationDetails]);

  const [groupedLabRecords, setGroupedLabRecords] = useState([]) as any;
  useEffect(() => {
    const groupLabRecordByDate = () => {
      const dataLab = labRecord?.reduce((value, item) => {
        const createdDate = moment(item.createdDate).format('DD-MM-YYYY');
        if (!value[createdDate]) {
          value[createdDate] = [item];
        } else {
          value[createdDate].push(item);
        }
        return value;
      }, {});
      return dataLab;
    };

    const data = groupLabRecordByDate();
    setGroupedLabRecords(data);
  }, [labRecord]);

  const onChangeAppoinment = index => {
    setSelectedCard(index);
    setVaryingAppointmentId(medicalHistoryResponse[index].id);
    setVaryingAppointmentMode(medicalHistoryResponse[index].type);
    setDoctorName(medicalHistoryResponse[index].doctorName);
    setDate(medicalHistoryResponse[index].date);
  };

  const calculateAge = value => {
    let dob = moment(value);
    const years = moment().diff(dob, 'years');
    dob = moment(dob).add(years, 'years');
    const months = moment().diff(dob, 'months');
    setAge(years + 'y ' + months + 'm');
    let dobInt = parseInt(dob.format('DD'));
    let todayInt = parseInt(moment().format('DD'));
    const days = Math.abs(dobInt - todayInt);
    setAge(years + 'y ' + months + 'm ' + days + 'd');
  };

  const renderLabelAndValue = ({ label, value, type = null }) => {
    return (
      <>
        <Col md={6}>
          <p className="label">{label}</p>
        </Col>
        <Col md={6}>
          {type === APP_CUSTOM_FIELD_INPUT_TYPES.ATTACHMENT ? (
            <Link href={value} target="_blank">
              Attachment &nbsp;
              <FontAwesomeIcon icon={faDownload} color="var(--bs-grey-teal)" />
            </Link>
          ) : (
            <p className="value">{value}</p>
          )}
        </Col>
      </>
    );
  };

  return (
    <div id="video-consultation">
      {date ? (
        <>
          <Container fluid className="px-0  d-flex main-container ">
            <Col className="margin-right shadow mt-3 " md={8}>
              <Card>
                <Row className="heading-style-row">
                  <Col className="heading-style-col " md={6}>
                    <span className="heading-left-style">
                      {doctorName} &nbsp;{' '}
                      <span className="medical-history-type">
                        {consultationDetails?.type}
                      </span>
                    </span>
                  </Col>
                </Row>
                <CardBody>
                  <Row>
                    <Col md={9} className="subheader-style pb-0">
                      {translate(
                        'consultation_tab.consultation_details.tab_header',
                      )}
                    </Col>
                    {consultationDetails?.consultationType && (
                      <Col md={3} className="subheader-style pb-0">
                        <Label>
                          {translate('appointment_form.consultation_type')}
                          :&nbsp;
                          {consultationDetails?.consultationType}
                        </Label>
                      </Col>
                    )}
                  </Row>
                  <hr className="mt-0" />
                  {consultationDetails?.questionnaire?.length &&
                  consultationDetails?.questionnaire?.length > 0 ? (
                    consultationDetails?.questionnaire?.map((item, index) => {
                      return (
                        item?.response &&
                        item?.response != -1 && (
                          <Col
                            key={index}
                            className="pet-info-left-col pt-3"
                            md={6}>
                            <Row>
                              {renderLabelAndValue({
                                label: item?.displayName,
                                value:
                                  item?.inputType === 'MULTI_SELECT'
                                    ? item?.response?.slice(
                                        0,
                                        item?.response?.length - 1,
                                      )
                                    : item?.response,
                                type: item?.inputType,
                              })}
                            </Row>
                          </Col>
                        )
                      );
                    })
                  ) : (
                    <Col className="mb-3">
                      {translate(
                        'consultation_tab.consultation_details.no_questionnaire',
                      )}
                    </Col>
                  )}
                  <Row>
                    <Col className="subheader-style pt-0 pb-0 mb-0">
                      {translate(
                        'consultation_tab.prescription_details.prescription-details',
                      )}
                    </Col>
                  </Row>
                  <hr className="mt-0" />
                  {prescriptionResponse?.prescriptions?.length ? (
                    <>
                      <Row className="prescription-header-border">
                        <Col md={12} className="mt-3">
                          <Card
                            className="custom-card-font"
                            style={{ border: 'none' }}>
                            <Row className="mb-3">
                              <Col md={8} className="justify-content-start">
                                {consultationDetails?.header?.businessName}
                                <br />
                                {consultationDetails?.header?.city},
                                {consultationDetails?.header?.state}
                              </Col>
                              <Col md={4}>
                                <Row>
                                  <Col>
                                    {translate(
                                      'consultation_tab.consultation_details.pet_name',
                                    )}
                                    <br />{' '}
                                    {translate(
                                      'consultation_tab.consultation_details.pet_type',
                                    )}
                                    <br />
                                    {translate(
                                      'consultation_tab.consultation_details.dob',
                                    )}
                                    <br />
                                  </Col>
                                  <Col>
                                    {consultationDetails?.petDetails?.name}
                                    <br />
                                    {consultationDetails?.petDetails?.petType}
                                    <br />
                                    {consultationDetails?.petDetails?.dob
                                      ? age
                                      : null}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>

                      <Row
                        className="bottom-container"
                        style={{ backgroundColor: 'white' }}>
                        {prescription?.map((prescriptionItem, index) => (
                          <Row
                            key={index}
                            className="medical-history-item-container">
                            <div className="d-flex justify-content-between pb-2">
                              <div className="pt-4">
                                <div
                                  style={{ fontSize: '14px', fontWeight: 600 }}>
                                  {moment(prescriptionItem?.createdDate).format(
                                    'DD-MM-YYYY h:mm A',
                                  )}
                                </div>
                                <div
                                  className="pt-2"
                                  style={{ fontSize: '14px' }}>
                                  {prescriptionItem?.doctorName}
                                  <br />
                                  {prescriptionItem?.qualifications}
                                </div>
                              </div>
                            </div>
                            {prescriptionItem?.prescriptionItems?.length ? (
                              <Row
                                className="table-item custom-font-weight"
                                style={{ backgroundColor: '#c9dfda' }}>
                                <Col className="title d-flex justify-content-center">
                                  {translate(
                                    'prescription_entry.serial_number',
                                  )}
                                </Col>
                                <Col xxl={5} className="title">
                                  {translate(
                                    'prescription_entry.medicine_name',
                                  )}
                                </Col>
                                <Col xxl={2} className="title ">
                                  {translate('prescription_entry.time')}
                                </Col>
                                <Col xxl={2} className="title">
                                  {translate('prescription_entry.duration')}
                                </Col>
                                <Col xxl={3} className="title">
                                  {translate('prescription_entry.direction')}
                                </Col>
                              </Row>
                            ) : null}

                            {prescriptionItem?.prescriptionItems?.map(
                              (item, index) => (
                                <Row
                                  key={index}
                                  className="table-item mt-2 bg-white">
                                  <Col className="title justify-content-center d-flex table-text">
                                    {index + 1}
                                  </Col>
                                  <Col xxl={5} className="title">
                                    {item?.medicineName}
                                  </Col>
                                  <Col xxl={2} className="title">
                                    {item?.dosage}
                                  </Col>
                                  <Col xxl={2} className="title auto-width">
                                    {item?.durationInDays ? (
                                      <Col className="h-100">
                                        {item?.durationInDays}
                                        <span className="table-text m-2">
                                          {translate('prescription_entry.days')}
                                        </span>
                                      </Col>
                                    ) : null}
                                  </Col>
                                  <Col xxl={3} className="title">
                                    {item?.directions}
                                  </Col>
                                </Row>
                              ),
                            )}
                            {prescriptionItem?.prognosis ? (
                              <Row
                                className={`table-item ${
                                  prescriptionItem?.prescriptionItems?.length
                                    ? 'mt-2'
                                    : 'mt-0'
                                } bg-white `}>
                                <Col md={3} className="title d-block">
                                  Prognosis
                                </Col>
                                <Col
                                  md={10}
                                  className=" d-block"
                                  style={{ whiteSpace: 'pre-line' }}>
                                  {prescriptionItem?.prognosis}
                                </Col>
                              </Row>
                            ) : null}
                            {prescriptionItem?.diagnosis ? (
                              <Row
                                className={`table-item ${
                                  prescriptionItem?.prescriptionItems?.length
                                    ? 'mt-2'
                                    : 'mt-0'
                                } bg-white `}>
                                <Col md={3} className="title d-block">
                                  Diagnosis
                                </Col>
                                <Col
                                  md={10}
                                  className=" d-block"
                                  style={{ whiteSpace: 'pre-line' }}>
                                  {prescriptionItem?.diagnosis}
                                </Col>
                              </Row>
                            ) : null}
                            {prescriptionItem?.adviceGiven ? (
                              <Row
                                className={`table-item ${
                                  prescriptionItem?.prescriptionItems?.length
                                    ? 'mt-2'
                                    : 'mt-0'
                                } bg-white `}>
                                <Col md={3} className="title d-block">
                                  Advice Given
                                </Col>
                                <Col
                                  md={10}
                                  className=" d-block c-gray"
                                  style={{ whiteSpace: 'pre-line' }}>
                                  {prescriptionItem?.adviceGiven}
                                </Col>
                              </Row>
                            ) : null}
                            {prescriptionItem?.treatmentNotes ? (
                              <Row
                                className={`table-item ${
                                  prescriptionItem?.prescriptionItems?.length
                                    ? 'mt-2'
                                    : 'mt-0'
                                } bg-white `}>
                                <Col md={3} className="title d-block">
                                  Treatment Notes
                                </Col>
                                <Col
                                  md={10}
                                  className=" d-block c-gray"
                                  style={{ whiteSpace: 'pre-line' }}>
                                  {prescriptionItem?.treatmentNotes}
                                </Col>
                              </Row>
                            ) : null}
                          </Row>
                        ))}
                      </Row>
                    </>
                  ) : (
                    <Col>
                      {translate('prescription_entry.no_prescription_added')}
                    </Col>
                  )}

                  <>
                    <Row>
                      <Col className="subheader-style pt-3 pb-0 mb-0">
                        {translate(
                          'consultation_tab.laboratory_details.laboratory_details',
                        )}
                      </Col>
                    </Row>
                    <hr className="mt-0" />
                    {labRecord?.length ? (
                      <>
                        <Row className="prescription-header-border">
                          <Col md={12} className="mt-3">
                            <Card
                              className="custom-card-font"
                              style={{ border: 'none' }}>
                              <Row>
                                <Col md={8} className="justify-content-start">
                                  {consultationDetails?.header?.businessName}
                                  <br />
                                  {consultationDetails?.header?.city},
                                  {consultationDetails?.header?.state}
                                </Col>
                                <Col md={3}>
                                  <Row>
                                    <Col>
                                      {translate(
                                        'consultation_tab.consultation_details.pet_name',
                                      )}
                                      <br />
                                      {translate(
                                        'consultation_tab.consultation_details.pet_type',
                                      )}
                                      <br />
                                      {translate(
                                        'consultation_tab.consultation_details.dob',
                                      )}
                                      <br />
                                    </Col>

                                    {consultationDetails?.petDetails?.name}
                                    <br />
                                    {consultationDetails?.petDetails?.petType}
                                    <br />
                                    {consultationDetails?.petDetails?.dob
                                      ? age
                                      : null}
                                  </Row>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Row>
                        <Row className="bottom-container">
                          <Row
                            className="table-item custom-font-weight"
                            style={{ backgroundColor: '#c9dfda' }}>
                            <Col
                              md={1}
                              className="title d-flex justify-content-center">
                              {translate('laboratory_entry.serial_number')}
                            </Col>
                            <Col md={2} className="title">
                              {translate('laboratory_entry.test')}
                            </Col>
                            <Col md={2} className="title">
                              {translate('laboratory_entry.reference_range')}
                            </Col>
                            <Col md={1} className="title">
                              {translate('laboratory_entry.units')}
                            </Col>
                            <Col md={2} className="title">
                              {translate('laboratory_entry.methodology')}
                            </Col>
                            <Col md={2} className="title">
                              {translate('laboratory_entry.result')}
                            </Col>
                          </Row>

                          {Object.entries(groupedLabRecords)?.map(
                            ([date, records]) => (
                              <>
                                <Row className="table-item mt-2 date-container">
                                  <span>{date}</span>
                                </Row>
                                {Array.isArray(records) &&
                                  records?.map((labItem, index) => (
                                    <Row
                                      key={index}
                                      className="table-item mt-2 bg-white p-3">
                                      <Col
                                        md={1}
                                        className="title justify-content-center d-flex table-text">
                                        <span style={{ marginRight: 5 }}>
                                          {index + 1}
                                        </span>
                                      </Col>
                                      <Col md={2} className="title">
                                        <span className="table-text">
                                          {labItem?.labTest?.name}
                                        </span>
                                      </Col>
                                      <Col md={2} className="title">
                                        <span className="table-text">
                                          {labItem?.referenceRange}
                                        </span>
                                      </Col>
                                      <Col md={1} className="title">
                                        <span className="table-text">
                                          {labItem.unit}
                                        </span>
                                      </Col>
                                      <Col md={2} className="title">
                                        <span className="table-text">
                                          {labItem?.methodology}
                                        </span>
                                      </Col>
                                      <Col md={2} className="title">
                                        <span className="table-text">
                                          {labItem?.results}
                                        </span>
                                      </Col>
                                      <Col md={2} className="title">
                                        <span className="table-text">
                                          {labItem?.attachments.length ? (
                                            <a
                                              target="_blank"
                                              href={labItem?.attachments[0]}>
                                              report
                                              <FontAwesomeIcon
                                                icon={faDownload}
                                                className="ms-1 fa-lg"
                                                color="var(--bs-grey-teal)"
                                              />
                                            </a>
                                          ) : (
                                            ''
                                          )}
                                        </span>
                                      </Col>
                                    </Row>
                                  ))}
                              </>
                            ),
                          )}
                        </Row>
                      </>
                    ) : (
                      <Col>
                        {translate(
                          'laboratory_entry.no_laboratory_details_added',
                        )}
                      </Col>
                    )}
                  </>

                  <>
                    <Row>
                      <Col className="subheader-style pt-3 pb-0 mb-0">
                        {translate('consultation_tab.reminder.tab_header')}
                      </Col>
                    </Row>
                    <hr className="mt-0" />
                    {getRemindersResponse?.length ? (
                      <ul className="list-unstyled">
                        {getRemindersResponse?.map((reminder, index) => {
                          let displayReminder;
                          if (reminder.reminderType.name === 'VACCINATION') {
                            displayReminder = `The next vaccination is scheduled on ${moment(
                              reminder.date,
                            ).format('DD-MM-YYYY')}`;
                          } else if (
                            reminder.reminderType.name === 'REVIEW SCHEDULE'
                          ) {
                            displayReminder = `The next review is scheduled on ${moment(
                              reminder.date,
                            ).format('DD-MM-YYYY')}`;
                          }
                          return (
                            <li key={index} className="reminder-item">
                              <div className="icon">
                                <FontAwesomeIcon
                                  icon={faCircleDot}
                                  className="circle-icon"
                                />
                              </div>
                              {displayReminder && (
                                <div className="reminder-display">
                                  <div className="reminder-title">
                                    {displayReminder}
                                  </div>
                                  <span>{reminder.remarks}</span>
                                </div>
                              )}
                              {!displayReminder && (
                                <span className="reminder-title">
                                  {reminder.remarks} -{' '}
                                  {moment(reminder?.date).format('DD-MM-YYYY')}
                                </span>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <Col>
                        {translate(
                          'consultation_tab.reminder.no_reminders_added',
                        )}
                      </Col>
                    )}
                  </>
                </CardBody>
              </Card>
            </Col>

            <Col className="mt-3 ms-3">
              <div>
                {medicalHistoryResponse?.map((item, index, i) => {
                  const serialNumber =
                    i + 1 + (currentPage - 1) * ITEMS_PER_PAGE;
                  const showConsultationType =
                    item.consultationType === 'In Patient' &&
                    item.doctorName === null;
                  if (index === selectedCard) {
                    return (
                      <Card
                        onClick={() => onChangeAppoinment(index)}
                        className="style-card mb-2 shadow selected-card">
                        <Row>
                          <Col
                            md={9}
                            className="heading-card-date padding-history-card">
                            {item.doctorName}
                            {showConsultationType && (
                              <div className="consultation-type">
                                {item.consultationType}
                              </div>
                            )}
                          </Col>
                          <Col md={3}>
                            {date ? moment(item.date).format('ll') : ''}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={9} className="padding-history-card">
                            {item.designation}
                          </Col>
                          <Col md={3}>{item?.type}</Col>
                        </Row>
                      </Card>
                    );
                  } else {
                    return (
                      <Card
                        onClick={() => onChangeAppoinment(index)}
                        className="style-card mb-2 shadow ">
                        <Row>
                          <Col
                            md={9}
                            className="heading-card-date padding-history-card">
                            {item.doctorName}
                            {showConsultationType && (
                              <div className="consultation-type">
                                {item.consultationType}
                              </div>
                            )}
                          </Col>
                          <Col md={3}>
                            {date ? moment(item.date).format('ll') : ''}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={9} className="padding-history-card">
                            {item.designation}
                          </Col>
                          <Col md={3}>{item?.type}</Col>
                        </Row>
                      </Card>
                    );
                  }
                })}
              </div>
            </Col>
          </Container>
          {totalCount && medicalHistoryResponse?.length ? (
            <Pagination
              count={numberOfPages}
              onChange={onPaginationChange}
              variant="outlined"
              shape="rounded"
              className="d-flex justify-content-center mb-4 mt-4"
            />
          ) : null}
        </>
      ) : (
        <Row className="heading-style-row w-100">
          <Col className="heading-style-col ">
            <span className="heading-left-style">{errorMessage}</span>
          </Col>
        </Row>
      )}
    </div>
  );
};

const mapStateToProps = ({
  authentication,
  user,
  videoConsultation,
}: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  medicalHistoryResponse: videoConsultation.medicalHistoryResponse,
  getRemindersResponse: videoConsultation.getRemindersResponse,
  loading: user.loading,
  weekDetail: videoConsultation.weekDetail,
  doctors: videoConsultation.doctors,
  appointments: videoConsultation.appointments,
  consultationDetails: videoConsultation.consultationDetails,
  initiateVideoCallResponse: videoConsultation.initiateVideoCallResponse,
});

const mapDispatchToProps = {
  fetchConsultationDetails,
  fetchMedicalHistory,
  downloadAppointment,
  getReminders,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicalHistory);
