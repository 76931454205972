import { API_URL } from './../config/constants';
import { ISpeciality } from './../model/speciality.model';
import axios from 'axios';
import { REQUEST, SUCCESS, FAILURE } from '../shared/reducers/action-type.util';
import { ILanguages } from '../model/language-model';
import { IPetTypes } from '../model/pet-type-model';
import { getToken } from '../config/constants';

export const ACTION_TYPES = {
  GET_SPECIALITIES: 'master/GET_SPECIALITIES',
  GET_LANGUAGES: 'master/GET_LANGUAGES',
  GET_PET_BREEDS: 'master/GET_PET_BREEDS',
  GET_PET_TYPES: 'master/GET_PET_TYPES',
  FETCH_SERVICES: 'master/FETCH_SERVICES',
  FETCH_QUALIFICATIONS: 'master/FETCH_QUALIFICATIONS',
  FETCH_USER_ROLES: 'master/FETCH_USER_ROLES',
  FETCH_COUNTRIES: 'master/FETCH_COUNTRIES',
  FETCH_STATES: 'master/FETCH_STATES',
  FETCH_CITIES: 'master/FETCH_CITIES',
  FETCH_CITY: 'master/FETCH_CITY',
  FETCH_COUNTRY: 'master/FETCH_COUNTRY',
  FETCH_STATE: 'master/FETCH_STATE',
  FETCH_ZONE: 'master/FETCH_ZONE',
  FETCH_ZONES: 'master/FETCH_ZONES',
  FETCH_DESIGNATIONS: 'master/FETCH_DESIGNATIONS',
  FETCH_STATES_BY_ZONE: 'master/FETCH_STATES_BY_ZONE',
  FETCH_PET_TYPE_VENDOR: 'master/FETCH_PET_TYPE_VENDOR',
  FETCH_BREED_PET_TYPE: 'master/FETCH_BREED_PET_TYPE',
};

const initialState = {
  loading: false,
  specialities: [] as ISpeciality[],
  languages: [] as ILanguages[],
  petTypes: [] as IPetTypes[],
  petBreeds: [] as any,
  services: [] as any[],
  qualifications: [] as any[],
  userRoles: [] as any[],
  countries: [] as any[],
  states: [] as any[],
  cities: [] as any[],
  city: {} as any,
  state: {} as any,
  country: {} as any,
  zone: {} as any,
  zones: [] as any[],
  designations: [] as any[],
  petTypeVendor: [] as any[],
  petBreedPetType: [] as any[],
};

export type MasterState = Readonly<typeof initialState>;

export const masterReducer = (
  state: MasterState = initialState,
  action,
): MasterState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_SPECIALITIES):
    case REQUEST(ACTION_TYPES.FETCH_SERVICES):
    case REQUEST(ACTION_TYPES.FETCH_QUALIFICATIONS):
    case REQUEST(ACTION_TYPES.FETCH_USER_ROLES):
    case REQUEST(ACTION_TYPES.GET_LANGUAGES):
    case REQUEST(ACTION_TYPES.FETCH_COUNTRIES):
    case REQUEST(ACTION_TYPES.FETCH_STATES):
    case REQUEST(ACTION_TYPES.FETCH_CITIES):
    case REQUEST(ACTION_TYPES.FETCH_DESIGNATIONS):
    case REQUEST(ACTION_TYPES.FETCH_CITY):
    case REQUEST(ACTION_TYPES.FETCH_STATE):
    case REQUEST(ACTION_TYPES.FETCH_COUNTRY):
    case REQUEST(ACTION_TYPES.FETCH_ZONE):
    case REQUEST(ACTION_TYPES.FETCH_ZONES):
    case REQUEST(ACTION_TYPES.FETCH_STATES_BY_ZONE):
    case REQUEST(ACTION_TYPES.FETCH_PET_TYPE_VENDOR):
    case REQUEST(ACTION_TYPES.FETCH_BREED_PET_TYPE): {
      return {
        ...state,
        loading: true,
      };
    }

    case SUCCESS(ACTION_TYPES.FETCH_USER_ROLES): {
      return {
        ...state,
        userRoles: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.FETCH_QUALIFICATIONS): {
      return {
        ...state,
        qualifications: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.GET_SPECIALITIES): {
      return {
        ...state,
        specialities: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.FETCH_SERVICES): {
      return {
        ...state,
        services: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.GET_LANGUAGES): {
      return {
        ...state,
        languages: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.FETCH_COUNTRIES): {
      return {
        ...state,
        countries: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.FETCH_STATES): {
      return {
        ...state,
        states: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.FETCH_CITIES): {
      return {
        ...state,
        cities: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.FETCH_CITY): {
      return {
        ...state,
        city: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.FETCH_COUNTRY): {
      return {
        ...state,
        country: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.FETCH_STATE): {
      return {
        ...state,
        state: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.FETCH_DESIGNATIONS): {
      return {
        ...state,
        designations: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.FETCH_ZONES): {
      return {
        ...state,
        zones: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.FETCH_ZONE): {
      return {
        ...state,
        zone: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.FETCH_STATES_BY_ZONE): {
      return {
        ...state,
        states: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.FETCH_PET_TYPE_VENDOR): {
      return {
        ...state,
        petTypeVendor: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.FETCH_BREED_PET_TYPE): {
      return {
        ...state,
        petBreedPetType: action.payload.data,
        loading: false,
      };
    }

    case FAILURE(ACTION_TYPES.GET_LANGUAGES): {
      return {
        ...state,
        languages: [],
        loading: false,
      };
    }

    case REQUEST(ACTION_TYPES.GET_PET_BREEDS):
    case REQUEST(ACTION_TYPES.GET_PET_TYPES): {
      return {
        ...state,
        loading: true,
      };
    }

    case SUCCESS(ACTION_TYPES.GET_PET_TYPES): {
      return {
        ...state,
        petTypes: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.GET_PET_BREEDS): {
      return {
        ...state,
        petBreeds: action.payload.data,
        loading: false,
      };
    }

    case FAILURE(ACTION_TYPES.GET_PET_BREEDS):
    case FAILURE(ACTION_TYPES.GET_PET_TYPES): {
      return {
        ...state,
        petTypes: [],
        loading: false,
      };
    }

    case FAILURE(ACTION_TYPES.GET_SPECIALITIES):
    case FAILURE(ACTION_TYPES.FETCH_SERVICES):
    case FAILURE(ACTION_TYPES.FETCH_QUALIFICATIONS):
    case FAILURE(ACTION_TYPES.FETCH_USER_ROLES):
    case FAILURE(ACTION_TYPES.FETCH_COUNTRIES):
    case FAILURE(ACTION_TYPES.FETCH_STATES):
    case FAILURE(ACTION_TYPES.FETCH_CITIES):
    case FAILURE(ACTION_TYPES.FETCH_COUNTRY):
    case FAILURE(ACTION_TYPES.FETCH_STATE):
    case FAILURE(ACTION_TYPES.FETCH_CITY):
    case FAILURE(ACTION_TYPES.FETCH_ZONES):
    case FAILURE(ACTION_TYPES.FETCH_ZONE):
    case FAILURE(ACTION_TYPES.FETCH_DESIGNATIONS):
    case FAILURE(ACTION_TYPES.FETCH_STATES_BY_ZONE):
    case FAILURE(ACTION_TYPES.FETCH_PET_TYPE_VENDOR):
    case FAILURE(ACTION_TYPES.FETCH_BREED_PET_TYPE): {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

export const getSpecialityList: any = payload => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_SPECIALITIES,
    payload: axios.get(
      `${API_URL}/v1/services/${payload?.serviceId}/specialities`,
      config,
    ),
  });
  return result;
};

export const getLanguageList: any = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_LANGUAGES,
    payload: axios.get(`${API_URL}/v1/languages?page=0&size=20`, config),
  });
  return result;
};

export const fetchServices: any = payload => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_SERVICES,
    payload: axios.get(`data/services.json`),
  });
  return result;
};

export const fetchQualifications: any = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_QUALIFICATIONS,
    payload: axios.get(`${API_URL}/qualifications`, config),
  });
  return result;
};

export const fetchVendorUserRoles: any = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_USER_ROLES,
    payload: axios.get(`${API_URL}/authorities?type=VENDOR`),
  });
  return result;
};

export const getPetTypes: any = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_PET_TYPES,
    payload: axios.get(`${API_URL}/v1/pet-types`, config),
  });
  return result;
};

export const getPetBreed: any = payload => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_PET_BREEDS,
    payload: axios.get(
      `${API_URL}/v1/breeds?page=${
        payload?.page ? payload?.page : 0
      }&size=${payload?.size?payload?.size:1000}${payload?.search ? '&search=' + payload?.search : ''}`,
      config,
    ),
  });
  return result;
};

export const fetchCountries: any = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_COUNTRIES,
    payload: axios.get(`${API_URL}/v1/countries`, config),
  });
  return result;
};

export const fetchZones: any = payload => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_ZONES,
    payload: axios.get(
      `${API_URL}/v1/zones?countryId=${payload?.countryId}`,
      config,
    ),
  });
  return result;
};

export const fetchStates: any = payload => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_STATES,
    payload: axios.get(
      `${API_URL}/v1/states?countryId=${payload?.countryId}`,
      config,
    ),
  });
  return result;
};

export const fetchCities: any = payload => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_CITIES,
    payload: axios.get(
      `${API_URL}/v1/cities?stateId=${payload?.stateId}`,
      config,
    ),
  });
  return result;
};

export const fetchCountry: any = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_COUNTRY,
    payload: axios.get(`${API_URL}/v1/countries/${id}`, config),
  });
  return result;
};

export const fetchState: any = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_STATE,
    payload: axios.get(`${API_URL}/v1/states/${id}`, config),
  });
  return result;
};

export const fetchCity: any = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_CITY,
    payload: axios.get(`${API_URL}/v1/cities/${id}`, config),
  });
  return result;
};

export const fetchZone: any = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_ZONE,
    payload: axios.get(`${API_URL}/v1/zones/${id}`, config),
  });
  return result;
};

export const fetchStatesByZone: any = payload => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_STATES_BY_ZONE,
    payload: axios.get(
      `${API_URL}/v1/states?zoneId=${payload?.zoneId}`,
      config,
    ),
  });
  return result;
};

export const fetchDesignations: any = payload => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_DESIGNATIONS,
    payload: axios.get(`${API_URL}/v1/designations`, config),
  });
  return result;
};

export const fetchPetTypeVendor: any = serviceId => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_PET_TYPE_VENDOR,
    payload: axios.get(
      `${API_URL}/v1/vendor/services/${serviceId}/pet-types`,
      config,
    ),
  });
  return result;
};

export const fetchBreedByPetType: any = petTypeId => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_BREED_PET_TYPE,
    payload: axios.get(
      `${API_URL}/customers/v1/petTypes/${petTypeId}/breeds?page=${0}&size=${1000}`,
      config,
    ),
  });
  return result;
};

export default masterReducer;
