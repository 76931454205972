import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { IRootState } from '../../shared/reducers';
import './style.scss';
import { translate, Translate } from 'react-jhipster';
import { Row, Col, Container } from 'reactstrap';
import {
  sendOtp,
  updatePostRegistrationState,
  verifyOtp,
} from '../../reducers/user.reducer';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { setLocale } from '../../shared/reducers/locale';
import leftBgImg from '../../assets/images/Mask Group.svg';
import { OTP_MAXIMUM_LENGTH, OTP_MINIMUM_LENGTH } from '../../config/constants';
import { AppLogo } from '../login/components/app-logo';
import Button from '../components/button';
import { toast } from 'react-toastify';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export const OtpValidation = (props: IPageProps) => {
  const { verifyOtpResponse, sendOtpResponse, otpLoading } = props;
  let [newOtp, setNewOtp] = useState('');
  let [number, setNumber] = useState('**********');
  const [key, setKey] = useState(props.match.params.id);
  const [sendOtpLoading, setSendOtpLoading] = useState(false);
  const [verifyOtpLoading, setVerifyOtpLoading] = useState(false);

  const handleChange = event => {
    setNewOtp(event.target.value);
  };

  const clickHandler = (event, value) => {
    let params = {
      key,
      otp: value?.otp,
    };
    setVerifyOtpLoading(true);
    props.verifyOtp(params);
  };

  const formatVerifyOtpResponse = () => {
    verifyOtpResponse.subscriptions =
      verifyOtpResponse?.vendor.subscriptions ?? [];
    verifyOtpResponse.id = verifyOtpResponse?.vendor.id;
    verifyOtpResponse.key = verifyOtpResponse?.vendor.activationKey;
    verifyOtpResponse.businessName = verifyOtpResponse?.vendor.businessName;
    verifyOtpResponse.contactPerson = verifyOtpResponse?.vendor.contactPerson;
    verifyOtpResponse.mobileNo = verifyOtpResponse?.vendor.mobileNo;
    verifyOtpResponse.email = verifyOtpResponse?.vendor.email;
    verifyOtpResponse.buildingName = verifyOtpResponse?.vendor.buildingName;
    verifyOtpResponse.address = verifyOtpResponse?.vendor.address;
    verifyOtpResponse.streetName = verifyOtpResponse?.vendor.streetName;
    verifyOtpResponse.registerNo = verifyOtpResponse?.vendor.registerNo;
    verifyOtpResponse.inceptionYear = verifyOtpResponse?.vendor.inceptionYear;
    verifyOtpResponse.landMark = verifyOtpResponse?.vendor.landMark;
    verifyOtpResponse.pincode = verifyOtpResponse?.vendor.pincode;
    verifyOtpResponse.status = verifyOtpResponse?.vendor.status;
    verifyOtpResponse.authorizedPerson =
      verifyOtpResponse?.vendor.authorizedPerson;
    verifyOtpResponse.onboardingDiscountPercentage =
      verifyOtpResponse?.vendor.onboardingDiscountPercentage;
    verifyOtpResponse.countryId = verifyOtpResponse?.vendor.country?.id;
    verifyOtpResponse.stateId = verifyOtpResponse?.vendor.state?.id;
    verifyOtpResponse.cityId = verifyOtpResponse?.vendor.city?.id;
    verifyOtpResponse.services = verifyOtpResponse?.services;
  };

  useEffect(() => {
    if (verifyOtpResponse && verifyOtpLoading) {
      formatVerifyOtpResponse();
      props.updatePostRegistrationState(verifyOtpResponse);
      props.history.push('/post-registration');
    }
  }, [verifyOtpResponse]);

  useEffect(() => {
    if (props.match.params.id && !otpLoading) {
      sendOtp();
    }
  }, [props.match.params.id]);

  const resendOtp = () => {
    sendOtp();
    toast.success(translate('OTP.resend_message'));
  };

  const sendOtp = () => {
    const { id } = props.match.params;
    setKey(id);
    setSendOtpLoading(true);
    props.sendOtp({ key: id });
  };

  useEffect(() => {
    if (sendOtpResponse) {
      setNumber(sendOtpResponse?.data?.mobile);
    }
  }, [sendOtpResponse]);

  useEffect(() => {
    if (sendOtpResponse && sendOtpLoading && sendOtpResponse?.data?.mobile) {
      setNumber(sendOtpResponse?.data?.mobile);
    }
  }, [sendOtpResponse]);

  return (
    <Container fluid className="px-0">
      <AvForm model={{}} onValidSubmit={clickHandler}>
        <Row>
          <Col className="left-content column p-0 logo-column">
            <Col>
              <div className="d-flex align-items-center justify-content-center">
                <div className="logo-container">
                  <AppLogo />
                </div>
              </div>
              <p className="title m-0">
                <Translate contentKey="OTP.welcome"> Welcome </Translate>{' '}
              </p>
              <div className="d-flex">
                <img style={{ width: '100%' }} src={leftBgImg} alt="bg-image" />
              </div>
            </Col>
          </Col>
          <Col className="d-flex justify-content-center align-items-center right-container column">
            <div className="otp-text text-number">
              <p className="enter-otp">Enter Your OTP</p>
              <Col className="mt-2 mb-2" lg="12">
                <br />
                <p>
                  <Translate contentKey="OTP.enter"> Enter OTP </Translate>
                  <br />
                  {number}
                </p>
                <AvField
                  value={newOtp}
                  onChange={handleChange}
                  className="p-3"
                  name="otp"
                  id="otp"
                  type="number"
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_otp.otp_number_error_message',
                      ),
                    },
                    minLength: {
                      value: `${OTP_MINIMUM_LENGTH}`,
                      errorMessage: translate(
                        'validation_otp.invalid_otp_error_message',
                      ),
                    },
                    maxLength: {
                      value: `${OTP_MAXIMUM_LENGTH}`,
                      errorMessage: translate(
                        'validation_otp.invalid_otp_error_message',
                      ),
                    },
                  }}
                />
              </Col>
              <Col className="mt-4 mb-4" lg="12">
                <Button type="submit" className="submit-btn w-100 py-2 px-4">
                  Continue
                </Button>
                <br />
                <button
                  type="button"
                  onClick={resendOtp}
                  className="resend-color mt-3">
                  <span className="text-center">
                    <Translate contentKey="OTP.resend"> Resend </Translate>
                  </span>
                </button>
              </Col>
            </div>
          </Col>
        </Row>
      </AvForm>
    </Container>
  );
};

const mapStateToProps = ({ authentication, plan, user }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  services: plan.services,
  loading: plan.loading,
  sendOtpResponse: user.updateSendOtpResponse,
  verifyOtpResponse: user.updateSuccessOtpResponse,
  otpLoading: user.loading,
});

const mapDispatchToProps = {
  sendOtp,
  verifyOtp,
  updatePostRegistrationState,
  setLocale,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OtpValidation);
