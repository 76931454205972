import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { IRootState } from '../../shared/reducers';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Input, Row, Table } from 'reactstrap';
import { translate } from 'react-jhipster';
import {
  fetchCustomerAppointments,
  fetchCustomerPet,
  fetchVetList,
} from '../../reducers/user.reducer';
import './styles.scss';
import filterSvg from '../customers/svg/filterSvg.svg';
import searchSvg from '../customers/svg/searchSvg.svg';
import { Cascader, DatePicker, Space } from 'antd';
import 'antd/dist/antd.css';
import { getPetTypes, getPetBreed } from '../../reducers/master.reducer';
import {
  CUSTOMER_APPOINTMENTS_FILTER,
  CUSTOMER_APPOINTMENT_MODE,
  CUSTOMER_APPOINTMENT_STATUS,
  DATE_TIME_FORMATS,
  PAYMENT_STATUS,
} from '../../config/constants';
import moment from 'moment-timezone';
import { PERMISSION_ACTIONS } from '../../config/permission-constants';
import { IsPermittedAction } from '../../shared/util/permission-utils';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchDoctors } from '../../reducers/video-consultation-reducer';
import Pagination from '@mui/material/Pagination';
import { ITEMS_PER_PAGE } from '../../shared/util/pagination.constants';

const UN_ASSIGNED_LIST_KEY = -1;

const { RangePicker } = DatePicker;

export interface ICustomerListProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ url: string }> {}

export const CustomerAppointmentDetails = (props: any) => {
  const {
    customerPets,
    customerAppointments,
    loading,
    petTypes,
    petBreeds,
    vetList,
    totalCount,
  } = props;
  const customerId = props.customerId;
  const [filterAppointment, setFilterAppointment] = useState([]);
  const [selectedOption, setSelectedOption] = useState([['', 0]]);
  const [selectedPetIds, setSelectedPetIds] = useState('');
  const [selectedBreedIds, setSelectedBreedIds] = useState('');
  const [selectedAppointmentStatus, setSelectedAppointmentStatus] =
    useState('');
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState('');
  const [appointmentMode, setAppointmentMode] = useState<string>('');
  const [selectedPetTypeIds, setSelectedPetTypeIds] = useState('');
  const [selectedVetIds, setSelectedVetIds] = useState('');
  const [searchForm, setSearchForm] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );

  useEffect(() => {
    props.fetchCustomerAppointments({ customerId });
    props.fetchCustomerPet(customerId);
    props.fetchDoctors();
    props.getPetTypes({});
    props.getPetBreed({});
  }, []);

  useEffect(() => {
    const data = [{ value: '0', label: 'All', children: [] as any }];
    CUSTOMER_APPOINTMENTS_FILTER.forEach(element =>
      data.push({ value: element, label: element, children: [] }),
    );
    customerPets.map(petName => {
      data[1].children.push({ value: petName.id, label: petName.petName });
    });
    petTypes.map(petType => {
      data[2].children.push({ value: petType.id, label: petType.name });
    });
    petBreeds.map(breed => {
      data[3].children.push({ value: breed.id, label: breed.name });
    });
    vetList.map(vetName => {
      vetName.id !== UN_ASSIGNED_LIST_KEY &&
        data[4].children.push({ value: vetName.id, label: vetName.name });
    });
    PAYMENT_STATUS.map(status => {
      data[6].children.push({ value: status, label: status });
    });
    CUSTOMER_APPOINTMENT_STATUS?.forEach(status => {
      data[5].children.push({ value: status, label: status });
    });
    CUSTOMER_APPOINTMENT_MODE?.forEach(mode => {
      data[7].children.push({ value: mode, label: mode });
    });
    setFilterAppointment(data as any);
  }, [petTypes, petBreeds, customerPets]);

  useEffect(() => {
    let petIds = '' as any;
    let petTypeIds = '' as any;
    let breedIds = '' as any;
    let vetIds = '' as any;
    let appointmentStatus = '' as any;
    let paymentStatus = '' as any;
    let appointmentMode = '' as any;
    selectedOption.forEach(element => {
      if (element[0] === CUSTOMER_APPOINTMENTS_FILTER[0]) {
        petIds.length === 0
          ? (petIds = element[1])
          : (petIds = petIds + ',' + element[1]);
      }
      if (element[0] === CUSTOMER_APPOINTMENTS_FILTER[1]) {
        petTypeIds.length === 0
          ? (petTypeIds = element[1])
          : (petTypeIds = petTypeIds + ',' + element[1]);
      }
      if (element[0] === CUSTOMER_APPOINTMENTS_FILTER[2]) {
        breedIds.length === 0
          ? (breedIds = element[1])
          : (breedIds = breedIds + ',' + element[1]);
      }
      if (element[0] === CUSTOMER_APPOINTMENTS_FILTER[3]) {
        vetIds.length === 0
          ? (vetIds = element[1])
          : (vetIds = vetIds + ',' + element[1]);
      }
      if (element[0] === CUSTOMER_APPOINTMENTS_FILTER[4]) {
        appointmentStatus.length === 0
          ? (appointmentStatus = element[1])
          : (appointmentStatus = appointmentStatus + ',' + element[1]);
      }
      if (element[0] === CUSTOMER_APPOINTMENTS_FILTER[5]) {
        paymentStatus.length === 0
          ? (paymentStatus = element[1])
          : (paymentStatus = paymentStatus + ',' + element[1]);
      }
      if (element[0] === CUSTOMER_APPOINTMENTS_FILTER[6]) {
        appointmentMode.length === 0
          ? (appointmentMode = element[1])
          : (appointmentMode = appointmentMode + ',' + element[1]);
      }
    });
    setSelectedPetIds(petIds);
    setSelectedPetTypeIds(petTypeIds);
    setSelectedBreedIds(breedIds);
    setSelectedVetIds(vetIds);
    setSelectedAppointmentStatus(appointmentStatus);
    setSelectedPaymentStatus(paymentStatus);
    setAppointmentMode(appointmentMode);
  }, [selectedOption]);

  useEffect(() => {
    setCurrentPage(1);
    customerAppointmentsApi(1);
  }, [
    customerId,
    selectedBreedIds,
    selectedPetTypeIds,
    selectedAppointmentStatus,
    selectedPaymentStatus,
    selectedPetIds,
    selectedVetIds,
    searchForm,
    fromDate,
    toDate,
    appointmentMode,
  ]);

  useEffect(() => {
    customerAppointmentsApi(currentPage);
  }, [currentPage]);

  const customerAppointmentsApi = page => {
    props.fetchCustomerAppointments({
      customerId: customerId,
      petIds: selectedPetIds,
      petTypeIds: selectedPetTypeIds,
      breedIds: selectedBreedIds,
      vetIds: selectedVetIds,
      appointmentStatus: selectedAppointmentStatus,
      paymentStatus: selectedPaymentStatus,
      type: appointmentMode,
      search: searchForm,
      fromDate,
      toDate,
      page: page - 1,
      size: ITEMS_PER_PAGE,
    });
  };

  useEffect(() => {
    setNumberOfPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
  }, [customerAppointments]);

  const searchHandler = event => {
    event.persist();
    setSearchForm(event.target.value);
  };

  const handleDate = value => {
    if (value) {
      setFromDate(moment.utc(value[0]).format('YYYY-MM-DD'));
      setToDate(moment.utc(value[1]).format('YYYY-MM-DD'));
    } else {
      setFromDate('');
      setToDate('');
    }
  };

  const onPaginationChange = (e, page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Row className="d-flex align-items-right mb-4 mt-4">
        <Col md={3}></Col>
        <Col md={3}>
          <Space direction="vertical" size={12}>
            <RangePicker
              className="mt-1 mr-1 w-100"
              onChange={data => handleDate(data)}
              format={DATE_TIME_FORMATS.DD_MM_YYYY}
            />
          </Space>
        </Col>
        <Col md={3}>
          <div className="fa-search position-relative ant-d-style">
            <Cascader
              className="w-100"
              multiple
              options={filterAppointment}
              onChange={data => setSelectedOption(data)}
              placeholder="Please select"
              maxTagCount="responsive"
            />
            <img
              src={filterSvg}
              alt=""
              className="position-absolute pr-3"
              style={{ top: 10, left: 6 }}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="fa-search position-relative">
            <Input
              className="form-control input-lg search-style"
              type="text"
              value={searchForm}
              onChange={searchHandler}
              placeholder="Pet Name"></Input>
            <img
              src={searchSvg}
              alt=""
              className="position-absolute"
              style={{ top: 6, left: 6 }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        {customerAppointments && customerAppointments?.length ? (
          <>
            <Col md={12} className="border-2 shadow-sm mt-3">
              <Table responsive className="mb-0 ">
                <thead className="border-0 border-bottom border-1">
                  <tr>
                    <th className="border-0 ps-4 pe-2 py-3">
                      {translate(
                        `customer_tab.customer_appointment_details.pet_name`,
                      )}
                    </th>
                    <th className="border-0 px-2 py-3">
                      {translate(
                        `customer_tab.customer_appointment_details.pet_type`,
                      )}
                    </th>
                    <th className="border-0 px-2 py-3">
                      {translate(
                        `customer_tab.customer_appointment_details.breed`,
                      )}
                    </th>
                    <th className="border-0 px-2 py-3">
                      {translate(
                        `customer_tab.customer_appointment_details.vet_name`,
                      )}
                    </th>
                    <th className="border-0 ps-2 pe-4 py-3">
                      {translate(
                        `customer_tab.customer_appointment_details.time`,
                      )}
                    </th>
                    <th className="border-0 px-2 py-3">
                      {translate(
                        `customer_tab.customer_appointment_details.appointment_status`,
                      )}
                    </th>
                    <th className="border-0 ps-2 pe-4 py-3">
                      {translate(
                        `customer_tab.customer_appointment_details.payment_status`,
                      )}
                    </th>
                    <th className="border-0 ps-2 pe-4 py-3">
                      {translate(
                        `customer_tab.customer_appointment_details.paid_amount`,
                      )}
                    </th>
                    <th className="border-0 ps-2 pe-4 py-3">
                      {translate(
                        `customer_tab.customer_appointment_details.appointment_mode`,
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody className="border-top-0">
                  {Array.isArray(customerAppointments) &&
                    customerAppointments?.map((customerAppointment, i) => (
                      <tr
                        className="border-0 border-bottom border-1"
                        key={`entity-${i}`}
                        data-cy="entityTable">
                        <td className="border-0 px-2 py-4">
                          {customerAppointment?.petName}
                        </td>
                        <td className="border-0 ps-4 pe-2 py-4">
                          {customerAppointment?.petType}
                        </td>
                        <td className="border-0 px-2 py-4">
                          {customerAppointment?.breed}
                        </td>
                        <td className="border-0 px-2 py-4">
                          {customerAppointment?.vetName}
                        </td>
                        <td className="border-0 px-2 py-4">
                          {moment(customerAppointment?.appointmentDate).format(
                            'MMMM Do YYYY',
                          )}
                          <br />
                          {moment(
                            customerAppointment?.slotFrom,
                            'hh:mm',
                          ).format('h:mm a')}
                          -
                          {moment(customerAppointment?.slotTo, 'hh:mm').format(
                            'h:mm a',
                          )}
                        </td>
                        <td className="border-0 px-2 py-4">
                          {customerAppointment?.appointmentStatus}
                        </td>
                        <td className="border-0 px-2 py-4">
                          {customerAppointment?.paymentStatus}
                        </td>
                        <td className="border-0 px-2 py-4">
                          {customerAppointment?.paidAmount}
                        </td>
                        <td className="border-0 px-2 py-4">
                          {customerAppointment?.type}
                        </td>
                        <td className="border-0 pa-2 pe-4 py-4">
                          <div>
                            {IsPermittedAction(
                              PERMISSION_ACTIONS.GET_CUSTOMER_APPOINTMENT,
                              authorities,
                              permissionList,
                            ) &&
                            customerAppointment?.appointmentStatus ===
                              'COMPLETED' ? (
                              <Button
                                tag={Link}
                                to={{
                                  pathname: `/customer/appointment-details/${customerAppointment.id}`,
                                  state: { customerId },
                                }}
                                color="danger"
                                size="sm"
                                data-cy="entityDeleteButton">
                                <div style={{ color: 'white' }}>
                                  View Details
                                </div>
                              </Button>
                            ) : (
                              ''
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
            {totalCount && customerAppointments?.length ? (
              <Pagination
                count={numberOfPages}
                onChange={onPaginationChange}
                variant="outlined"
                shape="rounded"
                className="d-flex justify-content-center mb-4 mt-4"
              />
            ) : null}
          </>
        ) : (
          !loading && (
            <div
              className="alert alert-warning"
              style={{ display: 'flex', justifyContent: 'center' }}>
              {translate(
                `customer_tab.customer_appointment_details.no_appointments_found`,
              )}
            </div>
          )
        )}
      </Row>
    </div>
  );
};

const mapStateToProps = ({
  vendorUser,
  user,
  master,
  videoConsultation,
}: IRootState) => ({
  customerAppointments: user.customerAppointments,
  customerPets: user.customerPets,
  loading: vendorUser.loading,
  petTypes: master.petTypes,
  petBreeds: master.petBreeds,
  vetList: videoConsultation.doctors,
  totalCount: user.totalCount,
});

const mapDispatchToProps = {
  fetchCustomerAppointments,
  getPetTypes,
  fetchCustomerPet,
  getPetBreed,
  fetchDoctors,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerAppointmentDetails);
