import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { IRootState } from '../../shared/reducers';
import { Link } from 'react-router-dom';
import './style.scss';
import { translate, Translate } from 'react-jhipster';
import { Navbar } from 'react-bootstrap';
import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import {
  getVendorProfile,
  editVendorProfile,
} from '../../reducers/user.reducer';
import {
  fetchCities,
  fetchCountries,
  fetchStates,
  fetchCountry,
  fetchState,
  fetchCity,
} from '../../reducers/master.reducer';
import { REG_EX } from '../../config/constants';
import Button from '../components/button';
import { PHONE_NUMBER_LENGTH } from '../../config/constants';
export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}
export const VendorProfileEdit = (props: any) => {
  const history = useHistory();
  const { getVendorResponse } = props;
  const NAME_MINIMUM_LENGTH = 2;
  const PIN_NUMBER_LENGTH = 6;
  const { editVendorResponse } = props;
  const [countries, setCountries] = useState([] as any[]);
  const [states, setStates] = useState([] as any[]);
  const [cities, setCities] = useState([] as any[]);
  const [zones, setZones] = useState([] as any[]);
  const [profileDetail, setProfileDetail] = useState({
    name: '',
    email: '',
    buildingName: '',
    streetName: '',
    address: '',
    countryId: '',
    zoneId: '',
    stateId: '',
    cityId: '',
    inceptionYear: '',
    landMark: '',
    pincode: '',
  } as any);

  useEffect(() => {
    props.getVendorProfile({});
  }, []);
  useEffect(() => {
    if (getVendorResponse) {
      setProfileDetail(getVendorResponse);
    }
    if (getVendorResponse.countryId) {
      props?.fetchCountries({});
      props?.fetchStates({ countryId: getVendorResponse?.countryId });
      props?.fetchCities({ stateId: getVendorResponse?.stateId });
    }
  }, [getVendorResponse]);

  useEffect(() => {
    setCountries(props?.countries);
  }, [props.countries]);

  useEffect(() => {
    setStates(props?.states);
  }, [props.states]);

  useEffect(() => {
    setCities(props?.cities);
  }, [props.cities]);

  useEffect(() => {
    setZones(props?.zones);
  }, [props.zones]);

  const saveVendorDetails = (event, values) => {
    const entity = {
      ...profileDetail,
    };
    props.editVendorProfile(entity);
    history.push('/vendor-profile-view');
  };

  const handleChange = (event, key) => {
    setProfileDetail({ ...profileDetail, [key]: event.target.value });
  };

  const handleCountryChange = (event, key) => {
    props?.fetchStates({
      countryId: event.target?.value,
    });
    setProfileDetail({ ...profileDetail, [key]: event.target.value });
  };

  const handleStateChange = (event, key) => {
    props?.fetchCities({
      stateId: event.target?.value,
    });
    setProfileDetail({ ...profileDetail, [key]: event.target.value });
  };

  const year = new Date().getFullYear();
  return (
    <div>
      <Navbar className="navbar">
        <Navbar.Brand className="my-profile-text">
          {translate('form.register.my_profile')}
        </Navbar.Brand>
      </Navbar>
      <div>
        <Row className="heading-center">
          {translate('form.register.basic_details')}
        </Row>
        <hr />
        <div>
          <AvForm className="form-style" onValidSubmit={saveVendorDetails}>
            <Row className="style-row mt-3 mb-4">
              <Col>
                <p className="mb-2">
                  <Translate contentKey="form.register.name"> name</Translate>
                  <span>*</span>
                </p>
                <AvField
                  className="avfield-style"
                  type="text"
                  name="name"
                  validate={{
                    pattern: {
                      value: REG_EX.name,
                      errorMessage: translate(
                        'validation_registration.invalid_name_error_message',
                      ),
                    },
                    required: {
                      errorMessage: translate(
                        'validation_registration.name_error_message',
                      ),
                    },
                  }}
                  value={profileDetail?.name}
                  onChange={event => handleChange(event, [event.target.name])}
                />
              </Col>
              <Col>
                <p className="mb-2">
                  <Translate contentKey="form.register.building">
                    Building No/Name
                  </Translate>
                  <span>*</span>
                </p>
                <AvField
                  className="avfield-style"
                  type="text"
                  name="buildingName"
                  value={profileDetail?.buildingName}
                  onChange={event => handleChange(event, [event.target.name])}
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_registration.building_number_error_message',
                      ),
                    },
                  }}
                />
              </Col>
            </Row>
            <Row className="style-row mb-4">
              <Col>
                <p className="mb-2">
                  <Translate contentKey="form.register.email"> email</Translate>
                  <span>*</span>
                </p>
                <AvField
                  readOnly={true}
                  className="avfield-style"
                  type="text"
                  name="email"
                  required
                  value={profileDetail?.email}
                  onChange={event => handleChange(event, [event.target.name])}
                />
              </Col>
              <Col>
                <p className="mb-2">
                  <Translate contentKey="form.register.mobile">
                    {' '}
                    Mobile
                  </Translate>
                  <span>*</span>
                </p>
                <AvField
                  className="avfield-style"
                  type="number"
                  name="mobile"
                  value={profileDetail?.mobile}
                  onChange={event => handleChange(event, [event.target.name])}
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_registration.number_error_message',
                      ),
                    },
                    minLength: {
                      value: `${PHONE_NUMBER_LENGTH}`,
                      errorMessage: translate(
                        'validation_registration.invalid_number_error_message',
                      ),
                    },
                    maxLength: {
                      value: `${PHONE_NUMBER_LENGTH}`,
                      errorMessage: translate(
                        'validation_registration.invalid_number_error_message',
                      ),
                    },
                  }}
                />
              </Col>
            </Row>
            <Row className="style-row mb-4">
              <Col>
                <p className="mb-2">
                  <Translate contentKey="form.register.address">
                    address
                  </Translate>
                  <span>*</span>
                </p>
                <AvField
                  className="avfield-style"
                  type="text"
                  name="address"
                  value={profileDetail?.address}
                  onChange={event => handleChange(event, [event.target.name])}
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_registration.address_error_message',
                      ),
                    },
                  }}
                />
              </Col>
              <Col>
                <p className="mb-2">
                  <Translate contentKey="form.register.street">
                    street
                  </Translate>
                  <span>*</span>
                </p>
                <AvField
                  className="avfield-style"
                  type="text"
                  name="streetName"
                  value={profileDetail?.streetName}
                  onChange={event => handleChange(event, event.target.name)}
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_registration.street_error_message',
                      ),
                    },
                  }}
                />
              </Col>
            </Row>
            <Row className="style-row mb-4">
              <Col>
                <p className="mb-2">
                  <Translate contentKey="form.register.country">
                    country
                  </Translate>
                  <span>*</span>
                </p>
                <AvField
                  type="select"
                  name="countryId"
                  id="countryId"
                  onChange={event =>
                    handleCountryChange(event, event.target.name)
                  }
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_registration.country_error_message',
                      ),
                    },
                  }}
                  value={profileDetail.countryId}>
                  <option value="" key="">
                    {translate('form.register.select_country')}
                  </option>
                  {countries?.map((country, index) => {
                    return (
                      <option key={index} value={country?.id}>
                        {country?.name}
                      </option>
                    );
                  })}
                </AvField>
              </Col>
              <Col>
                <p className="mb-2">
                  <Translate contentKey="form.register.state"> state</Translate>
                  <span>*</span>
                </p>
                <AvField
                  type="select"
                  name="stateId"
                  id="stateId"
                  onChange={event =>
                    handleStateChange(event, event.target.name)
                  }
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_registration.state_error_message',
                      ),
                    },
                  }}
                  value={profileDetail.stateId}>
                  <option value="">
                    {translate('form.register.select_state')}
                  </option>
                  {states?.map((state, index) => {
                    return (
                      <option key={index} value={state?.id}>
                        {state?.name}
                      </option>
                    );
                  })}
                </AvField>
              </Col>
            </Row>
            <Row className="style-row mb-4">
              <Col>
                <p className="mb-2">
                  <Translate contentKey="form.register.city_location">
                    city
                  </Translate>
                  <span>*</span>
                </p>
                <AvField
                  type="select"
                  name="cityId"
                  id="cityId"
                  value={profileDetail.cityId}
                  onChange={event => handleChange(event, event.target.name)}
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_registration.city_error_message',
                      ),
                    },
                  }}>
                  <option value="">
                    {translate('form.register.select_city')}
                  </option>
                  {cities?.map((city, index) => {
                    return (
                      <option key={index} value={city?.id}>
                        {city?.name}
                      </option>
                    );
                  })}
                </AvField>
              </Col>
              <Col>
                <p className="mb-2">
                  <Translate contentKey="form.register.pincode">
                    pincode
                  </Translate>
                  <span>*</span>
                </p>
                <AvField
                  className="avfield-style"
                  type="number"
                  name="pincode"
                  id="pincode"
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_registration.pin_error_message',
                      ),
                    },
                    maxLength: {
                      value: `${PIN_NUMBER_LENGTH}`,
                      errorMessage: translate(
                        'validation_registration.invalid_pin_error_message',
                      ),
                    },
                    minLength: {
                      value: `${PIN_NUMBER_LENGTH}`,
                      errorMessage: translate(
                        'validation_registration.invalid_pin_error_message',
                      ),
                    },
                  }}
                  value={`${profileDetail?.pincode}`}
                  onChange={event => handleChange(event, event.target.name)}
                />
              </Col>
            </Row>
            <Row className="style-row mb-4">
              <Col>
                <p className="mb-2">
                  <Translate contentKey="form.register.landmark">
                    landMark
                  </Translate>
                </p>
                <AvField
                  className="avfield-style"
                  type="text"
                  name="landMark"
                  value={profileDetail?.landMark}
                  onChange={event => handleChange(event, event.target.name)}
                />
              </Col>
              <Col></Col>
            </Row>
            <Row className="pt-3 mb-3">
              <Col>
                <Button
                  transparent
                  tag={Link}
                  id="cancel-save"
                  to="/vendor-profile-view"
                  replace
                  className="px-4"
                  color="primary">
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
              </Col>
              <Col>
                <Button id="save" color="danger" type="submit" className="px-4">
                  <Translate contentKey="entity.action.save">save</Translate>
                </Button>
              </Col>
            </Row>
          </AvForm>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  authentication,
  plan,
  user,
  master,
}: IRootState) => ({
  getVendorResponse: user.getVendorProfileResponse,
  editVendorResponse: user.editVendorProfile,
  countries: master?.countries,
  states: master?.states,
  cities: master?.cities,
  country: master?.country,
  state: master?.state,
  city: master?.city,
});

const mapDispatchToProps = {
  getVendorProfile,
  editVendorProfile,
  fetchCountry,
  fetchCity,
  fetchState,
  fetchCountries,
  fetchStates,
  fetchCities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VendorProfileEdit);
