import { Button } from "reactstrap";
import './styles.scss';
export const ButtonComponent = (props) => {
    return (
        <Button
            {...props}
            onClick={props.onClick}
            className={`common-button bg-danger text-light p-2 
            ${props?.className}
            ${props?.transparent ? 'transparent' : ''}`}
        >
            {props.children}
        </Button>
    )
}
export default ButtonComponent;