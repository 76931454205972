import { useSelector } from 'react-redux';
import { APP_MENUS_AND_PERMISSIONS } from '../../config/permission-constants';
import { IRootState } from '../../shared/reducers';

export const getUserAuthorizedPermissions = (permissionList, authorities) => {
  let userAuthorizedPermissions = [] as any;
  permissionList.forEach(permission => {
    if (authorities?.includes(permission?.roleCode))
      userAuthorizedPermissions = [
        ...userAuthorizedPermissions,
        ...permission.allowedActions,
      ];
  });
  return userAuthorizedPermissions;
};

export const getPermittedMenus = (menus, authorities, permissions) => {
  const permissionList = permissions?.rolePermissions ?? [];
  const userAuthorizedPermissions = getUserAuthorizedPermissions(
    permissionList,
    authorities,
  );

  const appMenus = menus.filter(menu => {
    const menu_key = menu.key;
    if (!menu_key) return false;

    return APP_MENUS_AND_PERMISSIONS[menu_key]?.every(permission =>
      userAuthorizedPermissions.includes(permission),
    );
  });

  return appMenus;
};

export function IsPermittedAction(permissionKey,authorities=[],permissionList=[]) {
  const userAuthorizedPermissions = getUserAuthorizedPermissions(
    permissionList,
    authorities,
  );

  return userAuthorizedPermissions.includes(permissionKey);
}