import { isPromise, translate } from 'react-jhipster';
import { Redirect } from 'react-router';
import { toast } from 'react-toastify';
import { HTTP_CREATED_RESPONSE, TOAST_TIMEOUT } from './constants';
import * as Sentry from '@sentry/browser';
import { sentryApiLog } from '../utils/sentry-api-log-util';

const addErrorAlert = (message, key?, data?) => {
  key = key ? key : message;
  //commented for future
  // toast.error(translate(key, data));
};

const showNotification = (msg, isSuccess) => {
  if (isSuccess) {
    //commented for future
    // toast.success(msg);
  } else {
    // toast.error(msg);
  }
};

const sentryThrow = (error, response) => {
  const account = localStorage.getItem('account') || '';
  let accountObj = JSON.parse(account);

  return Sentry.configureScope(scope => {
    scope.setExtra('Response', response);
    scope.setUser({
      Id: accountObj?.id,
      Email: accountObj?.login,
      Name: accountObj?.firstName,
      Role: accountObj?.authorities,
    });
    Sentry.captureException(error);
  });
};

export default () => next => action => {
  // If not a promise, continue on
  if (!isPromise(action.payload)) {
    return next(action);
  }

  /**
   *
   * The notification middleware serves to dispatch the initial pending promise to
   * the promise middleware, but adds a `then` and `catch.
   */
  return next(action)
    .then(response => {
      if(response?.value?.config.method != 'get' && response?.value?.config.method != 'patch' && !response.value.data.id_token) {
        sentryApiLog(response.value)
      }
      if (response.value.status === HTTP_CREATED_RESPONSE) {
        showNotification(translate('success'), true);
      }
      if (action.meta && action.meta.successMessage) {
        showNotification(action.meta.successMessage, true);
      } else if (
        response &&
        response.action &&
        response.action.payload &&
        response.action.payload.headers
      ) {
        const headers = response.action.payload.headers;
        let alert: string | null = null;
        let alertParams: string | null = null;
        Object.entries<string>(headers).forEach(([k, v]) => {
          if (k.toLowerCase().endsWith('app-alert')) {
            alert = v;
          } else if (k.toLowerCase().endsWith('app-params')) {
            alertParams = decodeURIComponent(v.replace(/\+/g, ' '));
          }
        });
        if (alert) {
          const alertParam = alertParams;
          showNotification(translate(alert, { param: alertParam }), true);
        }
      }
      return Promise.resolve(response);
    })
    .catch(error => {
      if(error?.response?.config?.method != 'get' && error?.response?.config?.method != 'patch') {
        sentryApiLog(error.response)
      }
      if (action.meta && action.meta.errorMessage) {
        toast.error(action.meta.errorMessage);
      } else if (error && error.response) {
        const response = error.response;
        const data = response.data;
        if (
          !(
            response.status === 401 &&
            (error.message === '' ||
              (data && data.path && data.path.includes('/api/v1/account')))
          )
        ) {
          let i;
          switch (response.status) {
            // connection refused, server not reachable
            case 0:
              toast.error('Server not reachable');
              addErrorAlert(
                'Server not reachable',
                'error.server.not.reachable',
              );
              break;

            case 400: {
              const headers = Object.entries<string>(response.headers);
              let errorHeader: string | null = null;
              let entityKey: string | null = null;
              headers.forEach(([k, v]: [string, string]) => {
                if (k.toLowerCase().endsWith('app-error')) {
                  errorHeader = v;
                } else if (k.toLowerCase().endsWith('app-params')) {
                  entityKey = v;
                }
              });
              if (errorHeader) {
                const entityName = translate(
                  'global.menu.entities.' + entityKey,
                );
                addErrorAlert(errorHeader, errorHeader, { entityName });
                showNotification(errorHeader, false);
              } else if (data !== '' && data.fieldErrors) {
                const fieldErrors = data.fieldErrors;
                for (i = 0; i < fieldErrors.length; i++) {
                  const fieldError = fieldErrors[i];
                  if (
                    ['Min', 'Max', 'DecimalMin', 'DecimalMax'].includes(
                      fieldError.message,
                    )
                  ) {
                    fieldError.message = 'Size';
                  }
                  // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                  const convertedField = fieldError.field.replace(
                    /\[\d*\]/g,
                    '[]',
                  );
                  const fieldName = translate(
                    `majwebApp.${fieldError.objectName}.${convertedField}`,
                  );
                  fieldErrors?.forEach(item => {
                    showNotification(
                      `Error on field ${item?.field}: ${item?.message}`,
                      false,
                    );
                  });
                  addErrorAlert(
                    `Error on field "${fieldName}"`,
                    `error.${fieldError.message}`,
                    { fieldName },
                  );
                }
              } else if (data !== '' && data.title) {
                toast.error(data.title);
              } else if (data !== '' && data.message) {
                //commented for future
                toast.error(data.message);
                showNotification(data.title ?? data.message, false);
                addErrorAlert(data.message, data.message, data.params);
              } else {
                toast.error(data?.title || data);
                addErrorAlert(data);
              }
              break;
            }
            case 404:
              showNotification('Not found', false);
              addErrorAlert('Not found', 'error.url.not.found');
              break;
            case 403:
              toast.error(translate('error_messages.you_are_not_authorized'), {
                autoClose: TOAST_TIMEOUT,
              });
              break;
            default:
              if (data !== '' && data.message) {
                showNotification(data?.title ?? data.message, false);
                addErrorAlert(data.message);
              } else {
                toast.error(data);
                addErrorAlert(data);
              }
          }
        }
      } else if (
        error &&
        error?.config &&
        error?.config?.url === 'api/v1/account' &&
        error?.config?.method === 'get'
      ) {
        /* eslint-disable no-console */
        console.log(
          'Authentication Error: Trying to access url api/v1/account with GET.',
        );
      } else if (error && error.message) {
        showNotification(error.message, false);
      } else {
        showNotification('Unknown error!', false);
      }
      return Promise.reject(error), sentryThrow(error, error?.response?.data);
    });
};
