export const PendingCallIcon = () => {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.00432 3.30286C2.09953 3.46785 2.16077 3.57411 2.22207 3.68031C2.49028 4.14503 2.74653 4.61719 3.03078 5.072C3.23762 5.40302 3.36619 5.67595 3.13172 6.09103C2.87648 6.54278 2.96988 7.06808 3.23014 7.52328C4.18307 9.19085 5.13791 10.8576 6.11155 12.5132C6.45238 13.0928 6.99309 13.3704 7.67355 13.3658C7.73667 13.3653 7.82974 13.4039 7.85905 13.4537C8.28484 14.1769 8.70213 14.9051 9.13164 15.6499C8.55878 15.8773 7.97878 15.9536 7.38645 15.8817C5.90327 15.7016 4.78183 14.9457 4.0275 13.6685C3.07055 12.0483 2.1199 10.4236 1.20576 8.77893C0.329412 7.20204 0.321842 5.61635 1.3346 4.08168C1.51189 3.81287 1.75604 3.58812 2.00432 3.30286Z" fill="#EF4136" />
            <path d="M9.56333 11.0813C9.95281 11.0819 10.2134 11.2235 10.3788 11.5057C10.9137 12.4184 11.4452 13.3334 11.9659 14.2544C12.2097 14.6859 12.0755 15.1723 11.6571 15.4357C11.4137 15.5889 11.1638 15.733 10.9096 15.8675C10.4592 16.1057 9.97673 15.9771 9.71804 15.5365C9.18598 14.631 8.66135 13.7211 8.14353 12.8075C7.91774 12.4091 8.01144 11.933 8.37583 11.6825C8.66963 11.4806 8.98906 11.3147 9.3041 11.1459C9.39603 11.0967 9.51244 11.0931 9.56333 11.0813Z" fill="#EF4136" />
            <path d="M4.81692 6.05062C4.42399 6.05273 4.16228 5.91136 3.99745 5.62961C3.4594 4.71015 2.92493 3.78831 2.40372 2.8593C2.16824 2.43964 2.30972 1.95565 2.71997 1.6991C2.97039 1.54252 3.22694 1.39451 3.48844 1.25753C3.91772 1.03277 4.39769 1.1581 4.64492 1.57726C5.18231 2.48811 5.70988 3.40479 6.23098 4.32513C6.45782 4.72583 6.36539 5.19766 6.00053 5.44992C5.70757 5.6525 5.38784 5.81785 5.07296 5.98663C4.98097 6.03574 4.86462 6.0395 4.81692 6.05062Z" fill="#EF4136" />
            <path d="M12.8049 3.12255C12.8049 3.86931 12.8063 4.61611 12.8045 5.36295C12.8032 5.86519 12.4936 6.20445 12.0387 6.20787C11.5733 6.21129 11.2591 5.87001 11.2587 5.35437C11.2576 3.86854 11.2577 2.38272 11.2587 0.896891C11.2591 0.386272 11.5809 0.0313312 12.0358 0.0344132C12.4934 0.0374618 12.8038 0.387545 12.8046 0.905433C12.8059 1.64449 12.8049 2.38352 12.8049 3.12255Z" fill="#EF4136" />
            <path d="M12.0446 6.64134C12.5865 6.64368 13.0282 7.09517 13.0272 7.64559C13.0262 8.19246 12.5743 8.65085 12.0383 8.64884C11.4912 8.64676 11.0416 8.18251 11.0449 7.62301C11.0483 7.06938 11.4866 6.63889 12.0446 6.64134Z" fill="#EF4136" />
        </svg>
    );
};
