import React, { Fragment, useEffect, useState } from "react";
import "./App.scss";
import { connect } from "react-redux";
import { IRootState } from "./shared/reducers";
import { getSession, logout } from "./shared/reducers/authentication";
import { setLocale } from "./shared/reducers/locale";
import ErrorBoundary from "./shared/error/error-boundary";
import AppRoutes from "./routes";
import { hasAnyAuthority } from "./shared/auth/private-route";
import { AUTHORITIES, getToken } from "./config/constants";
import "react-toastify/dist/ReactToastify.css";



export interface IAppProps extends StateProps, DispatchProps { }

export const App = (props: IAppProps) => {
  const { isAuthenticated } = props;
  useEffect(() => {
    props.getSession();
  }, []);

  return (
    <AppRoutes
      {...props}
    />
  );
};
const mapStateToProps = ({ authentication, locale, user }: IRootState) => ({
  currentLocale: locale?.currentLocale,
  isAuthenticated: authentication?.isAuthenticated,
  isAdmin: hasAnyAuthority(authentication?.account?.authorities, [
    AUTHORITIES.ADMIN,
  ]),
});

const mapDispatchToProps = { getSession, setLocale, logout };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(App);
