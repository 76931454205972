import { API_URL } from './../config/constants';
import axios from 'axios';
import { getToken } from '../config/constants';

import { REQUEST, SUCCESS, FAILURE } from '../shared/reducers/action-type.util';

export const ACTION_TYPES = {
  GET_MEDICINE_LIST: 'plan/GET_MEDICINE_LIST',
  GET_PRESCRIPTION: 'plan/GET_PRESCRIPTION',
  CREATE_PRESCRIPTION: 'plan/CREATE_PRESCRIPTION',
  UPDATE_PRESCRIPTION: 'plan/UPDATE_PRESCRIPTION',
  GET_TEST_LIST: 'plan/GET_TEST_LIST',
  GET_LAB_RECORD: 'plan/GET_LAB_RECORD',
  CREATE_LABORATORY: 'plan/CREATE_LABORATORY',
  UPDATE_LABORATORY: 'plan/UPDATE_LABORATORY',
};

const initialState = {
  loading: false,
  medicines: [] as any[],
  prescription: {} as any,
  createPrescriptionResponse: {} as any,
  updatePrescriptionResponse: {} as any,
  tests: [] as any[],
  labRecord: [] as any[],
  createLaboratoryResponse: {} as any,
  updateLaboratoryResponse: {} as any,
};

export type PrescriptionState = Readonly<typeof initialState>;

export default (
  state: PrescriptionState = initialState,
  action,
): PrescriptionState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_MEDICINE_LIST):
    case REQUEST(ACTION_TYPES.GET_PRESCRIPTION):
    case REQUEST(ACTION_TYPES.CREATE_PRESCRIPTION):
    case REQUEST(ACTION_TYPES.UPDATE_PRESCRIPTION):
    case REQUEST(ACTION_TYPES.GET_TEST_LIST):
    case REQUEST(ACTION_TYPES.GET_LAB_RECORD):
    case REQUEST(ACTION_TYPES.CREATE_LABORATORY):
    case REQUEST(ACTION_TYPES.UPDATE_LABORATORY): {
      return {
        ...state,
        prescription: {},
        createPrescriptionResponse: {},
        updatePrescriptionResponse: {},
        loading: true,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_MEDICINE_LIST): {
      return {
        ...state,
        medicines: action.payload?.data?.length ? action.payload?.data : [],
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_TEST_LIST): {
      return {
        ...state,
        tests: action.payload?.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_PRESCRIPTION): {
      return {
        ...state,
        prescription: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.CREATE_PRESCRIPTION): {
      return {
        ...state,
        createPrescriptionResponse: action.payload,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.UPDATE_PRESCRIPTION): {
      return {
        ...state,
        updatePrescriptionResponse: action.payload,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_LAB_RECORD): {
      return {
        ...state,
        labRecord: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.CREATE_LABORATORY):{
      return {
        ...state,
        createLaboratoryResponse: action.payload,
        loading: false
      }
    }
    case SUCCESS(ACTION_TYPES.UPDATE_LABORATORY):{
      return {
        ...state,
        updateLaboratoryResponse: action.payload,
        loading: false
      }
    }

    case FAILURE(ACTION_TYPES.GET_MEDICINE_LIST):
    case FAILURE(ACTION_TYPES.CREATE_PRESCRIPTION):
    case FAILURE(ACTION_TYPES.UPDATE_PRESCRIPTION):
    case FAILURE(ACTION_TYPES.GET_PRESCRIPTION):
    case FAILURE(ACTION_TYPES.GET_TEST_LIST):
    case FAILURE(ACTION_TYPES.GET_LAB_RECORD):
    case FAILURE(ACTION_TYPES.CREATE_LABORATORY):
    case FAILURE(ACTION_TYPES.UPDATE_LABORATORY): {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

export const getMedicineList: () => void = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_MEDICINE_LIST,
    payload: axios.get(`${API_URL}/v1/medicines`, config),
  });
  return result;
};

export const getTestList: () => void = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_TEST_LIST,
    payload: axios.get(`${API_URL}/v1/lab-tests`, config),
  });
  return result;
};

export const getPrescriptionByAppointment: (appointmentId) => void =
  appointmentId => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.GET_PRESCRIPTION,
      payload: axios.get(
        `${API_URL}/v1/appointments/${appointmentId}/prescriptions`,
        config,
      ),
    });
    return result;
  };

  export const getLabRecord: (appointmentId) => void =
  appointmentId => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.GET_LAB_RECORD,
      payload: axios.get(
        `${API_URL}/v1/appointments/${appointmentId}/lab-reports`,
        config,
      ),
    });
    return result;
  };


export const createPrescription: (payload) => void =
  ({ appointmentId, prescription }) =>
  async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.CREATE_PRESCRIPTION,
      payload: axios.post(
        `${API_URL}/v1/appointments/${appointmentId}/prescriptions`,
        prescription,
      ),
    });
    return result;
  };

  export const createLaboratory: (payload) => void =
  ({ appointmentId, laboratoryDetails }) =>
  async dispatch => {
    const laboratoryItemTemp = laboratoryDetails.map((item) => {
      return {
        ...item,
        labTestId: item?.id ? item?.id : null
      }
    })
    const result = await dispatch({
      type: ACTION_TYPES.CREATE_LABORATORY,
      payload: axios.post(
        `${API_URL}/v1/appointments/${appointmentId}/lab-reports`,
        laboratoryItemTemp
      ),
    });
    return result;
  };

  export const updateLaboratory: (payload) => void =
  ({ appointmentId, laboratoryItems }) =>
  async dispatch => {
    const laboratoryItemTemp = laboratoryItems.map((item) => {
      return {
        ...item,
        labTestId: item?.id ? item?.id : null
      }
    })
    const result = await dispatch({
      type: ACTION_TYPES.UPDATE_LABORATORY,
      payload: axios.put(
        `${API_URL}/v1/appointments/${appointmentId}/lab-reports`,
        laboratoryItemTemp
      ),
    });
    return result;
  };

export const updatePrescription: (payload) => void =
  ({ appointmentId, prescription }) =>
  async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.UPDATE_PRESCRIPTION,
      payload: axios.put(
        `${API_URL}/v1/appointments/${appointmentId}/prescriptions`,
        prescription,
      ),
    });
    return result;
  };
