import {
  faCircle,
  faCircleNotch,
  faMicrophoneSlash,
  faSpinner,
  faVideoSlash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AgoraVideoPlayer, IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack
} from 'agora-rtc-react';
import React from 'react';
import { translate } from 'react-jhipster';
import { Colors, CUSTOMER_CALL_STATUS } from '../../../config/constants';
import VideoControls from './video-controls';

const Videos = (props: {
  users: IAgoraRTCRemoteUser[];
  tracks: [IMicrophoneAudioTrack, ICameraVideoTrack];
  remoteVideo: boolean;
  remoteAudio: boolean;
}) => {
  const { users, tracks, remoteAudio, remoteVideo } = props;

  return (
    <div id="videos">
      <AgoraVideoPlayer className="video self-video" videoTrack={tracks[1]} />
      <div className="video">
        {(!remoteVideo || !remoteAudio) && (
          <span className="remote-status-indicator">
            {!remoteVideo && (
              <FontAwesomeIcon
                className="icon"
                icon={faVideoSlash}
                color={Colors.red}
              />
            )}

            {!remoteAudio && (
              <FontAwesomeIcon
                className="icon"
                icon={faMicrophoneSlash}
                color={Colors.red}
              />
            )}
          </span>
        )}
        {users.length > 0 &&
          users.map(user => {
            if (user.videoTrack) {
              return (
                <AgoraVideoPlayer
                  className="w-100 h-100 user-video"
                  videoTrack={user.videoTrack}
                  key={user.uid}
                />
              );
            } else return null;
          })}
      </div>
    </div>
  );
};

const VideoCallComponent = (props: {
  setInCall: React.Dispatch<React.SetStateAction<boolean>>;
  channelName: string;
  handleCloseVideo: any;
  setUsers: any;
  users: any;
  onClickEditPrescription: Function;
  ready: any;
  callStatus: string;
  tracks: any;
  setCallStatus: Function;
  videoStarted: boolean;
  start: boolean;
  remoteAudio: boolean;
  remoteVideo: boolean;
  setStart: React.Dispatch<React.SetStateAction<boolean>>;
  trackState: any;
  setTrackState: Function;
}) => {
  const {
    setInCall,
    channelName,
    handleCloseVideo,
    setUsers,
    users,
    onClickEditPrescription,
    ready,
    callStatus,
    tracks,
    videoStarted,
    start,
    setStart,
    remoteVideo,
    remoteAudio,
    trackState,
    setTrackState,
  } = props;

  const renderStatusIndicator = (callStatus: string) => {
    return (
      <>
        {callStatus === CUSTOMER_CALL_STATUS.CUSTOMER_CALL_ANSWERED && (
          <>
            <FontAwesomeIcon
              className="icon"
              icon={faCircle}
              color={Colors.green}
            />
            <span>{translate(`customer_call_status.${callStatus}`)}</span>
          </>
        )}

        {callStatus === CUSTOMER_CALL_STATUS.CUSTOMER_CALL_MISSED && (
          <>
            <FontAwesomeIcon
              className="icon"
              icon={faCircle}
              color={Colors.yellow}
            />
            <span>{translate(`customer_call_status.${callStatus}`)}</span>
          </>
        )}

        {callStatus === CUSTOMER_CALL_STATUS.CUSTOMER_CALL_ON_HOLD && (
          <>
            <FontAwesomeIcon
              className="icon"
              icon={faCircle}
              color={Colors.geraldine}
            />
            <span>{translate(`customer_call_status.${callStatus}`)}</span>
          </>
        )}
        
        {callStatus === CUSTOMER_CALL_STATUS.CUSTOMER_CALL_DECLINED && (
          <>
            <FontAwesomeIcon
              className="icon"
              icon={faCircle}
              color={Colors.red}
            />
            <span>{translate(`customer_call_status.${callStatus}`)}</span>
          </>
        )}

        {callStatus ===
          CUSTOMER_CALL_STATUS.CUSTOMER_CALL_NOTIFICATION_RECEIVED && (
          <>
            <FontAwesomeIcon
              className="icon"
              icon={faCircleNotch}
              color={Colors.blue}
              pulse
            />
            <span>{translate(`customer_call_status.${callStatus}`)}</span>
          </>
        )}
      </>
    );
  };

  return (
    <div className="main-wrapper">
      {(!ready || !tracks) && (
        <div className="text-info my-5" style={{ fontSize: 32 }}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      )}
      {ready && tracks && (
        <>
          {renderStatusIndicator(callStatus)}

          <VideoControls
            tracks={tracks}
            setStart={setStart}
            setInCall={setInCall}
            onClickEditPrescription={onClickEditPrescription}
            handleCloseVideo={handleCloseVideo}
            setCallStatus={props.setCallStatus}
            trackState={trackState}
            setTrackState={setTrackState}
          />
        </>
      )}
      {start && tracks && (
        <Videos
          users={users}
          tracks={tracks}
          remoteVideo={remoteVideo}
          remoteAudio={remoteAudio}
        />
      )}
    </div>
  );
};

export default VideoCallComponent;
