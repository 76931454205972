import { useState } from 'react';
import { UN_ASSIGNED_LIST_KEY } from '../config/constants';


export const checkIsAdmin = doctors => {
  let isAdmin = false;
  doctors?.some(item => item.id === UN_ASSIGNED_LIST_KEY)
    ? (isAdmin = true)
    : (isAdmin = false);
  return isAdmin;
};
