import { Col, Container, Label, Row } from 'reactstrap';
import LogoImage from '../../svg/login-logo.svg';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { translate, Translate } from 'react-jhipster';
import './style.scss';
import { connect } from 'react-redux';
import { login } from '../../shared/reducers/authentication';
import { IRootState } from '../../shared/reducers';
import { Colors } from '../../config/constants';
import { RouteComponentProps, useHistory } from 'react-router';
import { AppLogo } from './components/app-logo';
import Button from '../components/button';
import { AppLogoHeader } from './components/app-logo-header';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export const Login = (props: IPageProps) => {
  const history = useHistory();

  const clickHandler = (event, values) => {
    const { username, password, rememberMe = false } = values;
    props.login(username, password, rememberMe);
  };

  const gotoForgotPassword = () => {
    history.push('/forgot-password');
  };

  return (
    <Container id={'login-form'} fluid className="p-0">
      <Row className="m-0">
        <Col md={7} className="column p-0 bg-light position-relative">
          <div className="d-flex justify-content-center align-items-start w-100">
            <div style={{  zIndex: 1000, marginTop:"2rem" }}>
              <AppLogoHeader />
            </div>
            <img
              className="position-absolute w-100"
              style={{ left: 0, bottom: 0 }}
              src={LogoImage}
              alt=""
            />
          </div>
        </Col>
        <Col
          style={{ backgroundColor: Colors.pageBackground }}
          className="px-0 px-lg-5 column login-form-column">
          <Col>
            <div className="p-5 mt-5">
              <p className="login-head-text">
                {translate('form.login.login_text')}
              </p>
              <AvForm model={{}} onValidSubmit={clickHandler}>
                <Col className="mt-4" md={12}>
                  <Label className="label" for="username">
                    <Translate contentKey="form.login.email">Email</Translate>
                    <span className="text-danger">*</span>
                  </Label>
                  <AvField
                    className="p-3"
                    name="username"
                    id="username"
                    type="text"
                    validate={{
                      required: {
                        errorMessage: translate(
                          'validation_registration.email_error_message',
                        ),
                      },
                      email: {
                        errorMessage: translate(
                          'validation_registration.invalid_email_error_message',
                        ),
                      },
                    }}
                  />
                </Col>
                <Col className="mt-4" md={12}>
                  <Label className="label" for="password">
                    <Translate contentKey="form.login.password">
                      Password
                    </Translate>
                    <span className="text-danger">*</span>
                  </Label>
                  <AvField
                    className="p-3"
                    name="password"
                    id="password"
                    type="password"
                    validate={{
                      required: {
                        errorMessage: translate(
                          'validation_login.password_error_message',
                        ),
                      },
                    }}
                  />
                </Col>
                <Col md={12}>
                  <Button
                    type="submit"
                    style={{ width: '100%' }}
                    className="my-4 py-1 px-4">
                    <p className="text-center button-label mb-0 mt-0 pt-2 pb-2">
                      {translate('form.login.login_text')}
                    </p>
                  </Button>
                </Col>
                <Col md={12}>
                  <div
                    className="forgot-password mt-3"
                    onClick={gotoForgotPassword}>
                    <p className="text-center">
                      {translate('form.login.forgot_password')}
                    </p>
                  </div>
                </Col>
              </AvForm>
            </div>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};
const mapStateToProps = ({ authentication, plan, user }: IRootState) => ({
  isAuthenticated: authentication?.isAuthenticated,
  services: plan?.services,
  loading: plan?.loading,
  loginResponse: user?.loginResponse,
});

const mapDispatchToProps = {
  login,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Login);
