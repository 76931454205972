import { API_URL } from './../config/constants';
import { IPostRegistrationDetails } from './../model/plans.model';
import { IBankDetail } from './../model/bank-detail.model';
import { ISubscriptionDetail } from './../model/subscription-detail.model';
import axios from 'axios';
import { IBusinessInfo } from '../model/business-info.model';
import { toast } from 'react-toastify';
import { REQUEST, SUCCESS, FAILURE } from '../shared/reducers/action-type.util';
import { cleanEntity } from '../shared/util/entity-utils';
import {
  getToken,
  HTTP_OK_RESPONSE,
  HTTP_CREATED_RESPONSE,
} from '../config/constants';
import { translate } from 'react-jhipster';
import { ICustomer } from '../model/customer.model';

export const ACTION_TYPES = {
  GET_MENUS: 'user/GET_MENUS',
  PRE_REGISTER: 'user/PRE_REGISTER',
  GET_PRE_REGISTER_USER: 'user/GET_PRE_REGISTER_USER',
  GET_USER_BUSINESS_INFO: 'user/GET_USER_BUSINESS_INFO',
  SET_REGISTER_USER: 'user/SET_REGISTER_USER',
  POST_REGISTER: 'user/POST_REGISTER',
  CREATE_SUBSCRIPTION_ORDER: 'user/CREATE_SUBSCRIPTION_ORDER',
  CREATE_PRE_SUBSCRIPTION_ORDER: 'user/CREATE_PRE_SUBSCRIPTION_ORDER',
  UPDATE_PAYMENT_SUCCESS_STATUS: 'user/UPDATE_PAYMENT_SUCCESS_STATUS',
  UPDATE_PAYMENT_FAILURE_STATUS: 'user/UPDATE_PAYMENT_FAILURE_STATUS',
  GET_SUBSCRIPTION_DETAILS: 'user/GET_SUBSCRIPTION_DETAILS',
  CREATE_SUBSCRIPTION_DETAILS: 'user/CREATE_SUBSCRIPTION_DETAILS',
  UPDATE_SUBSCRIPTION_DETAILS: 'user/UPDATE_SUBSCRIPTION_DETAILS',
  SEND_RESET_PASSWORD_MAIL: 'user/SEND_FORGOT_PASSWORD_MAIL',
  SEND_OTP: 'user/SEND_OTP',
  GET_SERVICE_ADDITIONAL_CHARGES: 'user/GET_SERVICE_ADDITIONAL_CHARGES',
  VERIFY_OTP: 'user/VERIFY_OTP',
  GET_SERVICE_AREA_LOCATION: 'user/GET_SERVICE_AREA_LOCATION',
  GET_VENDOR_PROFILE: 'user/GET_VENDOR_PROFILE',
  EDIT_VENDOR_PROFILE: 'user/EDIT_VENDOR_PROFILE',
  EDIT_USER_DEVICE: 'user/EDIT_USER_DEVICE',
  GET_SERVICE_DETAILS: 'user/GET_SERVICE_DETAILS',
  GET_SERVICE_DETAILS_OFFLINE: 'user/GET_SERVICE_DETAILS_OFFLINE',
  CREATE_SERVICE_DETAILS: 'user/CREATE_SERVICE_DETAILS',
  UPDATE_SERVICE_DETAILS: 'user/UPDATE_SERVICE_DETAILS',
  FETCH_BANK_DETAILS: 'user/FETCH_BANK_DETAILS',
  FETCH_FACILITIES_AND_OTHERS: 'user/FETCH_FACILITIES_AND_OTHERS',
  FETCH_VENDOR_FACILITIES_AND_OTHERS: 'user/FETCH_VENDOR_FACILITIES_AND_OTHERS',
  UPDATE_FACILITIES_AND_OTHERS: 'user/UPDATE_FACILITIES_AND_OTHERS',
  CREATE_FACILITIES_AND_OTHERS: 'user/CREATE_FACILITIES_AND_OTHERS',
  CREATE_BANK_DETAILS: 'user/CREATE_BANK_DETAILS',
  UPDATE_BANK_DETAILS: 'user/UPDATE_BANK_DETAILS',
  RESET_SUBSCRIPTION_ORDER_RESPONSE: 'user/RESET_SUBSCRIPTION_ORDER_RESPONSE',

  CREATE_BUSINESS_INFO: 'services/CREATE_BUSINESS_INFO',
  UPDATE_BUSINESS_INFO: 'services/UPDATE_BUSINESS_INFO',
  UPDATE_BUSINESS_INFO_STATE: 'services/UPDATE_BUSINESS_INFO_STATE',

  UPDATE_POST_REGISTRATION_STATE: 'user/UPDATE_POST_REGISTRATION_STATE',
  RESET_TO_DEFAULT: 'user/RESET_TO_DEFAULT',
  FETCH_SERVICE_PACKAGES: 'service/FETCH_SERVICE_PACKAGES',
  FETCH_SERVICE_SLOTS: 'service/FETCH_SERVICE_SLOTS',
  FETCH_SERVICE_PAYMENT_PERIODS: 'service/FETCH_SERVICE_PAYMENT_PERIODS',
  UPDATE_VENDOR_SUBSCRIPTION_DETAILS: 'user/UPDATE_VENDOR_SUBSCRIPTION_DETAILS',
  LOGIN: 'user/LOGIN',
  CREATE_NEW_PASSWORD: 'user/CREATE_NEW_PASSWORD',

  REQUEST_TO_PUBLISH: 'service/REQUEST_TO_PUBLISH',
  FETCH_VENDOR_SERVICE: 'service/FETCH_VENDOR_SERVICE',

  FETCH_CUSTOMER_LIST: 'user/FETCH_CUSTOMER_LIST',
  FETCH_CUSTOMER_PETS: 'user/FETCH_CUSTOMER_PETS',
  FETCH_ALL_CUSTOMER_PETS: 'user/FETCH_ALL_CUSTOMER_PETS',
  FETCH_CUSTOMER_APPOINTMENTS: 'user/FETCH_CUSTOMER_APPOINTMENTS',
  FETCH_VET_LIST: 'user/FETCH_VET_LIST',
  FETCH_ALL_CUSTOMERS: 'user/FETCH_ALL_CUSTOMERS',
};

const initialState = {
  loading: false,
  updateSuccess: false,
  menus: [] as any,
  user: {} as any,
  userBusinessInfo: {} as IBusinessInfo,
  subscriptionDetails: {} as ISubscriptionDetail,
  createSubscriptionDetailResponse: {} as any,
  updateSubscriptionDetailResponse: {} as any,
  updateSendOtpResponse: {} as any,
  updateSuccessOtpResponse: {} as any,
  sendResetPasswordResponse: {} as any,
  getVendorProfileResponse: {} as any,
  editVendorProfile: {} as any,
  userDevice: {} as any,
  serviceDetails: {} as any,
  serviceDetailsOffline: {} as any,
  createServiceDetailResponse: {} as any,
  updateServiceDetailResponse: {} as any,
  fetchBankDetailsResponse: {} as any,
  facilitiesAndOthers: [] as any,
  vendorFacilitiesAndOthers: [] as any,
  updateFacilitiesAndOthersResponse: {} as any,
  isSuccess: false,
  createBankDetailResponse: {} as any,
  updateBankDetailResponse: {} as any,
  postRegistrationDetails: {} as IPostRegistrationDetails,
  servicePackages: [] as any,
  serviceSlots: [] as any,
  servicePaymentPeriods: [] as any,
  postRegisterResponse: {} as any,
  subscriptionOrderResponse: {} as any,
  preSubscriptionOrderResponse: {} as any,
  subscriptionPaymentStatus: {} as any,
  preRegisterResponse: {} as any,
  createBusinessInfoResponse: {} as any,
  updateBusinessInfoResponse: {} as any,
  loginResponse: {} as any,
  createNewPasswordResponse: {} as any,
  serviceAreaLocationResponse: [] as any,
  updateVendorSubscriptionAreaResponse: {} as any,
  publishResponse: {} as any,
  additionalChargesResponse: {} as any,
  service: {} as any,
  customerList: [] as ReadonlyArray<ICustomer>,
  customerPets: [] as any,
  pets: [] as any,
  customerAppointments: [] as any,
  customers: [] as ReadonlyArray<ICustomer>,
  vetList: [] as any,
  totalCount: 0,
};

export type UserState = Readonly<typeof initialState>;

export const userReducer = (
  state: UserState = initialState,
  action,
): UserState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_MENUS):
    case REQUEST(ACTION_TYPES.GET_PRE_REGISTER_USER):
    case REQUEST(ACTION_TYPES.GET_USER_BUSINESS_INFO):
    case REQUEST(ACTION_TYPES.GET_SUBSCRIPTION_DETAILS):
    case REQUEST(ACTION_TYPES.CREATE_SUBSCRIPTION_DETAILS):
    case REQUEST(ACTION_TYPES.UPDATE_SUBSCRIPTION_DETAILS):
    case REQUEST(ACTION_TYPES.SEND_OTP):
    case REQUEST(ACTION_TYPES.VERIFY_OTP):
    case REQUEST(ACTION_TYPES.GET_VENDOR_PROFILE):
    case REQUEST(ACTION_TYPES.EDIT_VENDOR_PROFILE):
    case REQUEST(ACTION_TYPES.EDIT_USER_DEVICE):
    case REQUEST(ACTION_TYPES.GET_SERVICE_DETAILS):
    case REQUEST(ACTION_TYPES.GET_SERVICE_DETAILS_OFFLINE):
    case REQUEST(ACTION_TYPES.CREATE_SERVICE_DETAILS):
    case REQUEST(ACTION_TYPES.UPDATE_SERVICE_DETAILS):
    case REQUEST(ACTION_TYPES.FETCH_BANK_DETAILS):
    case REQUEST(ACTION_TYPES.FETCH_FACILITIES_AND_OTHERS):
    case REQUEST(ACTION_TYPES.FETCH_VENDOR_FACILITIES_AND_OTHERS):
    case REQUEST(ACTION_TYPES.CREATE_BANK_DETAILS):
    case REQUEST(ACTION_TYPES.UPDATE_BANK_DETAILS):
    case REQUEST(ACTION_TYPES.FETCH_SERVICE_PACKAGES):
    case REQUEST(ACTION_TYPES.FETCH_SERVICE_SLOTS):
    case REQUEST(ACTION_TYPES.FETCH_SERVICE_PAYMENT_PERIODS):
    case REQUEST(ACTION_TYPES.POST_REGISTER):
    case REQUEST(ACTION_TYPES.CREATE_SUBSCRIPTION_ORDER):
    case REQUEST(ACTION_TYPES.CREATE_PRE_SUBSCRIPTION_ORDER):
    case REQUEST(ACTION_TYPES.UPDATE_PAYMENT_SUCCESS_STATUS):
    case REQUEST(ACTION_TYPES.UPDATE_PAYMENT_FAILURE_STATUS):
    case REQUEST(ACTION_TYPES.CREATE_BUSINESS_INFO):
    case REQUEST(ACTION_TYPES.UPDATE_BUSINESS_INFO):
    case REQUEST(ACTION_TYPES.LOGIN):
    case REQUEST(ACTION_TYPES.CREATE_NEW_PASSWORD):
    case REQUEST(ACTION_TYPES.SEND_RESET_PASSWORD_MAIL):
    case REQUEST(ACTION_TYPES.GET_SERVICE_AREA_LOCATION):
    case REQUEST(ACTION_TYPES.UPDATE_VENDOR_SUBSCRIPTION_DETAILS):
    case REQUEST(ACTION_TYPES.REQUEST_TO_PUBLISH):
    case REQUEST(ACTION_TYPES.FETCH_CUSTOMER_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ALL_CUSTOMERS):
    case REQUEST(ACTION_TYPES.FETCH_CUSTOMER_PETS):
    case REQUEST(ACTION_TYPES.FETCH_ALL_CUSTOMER_PETS):
    case REQUEST(ACTION_TYPES.FETCH_CUSTOMER_APPOINTMENTS):
    case REQUEST(ACTION_TYPES.FETCH_VET_LIST):
    case REQUEST(ACTION_TYPES.GET_SERVICE_ADDITIONAL_CHARGES): {
      return {
        ...state,
        loading: true,
      };
    }

    case REQUEST(ACTION_TYPES.CREATE_FACILITIES_AND_OTHERS):
    case REQUEST(ACTION_TYPES.UPDATE_FACILITIES_AND_OTHERS): {
      return {
        ...state,
        loading: true,
        isSuccess: false,
      };
    }

    case SUCCESS(ACTION_TYPES.CREATE_SERVICE_DETAILS): {
      toast.success(translate('updated_msg'));
      return {
        ...state,
        createServiceDetailResponse: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.CREATE_NEW_PASSWORD): {
      return {
        ...state,
        createNewPasswordResponse: action.payload,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.UPDATE_SERVICE_DETAILS): {
      toast.success(translate('updated_msg'));
      return {
        ...state,
        updateServiceDetailResponse: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_MENUS): {
      return {
        ...state,
        menus: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.GET_SERVICE_DETAILS): {
      return {
        ...state,
        serviceDetails: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_SERVICE_DETAILS_OFFLINE): {
      return {
        ...state,
        serviceDetailsOffline: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_PRE_REGISTER_USER): {
      return {
        ...state,
        user: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_USER_BUSINESS_INFO): {
      return {
        ...state,
        userBusinessInfo: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_SUBSCRIPTION_DETAILS): {
      return {
        ...state,
        subscriptionDetails: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.CREATE_SUBSCRIPTION_DETAILS): {
      toast.success(translate('updated_msg'));
      return {
        ...state,
        createSubscriptionDetailResponse: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.UPDATE_SUBSCRIPTION_DETAILS): {
      toast.success(translate('updated_msg'));
      return {
        ...state,
        updateSubscriptionDetailResponse: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.SEND_RESET_PASSWORD_MAIL): {
      toast.success(translate('email_sent'));
      return {
        ...state,
        sendResetPasswordResponse: action.payload,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.SEND_OTP): {
      return {
        ...state,
        updateSendOtpResponse: action.payload,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_SERVICE_ADDITIONAL_CHARGES): {
      return {
        ...state,
        additionalChargesResponse: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.VERIFY_OTP): {
      return {
        ...state,
        updateSuccessOtpResponse: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_BANK_DETAILS): {
      return {
        ...state,
        fetchBankDetailsResponse: action.payload,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_FACILITIES_AND_OTHERS): {
      return {
        ...state,
        facilitiesAndOthers: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_VENDOR_FACILITIES_AND_OTHERS): {
      return {
        ...state,
        vendorFacilitiesAndOthers: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.UPDATE_FACILITIES_AND_OTHERS):
    case SUCCESS(ACTION_TYPES.CREATE_FACILITIES_AND_OTHERS): {
      return {
        ...state,
        updateFacilitiesAndOthersResponse: action.payload.data,
        isSuccess: true,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_VENDOR_PROFILE): {
      return {
        ...state,
        getVendorProfileResponse: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.EDIT_VENDOR_PROFILE): {
      return {
        ...state,
        editVendorProfile: action.payload,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.EDIT_USER_DEVICE): {
      return {
        ...state,
        userDevice: action.payload,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.CREATE_BANK_DETAILS): {
      toast.success(translate('updated_msg'));
      return {
        ...state,
        createBankDetailResponse: action.payload,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.UPDATE_BANK_DETAILS): {
      toast.success(translate('updated_msg'));
      return {
        ...state,
        updateBankDetailResponse: action.payload,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_SERVICE_PACKAGES):
      return {
        ...state,
        servicePackages: action.payload.data,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SERVICE_SLOTS):
      return {
        ...state,
        serviceSlots: action.payload.data,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SERVICE_PAYMENT_PERIODS):
      return {
        ...state,
        servicePaymentPeriods: action.payload.data,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.POST_REGISTER):
      return {
        ...state,
        postRegisterResponse: action.payload,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SUBSCRIPTION_ORDER):
      return {
        ...state,
        subscriptionOrderResponse: action.payload,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PRE_SUBSCRIPTION_ORDER):
      return {
        ...state,
        preSubscriptionOrderResponse: action.payload,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_PAYMENT_SUCCESS_STATUS):
      return {
        ...state,
        subscriptionPaymentStatus: action.payload.data,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_PAYMENT_FAILURE_STATUS):
      return {
        ...state,
        subscriptionPaymentStatus: action.payload.data,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.CREATE_BUSINESS_INFO):
      toast.success(translate('updated_msg'));
      return {
        ...state,
        createBusinessInfoResponse: action.payload,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_BUSINESS_INFO):
      toast.success(translate('updated_msg'));
      return {
        ...state,
        updateBusinessInfoResponse: action.payload,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.LOGIN):
      return {
        ...state,
        loginResponse: action.payload,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.GET_SERVICE_AREA_LOCATION):
      return {
        ...state,
        serviceAreaLocationResponse: action.payload.data,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_VENDOR_SUBSCRIPTION_DETAILS): {
      toast.success(translate('updated_msg'));
      return {
        ...state,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_CUSTOMER_LIST): {
      return {
        ...state,
        customerList: action.payload.data,
        totalCount: action.payload.headers['x-total-count'],
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_ALL_CUSTOMERS): {
      return {
        ...state,
        customers: action.payload.data,
        totalCount: action.payload.headers['x-total-count'],
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_CUSTOMER_PETS): {
      return {
        ...state,
        customerPets: action.payload.data,
        totalCount: action.payload.headers['x-total-count'],
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_ALL_CUSTOMER_PETS): {
      return {
        ...state,
        pets: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_CUSTOMER_APPOINTMENTS): {
      return {
        ...state,
        customerAppointments: action.payload.data,
        totalCount: action.payload.headers['x-total-count'],
        loading: false,
      };
    }
    case ACTION_TYPES.RESET_SUBSCRIPTION_ORDER_RESPONSE:
      return {
        ...state,
        subscriptionOrderResponse: {},
        preSubscriptionOrderResponse: {},
      };
    case FAILURE(ACTION_TYPES.CREATE_BANK_DETAILS): {
      return {
        ...state,
        createBankDetailResponse: action.payload,
        loading: false,
      };
    }
    case FAILURE(ACTION_TYPES.UPDATE_BANK_DETAILS): {
      return {
        ...state,
        updateBankDetailResponse: action.payload,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.REQUEST_TO_PUBLISH): {
      toast.success(translate('request_send_successfully'));
      return {
        ...state,
        publishResponse: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_VENDOR_SERVICE): {
      return {
        ...state,
        service: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_VET_LIST): {
      return {
        ...state,
        vetList: action.payload.data,
        loading: false,
      };
    }
    case FAILURE(ACTION_TYPES.REQUEST_TO_PUBLISH): {
      toast.error(
        action.payload?.response?.data?.errorKey ??
          translate('error.something_went_wrong'),
      );
      return {
        ...state,
        publishResponse: action.payload,
        loading: false,
      };
    }

    case FAILURE(ACTION_TYPES.FETCH_BANK_DETAILS): {
      return {
        ...state,
        updateBankDetailResponse: action.payload,
        loading: false,
      };
    }
    case FAILURE(ACTION_TYPES.GET_MENUS):
    case FAILURE(ACTION_TYPES.GET_PRE_REGISTER_USER):
    case FAILURE(ACTION_TYPES.GET_USER_BUSINESS_INFO):
    case FAILURE(ACTION_TYPES.GET_SUBSCRIPTION_DETAILS):
    case FAILURE(ACTION_TYPES.CREATE_SUBSCRIPTION_DETAILS):
    case FAILURE(ACTION_TYPES.UPDATE_SUBSCRIPTION_DETAILS):
    case FAILURE(ACTION_TYPES.SEND_OTP):
    case FAILURE(ACTION_TYPES.GET_SERVICE_ADDITIONAL_CHARGES):
    case FAILURE(ACTION_TYPES.GET_VENDOR_PROFILE):
    case FAILURE(ACTION_TYPES.EDIT_VENDOR_PROFILE):
    case FAILURE(ACTION_TYPES.EDIT_USER_DEVICE):
    case FAILURE(ACTION_TYPES.GET_SERVICE_DETAILS):
    case FAILURE(ACTION_TYPES.GET_SERVICE_DETAILS_OFFLINE):
    case FAILURE(ACTION_TYPES.CREATE_SERVICE_DETAILS):
    case FAILURE(ACTION_TYPES.UPDATE_SERVICE_DETAILS):
    case FAILURE(ACTION_TYPES.FETCH_FACILITIES_AND_OTHERS):
    case FAILURE(ACTION_TYPES.FETCH_VENDOR_FACILITIES_AND_OTHERS):
    case FAILURE(ACTION_TYPES.FETCH_SERVICE_PACKAGES):
    case FAILURE(ACTION_TYPES.FETCH_SERVICE_SLOTS):
    case FAILURE(ACTION_TYPES.CREATE_BUSINESS_INFO):
    case FAILURE(ACTION_TYPES.UPDATE_BUSINESS_INFO):
    case FAILURE(ACTION_TYPES.LOGIN):
    case FAILURE(ACTION_TYPES.CREATE_NEW_PASSWORD):
    case FAILURE(ACTION_TYPES.FETCH_SERVICE_PAYMENT_PERIODS):
    case FAILURE(ACTION_TYPES.GET_SERVICE_AREA_LOCATION):
    case FAILURE(ACTION_TYPES.UPDATE_VENDOR_SUBSCRIPTION_DETAILS):
    case FAILURE(ACTION_TYPES.FETCH_VENDOR_SERVICE):
    case FAILURE(ACTION_TYPES.FETCH_CUSTOMER_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ALL_CUSTOMERS):
    case FAILURE(ACTION_TYPES.FETCH_CUSTOMER_PETS):
    case FAILURE(ACTION_TYPES.FETCH_CUSTOMER_APPOINTMENTS):
    case FAILURE(ACTION_TYPES.FETCH_VET_LIST): {
      return {
        ...state,
        loading: false,
      };
    }

    case FAILURE(ACTION_TYPES.VERIFY_OTP): {
      toast.error(translate('error.incorrect_otp'));
      return {
        ...state,
        loading: false,
      };
    }

    case FAILURE(ACTION_TYPES.POST_REGISTER): {
      if (action.payload?.response?.data?.errorKey === 'email-already-exists') {
        toast.error(translate('error.email_exists'));
      } else {
        toast.error(translate('error.something_went_wrong'));
      }
      return {
        ...state,
        loading: false,
      };
    }

    case FAILURE(ACTION_TYPES.CREATE_SUBSCRIPTION_ORDER): {
      toast.error(translate('error.something_went_wrong'));
      return {
        ...state,
        loading: false,
      };
    }

    case FAILURE(ACTION_TYPES.CREATE_PRE_SUBSCRIPTION_ORDER): {
      toast.error(translate('error.something_went_wrong'));
      return {
        ...state,
        loading: false,
      };
    }

    case FAILURE(ACTION_TYPES.UPDATE_PAYMENT_SUCCESS_STATUS): {
      toast.error(translate('error.something_went_wrong'));
      return {
        ...state,
        loading: false,
      };
    }

    case FAILURE(ACTION_TYPES.UPDATE_PAYMENT_FAILURE_STATUS): {
      toast.error(translate('error.something_went_wrong'));
      return {
        ...state,
        loading: false,
      };
    }

    case FAILURE(ACTION_TYPES.UPDATE_FACILITIES_AND_OTHERS):
    case FAILURE(ACTION_TYPES.CREATE_FACILITIES_AND_OTHERS): {
      return {
        ...state,
        isSuccess: false,
        loading: false,
      };
    }

    case FAILURE(ACTION_TYPES.SEND_RESET_PASSWORD_MAIL): {
      toast.error(translate('error.email_invalid'));
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST(ACTION_TYPES.PRE_REGISTER): {
      return {
        ...state,
        updateSuccess: false,
        loading: true,
      };
    }
    case SUCCESS(ACTION_TYPES.PRE_REGISTER): {
      return {
        ...state,
        preRegisterResponse: action.payload,
        loading: false,
      };
    }
    case FAILURE(ACTION_TYPES.PRE_REGISTER): {
      toast.error(
        action.payload?.response?.data?.title ??
          translate('error.email_exists'),
      );
      return {
        ...state,
        updateSuccess: false,
        loading: false,
      };
    }
    case ACTION_TYPES.SET_REGISTER_USER: {
      return {
        ...state,
        user: action.user,
      };
    }

    case ACTION_TYPES.RESET_TO_DEFAULT: {
      return {
        ...state,
        user: {},
      };
    }
    case ACTION_TYPES.UPDATE_POST_REGISTRATION_STATE: {
      return {
        ...state,
        postRegistrationDetails: {
          ...state.postRegistrationDetails,
          ...action.payload,
        },
      };
    }

    case ACTION_TYPES.UPDATE_BUSINESS_INFO_STATE: {
      return {
        ...state,
        userBusinessInfo: action.payload,
      };
    }

    default:
      return state;
  }
};

const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

export const updateBusinessInfoState = payload => {
  return {
    type: ACTION_TYPES.UPDATE_BUSINESS_INFO_STATE,
    payload,
  };
};

export const getMenus: () => void = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_MENUS,
    payload: axios.get(`/data/menus.json`),
  });
  return result;
};

export const preRegister: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.PRE_REGISTER,
    payload: axios.post(`${API_URL}/v1/vendors`, cleanEntity(entity)),
  });
  return result;
};

export const getPreRegisteredUser: any = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_PRE_REGISTER_USER,
    payload: axios.get(`data/pre-registration.json`),
  });
  return result;
};

export const getUserBusinessInfo: any = payload => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_USER_BUSINESS_INFO,
    payload: axios.get(
      `${API_URL}/v1/vendor/services/${payload?.serviceId}/business-info`,
      config,
    ),
  });
  return result;
};

export const setRegisteredUser = user => {
  return {
    type: ACTION_TYPES.SET_REGISTER_USER,
    user,
  };
};

export const getSubscriptionDetail: any = serviceId => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_SUBSCRIPTION_DETAILS,
    payload: axios.get(
      `${API_URL}/v1/vendor/services/${serviceId}/subscriptions`,
    ),
  });
  return result;
};

export const createSubscriptionDetail: (payload) => void =
  () => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.CREATE_SUBSCRIPTION_DETAILS,
      payload: true,
    });
    return result;
  };

export const updateSubscriptionDetail: (payload) => void =
  () => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.UPDATE_SUBSCRIPTION_DETAILS,
      payload: true,
    });
    return result;
  };

export const getServiceDetails: any = payload => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_SERVICE_DETAILS,
    payload: axios.get(
      `${API_URL}/v1/vendor/services/${payload?.serviceId}/service-details`,
      config,
    ),
  });
  if (!result?.specialities?.length) {
    result.consultationCharges = null;
  }

  return result;
};

export const getServiceDetailsOffline: any = payload => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_SERVICE_DETAILS_OFFLINE,
    payload: axios.get(
      `${API_URL}/v1/vendor/services/${payload?.serviceId}/service-details/offline`,
      config,
    ),
  });
  if (!result?.specialities?.length && result) {
    result.consultationChargeOffline = null;
  }
  return result;
};

export const sendResetPassword: any = payload => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.SEND_RESET_PASSWORD_MAIL,
    payload: axios.post(`${API_URL}/v1/account/reset-password`, payload),
  });
  return result;
};

export const sendOtp: (payload) => void = payload => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.SEND_OTP,
    payload: axios.get(`${API_URL}/v1/otp/${payload?.key}`),
  });
  return result;
};
export const createBankDetails: (payload) => void = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_BANK_DETAILS,
    payload: true,
  });
  return result;
};

export const getAdditionalCharges: () => void = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_SERVICE_ADDITIONAL_CHARGES,
    payload: axios.get(`${API_URL}/v1/services/additional-charges`),
  });
  return result;
};

export const verifyOtp: (payload) => void = payload => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.VERIFY_OTP,
    payload: axios.post(`${API_URL}/v1/vendor/otp`, payload),
  });
  return result;
};

export const createServiceDetail: (serviceId, entity) => void =
  (serviceId, entity) => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.CREATE_SERVICE_DETAILS,
      payload: axios.post(
        `${API_URL}/v1/vendor/services/${serviceId}/service-details`,
        cleanEntity(entity),
        config,
      ),
    });
    return result;
  };

export const updateServiceDetail: (serviceId, payload) => void =
  (serviceId, entity) => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.UPDATE_SERVICE_DETAILS,
      payload: axios.put(
        `${API_URL}/v1/vendor/services/${serviceId}/service-details`,
        entity,
        config,
      ),
    });
    return result;
  };

export const fetchBankDetails: (payload) => void =
  payload => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.FETCH_BANK_DETAILS,
      payload: axios.get(
        `${API_URL}/v1/vendor/services/${payload?.serviceId}/banks`,
        config,
      ),
    });
    return result;
  };
export const getVendorProfile: (payload) => void = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_VENDOR_PROFILE,
    payload: axios.get(`${API_URL}/v1/vendors/profile`),
  });
  return result;
};
export const editVendorProfile: (payload) => void =
  entity => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.EDIT_VENDOR_PROFILE,
      payload: axios.put(`${API_URL}/v1/vendors/profile`, entity, config),
    });
    return result;
  };

export const editUserDeviceInfo: (payload) => void =
  entity => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.EDIT_USER_DEVICE,
      payload: axios.put(`${API_URL}/customers/v1/voip-tokens`, entity, config),
    });
    return result;
  };

export const fetchServiceSlots: (payload) => void =
  payload => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.FETCH_SERVICE_SLOTS,
      payload: axios.get(`${API_URL}/v1/service/${payload?.serviceId}/slots`),
    });
    return result;
  };
export const fetchServicePackages: (payload) => void =
  payload => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.FETCH_SERVICE_PACKAGES,
      payload: axios.get(
        `${API_URL}/v1/service/${payload?.serviceId}/packages`,
      ),
    });
    return result;
  };
export const fetchServicePaymentPeriods: (payload) => void =
  payload => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.FETCH_SERVICE_PAYMENT_PERIODS,
      payload: axios.get(
        `${API_URL}/v1/service/${payload?.serviceId}/payment-periods?page=0&size=20`,
      ),
    });
    return result;
  };

export const updatePostRegistrationState = payload => {
  return {
    type: ACTION_TYPES.UPDATE_POST_REGISTRATION_STATE,
    payload,
  };
};

export const resetToDefault = () => {
  return {
    type: ACTION_TYPES.RESET_TO_DEFAULT,
  };
};
export const createBusinessInfo: any =
  (serviceId, entity) => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.CREATE_BUSINESS_INFO,
      payload: axios.post(
        `${API_URL}/v1/vendor/services/${serviceId}/business-info`,
        cleanEntity(entity),
        config,
      ),
    });
    return result;
  };

export const updateBusinessInfo: any =
  (serviceId, entity) => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.UPDATE_BUSINESS_INFO,
      payload: axios.put(
        `${API_URL}/v1/vendor/services/${serviceId}/business-info`,
        entity,
        config,
      ),
    });
    return result;
  };

export const createBankDetail: any = (serviceId, entity) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_BANK_DETAILS,
    payload: axios.post(
      `${API_URL}/v1/vendor/services/${serviceId}/banks`,
      cleanEntity(entity),
      config,
    ),
  });
  return result;
};

export const login: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.LOGIN,
    payload: axios.post(`${API_URL}/authenticate`, cleanEntity(entity), config),
  });
  return result;
};

export const updateBankDetail: any = (serviceId, entity) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_BANK_DETAILS,
    payload: axios.put(
      `${API_URL}/v1/vendor/services/${serviceId}/banks`,
      entity,
      config,
    ),
  });
  return result;
};

export const fetchFacilitiesAndOthers = serviceId => ({
  type: ACTION_TYPES.FETCH_FACILITIES_AND_OTHERS,
  payload: axios.get(`${API_URL}/v1/service/${serviceId}/custom-attributes`),
});

export const fetchVendorFacilitiesAndOthers = serviceId => ({
  type: ACTION_TYPES.FETCH_VENDOR_FACILITIES_AND_OTHERS,
  payload: axios.get(`${API_URL}/v1/vendor/services/${serviceId}/facilities`),
});

export const updateFacilitiesAndOthers: any =
  (entity, serviceId) => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.UPDATE_FACILITIES_AND_OTHERS,
      payload: axios.put(
        `${API_URL}/v1/vendor/services/${serviceId}/facilities`,
        entity,
      ),
    });
    if (result.value?.status === HTTP_OK_RESPONSE) {
      toast.success(translate('updated_msg'));
      fetchVendorFacilitiesAndOthers(serviceId);
    }
    return result;
  };

export const createFacilitiesAndOthers: any =
  (entity, serviceId) => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.CREATE_FACILITIES_AND_OTHERS,
      payload: axios.post(
        `${API_URL}/v1/vendor/services/${serviceId}/facilities`,
        entity,
      ),
    });
    if (result.value?.status === HTTP_CREATED_RESPONSE) {
      toast.success(translate('updated_msg'));
      fetchVendorFacilitiesAndOthers(serviceId);
    }
    return result;
  };

export const postRegister: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.POST_REGISTER,
    payload: axios.put(`${API_URL}/v1/vendors`, entity),
  });
  return result;
};

export const createSubscriptionOrder: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SUBSCRIPTION_ORDER,
    payload: axios.post(
      `${API_URL}/v1/payments/vendor-subscriptions/orders`,
      entity,
    ),
  });
  return result;
};

export const createVendorPreSubscriptionOrder: any =
  entity => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.CREATE_PRE_SUBSCRIPTION_ORDER,
      payload: axios.post(`${API_URL}/v1/payments/orders`, entity),
    });
    return result;
  };

export const updatePaymentStatus: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PAYMENT_SUCCESS_STATUS,
    payload: axios.post(`${API_URL}/v1/payments/success`, entity),
  });
  return result;
};

export const updatePaymentFailureStatus: any = entity => async dispatch => {
  toast.error(translate('error.payment_failed'), { autoClose: 7000 });
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PAYMENT_FAILURE_STATUS,
    payload: axios.post(`${API_URL}/v1/payments/failure`, entity),
  });
  return result;
};

export const getServiceAreaLocation: any = serviceAreaId => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_SERVICE_AREA_LOCATION,
    payload: axios.get(
      `${API_URL}/v1/service-area/${serviceAreaId}/service-locations`,
    ),
  });
  return result;
};

export const createNewPassword: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_NEW_PASSWORD,
    payload: axios.post(`${API_URL}/v1/account/password`, entity, config),
  });
  return result;
};

export const updateVendorSubscription: any = payload => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_VENDOR_SUBSCRIPTION_DETAILS,
    payload: axios.put(
      `${API_URL}/v1/vendor/services/${payload.serviceId}/subscriptions`,
      payload.entity,
      config,
    ),
  });
  return result;
};

export const updateBankDetails: any = (serviceId, entity) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_BANK_DETAILS,
    payload: axios.put(
      `${API_URL}/v1/vendor/services/${serviceId}/banks`,
      entity,
      config,
    ),
  });
  return result;
};

export const requestToPublish: any = serviceId => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.REQUEST_TO_PUBLISH,
    payload: axios.put(`${API_URL}/v1/vendor/services/${serviceId}/publish`),
  });
};
export const getVendorService: any = serviceId => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.FETCH_VENDOR_SERVICE,
    payload: axios.get(`${API_URL}/v1/vendor/services/${serviceId}`),
  });
};

export const fetchCustomerList = ({ search, page, size }: any) => ({
  type: ACTION_TYPES.FETCH_CUSTOMER_LIST,
  payload: axios.get<ICustomer>(
    `${API_URL}/v1/vendor/customers?page=${page ?? 0}&size=${size ?? 10}${
      search ? '&search=' + search : ''
    }`,
  ),
});

export const fetchCustomerPet = ({
  customerId,
  petTypeIds,
  breedIds,
  search,
  page,
  size
}: any) => ({
  type: ACTION_TYPES.FETCH_CUSTOMER_PETS,
  payload: axios.get<any>(
    `${API_URL}/v1/vendor/customer/${customerId}/pets?${
      petTypeIds ? '&petTypeIds=' + petTypeIds : ''
    }${breedIds ? '&breedIds=' + breedIds : ''}${
      search ? '&search=' + search : ''
    }&size=${size}&page=${page}`,
  ),
});

export const fetchAllCustomerPets = ({ customerId, petTypeId ,size,page}: any) => ({
  type: ACTION_TYPES.FETCH_ALL_CUSTOMER_PETS,
  payload: axios.get<any>(
    `${API_URL}/customers/v1/customer-pets?customerId=${customerId}${
      petTypeId ? '&petTypeId=' + petTypeId : ''
    }&size=${size}&page=${page}`,
  ),
});

export const fetchCustomerAppointments = ({
  customerId,
  petTypeIds,
  breedIds,
  appointmentStatus,
  paymentStatus,
  petIds,
  vetIds,
  type,
  search,
  fromDate,
  toDate,
  page,
  size
}: any) => ({
  type: ACTION_TYPES.FETCH_CUSTOMER_APPOINTMENTS,
  payload: axios.get<any>(
    `${API_URL}/v1/vendor/customer/${customerId}/appointments?page=${page}&size=${size}${
      petTypeIds ? '&petTypeIds=' + petTypeIds : ''
    }${breedIds ? '&breedIds=' + breedIds : ''}${
      appointmentStatus ? '&appointmentStatus=' + appointmentStatus : ''
    }${paymentStatus ? '&paymentStatus=' + paymentStatus : ''}${
      petIds ? '&petIds=' + petIds : ''
    }${vetIds ? '&vetIds=' + vetIds : ''}${type ? '&type=' + type : ''}${
      search ? '&search=' + search : ''
    }${fromDate ? '&fromDate=' + fromDate : ''}${
      toDate ? '&toDate=' + toDate : ''
    }`,
  ),
});

export const fetchVetList = customerId => ({
  type: ACTION_TYPES.FETCH_VET_LIST,
  payload: axios.get<any>(
    `${API_URL}/v1/vendor/customer/${customerId}/appointments/doctors`,
  ),
});

export const resetSubscriptionOrderResponse = () => ({
  type: ACTION_TYPES.RESET_SUBSCRIPTION_ORDER_RESPONSE,
});

export default userReducer;
