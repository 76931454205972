import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Col, Input, Row, Label } from 'reactstrap';
import { IRootState } from '../../shared/reducers';
import 'react-multi-carousel/lib/styles.css';
import './styles.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { updateAppointmentStatus } from '../../reducers/appointment.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { translate } from 'react-jhipster';
import AppointmentSchedule from '../services/appointment-schedule';
import { ClientConfig } from 'agora-rtc-react';
import AppointmentReSchedule from '../services/appointment-reschedule';
import { getServices } from '../../reducers/plan.reducer';

const APPOINTMENT_SCHEDULE = 'appointment_schedule';
const APPOINTMENT_RESCHEDULE = 'appointment_reschedule';

const APPOINTMENT_TABS = [APPOINTMENT_SCHEDULE, APPOINTMENT_RESCHEDULE];

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export const AppointmentManagmentTab = (props: any) => {
  const { services } = props;
  const [activeTab, setActiveTab] = useState(APPOINTMENT_TABS[0]);
  const [serviceId, setServiceId] = useState(0);

  useEffect(() => {
    props?.getServices();
  }, []);

  useEffect(() => {
    if (services[0].id) {
      setServiceId(services[0].id);
    }
  }, [services]);

  useEffect(() => {
    props?.getServices();
  }, [serviceId]);

  const setService = event => {
    setServiceId(event.target.value);
  };

  return (
    <div>
      <Row className="ps-4 pe-5 py-2" style={{ backgroundColor: '#E5E5E5' }}>
        <Col md="10">
          <h6 className="text-greyTeal mb-0 px-2 py-3">
            {translate('placeholders.schedule')}
          </h6>
        </Col>
        <Col
          md="2"
          className="py-1 d-flex justify-content-center align-items-center"></Col>
      </Row>
      <Row className="d-flex mb-3 mt-4">
        <Col md={8}></Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="me-4 ms-4" id="consultation-tab">
            <ul
              style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
              className="nav nav-pills justify-content-start tab-container"
              id="pills-tab"
              role="tablist">
              {APPOINTMENT_TABS.map((tab, index) => (
                <li key={index} className="nav-item" role="presentation">
                  <button
                    className={`nav-link mt-0 ${
                      tab === activeTab && 'active'
                    } tab-font`}
                    onClick={() => setActiveTab(tab)}
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true">
                    <span>
                      {translate(`appointment_tab.${tab}.tab_header`)}
                    </span>
                  </button>
                </li>
              ))}

              <div
                style={{ position: 'absolute', right: '80px' }}
                className="d-flex me-4 align-items-center">
                <Label style={{ width: '10rem' }} className=" me-2">
                  {translate(
                    'form.service.appointment_schedule.service_selection_label',
                  )}
                </Label>
                <select
                  placeholder="Select"
                  onChange={event => setService(event)}
                  style={{
                    width: '100%',
                  }}>
                  {services?.map((service, i) => (
                    <option key={i} value={service?.id}>
                      {service?.serviceName}
                    </option>
                  ))}
                </select>
              </div>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              {props.loading && !serviceId ? (
                <div className="text-info my-2" style={{ fontSize: 32 }}>
                  <FontAwesomeIcon icon={faSpinner} spin />
                </div>
              ) : (
                <Col md={12}>
                  {activeTab === APPOINTMENT_SCHEDULE ? (
                    <AppointmentSchedule serviceId={serviceId} />
                  ) : activeTab === APPOINTMENT_RESCHEDULE ? (
                    <Col>
                      <AppointmentReSchedule
                        serviceId={serviceId}
                        className="mb-2"
                      />
                    </Col>
                  ) : (
                    <Col />
                  )}
                </Col>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({
  authentication,
  user,
  videoConsultation,
  plan,
}: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  userMenus: user.menus,
  loading: user.loading,
  weekDetail: videoConsultation.weekDetail,
  doctors: videoConsultation.doctors,
  appointments: videoConsultation.appointments,
  consultationDetails: videoConsultation.consultationDetails,
  initiateVideoCallResponse: videoConsultation.initiateVideoCallResponse,
  services: plan.services,
});

const mapDispatchToProps = {
  updateAppointmentStatus,
  getServices,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppointmentManagmentTab);
