import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, ModalBody, Button } from 'reactstrap';
import { getLabRecord } from '../../../reducers/prescription.reducer';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { FaPen } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { translate } from 'react-jhipster';
import LaboratoryEntry from '../../components/prescription-entry/laboratory-entry';
import {
  Colors,
  HISTORY_LENGTH_FROM_REPORT_SCREEN,
  HTTP_CREATED_RESPONSE,
  HTTP_OK_RESPONSE,
  UNASSIGNED,
} from '../../../config/constants';
import { toast } from 'react-toastify';
import { PERMISSION_ACTIONS } from '../../../config/permission-constants';
import { IsPermittedAction } from '../../../shared/util/permission-utils';
import moment from 'moment-timezone';

const LaboratoryDetail = ({
  appointmentId,
  doctor,
  consultationDetails,
  historyLength,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: IRootState) => state.prescription.loading,
  );

  const labRecord = useSelector(
    (state: IRootState) => state.prescription.labRecord,
  );
  const updateLabaratoryResponse = useSelector(
    (state: IRootState) => state.prescription.updateLaboratoryResponse,
  );
  const createLaboratoryResponse = useSelector(
    (state: IRootState) => state.prescription.createLaboratoryResponse,
  );
  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );

  useEffect(() => {
    if (updateLabaratoryResponse?.status === HTTP_OK_RESPONSE) {
      dispatch(getLabRecord(appointmentId));
    }
  }, [updateLabaratoryResponse]);

  useEffect(() => {
    if (createLaboratoryResponse?.status === HTTP_CREATED_RESPONSE) {
      dispatch(getLabRecord(appointmentId));
    }
  }, [createLaboratoryResponse]);

  const [showPrescriptionPopup, setShowPrescriptionPopup] = useState(false);
  const [groupedLabRecords, setGroupedLabRecords] = useState([]) as any;

  const togglePrescriptionModal = () => {
    dispatch(getLabRecord(appointmentId));
    setShowPrescriptionPopup(!showPrescriptionPopup);
  };

  useEffect(() => {
    dispatch(getLabRecord(appointmentId));
  }, []);

  useEffect(() => {
    const groupLabRecordByDate = () => {
      const dataLab = labRecord?.reduce((value, item) => {
        const createdDate = moment(item.createdDate).format('DD-MM-YYYY');
        if (!value[createdDate]) {
          value[createdDate] = [item];
        } else {
          value[createdDate].push(item);
        }
        return value;
      }, {});
      return dataLab;
    };

    const data = groupLabRecordByDate();
    setGroupedLabRecords(data);
  }, [labRecord]);

  return (
    <div id="prescription-detail">
      {loading ? (
        <div
          className="text-info my-4 d-flex justify-content-center"
          style={{ fontSize: 32 }}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      ) : (
        <Row className="d-flex">
          <Col className={`main-container ${labRecord ? 'mw-100' : null}`}>
            {labRecord.length ? (
              <>
                <Col className="prescription-container">
                  <Row className="header-container">
                    <Col className="p-0">
                      {doctor ? (
                        <h5 className="header-texts">{doctor}</h5>
                      ) : null}
                      <h5 className="header-texts">
                        {consultationDetails?.businessName ?? ''}
                      </h5>
                      <h5 className="header-texts">
                        {consultationDetails?.city ?? ''},
                        {consultationDetails?.state ?? ''}
                      </h5>
                    </Col>
                    <Col className="d-flex align-items-end flex-column">
                      {/* Show pet details after integrating consultation details API */}
                    </Col>
                  </Row>

                  <Row className="bottom-container">
                    <Row
                      className="table-item custom-font-weight"
                      style={{ backgroundColor: '#c9dfda' }}>
                      <Col md={1} className="title justify-content-center">
                        {translate('laboratory_entry.serial_number')}
                      </Col>
                      <Col md={2} className="title">
                        {translate('laboratory_entry.test')}
                      </Col>
                      <Col md={2} className="title">
                        {translate('laboratory_entry.reference_range')}
                      </Col>
                      <Col md={1} className="title">
                        {translate('laboratory_entry.units')}
                      </Col>
                      <Col md={2} className="title">
                        {translate('laboratory_entry.methodology')}
                      </Col>
                      <Col md={1} className="title">
                        {translate('laboratory_entry.result')}
                      </Col>
                      <Col md={1} className="title">
                        {translate('laboratory_entry.time')}
                      </Col>
                    </Row>
                    {Object.entries(groupedLabRecords).map(
                      ([date, records]) => (
                        <>
                          <Row className="table-item mt-2 date-container">
                            <span>{date}</span>
                          </Row>
                          {Array.isArray(records) &&
                            records?.map((labItem, index) => (
                              <Row
                                key={index}
                                className="table-item mt-2 bg-white p-3">
                                <Col
                                  md={1}
                                  className="title justify-content-center d-flex table-text">
                                  <span style={{ marginRight: 5 }}>
                                    {index + 1}
                                  </span>
                                </Col>
                                <Col md={2} className="title d-block">
                                  <span className="table-text">
                                    {labItem?.labTest?.name}
                                  </span>
                                </Col>
                                <Col md={2} className="title d-block">
                                  <span className="table-text">
                                    {labItem?.referenceRange}
                                  </span>
                                </Col>
                                <Col md={1} className="title d-block">
                                  <span className="table-text">
                                    {labItem.unit}
                                  </span>
                                </Col>
                                <Col md={2} className="title d-block">
                                  <span className="table-text">
                                    {labItem?.methodology}
                                  </span>
                                </Col>
                                <Col md={1} className="title d-block">
                                  <span className="table-text">
                                    {labItem?.results}
                                  </span>
                                </Col>
                                <Col md={1} className="title d-block">
                                  <span className="table-text">
                                    {labItem?.createdDate
                                      ? new Date(
                                          labItem.createdDate,
                                        ).toLocaleTimeString('en-US', {
                                          hour: 'numeric',
                                          minute: 'numeric',
                                          hour12: true,
                                        })
                                      : ''}
                                  </span>
                                </Col>
                                <Col md={2} className="title">
                                  <span className="table-text">
                                    {labItem?.attachments.length ? (
                                      <a
                                        target="_blank"
                                        href={labItem?.attachments[0]}>
                                        report
                                        <FontAwesomeIcon
                                          icon={faDownload}
                                          className="ms-1 fa-lg"
                                          color="var(--bs-grey-teal)"
                                        />
                                      </a>
                                    ) : (
                                      ''
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            ))}
                        </>
                      ),
                    )}
                  </Row>
                </Col>
              </>
            ) : (
              <>
                <h4 className="mt-5 text-muted empty-list-text">
                  {translate('laboratory_entry.no_laboratory')}
                </h4>
                <Row className="d-flex justify-content-center p-0 m-0">
                  <Col md={2} className="d-flex justify-content-center">
                    {IsPermittedAction(
                      PERMISSION_ACTIONS.CREATE_LAB_REPORT,
                      authorities,
                      permissionList,
                    ) ? (
                      <>
                        {historyLength > HISTORY_LENGTH_FROM_REPORT_SCREEN && (
                          <Button
                            onClick={togglePrescriptionModal}
                            color={Colors.white}
                            className="submit-btn add-laboratory-button text-white">
                            {translate('laboratory_entry.add_laboratory')}
                          </Button>
                        )}
                      </>
                    ) : null}
                  </Col>
                </Row>
              </>
            )}
          </Col>
          {labRecord.length &&
          IsPermittedAction(
            PERMISSION_ACTIONS.UPDATE_LAB_REPORT,
            authorities,
            permissionList,
          ) ? (
            <>
              {historyLength > HISTORY_LENGTH_FROM_REPORT_SCREEN && (
                <div className="w-auto">
                  <Button
                    onClick={togglePrescriptionModal}
                    className="submit-btn add-reminder-button add-test">
                    Add Test
                  </Button>
                </div>
              )}
            </>
          ) : null}
        </Row>
      )}

      <Modal
        className="prescription-entry-modal"
        isOpen={showPrescriptionPopup}
        centered>
        <ModalBody>
          <LaboratoryEntry
            appointmentId={appointmentId}
            onCloseModal={togglePrescriptionModal}
            doctor={doctor}
            consultationDetails={consultationDetails}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LaboratoryDetail;
