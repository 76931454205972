import { Col, Container, Label, Row } from 'reactstrap';
import LogoImage from '../../svg/login-logo.svg';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { translate, Translate } from 'react-jhipster';
import './style.scss';
import { connect } from 'react-redux';
import { createNewPassword } from '../../reducers/user.reducer';
import { IRootState } from '../../shared/reducers';
import {
  Colors,
  HTTP_CREATED_RESPONSE,
  TOAST_TIMEOUT,
} from '../../config/constants';
import { RouteComponentProps } from 'react-router';
import { useEffect, useState } from 'react';
import Button from '../components/button';
import { AppLogo } from '../login/components/app-logo';
import { AppLogoHeader } from '../login/components/app-logo-header';
import { toast } from 'react-toastify';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export const NewPassword = (props: IPageProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [password, setPassword] = useState('');
  const updatePassword = event => setPassword(event.target.value);
  const clickHandler = (event, values) => {
    let payload = {
      password: values?.password,
      key: props?.match.params?.id,
    };
    setFormLoading(true);
    props.createNewPassword(payload);
  };

  useEffect(() => {
    if (formLoading) {
      if (props?.createNewPasswordResponse?.status === HTTP_CREATED_RESPONSE) {
        props.history.push('/landing-page');
        toast.success(translate('new_password.password_reset_successfully'), {
          autoClose: TOAST_TIMEOUT,
        });
      }
      setFormLoading(false);
    }
  }, [props?.createNewPasswordResponse]);

  return (
    <Container id={'login-form'} fluid className="p-0">
      <Row className="m-0">
        <Col
          md={7}
          className="column p-0 bg-light position-relative picture-div order-1 order-lg-0">
          <div className="d-flex justify-content-center align-items-start w-100">
            <div style={{ zIndex: 1000, marginTop: '2rem' }}>
              <AppLogoHeader />
            </div>
            <img
              className="position-absolute w-100"
              style={{ left: 0, bottom: 0 }}
              src={LogoImage}
              alt=""
            />
          </div>
          .
        </Col>
        <Col
          style={{ backgroundColor: Colors.pageBackground }}
          className="px-0 px-lg-5 column login-form-column email-div order-0 order-lg-1"
          fluid>
          <Col className="p-5 mt-5">
            <p className="login-head-text">
              {translate('new_password.new_password_head')}
            </p>
            <AvForm model={{}} onValidSubmit={clickHandler}>
              <Col className="mt-4" md={12}>
                <Label className="label" for="password">
                  <Translate contentKey="new_password.password">
                    New Password
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  required
                  className="p-3"
                  name="password"
                  id="password"
                  type="password"
                  onChange={updatePassword}
                  validate={{
                    required: {
                      errorMessage: translate('new_password.password_message'),
                    },
                  }}
                />
              </Col>
              <Col className="mt-4" md={12}>
                <Label className="label" for="confirm-password">
                  <Translate contentKey="new_password.confirm_password">
                    Confirm New Password
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  className="p-3"
                  name="confirm-password"
                  id="confirm-password"
                  type="password"
                  validate={{
                    required: {
                      errorMessage: translate(
                        'new_password.confirm_password_message',
                      ),
                    },
                    validate: v =>
                      v === password ||
                      translate('new_password.password_match_error_message'),
                  }}
                />
              </Col>
              <Col md={12}>
                <Button
                  type="submit"
                  style={{ width: '100%' }}
                  color="danger"
                  className="pb-0 pt-1 p-5 m-0 mt-5 text-light px-4">
                  <p className="text-center button-label mb-0 mt-0 pt-2 pb-2">
                    {translate('new_password.continue')}
                  </p>
                </Button>
              </Col>
            </AvForm>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};
const mapStateToProps = ({ authentication, plan, user }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  services: plan.services,
  loading: plan.loading,
  loginResponse: user.loginResponse,
  createNewPasswordResponse: user.createNewPasswordResponse,
});

const mapDispatchToProps = {
  createNewPassword,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
