import React, { useEffect, useState } from 'react';
import './style.scss';
import { Row, Col, Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import {
  getTestList,
  getLabRecord,
  createLaboratory,
  updateLaboratory,
} from '../../../reducers/prescription.reducer';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { Colors, UNASSIGNED } from '../../../config/constants';
import {
  IoMdAdd,
  AiOutlineDelete,
  GrClose,
  BsPaperclip,
  AiOutlineClose,
} from 'react-icons/all';
import CreatableSelect from 'react-select/creatable';
import { setFileData, translate, Translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import { startsWithFilter } from '../../../utils/startsWithSearch';
import moment from 'moment-timezone';

const filter = createFilterOptions();

const LaboratoryEntry = ({
  appointmentId,
  onCloseModal,
  doctor,
  consultationDetails,
}) => {
  const dispatch = useDispatch();
  const tests = useSelector((state: IRootState) => state.prescription.tests);
  const loading = useSelector(
    (state: IRootState) => state.prescription.loading,
  );
  const laboratory = useSelector(
    (state: IRootState) => state.prescription.labRecord,
  );

  const [laboratoryItems, setLaboratoryItems] = useState([] as any[]);
  const [errorMessage, setErrorMessage] = useState('' as string);
  const [laboratoryId, setLaboratoryId] = useState(0);
  const [errorRows, setErrorRows] = useState([] as any[]);
  const [prescriptionLoading, setPrescriptionLoading] = useState(false);
  const [testSelect, setTestSelect] = useState([] as any[]);
  const divRef = React.useRef(null as any);
  const [laboratoryValues, setLaboratoryValues] = useState([] as any[]);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    setErrorMessage('');
    setErrorRows([]);
  }, [laboratoryItems]);

  useEffect(() => {
    setTestSelect([]);
    dispatch(getLabRecord(appointmentId));
    setPrescriptionLoading(true);
    dispatch(getTestList());
  }, []);

  const handleClick = index => {
    divRef.current.index = index;
    divRef.current?.click();
  };

  const handleImagePick = (name, index) => event => {
    setFileData(
      event,
      (contentType, data) => {
        const laboratory = [...laboratoryItems];
        laboratory[divRef.current.index].attachments = [
          {
            imageContentType: contentType,
            imageData: data,
          },
        ];
        laboratory[divRef.current.index].previewAttachments = [
          {
            imageContentType: contentType,
            imageData: data,
          },
        ];
        setLaboratoryItems(laboratory);
      },
      isAnImage(event.target.files[0]),
    );
  };

  const isAnImage = file => {
    const fileExtension = file?.name?.split('.').pop();
    if (fileExtension === 'pdf') {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const testTemp = tests.map(test => ({
      value: test?.name,
      label: test?.name,
      id: test?.id,
      methodology: test?.methodology,
      referenceRange: test?.referenceRange,
      unit: test?.unit,
    }));
    setTestSelect(testTemp);
  }, [tests]);

  const handleChangeTest = (value, index) => {
    let laboratory = [...laboratoryItems];
    laboratory[index].testParameter = value?.value;
    laboratory[index].name = value?.value;
    laboratory[index].methodology = value?.methodology;
    laboratory[index].unit = value?.unit;
    laboratory[index].referenceRange = value?.referenceRange;
    laboratory[index].id = value?.id;
    setLaboratoryItems(laboratory);
  };

  const handleAttachmentDelete = index => {
    let laboratoryItem = [...laboratoryItems];
    laboratoryItem[index].attachments = [];
    laboratoryItem[index].previewAttachments = [];
    laboratoryItem[index].attachedUrls = [];
    setLaboratoryItems(laboratoryItem);
  };

  useEffect(() => {
    if (laboratory?.length) {
      const temp = [] as any;
      laboratory?.forEach((item: any) => {
        const full = item?.labTest;
        full.createdDate = item?.createdDate;
        full.testParameter = item?.labTest?.name;
        full.unit = item?.unit;
        full.referenceRange = item?.referenceRange;
        full.methodology = item?.methodology;
        full.results = item?.results;
        full.previewAttachments = item?.attachments;
        full.attachedUrls = item?.attachments;
        temp.push(full);
      });
      setLaboratoryItems(temp);
      setIsEmpty(false);
    } else setIsEmpty(true);
  }, []);

  const addPrescriptionItem = () => {
    const laboratory = [...laboratoryItems];
    laboratory.push({
      createdDate: moment().toISOString(),
      testParameter: '',
      methodology: '',
      referenceRange: '',
      unit: '',
      results: '',
      id: '',
      attachments: [],
      attachedUrls: [],
    });
    setLaboratoryItems(laboratory);
  };

  const onChangeReferenceRange = (e, index) => {
    let laboratory = [...laboratoryItems];
    laboratory[index].referenceRange = e.target.value;
    setLaboratoryItems(laboratory);
  };

  const onChangeMethodology = (e, index) => {
    let laboratory = [...laboratoryItems];
    laboratory[index].methodology = e.target.value;
    setLaboratoryItems(laboratory);
  };

  const onChangeResult = (e, index) => {
    let laboratory = [...laboratoryItems];
    laboratory[index].results = e.target.value;
    setLaboratoryItems(laboratory);
  };

  const onChangeUnit = (e, index) => {
    let laboratory = [...laboratoryItems];
    laboratory[index].unit = e.target.value;
    setLaboratoryItems(laboratory);
  };

  const removePrescriptionItem = index => {
    let laboratory = [...laboratoryItems];
    laboratory.splice(index, 1);
    setLaboratoryItems(laboratory);
  };

  const handleValidSubmit = (event, errors, values) => {
    if (!laboratoryItems?.length) {
      setErrorRows([0]);
      setErrorMessage(translate('laboratory_entry.add_at_least_one'));
      return false;
    }

    let invalidIndexes = [] as any[];
    laboratoryItems.forEach((item, index) => {
      if (!item.testParameter) {
        invalidIndexes.push(index);
      }
    });

    if (invalidIndexes.length) {
      setErrorRows(invalidIndexes);
      return setErrorMessage(
        translate('laboratory_entry.please_check_the_details'),
      );
    }
    if (laboratoryItems.length) {
      if (!isEmpty)
        dispatch(updateLaboratory({ appointmentId, laboratoryItems }));
      else {
        const laboratoryDetails = laboratoryItems?.map(
          ({ createdDate, ...rest }) => rest,
        );
        dispatch(createLaboratory({ appointmentId, laboratoryDetails }));
      }
      onCloseModal();
      toast.success(translate('laboratory_entry.saved'));
    } else toast.error(translate('error.something_went_wrong'));
  };

  const base64toBlob = (data: string) => {
    const bytes = atob(data);
    let length = bytes.length;
    const out = new Uint8Array(length);

    // eslint-disable-next-line no-plusplus
    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  };

  const getPdfUrlFromBlob = (blob: any) =>
    URL.createObjectURL(base64toBlob(blob));

  const [groupedLabRecords, setGroupedLabRecords] = useState([]) as any;
  useEffect(() => {
    const groupLabRecordByDate = () => {
      const dataLab = laboratoryItems?.reduce((value, item, index) => {
        const formattedCreatedDate = moment(item.createdDate).format(
          'DD-MM-YYYY',
        );
        if (!value[formattedCreatedDate]) {
          value[formattedCreatedDate] = [{ ...item, index }];
        } else {
          value[formattedCreatedDate].push({ ...item, index });
        }
        return value;
      }, {});
      return dataLab;
    };

    const data = groupLabRecordByDate();
    setGroupedLabRecords(data);
  }, [laboratoryItems]);

  return (
    <Row className="prescription-entry">
      <Col className="inner-container">
        <Row className="header-container">
          <Col className="p-0">
            <h5 className="header-texts">{doctor ?? UNASSIGNED}</h5>
            <h5 className="header-texts">
              {consultationDetails?.businessName ?? ''}
            </h5>
            <h5 className="header-texts">
              {consultationDetails?.city ?? ''},
              {consultationDetails?.state ?? ''}
            </h5>
          </Col>
          <Col className="d-flex justify-content-end p-2">
            <GrClose onClick={onCloseModal} role="button" />
          </Col>
        </Row>

        <Row className="bottom-container">
          <Row className="table-item">
            <Col xxl={10} xl={10} lg={10}>
              <Row>
                <Col lg={1} xl={1} xxl={1} className="title ">
                  {translate('laboratory_entry.serial_number')}
                </Col>
                <Col lg={3} xl={3} xxl={3} className="title ">
                  {translate('laboratory_entry.test')}
                </Col>
                <Col lg={2} xl={2} xxl={2} className="title">
                  {translate('laboratory_entry.reference_range')}
                </Col>
                <Col lg={1} xl={1} xxl={1} className="title">
                  {translate('laboratory_entry.units')}
                </Col>
                <Col lg={2} xl={2} xxl={2} className="title">
                  {translate('laboratory_entry.methodology')}
                </Col>
                <Col lg={1} xl={1} xxl={1} className="title">
                  {translate('laboratory_entry.result')}
                </Col>
                <Col lg={2} xl={2} xxl={2}></Col>
              </Row>
            </Col>
          </Row>

          {Object.entries(groupedLabRecords).map(([date, records]) => (
            <>
              <Row className="table-item mt-2 date-container">
                <span>{date}</span>
              </Row>
              {Array.isArray(records) &&
                records?.map((labItem, index) => (
                  <Row
                    key={index}
                    className={`table-item mt-2 bg-white ${
                      errorRows.includes(index) && 'highlight-error'
                    } `}>
                    <Col xxl={10} xl={10} lg={10}>
                      <Row>
                        <Col
                          xxl={1}
                          xl={1}
                          lg={1}
                          className="title justify-content-center d-flex table-text">
                          {index + 1}
                        </Col>
                        <Col xxl={3} xl={3} lg={3} className=" d-block">
                          <Tooltip title={labItem?.name ? labItem?.name : ''}>
                            <div>
                              <CreatableSelect
                                className="style-creatable-box"
                                isClearable
                                options={testSelect}
                                value={{
                                  value: labItem?.name,
                                  label: labItem?.name,
                                  id: labItem?.id,
                                  methodology: labItem?.methodology,
                                  referenceRange: labItem?.referenceRange,
                                  unit: labItem?.unit,
                                }}
                                createOptionPosition="first"
                                filterOption={startsWithFilter}
                                onChange={value => {
                                  handleChangeTest(value, labItem?.index);
                                }}
                              />
                            </div>
                          </Tooltip>
                        </Col>
                        <Col xxl={2} xl={2} lg={2}>
                          <input
                            value={labItem?.referenceRange}
                            className="input-box"
                            type="text"
                            onChange={e =>
                              onChangeReferenceRange(e, labItem?.index)
                            }
                          />
                        </Col>
                        <Col xxl={1} xl={1} lg={1}>
                          <Col className="h-100 ">
                            <input
                              value={labItem?.unit}
                              className="input-box"
                              type="text"
                              onChange={e => onChangeUnit(e, labItem?.index)}
                            />
                          </Col>
                        </Col>
                        <Col xxl={2} xl={2} lg={2}>
                          <Tooltip
                            title={
                              labItem?.methodology ? labItem?.methodology : ''
                            }>
                            <input
                              value={labItem?.methodology}
                              className="input-box "
                              type="text"
                              onChange={e =>
                                onChangeMethodology(e, labItem?.index)
                              }
                            />
                          </Tooltip>
                        </Col>
                        <Col xxl={2} xl={2} lg={2}>
                          <input
                            value={labItem?.results}
                            className="input-box"
                            type="text"
                            onChange={e => onChangeResult(e, labItem?.index)}
                          />
                        </Col>
                        <Col xxl={1} xl={1} lg={1}>
                          <Row className="align-items-center justify-content-center">
                            <Col xs={5} className="p-1">
                              {labItem?.previewAttachments?.length ? (
                                <div className="lab-report-attachment">
                                  <div className="d-flex justify-content-center align-items-center w-100">
                                    <small>Report</small>
                                    <AiOutlineClose
                                      onClick={() => {
                                        handleAttachmentDelete(labItem?.index);
                                      }}
                                      cursor="pointer"
                                    />
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col xxl={2} xl={2} lg={2}>
                      <Row>
                        <Col xxl={6} xl={6} lg={6} className="p-2 mr-2">
                          <Button
                            size="sm"
                            onClick={() => {
                              handleClick(labItem?.index);
                            }}
                            className="icon-paper-clip">
                            <BsPaperclip />
                          </Button>
                          <input
                            type="file"
                            ref={divRef}
                            onChange={handleImagePick('image', labItem?.index)}
                            accept="image/x-png,image/jpeg,application/pdf,.doc,.docx"
                            style={{ display: 'none' }}
                          />
                        </Col>
                        <Col xxl={6} xl={6} lg={6} className="p-2">
                          <div
                            onClick={() =>
                              removePrescriptionItem(labItem?.index)
                            }
                            className="add-item-button bg-white align-items-center d-flex justify-content-center pt-2 pb-2 pl-2 ml-2">
                            <AiOutlineDelete
                              size={20}
                              color={Colors.geraldine}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ))}
            </>
          ))}

          <Row className="table-item mt-2 bg-white">
            <Col className="justify-content-end d-flex">
              <div
                onClick={addPrescriptionItem}
                className="add-item-button align-items-center d-flex justify-content-center pt-2 pb-2 pr-2">
                <IoMdAdd className="text-light" />
              </div>
            </Col>
          </Row>

          {errorMessage && (
            <Row className="pl-0 ml-0">
              <span className="text-danger error-text mt-2 mb-2">
                {errorMessage}
              </span>
            </Row>
          )}

          <AvForm onSubmit={handleValidSubmit}>
            <Row className="d-grid justify-content-center mt-3">
              <Button
                disabled={loading}
                type="submit"
                color="danger"
                className="save-button">
                <span className="text-center title">
                  {translate('placeholders.save')}
                </span>
              </Button>
            </Row>
          </AvForm>
        </Row>
      </Col>
    </Row>
  );
};

export default LaboratoryEntry;
