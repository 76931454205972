import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Card, Col, Container, Row } from 'reactstrap';
import {
  getPreRegisteredUser,
  postRegister,
  setRegisteredUser,
  updatePostRegistrationState,
  createVendorPreSubscriptionOrder,
  resetSubscriptionOrderResponse,
  updatePaymentFailureStatus,
  updatePaymentStatus
} from '../../reducers/user.reducer';
import { IRootState } from '../../shared/reducers';
import { AvForm } from 'availity-reactstrap-validation';
import { getPlans } from '../../reducers/plan.reducer';
import './style.scss';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  HTTP_CREATED_RESPONSE,
  PAYMENT_AMOUNT_MULTIPLIER,
  PAYMENT_CURRENCY,
  PAYMENT_DESCRIPTION,
  PAYMENT_IMAGE,
  PAYMENT_METHOD,
  PAYMENT_NAME,
  RAZORPAY_PAYMENT_TEST_KEY,
  VENDOR_SUBSCRIPTION,
} from '../../config/constants';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { translate, Translate } from 'react-jhipster';
import Steps from '../components/steps';
import {
  DISCOUNT_PERCENTAGE_BASE,
  HTTP_OK_RESPONSE,
} from '../../config/constants';
import Button from '../components/button';
import { AppLogo } from '../login/components/app-logo';
import leftBgImg from '../../assets/images/left-container-bg.png';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export const ConfirmRegistration = (props: IPageProps) => {
  const {
    plans,
    user,
    postRegistrationDetails,
    subscriptionOrderResponse,
    postRegisterResponse,
  } = props;
  const [registerLoading, setRegisterLoading] = useState(false as boolean);

  useEffect(() => {
    props.getPlans();
  }, []);

  const removePlan = plan => {
    let filteredSubscriptions = postRegistrationDetails?.subscriptions?.filter(
      (x, i) => x.id !== plan?.id,
    );
    postRegistrationDetails.subscriptions = filteredSubscriptions;
    props.updatePostRegistrationState(postRegistrationDetails);
  };

  const discount = postRegistrationDetails?.onboardingDiscountPercentage ?? 0;

  const [total, setTotal] = useState(
    postRegistrationDetails?.subscriptions?.reduce(
      (sum, obj) => sum + (obj?.total ? obj?.total : 0),
      0,
    ) ?? 0,
  );

  const discountAmount = total
    ? Math.round((total / DISCOUNT_PERCENTAGE_BASE) * discount)
    : 0;

  const netTotal = total === 0 ? 0 : total - discountAmount;

  const handleAddMoreService = () => {
    props.history.replace('/service-subscription');
  };

  useEffect(() => {
    if (registerLoading) {
      if (postRegisterResponse.status === HTTP_OK_RESPONSE) {
        const data = {
          id: postRegisterResponse.data?.id,
          type: VENDOR_SUBSCRIPTION,
        };
        props.createVendorPreSubscriptionOrder(data);
      }
      else {
        toast.error(postRegisterResponse.data?.message);
      }
      setRegisterLoading(false);
    }
  }, [postRegisterResponse]);

  const onPressMakePayment = () => {
    setRegisterLoading(true);
    props.postRegister(postRegistrationDetails);
  };

  useEffect(() => {
    props.resetSubscriptionOrderResponse();
  }, []);

  useEffect(() => {
    if (subscriptionOrderResponse?.status === HTTP_CREATED_RESPONSE) {
      var options = {
        key: RAZORPAY_PAYMENT_TEST_KEY,
        amount:
          subscriptionOrderResponse?.data.amount * PAYMENT_AMOUNT_MULTIPLIER,
        currency: PAYMENT_CURRENCY,
        name: PAYMENT_NAME,
        description: PAYMENT_DESCRIPTION,
        image: PAYMENT_IMAGE,
        order_id: subscriptionOrderResponse?.data.orderId,
        handler: function (response) {
          const data = {
            paymentId: response.razorpay_payment_id,
            orderId: response.razorpay_order_id,
            signature: response.razorpay_signature,
            type: VENDOR_SUBSCRIPTION,
          };

          if (data) {
            props.updatePaymentStatus(data);
            props.history.push({
              pathname: '/payment-success',
              state: { isRenewalPaymentSuccessPage: false },
            });
          }
        },
        prefill: {
          name: postRegistrationDetails.businessName,
          email: postRegistrationDetails.email,
          contact: postRegistrationDetails.mobileNo,
        },
        config: {
          display: {
            hide: [
              {
                method: PAYMENT_METHOD.EMI,
              },
              {
                method: PAYMENT_METHOD.WALLET,
              },
              {
                method: PAYMENT_METHOD.PAYLATER,
              },
            ],
            preferences: {
              show_default_blocks: true,
            },
          },
        },       
        theme: {
          color: '#61dafb',
        },
      };
      var pay = new window.Razorpay(options);
      pay.on('payment.failed', function (response) {
        const data = {
          paymentId: response.error.metadata.payment_id,
          orderId: subscriptionOrderResponse.data.orderId,
          code: response.error.code,
          description: response.error.description,
          source: response.error.source,
          step: response.error.step,
          reason: response.error.reason,
          type: VENDOR_SUBSCRIPTION,
        };
        if (data != null) {
          pay.close();
          props.updatePaymentFailureStatus(data);
        }
      });
      pay.open();
    }
  }, [subscriptionOrderResponse]);

  const checkSubscriptionsLength = () => {
    let checkExist = false;
    if (postRegistrationDetails.subscriptions) {
      checkExist = postRegistrationDetails.subscriptions?.length > 0;
    }
    return true;
  };

  return (
    <Row className="w-100 mx-0" style={{ height: '100vh' }}>
      <Col xs="12" md="4" className="px-0 h-100 sm-h-20">
        <div className="left-container">
          <div className="d-flex flex-column h-100 justify-content-center align-items-center">
            <div className="logo-container">
              <AppLogo />
            </div>
            <h2 className="text-white">
              <Translate contentKey="pages.confirmation">
                confirmation
              </Translate>
            </h2>
          </div>
          <img src={leftBgImg} alt="left-bg"></img>
        </div>
      </Col>
      <Col xs="12" md="8" className="right-container">
        <Steps
          className="mt-sm-4"
          total={3}
          selected={3}
          key={3}
          labels={[
            translate('placeholders.pre_registration'),
            translate('placeholders.post_registration'),
            translate('placeholders.finish'),
          ]}
        />

        <AvForm model={{}}>
          <Row className="services-container">
            <Col lg="12">
              <h2>
                <Translate contentKey="placeholders.services_selected">
                  services_selected
                </Translate>
              </h2>
              {!postRegistrationDetails.subscriptions?.length && (
                <h4 className="my-4 text-muted">
                  <Translate contentKey="placeholders.noServices">
                    No services found
                  </Translate>
                </h4>
              )}
              <Row>
                <Col className="services" style={{marginLeft:'80px'}}>
                  {postRegistrationDetails.subscriptions?.map((plan, index) => (
                    <Card key={index} className="summary">
                      <h6> {plan?.name} </h6>
                      <h6 className="inter"> ₹ {plan?.total ?? 0} </h6>
                    </Card>
                  ))}
                </Col>
                {/* for future
                 <Col md="12">
                  <Button
                    transparent
                    className="add-more w-100 py-2"
                    onClick={handleAddMoreService}>
                    <Translate contentKey="placeholders.addMoreService">
                      Add more service
                    </Translate>
                  </Button>
                </Col>  */}
              </Row>
            </Col>
            <Row className="my-4 px-lg-5">
              <Col>
                <div className="summary-info">
                  <div className="item">
                    <h6>
                      <Translate contentKey="placeholders.subscription_rate">
                        Subscription rate
                      </Translate>
                    </h6>
                    <h6 className="inter"> ₹ {total} </h6>
                  </div>
                  <div className="item">
                    <h6>
                      <Translate contentKey="placeholders.discount">
                        Discount
                      </Translate>
                      &nbsp; (
                      {postRegistrationDetails?.onboardingDiscountPercentage}
                      %)
                    </h6>
                    <h6 className="inter">₹ {discountAmount} </h6>
                  </div>
                  <div className="item">
                    <h6>
                      <Translate contentKey="placeholders.total">
                        total
                      </Translate>
                    </h6>
                    <h6 className="inter"> ₹ {netTotal} </h6>
                  </div>
                  <Button
                    disabled={!checkSubscriptionsLength()}
                    onClick={onPressMakePayment}
                    color="primary"
                    className="confirm w-100 py-3 px-4">
                    <Translate contentKey="placeholders.confirmPayment">
                      Confirm and make the payment
                    </Translate>
                  </Button>
                </div>
              </Col>
            </Row>
          </Row>
        </AvForm>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ authentication, user, plan }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  user: user.user,
  loading: user.loading,
  plans: plan.plans,
  postRegistrationDetails: user.postRegistrationDetails,
  postRegisterResponse: user.postRegisterResponse,
  subscriptionOrderResponse : user.preSubscriptionOrderResponse,
});

const mapDispatchToProps = {
  getPreRegisteredUser,
  setRegisteredUser,
  getPlans,
  updatePostRegistrationState,
  postRegister,
  createVendorPreSubscriptionOrder,
  resetSubscriptionOrderResponse,
  updatePaymentStatus,
  updatePaymentFailureStatus
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmRegistration);
