export const PERMISSION_ACTIONS = {
  CREATE_VENDOR: 'createVendor',
  GET_VENDOR_CUSTOMER: 'getVendorCustomer',
  GET_ALL_VENDOR_CUSTOMERS_PETS: 'getAllCustomerPets',
  GET_SERVICE_APPOINTMENTS_BY_CUSTOMER_ID: 'getServiceAppointmentsByCustomerId',
  GET_CUSTOMER_APPOINTMENT: 'getCustomerAppointment',
  GET_CUSTOMER_APPOINTMENT_PRESCRIPTIONS: 'getCustomerAppointmentPrescriptions',
  GET_ALL_VENDOR_USERS: 'getAllVendorUsers',
  GET_VENDOR_USERS: 'getVendorUsers',
  CREATE_VENDOR_USERS: 'createVendorUsers',
  UPDATE_VENDOR_USERS: 'updateVendorUsers',
  DELETE_VENDOR_USERS: 'deleteVendorUsers',
  GET_VENDOR_PROFILE: 'getVendorProfile',
  UPDATE_VENDOR_PROFILE: 'updateVendorsProfile',
  GET_VENDOR_APPOINTMENT_SUMMARY: 'getVendorAppointmentSummary',
  GET_DOCTORS_APPOINTMENTS: 'getDoctorsAppointments',
  GET_ALL_DOCTORS: 'getAllDoctors',
  INITIATE_CALL: 'initiateCall',
  CREATE_APPOINTMENT_PRESCRIPTION: 'createAppointmentPrescriptions',
  GET_APPOINTMENT_LAB_REPORT: 'getAppointmentsLabReport',
  CREATE_LAB_REPORT: 'createLabReport',
  UPDATE_LAB_REPORT: 'updateLabReport',
  SET_VENDOR_SPECIALITY_QUESTIONNAIRES: 'setVendorSpecialityQuestionnaires',
  UPDATE_VENDOR_SPECIALITY_QUESTIONNAIRES:
    'updateVendorSpecialityQuestionnaires',
  CREATE_OFFLINE_APPOINTMENT: 'createOfflineAppointment',
  EDIT_OFFLINE_APPOINTMENT: 'updateOfflineAppointment',
  GET_OFFLINE_APPOINTMENT: 'getOfflineAppointment',
  UPDATE_CUSTOMER_APPOINTMENT_STATUS: 'updateCustomerAppointmentStatus',
  UPDATE_APPOINTMENT_PRISCRIPTION: 'updateAppointmentPrescriptions',
  CREATE_VENDOR_SERVICE_DEFAULT_SLOT: 'createVendorServiceDefaultSlot',
  GET_VENDOR_SERVICE_DEFAULT_SLOT: 'getVendorServiceDefaultSlot',
  UPDATE_VENDOR_SERVICE_DEFAULT_SLOT: 'updateVendorServiceDefaultSlot',
  GET_VENDOR_SERVICE_SLOT: 'getVendorServiceSlots',
  RESCHEDULE_SLOTS: 'rescheduleSlots',
  GET_VENDOR_SERVICE_BUSINESS_INFO: 'getVendorServiceBusinessInfo',
  CREATE_VENDOR_SERVICE_BUSINESS_INFO: 'createVendorServiceBusinessInfo',
  UPDATE_VENDOR_SERVICE_BUSINESS_INFO: 'updateVendorServiceBusinessInfo',
  GET_VENDOR_SERVICES: 'getVendorServices',
  CREATE_VENDOR_SERVICE_DETAILS: 'createVendorsServiceDetails',
  UPDATE_VENDOR_SERVICE_DETAILS: 'updateVendorsServiceDetails',
  GET_VENDOR_SERVICE_BANK_ACCOUNT_DETAILS: 'getVendorServiceBankAccountDetails',
  CREATE_VENDOR_SERVICE_BANK_DETAILS: 'createVendorServiceBankAccountDetails',
  EDIT_VENDOR_SERVICE_BANK_DETAILS: 'updateServiceBankAccountDetails',
  GET_VENDOR_SERVICE_FACILITIES: 'getVendorServicesFacilities',
  CREATE_VENDOR_SERVICE_FACILITIES: 'createVendorServicesFacilities',
  UPDATE_VENDOR_SERVICE_FACILITIES: 'updateVendorServicesFacilities',
  GET_VENDOR_SERVICE_SUBSCRIPTIONS: 'getVendorServiceSubscriptions',
  EDIT_VENDOR_SERVICE_SUBSCRIPTIONS: 'updateVendorServiceSubscriptions',
  EDIT_VENDOR_SERVICE_STATUS: 'updateVendorServiceStatus',
  RENEW_SUBSCRIPTION: 'createPaymentOrderSubscription',
  GET_MEDICAL_HISTORY: 'getAppointments',
  GET_REMINDERS:'getReminders',
  GET_APPOINTMENT_REPORT: 'getAppointmentsReports',
  GET_INPATIENT_APPOINTMENTS: 'getDoctorsAppointmentsInpatient',
  CREATE_REMINDER:'createReminder',
  EDIT_REMINDER:'editReminder',
  GET_SINGLE_REMINDER:'getSingleReminder',
  GET_ALL_REMINDER:'getAllReminder'
};

export const APP_MENUS_AND_PERMISSIONS = {
  services: [],
  schedule: [
    PERMISSION_ACTIONS.GET_VENDOR_SERVICE_DEFAULT_SLOT,
    PERMISSION_ACTIONS.GET_VENDOR_SERVICE_SLOT,
  ],
  users: [PERMISSION_ACTIONS.GET_ALL_VENDOR_USERS],
  appointments: [
    PERMISSION_ACTIONS.GET_VENDOR_APPOINTMENT_SUMMARY,
    PERMISSION_ACTIONS.GET_DOCTORS_APPOINTMENTS,
    PERMISSION_ACTIONS.GET_ALL_DOCTORS,
  ],
  customers: [PERMISSION_ACTIONS.GET_VENDOR_CUSTOMER],
  appointmentReport: [PERMISSION_ACTIONS.GET_APPOINTMENT_REPORT],
  inPatients: [
    PERMISSION_ACTIONS.GET_DOCTORS_APPOINTMENTS,
    PERMISSION_ACTIONS.GET_ALL_DOCTORS,
    PERMISSION_ACTIONS.GET_INPATIENT_APPOINTMENTS
  ],
};
