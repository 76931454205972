import {
  faCalendarAlt,
  faChevronDown,
  faChevronUp,
  faList,
  faUserFriends
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "./drop-down.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faVideo } from "@fortawesome/free-solid-svg-icons/faVideo";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
library.add(faUser, faUsers, faList, faVideo, faHome, faCalendarAlt, faUserFriends, faTableList);
interface NameAndUrl {
  name: string;
  url: string;
}

export interface IDropDownProps {
  title: string;
  icon: any;
  defaultLink?: string;
  subMenus: NameAndUrl[];
  isOpen?: boolean;
  isExpandable?: boolean;
  onClick?: Function;
  drawerToggle?: Function;
  sideDrawerOpen?: boolean;
  isRouteActive?: boolean;
  onHeaderClick: any;
  isLink?: boolean;
}
export const DropDown = (props: IDropDownProps) => {
  const history = useHistory();
  const checkIfActive = () => {
    for (let index = 0; index < props.subMenus?.length; index++) {
      const path = history.location.pathname.split("/");
      const menuPath = props.subMenus[index].url?.split("/");
      const menuEndingUrl = menuPath[menuPath?.length - 1];
      if (path.findIndex((obj) => "/" + obj === "/" + menuEndingUrl) > -1) {
        return true;
      }
    }
  };
  const [isOpen, setOpen] = useState(checkIfActive());

  useEffect(() => {
    if (isOpen && props.drawerToggle && !props.sideDrawerOpen) {
      // commented for future
      // props.drawerToggle();
    }
  }, [isOpen]);

  return (
    <Row>
      <Col>
        <div className="drop-down-container" onClick={() => setOpen(!isOpen)}>
          <div
            className={`drop-down-header ${
              props.isRouteActive ? "active" : ""
            }`}
            onClick={props.onHeaderClick}
          >
            <div className="active-border"></div>
           
            <span className="icon">
              {props.isLink ?
             <img src={props.icon} style={{width:'22px'}}/>
              :
              <FontAwesomeIcon icon={props.icon} />
}
            </span>
            <span className={`ms-2 title text-light`}>{props.title}</span>
            {props.isExpandable ? (
              <span className="chevron-icon ms-auto">
                <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
              </span>
            ) : null}
          </div>
        </div>
        {isOpen && props.isExpandable ? (
          <Row className="drop-down-body ms-2">
            <Col>
              {props.subMenus.map((item) => (
                <div key={item.name}>
                  <NavLink to={item.url}>{item.name}</NavLink>
                </div>
              ))}
            </Col>
          </Row>
        ) : null}
      </Col>
    </Row>
  );
};
