import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { toast, ToastContainer } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import { AUTHORITIES, SESSION_EXPIRE_REDIRECTION_TIMEOUT } from '../../../config/constants';
import { hasAnyAuthority } from '../../auth/private-route';
import ErrorBoundary from '../../error/error-boundary';
import { IRootState } from '../../reducers';
import { getSession, logout } from '../../reducers/authentication';
import { setLocale } from '../../reducers/locale';
import Header from '../header/header';
import SideDrawer from '../side-drawer/side-drawer';
import './styles.scss';

export const AppLayout = props => {
  const {
    children,
    isAuthenticated,
    isAdmin,
    currentLocale,
    backdropClickHandler,
  } = props;
  const history= useHistory();

  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const drawerToggleClickHandler = () => setSideDrawerOpen(!sideDrawerOpen);

  useEffect(() => {
    if(!isAuthenticated){
      setTimeout(() => {
        history.push('/landing-page')
      }, SESSION_EXPIRE_REDIRECTION_TIMEOUT);
    }
  }, [isAuthenticated])

  return (
    <div id="app-layout" className="App">
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        className="toastify-container"
        toastClassName="toastify-toast"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ErrorBoundary>
        <Header
          isAuthenticated={isAuthenticated}
          isAdmin={isAdmin}
          currentLocale={currentLocale}
          onLocaleChange={setLocale}
          sideDrawerOpen={sideDrawerOpen}
          backdropClickHandler={backdropClickHandler}
          logout={logout}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <SideDrawer
          sideDrawerOpen={sideDrawerOpen}
          drawerToggleClickHandler={drawerToggleClickHandler}
          isExpandable={false}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <div className="view-routes">
          <Row className="m-0 p-0">
            {sideDrawerOpen && <Col xs="0" sm="0" md="0" lg="3" xl="2"></Col>}
            <Col
              md="12"
              lg={sideDrawerOpen ? 9 : 12}
              xl={sideDrawerOpen ? 10 : 12}
              style={{
                paddingLeft: sideDrawerOpen ? 'unset' : '3.2rem',
                paddingRight: 0,
              }}>
              {children}
            </Col>
          </Row>
        </div>
      </ErrorBoundary>
    </div>
  );
};

const mapStateToProps = ({ authentication, locale, user }: IRootState) => ({
  currentLocale: locale.currentLocale,
  isAuthenticated: authentication.isAuthenticated,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [
    AUTHORITIES.ADMIN,
  ]),
});

const mapDispatchToProps = { getSession, setLocale, logout };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
