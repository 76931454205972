import React, { useState } from 'react';
import { ICameraVideoTrack, IMicrophoneAudioTrack } from 'agora-rtc-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faVideo,
  faVideoSlash,
  faMicrophone,
  faMicrophoneSlash,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import {
  Colors,
  CUSTOMER_CALL_STATUS,
  MEDIA_TYPES,
} from '../../../config/constants';
import { ImPhoneHangUp } from 'react-icons/im';

const VideoControls = (props: {
  tracks: [IMicrophoneAudioTrack, ICameraVideoTrack];
  setStart: React.Dispatch<React.SetStateAction<boolean>>;
  setInCall: React.Dispatch<React.SetStateAction<boolean>>;
  onClickEditPrescription: any;
  handleCloseVideo: any;
  setCallStatus: Function;
  trackState: any;
  setTrackState: Function;
}) => {
  const {
    tracks,
    setStart,
    setInCall,
    onClickEditPrescription,
    handleCloseVideo,
    setCallStatus,
    trackState,
    setTrackState,
  } = props;

  const mute = async (type: 'audio' | 'video') => {
    if (type === MEDIA_TYPES.AUDIO) {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState(ps => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === MEDIA_TYPES.VIDEO) {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState(ps => {
        return { ...ps, video: !ps.video };
      });
    }
  };

  const leaveChannel = async () => {
    setCallStatus(CUSTOMER_CALL_STATUS.VENDOR_CALL_DISCONNECTED);
    handleCloseVideo();
    setStart(false);
    setInCall(false);
  };

  return (
    <div className="controls">
      <div
        className={trackState.audio ? 'on' : ''}
        onClick={() => mute('audio')}>
        <FontAwesomeIcon
          className="icon"
          icon={trackState.audio ? faMicrophone : faMicrophoneSlash}
        />
      </div>

      <div
        className={trackState.video ? 'on' : ''}
        onClick={() => mute('video')}>
        <FontAwesomeIcon
          className="icon"
          icon={trackState.video ? faVideo : faVideoSlash}
        />
      </div>
      <div
        onClick={leaveChannel}
        style={{ backgroundColor: Colors.darkRedColor }}>
        <ImPhoneHangUp className="icon" />
      </div>
      <div
        className={trackState.video ? 'on' : ''}
        onClick={onClickEditPrescription}>
        <FontAwesomeIcon className="icon" icon={faPen} />
      </div>
    </div>
  );
};

export default VideoControls;
