import ReactDOM from "react-dom";
import { bindActionCreators } from "redux";
import reportWebVitals from "./reportWebVitals";
import initStore from "./config/store";
import { registerLocale } from "./config/translation";
import setupAxiosInterceptors from "./config/axios-interceptor";
import { clearAuthentication } from "./shared/reducers/authentication";
import ErrorBoundary from "./shared/error/error-boundary";
import { Provider } from "react-redux";
import App from "./App";
import { INVALID_CREDENTIALS_MESSAGE_KEY } from "./config/constants";
import { initializeFirebase } from './push-notification';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { SENTRY_DSN } from "./config/constants";
import moment from 'moment-timezone';

const { store } = initStore();

registerLocale(store);

const setDefaultTimeZone = () => {
  moment.tz.setDefault("Asia/Kolkata");
}

setDefaultTimeZone();

Sentry.init({
  dsn: SENTRY_DSN,
});

const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
setupAxiosInterceptors(() =>
  actions.clearAuthentication(INVALID_CREDENTIALS_MESSAGE_KEY)
);
const rootEl = document.getElementById("root");

const render = Component =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <div>
          <Component />
        </div>
      </Provider>
    </ErrorBoundary>,
    rootEl
  );

render(App);
initializeFirebase();    
