import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import { IRootState } from '../../shared/reducers';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  createSubscriptionDetail,
  updateSubscriptionDetail,
  getSubscriptionDetail,
  getServiceDetails,
  createServiceDetail,
  updateServiceDetail,
} from '../../reducers/user.reducer';
import { IMenu, ISubMenu } from '../../model/user-menu';
import SubHeader from '../../shared/layout/sub-header';
import { Translate, translate } from 'react-jhipster';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useWindowDimensions } from '../../shared/util/utility-functions';
import { getServicesMenus } from '../../reducers/plan.reducer';
import { REG_EX, SM_SCREEN_BREAKPOINT } from '../../config/constants';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import {
  getLanguageList,
  getPetTypes,
  getSpecialityList,
} from '../../reducers/master.reducer';
import moment from 'moment-timezone';
import Button from '../components/button';
import ServicePublishButton from './components/service-publish-button';
import { PERMISSION_ACTIONS } from '../../config/permission-constants';
import { IsPermittedAction } from '../../shared/util/permission-utils';
export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

const NUMBER_OF_LIMIT_TAGS = 1;

const ServiceDetails = (props: any) => {
  const {
    specialities,
    servicesLinks,
    languages,
    petTypes,
    serviceDetails,
    loading,
  } = props;
  const [selectedSubMenu, setSubMenu] = useState({} as ISubMenu);
  const [specialityList, setSpecialityList] = useState([] as any[]);

  const [selectedSpecialities, setSelectedSpecialities] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedPetTypes, setSelectedPetTypes] = useState([]);
  const [pharmaDetailsShow, setPharmaDetailsShow] = useState(false);
  const [consultationError, setConsultationError] = useState(false);
  const [consultationOfflineError, setConsultationOfflineError] =
    useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [serviceDetail, setServiceDetail] = useState({
    id: null,
    numberOfVets: '',
    workingHoursFrom: '',
    workingHoursTo: '',
    languages: [],
    drugLicenceNo: '',
    pharmaGstNo: '',
    consultationCharges: '',
    petTypes: [],
    specialities: [],
    consultationChargeOffline: '',
  });

  const [specialityError, setSpecialityError] = useState('');
  const [languageError, setLanguageError] = useState('');
  const [petTypeError, setPetTypeError] = useState('');
  const { width } = useWindowDimensions();
  const serviceId = props.serviceId;
  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );

  useEffect(() => {
    setServiceDetail({
      id: null,
      numberOfVets: '',
      workingHoursFrom: '',
      workingHoursTo: '',
      languages: [],
      drugLicenceNo: '',
      pharmaGstNo: '',
      consultationCharges: '',
      petTypes: [],
      specialities: [],
      consultationChargeOffline: '',
    });
    setFormLoading(true);
    props.getPetTypes({});
    props.getLanguageList({});
    props.getSpecialityList({ serviceId });
    props.getServicesMenus();
    props.getServiceDetails({ serviceId });
  }, [serviceId]);

  useEffect(() => {
    if (props.serviceDetails?.hasPharma === true) {
      setPharmaDetailsShow(true);
    }
  }, [props.serviceDetails]);

  useEffect(() => {
    if (Array.isArray(specialities)) {
      setSpecialityList(specialities);
    }
  }, [specialities]);

  useEffect(() => {
    if (formLoading) {
      if (serviceDetails) {
        setSelectedSpecialities(serviceDetails?.specialities);
        setSelectedLanguages(serviceDetails?.languages);
        setSelectedPetTypes(serviceDetails?.petTypes);
        setServiceDetail(serviceDetails);
      }
      setFormLoading(false);
    }
  }, [serviceDetails]);

  const specialityErrorChecking = () => {
    if (selectedSpecialities?.length <= 0) {
      setSpecialityError(
        translate('validation_service_details.specialities_error_message'),
      );
      return false;
    } else {
      setSpecialityError('');
      return true;
    }
  };

  const languageErrorChecking = () => {
    if (selectedLanguages?.length <= 0) {
      setLanguageError(
        translate('validation_service_details.languages_error_message'),
      );
      return false;
    } else {
      setLanguageError('');
      return true;
    }
  };
  const petTypeErrorChecking = () => {
    if (selectedPetTypes?.length <= 0) {
      setPetTypeError(
        translate('validation_service_details.petTypes_error_message'),
      );
      return false;
    } else {
      setPetTypeError('');
      return true;
    }
  };
  const handleValidSubmit = (event, errors, values) => {
    let specialityValid = specialityErrorChecking();
    let languageValid = languageErrorChecking();
    let petTypeValid = petTypeErrorChecking();

    if (!serviceDetail.consultationCharges) {
      setConsultationError(true);
    }
    if (!serviceDetail.consultationChargeOffline) {
      setConsultationOfflineError(true);
    }

    if (
      !serviceDetail.consultationCharges ||
      !serviceDetail.consultationChargeOffline
    ) {
      return true;
    }

    if (specialityValid && languageValid && petTypeValid && !errors?.length) {
      let payload = {
        ...serviceDetail,
        petTypes: selectedPetTypes,
        languages: selectedLanguages,
        specialities: selectedSpecialities,
      };

      if (serviceDetail?.id) {
        props.updateServiceDetail(serviceId, payload);
      } else {
        props.createServiceDetail(serviceId, payload);
      }
    }
  };

  const onChangeAutocompleteValue = (event, value, state, setState) => {
    let stateValue = state;
    stateValue = value?.map(item => {
      return item?.id;
    });
    setState(stateValue);
    event.target.id.startsWith('specialities') && setSpecialityError('');
    event.target.id.startsWith('languages') && setLanguageError('');
    event.target.id.startsWith('petTypes') && setPetTypeError('');
  };

  const handleCharge = (e: any) => {
    if (/^[0-9]*?[.]{0,1}?[0-9]*$/.test(e.target.value)) {
      setServiceDetail({
        ...serviceDetail,
        consultationCharges: e.target.value,
      });
      setConsultationError(false);
    }
  };

  const handleChargeOffline = (e: any) => {
    if (/^[0-9]*?[.]{0,1}?[0-9]*$/.test(e?.target?.value)) {
      setServiceDetail({
        ...serviceDetail,
        consultationChargeOffline: e?.target?.value,
      });
      setConsultationOfflineError(false);
    }
  };

  function customValidate(value, ctx, input, cb) {
    let validationResult = false;
    if (
      moment(ctx.workingHoursFrom, 'HH:mm').isBefore(
        moment(ctx.workingHoursTo, 'HH:mm'),
      )
    ) {
      validationResult = true;
    }
    cb(validationResult);
  }

  return loading ? (
    <div className="text-info my-4" style={{ fontSize: 32 }}>
      <FontAwesomeIcon icon={faSpinner} spin />
    </div>
  ) : (
    <AvForm onSubmit={handleValidSubmit}>
      <Container
        fluid
        className="px-0 px-lg-4 p-3 pb-5 mb-2"
        id="service-details">
        <Row className="mt-4">
          <Col lg="6">
            <Row>
              <Col md="6" className="mt-3">
                <Label className="mb-1" for="numberOfVets">
                  <Translate contentKey="form.service.details.total_vets_available">
                    Total vets Available
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  value={serviceDetail?.numberOfVets}
                  onChange={({ target }) =>
                    setServiceDetail({
                      ...serviceDetail,
                      numberOfVets: target?.value,
                    })
                  }
                  className="p-3"
                  name="numberOfVets"
                  id="numberOfVets"
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_service_details.vets_available_error_message',
                      ),
                    },
                  }}
                />
              </Col>

              <Col md="6" className="mt-3 specialities-z-index">
                <Label className="mb-1" for="specialities">
                  <Translate contentKey="form.service.details.specialities">
                    Specialities
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>

                <Autocomplete
                  multiple
                  id="specialities"
                  limitTags={NUMBER_OF_LIMIT_TAGS}
                  onChange={(event, value) =>
                    onChangeAutocompleteValue(
                      event,
                      value,
                      selectedSpecialities,
                      setSelectedSpecialities,
                    )
                  }
                  onBlur={specialityErrorChecking}
                  options={Array.isArray(specialities) ? specialities : []}
                  getOptionLabel={option => option?.name ?? ''}
                  getOptionSelected={(option, value) =>
                    option?.name === value?.name
                  }
                  defaultValue={
                    selectedSpecialities?.map(spec => {
                      return {
                        id: spec,
                        name: Array.isArray(specialities)
                          ? specialities.filter(x => x.id === spec)[0]?.name
                          : '',
                      };
                    }) ?? []
                  }
                  value={
                    selectedSpecialities?.map(spec => {
                      return {
                        id: spec,
                        name: Array.isArray(specialities)
                          ? specialities.filter(x => x.id === spec)[0]?.name
                          : '',
                      };
                    }) ?? []
                  }
                  filterSelectedOptions
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      className="multiple-input-custom"
                    />
                  )}
                />
                {specialityError && (
                  <span className="text-danger error-text">
                    {specialityError}
                  </span>
                )}
              </Col>

              <Col md="6" className="mt-3">
                <Label className="mb-1" for="workingHours">
                  <Translate contentKey="form.service.details.working_hours_from">
                    Working Hours From
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  value={serviceDetail?.workingHoursFrom}
                  onChange={({ target }) => {
                    setServiceDetail({
                      ...serviceDetail,
                      workingHoursFrom: target.value,
                    });
                  }}
                  className="p-3"
                  name="workingHoursFrom"
                  id="workingHoursFrom"
                  type="time"
                  required></AvField>
              </Col>

              <Col md="6" className="mt-3">
                <Label className="mb-1" for="workingHours">
                  <Translate contentKey="form.service.details.working_hours_to">
                    Working Hours To
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  value={serviceDetail?.workingHoursTo}
                  onChange={({ target }) => {
                    setServiceDetail({
                      ...serviceDetail,
                      workingHoursTo: target.value,
                    });
                  }}
                  validate={{
                    async: customValidate,
                  }}
                  className="p-3"
                  name="workingHoursTo"
                  id="workingHoursTo"
                  type="time"
                />
              </Col>

              <Col md="6" className="mt-3">
                <Label className="mb-1" for="languages">
                  <Translate contentKey="form.service.details.language">
                    Language
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <Autocomplete
                  multiple
                  id="languages"
                  limitTags={NUMBER_OF_LIMIT_TAGS}
                  onChange={(event, value) =>
                    onChangeAutocompleteValue(
                      event,
                      value,
                      selectedLanguages,
                      setSelectedLanguages,
                    )
                  }
                  onBlur={languageErrorChecking}
                  options={Array.isArray(languages) ? languages : []}
                  getOptionLabel={option => option?.name ?? ''}
                  getOptionSelected={(option, value) =>
                    option?.name === value?.name
                  }
                  defaultValue={
                    selectedLanguages?.map(spec => {
                      return {
                        id: spec,
                        name: Array.isArray(languages)
                          ? languages.filter(x => x.id === spec)[0]?.name
                          : '',
                      };
                    }) ?? []
                  }
                  value={
                    selectedLanguages?.map(spec => {
                      return {
                        id: spec,
                        name: Array.isArray(languages)
                          ? languages.filter(x => x.id === spec)[0]?.name
                          : '',
                      };
                    }) ?? []
                  }
                  filterSelectedOptions
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      className="multiple-input-custom"
                      required
                    />
                  )}
                />
                {languageError && (
                  <span className="text-danger error-text">
                    {languageError}
                  </span>
                )}
              </Col>

              <Col md="6" className="mt-3 pet-type-z-index">
                <Label className="mb-1" for="petTypes">
                  <Translate contentKey="form.service.details.pet_types_consulted">
                    Pet Types Consulted
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <Autocomplete
                  multiple
                  id="petTypes"
                  limitTags={NUMBER_OF_LIMIT_TAGS}
                  onChange={(event, value) =>
                    onChangeAutocompleteValue(
                      event,
                      value,
                      selectedPetTypes,
                      setSelectedPetTypes,
                    )
                  }
                  onBlur={petTypeErrorChecking}
                  options={Array.isArray(petTypes) ? petTypes : []}
                  getOptionLabel={option => option?.name}
                  getOptionSelected={(option, value) =>
                    option?.name === value?.name
                  }
                  defaultValue={
                    selectedPetTypes?.map(petType => {
                      return {
                        id: petType,
                        name: Array.isArray(petTypes)
                          ? petTypes.filter(x => x.id === petType)[0]?.name
                          : '',
                      };
                    }) ?? []
                  }
                  value={
                    selectedPetTypes?.map(spec => {
                      return {
                        id: spec,
                        name: Array.isArray(petTypes)
                          ? petTypes.filter(x => x.id === spec)[0]?.name
                          : '',
                      };
                    }) ?? []
                  }
                  filterSelectedOptions
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      className="multiple-input-custom"
                    />
                  )}
                />
                {petTypeError && (
                  <span className="text-danger error-text form-group">
                    {petTypeError}
                  </span>
                )}
              </Col>
              <Col md="6" className="mt-3">
                <Label className="mb-1" for="consultationCharges">
                  <Translate contentKey="form.service.details.consultation_charges">
                    Consultation Charges
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  value={serviceDetail?.consultationCharges}
                  onChange={handleCharge}
                  className="p-3"
                  name="charge"
                  id="charge"
                  required
                />
                {consultationError && (
                  <p style={{ color: ' var(--bs-danger)', fontSize: '.8rem' }}>
                    {translate(
                      'validation_service_details.charges_error_message',
                    )}
                  </p>
                )}
              </Col>
              <Col md="6" className="mt-3">
                <Label className="mb-1" for="consultationCharges">
                  <Translate contentKey="form.service.details.offline_consultation_charges">
                    Offline Consultation Charges
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  value={serviceDetail?.consultationChargeOffline}
                  onChange={handleChargeOffline}
                  className="p-3"
                  name="offlineCharge"
                  id="oflineCharge"
                  required
                />
                {consultationOfflineError && (
                  <p style={{ color: ' var(--bs-danger)', fontSize: '.8rem' }}>
                    {translate(
                      'validation_service_details.charges_error_message',
                    )}
                  </p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {pharmaDetailsShow && (
          <div>
            <Col xs="12" className="mt-5 mb-4">
              <hr />
              <h6>
                <Translate contentKey="form.service.details.pharmacy_service_details">
                  Pharmacy Service Details
                </Translate>
              </h6>
            </Col>

            <Row className="mt-1 mb-5">
              <Col md="3">
                <Label for="drugLicenceNo">
                  <Translate contentKey="form.service.details.drug_licence_no">
                    Drug Licence Number
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  value={serviceDetail?.drugLicenceNo}
                  onChange={({ target }) =>
                    setServiceDetail({
                      ...serviceDetail,
                      drugLicenceNo: target.value,
                    })
                  }
                  className="p-3"
                  name="drugLicenceNo"
                  id="drugLicenceNo"
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_service_details.licence_number_error_message',
                      ),
                    },
                  }}
                />
              </Col>
              <Col md="3">
                <Label Label className="mb-1" for="pharmaGstNo">
                  <Translate contentKey="form.service.details.gst_no_of_pharmacy">
                    GST Number Of Pharmacy
                  </Translate>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  value={serviceDetail?.pharmaGstNo}
                  onChange={({ target }) =>
                    setServiceDetail({
                      ...serviceDetail,
                      pharmaGstNo: target.value,
                    })
                  }
                  className="p-3"
                  name="pharmaGstNo"
                  id="pharmaGstNo"
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_service_details.gst_pharmacy_error_message',
                      ),
                    },
                  }}
                />
              </Col>
            </Row>
          </div>
        )}
      </Container>
      <div className="bottom-button-save-container d-flex justify-content-end">
        {IsPermittedAction(
          PERMISSION_ACTIONS.CREATE_VENDOR_SERVICE_DETAILS,
          authorities,
          permissionList,
        ) &&
          IsPermittedAction(
            PERMISSION_ACTIONS.UPDATE_VENDOR_SERVICE_DETAILS,
            authorities,
            permissionList,
          ) && (
            <Button
              type="submit"
              color="danger"
              className="m-2 text-light button-text mr-5 px-4"
              size={width > SM_SCREEN_BREAKPOINT ? '' : 'sm'}>
              {translate('placeholders.save_as_draft')}
            </Button>
          )}
      </div>
    </AvForm>
  );
};

const mapStateToProps = ({
  authentication,
  user,
  plan,
  master,
}: IRootState) => ({
  serviceDetails: user.serviceDetails,
  loading: user.loading,
  servicesLinks: plan.servicesLinks,
  specialities: master.specialities,
  languages: master.languages,
  petTypes: master.petTypes,
  subscriptionDetails: user.subscriptionDetails,
  updateResponse: user?.updateServiceDetailResponse,
  createResponse: user?.createServiceDetailResponse,
});

const mapDispatchToProps = {
  getSubscriptionDetail,
  createSubscriptionDetail,
  updateSubscriptionDetail,
  getServicesMenus,
  getSpecialityList,
  getLanguageList,
  getPetTypes,
  getServiceDetails,
  createServiceDetail,
  updateServiceDetail,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetails);
