import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { IRootState } from '../../shared/reducers';
import { translate, Translate } from 'react-jhipster';
import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import '../vendor-profile-view/style.scss';
import editImg from '../vendor-profile-view/svg/edit.svg';
import PROFILE_DEFAULT_IMAGE from '../vendor-profile-view/svg/default-profile.png';
import {
  fetchCountry,
  fetchState,
  fetchCity,
} from '../../reducers/master.reducer';
import { getVendorProfile } from '../../reducers/user.reducer';
import { PERMISSION_ACTIONS } from '../../config/permission-constants'
import { IsPermittedAction } from '../../shared/util/permission-utils';
export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export const VendorProfileView = (props: any) => {
  let { getVendorResponse, editVendorProfile } = props;
  const history = useHistory();
  const [country, setCountry] = useState({} as any);
  const [state, setState] = useState({} as any);
  const [city, setCity] = useState({} as any);
  const [profileDetail, setProfileDetail] = useState({
    name: '',
    email: '',
    buildingName: '',
    streetName: '',
    address: '',
    countryId: '',
    stateId: '',
    cityId: '',
    inceptionYear: '',
    landMark: '',
    pincode: '',
    mobile: ''
  });
  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );

  const clickHandler = () => {
    history.push('vendor-profile-edit');
  };

  useEffect(() => {
    props.getVendorProfile({});
  }, []);

  useEffect(() => {
    if (getVendorResponse) {
      setProfileDetail(getVendorResponse);
      if (getVendorResponse.countryId) {
        props?.fetchCountry(getVendorResponse?.countryId);
        props?.fetchState(getVendorResponse?.stateId);
        props?.fetchCity(getVendorResponse?.cityId);
      }
    }
  }, [getVendorResponse]);

  useEffect(() => {
    if (editVendorProfile?.status){
      props.getVendorProfile({});
    } 
  }, [editVendorProfile]);

  useEffect(() => {
    setCountry(props?.country);
  }, [props.country]);

  useEffect(() => {
    setState(props?.state);
  }, [props.state]);

  useEffect(() => {
    setCity(props?.city);
  }, [props.city]);

  return (
    <div>
      <Navbar className="navbar">
        <Navbar.Brand className="my-profile-text">
              <p className="m-0">{ ` ${translate('form.register.my_profile')}`}</p>
        </Navbar.Brand>
        <Navbar.Toggle />
        {IsPermittedAction(PERMISSION_ACTIONS.UPDATE_VENDOR_PROFILE,authorities,permissionList) &&<Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
             <button className="button-edit px-5 pl-0" onClick={clickHandler}>
              <img src={editImg} alt="" />
            </button>
          </Navbar.Text>
        </Navbar.Collapse>}
      </Navbar>
      <div className="header-box">
        <Row>
          <Col className="text-center pt-2 pb-2" md={12}>
            <img
              src={PROFILE_DEFAULT_IMAGE}
              className="rounded-circle"
              alt=""
              width="125"
              height="125"
            />
          </Col>
          <Col className="pt-2 pb-2" md={12}>
            <div className="text-center">
              <span className="profile-header-text">
                {profileDetail?.name}
                <br />
                {profileDetail?.email}
                <br />
                {profileDetail?.mobile}
              </span>
            </div>
          </Col>
        </Row>
      </div>
      <div className="vendor-text-box">
        <Row>
          <Col className="text-format">
            <br />
            <Translate contentKey="form.register.building">
              Building No/Name*:
            </Translate>
            <div className="text-right">{profileDetail?.buildingName}</div>
          </Col>
          <Col className="text-format">
            <br />
            <Translate contentKey="form.register.city_location">city</Translate>
            <div className="text-right">{city.name}</div>
          </Col>
        </Row>
        <Row>
          <Col className="text-format">
            <br />
            <Translate contentKey="form.register.address"> address</Translate>
            <div className="text-right">{profileDetail?.address}</div>
          </Col>
          <Col className="text-format">
            <br />
            <Translate contentKey="form.register.pincode"> pincode</Translate>
            <div className="text-right">{profileDetail?.pincode}</div>
          </Col>
        </Row>
        <Row>
          <Col className="text-format">
            <br />
            <Translate contentKey="form.register.street">
              {' '}
              streetname:
            </Translate>
            <div className="text-right">{profileDetail?.streetName}</div>
          </Col>
          <Col className="text-format">
            <br />
            <Translate contentKey="form.register.landmark">
              {' '}
              landmark:
            </Translate>
            <div className="text-right">{profileDetail?.landMark}</div>
          </Col>
        </Row>
        <Row>
          <Col className="text-format">
            <br />
            <Translate contentKey="form.register.country"> country</Translate>
            <div className="text-right">{country.name}</div>
          </Col>
          <Col className="text-format">
            <br />
            <Translate contentKey="form.register.state"> state</Translate>
            <div className="text-right">{state.name}</div>
            <Col className="text-format"></Col>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  authentication,
  plan,
  user,
  master,
}: IRootState) => ({
  getVendorResponse: user.getVendorProfileResponse,
  editVendorProfile: user.editVendorProfile,
  country: master?.country,
  state: master?.state,
  city: master?.city,
});

const mapDispatchToProps = {
  getVendorProfile,
  fetchCountry,
  fetchCity,
  fetchState,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VendorProfileView);
