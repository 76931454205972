import { useEffect, useState } from 'react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { IRootState } from '../../shared/reducers';
import 'react-multi-carousel/lib/styles.css';
import './styles.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { translate } from 'react-jhipster';
import {
  downloadAppointment,
  fetchConsultationDetails,
} from '../../reducers/video-consultation-reducer';
import {
  getPrescriptionByAppointment,
  getLabRecord,
} from '../../reducers/prescription.reducer';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CUSTOMER_TABS } from './customer-tab';
import moment from 'moment-timezone';
import { PERMISSION_ACTIONS } from '../../config/permission-constants';
import { IsPermittedAction } from '../../shared/util/permission-utils';
import Link from '@mui/material/Link';
import { APP_CUSTOM_FIELD_INPUT_TYPES } from '../../config/constants';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export const CustomerAppointmentViewDetails = (props: any) => {
  const { prescriptionResponse, consultationDetails } = props;
  const dispatch = useDispatch();
  const [age, setAge] = useState('' as any);
  const [isDownload, setIsDownload] = useState(false);
  const [varyingAppointmentMode, setVaryingAppointmentMode] = useState();
  const [prescription, setPrescription] = useState([]) as any;
  const [groupedLabRecords, setGroupedLabRecords] = useState([]) as any;
  const offlineMode = 'OFFLINE';
  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );
  const appointmentDownloadResponse = useSelector(
    (state: IRootState) => state?.videoConsultation.appointmentDownloadResponse,
  );
  useEffect(() => {
    props.fetchConsultationDetails({ appointmentId: props?.match?.params.id });
    if (
      IsPermittedAction(
        PERMISSION_ACTIONS.GET_CUSTOMER_APPOINTMENT_PRESCRIPTIONS,
        authorities,
        permissionList,
      )
    )
      props.getPrescriptionByAppointment(props?.match?.params.id);
    if (
      IsPermittedAction(
        PERMISSION_ACTIONS.GET_APPOINTMENT_LAB_REPORT,
        authorities,
        permissionList,
      )
    )
      props.getLabRecord(props?.match?.params.id);
  }, []);

  useEffect(() => {
    setVaryingAppointmentMode(props?.consultationDetails?.type);
  }, [consultationDetails]);

  useEffect(() => {
    calculateAge(consultationDetails?.petDetails?.dob);
  }, [consultationDetails]);

  useEffect(() => {
    if (prescriptionResponse?.prescriptions?.length)
      setPrescription(prescriptionResponse?.prescriptions);
  }, [prescriptionResponse]);

  useEffect(() => {
    const groupLabRecordByDate = () => {
      const dataLab = props?.labRecord?.reduce((value, item) => {
        const createdDate = moment(item.createdDate).format('DD-MM-YYYY');
        if (!value[createdDate]) {
          value[createdDate] = [item];
        } else {
          value[createdDate].push(item);
        }
        return value;
      }, {});
      return dataLab;
    };

    const data = groupLabRecordByDate();
    setGroupedLabRecords(data);
  }, [props?.labRecord]);

  const downloadPdf = () => {
    dispatch(downloadAppointment(consultationDetails?.id));
    setIsDownload(true);
  };

  useEffect(() => {
    if (appointmentDownloadResponse.url && isDownload) {
      window.open(appointmentDownloadResponse.url, '_blank');
      setIsDownload(false);
    }
  }, [appointmentDownloadResponse]);

  const renderLabelAndValue = ({ label, value, type = null }) => {
    return (
      <>
        <Col md={6}>
          <p className="label">{label}</p>
        </Col>
        <Col md={6}>
          {type === APP_CUSTOM_FIELD_INPUT_TYPES.CHECK_BOX &&
          value === 'true' ? (
            <p className="value">Yes</p>
          ) : type === APP_CUSTOM_FIELD_INPUT_TYPES.CHECK_BOX &&
            (!value || value === 'false') ? (
            <p className="value">No</p>
          ) : type === APP_CUSTOM_FIELD_INPUT_TYPES.ATTACHMENT && value ? (
            <Link href={value} target="_blank">
              Attachment &nbsp;
              <FontAwesomeIcon icon={faDownload} color="var(--bs-grey-teal)" />
            </Link>
          ) : type === APP_CUSTOM_FIELD_INPUT_TYPES.DATE && value ? (
            <p>{moment(value).format('DD-MM-yyyy')}</p>
          ) : (
            <p className="value">{value}</p>
          )}
        </Col>
      </>
    );
  };

  const calculateAge = value => {
    let dob = moment(value);
    const years = moment().diff(dob, 'years');
    dob = moment(dob).add(years, 'years');
    const months = moment().diff(dob, 'months');
    setAge(years + 'y ' + months + 'm');
    let dobInt = parseInt(dob.format('DD'));
    let todayInt = parseInt(moment().format('DD'));
    const days = Math.abs(dobInt - todayInt);
    setAge(years + 'y ' + months + 'm ' + days + 'd');
  };

  return (
    <div id="video-consultation">
      <Container fluid className="px-0  d-flex main-container ">
        <Col md={1} className="d-flex justify-content-center">
          <Button
            className="position-absolute m-4"
            color="danger"
            text-color="white"
            size="sm"
            data-cy="entityBackwardButton"
            onClick={() =>
              props.history.push({
                pathname: `/customer/${props.location.state.customerId}`,
                state: { tabId: CUSTOMER_TABS[1] },
              })
            }>
            <div style={{ color: 'white' }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
          </Button>
        </Col>
        <Col className="margin-right shadow " md={10}>
          <Card>
            <CardBody>
              <Row>
                <Col className="subheader-style pb-0">
                  {translate(
                    'consultation_tab.consultation_details.tab_header',
                  )}
                </Col>
                {consultationDetails?.questionnaire?.length > 0 ||
                prescriptionResponse?.prescriptions?.length ||
                props?.labRecord.length ? (
                  <Col className="heading-style-col float-left" md={6}>
                    <span className="heading-right-style">
                      <Button
                        onClick={downloadPdf}
                        style={{
                          backgroundColor: 'transparent',
                          borderColor: 'transparent',
                          color: 'black',
                        }}>
                        <FontAwesomeIcon
                          icon={faDownload}
                          color="var(--bs-grey-teal)"
                        />{' '}
                        &nbsp; Download
                      </Button>
                    </span>
                  </Col>
                ) : null}
              </Row>
              <hr />
              {consultationDetails?.questionnaire?.length > 0 ? (
                consultationDetails?.questionnaire?.map((item, index) => {
                  return (
                    item?.response &&
                    item?.response != -1 && (
                      <Col
                        key={index}
                        className="pet-info-left-col pt-3"
                        md={6}>
                        <Row>
                          {renderLabelAndValue({
                            label: item?.displayName,
                            value:
                              item?.inputType === 'MULTI_SELECT'
                                ? item?.response?.slice(
                                    0,
                                    item?.response?.length - 1,
                                  )
                                : item?.response,
                            type: item?.inputType,
                          })}
                        </Row>
                      </Col>
                    )
                  );
                })
              ) : (
                <Col className="pet-info-left-col pt-3" md={12}>
                  <Row>
                    {renderLabelAndValue({
                      label: 'There is no questionnaire added',
                      value: '',
                    })}
                  </Row>
                </Col>
              )}
              {IsPermittedAction(
                PERMISSION_ACTIONS.GET_CUSTOMER_APPOINTMENT_PRESCRIPTIONS,
                authorities,
                permissionList,
              ) && (
                <>
                  <Row>
                    <Col className="subheader-style pt-0 pb-0 mt-4">
                      {translate(
                        'consultation_tab.prescription_details.prescription-details',
                      )}
                    </Col>
                  </Row>
                  <hr className="mt-0" />
                  {prescriptionResponse?.prescriptions?.length ? (
                    <>
                      <Row className="prescription-header-border">
                        <Col md={12} className="mt-3">
                          <Card
                            className="custom-card-font"
                            style={{ border: 'none' }}>
                            <Row>
                              <Col md={8} className="justify-content-start">
                                {consultationDetails?.header?.businessName}
                                <br />
                                {consultationDetails?.header?.city},
                                {consultationDetails?.header?.state}
                              </Col>
                              <Col md={4}>
                                <Row>
                                  <Col className="text-right">
                                    {translate(
                                      'consultation_tab.consultation_details.pet_name',
                                    )}
                                    <br />{' '}
                                    {translate(
                                      'consultation_tab.consultation_details.pet_type',
                                    )}
                                    <br />
                                    {translate(
                                      'consultation_tab.consultation_details.dob',
                                    )}
                                    <br />
                                  </Col>
                                  <Col>
                                    {consultationDetails?.petDetails?.name}
                                    <br />
                                    {consultationDetails?.petDetails?.petType}
                                    <br />
                                    {consultationDetails?.petDetails?.dob
                                      ? age
                                      : null}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                      <Row
                        className="bottom-container"
                        style={{ backgroundColor: 'white' }}>
                        {prescription?.map((prescriptionItem, index) => (
                          <Row
                            key={index}
                            className="medical-history-item-container">
                            <div className="d-flex justify-content-between pb-2">
                              <div className="pt-4">
                                <div
                                  style={{ fontSize: '14px', fontWeight: 600 }}>
                                  {moment(prescriptionItem?.createdDate).format(
                                    'DD-MM-YYYY h:mm A',
                                  )}
                                </div>
                                <div
                                  className="pt-2"
                                  style={{ fontSize: '14px' }}>
                                  {prescriptionItem?.doctorName}
                                  <br />
                                  {prescriptionItem?.qualifications}
                                </div>
                              </div>
                            </div>
                            {prescriptionItem?.prescriptionItems?.length ? (
                              <Row
                                className="table-item custom-font-weight"
                                style={{ backgroundColor: '#c9dfda' }}>
                                <Col
                                  md={1}
                                  className="title justify-content-center">
                                  {translate(
                                    'prescription_entry.serial_number',
                                  )}
                                </Col>
                                <Col md={4} className="title">
                                  {translate(
                                    'prescription_entry.medicine_name',
                                  )}
                                </Col>
                                <Col
                                  md={2}
                                  className="title justify-content-center">
                                  {translate('prescription_entry.time')}
                                </Col>
                                <Col
                                  md={2}
                                  className="title justify-content-center">
                                  {translate('prescription_entry.duration')}
                                </Col>
                                <Col
                                  md={3}
                                  className="title justify-content-center">
                                  {translate('prescription_entry.direction')}
                                </Col>
                              </Row>
                            ) : null}
                            {prescriptionItem?.prescriptionItems?.map(
                              (item, index) => (
                                <Row
                                  key={index}
                                  className="table-item mt-2 bg-white">
                                  <Col
                                    md={1}
                                    className="title justify-content-center d-flex table-text">
                                    {index + 1}
                                  </Col>
                                  <Col md={4} className="title d-block ">
                                    {item?.medicineName}
                                  </Col>
                                  <Col
                                    md={2}
                                    className="title justify-content-center">
                                    {item?.dosage}
                                  </Col>
                                  <Col md={2} className="title auto-width">
                                    {item?.durationInDays ? (
                                      <Col
                                        className="h-100"
                                        style={{ textAlign: 'center' }}>
                                        {item?.durationInDays}
                                        <span className="table-text m-2">
                                          {translate('prescription_entry.days')}
                                        </span>
                                      </Col>
                                    ) : null}
                                  </Col>
                                  <Col
                                    md={3}
                                    className="title justify-content-center">
                                    <span className="table-text">
                                      {item?.directions}
                                    </span>
                                  </Col>
                                </Row>
                              ),
                            )}
                            {prescriptionItem?.prognosis ? (
                              <Row
                                className={`table-item ${
                                  prescriptionItem?.prescriptionItems?.length
                                    ? 'mt-2'
                                    : 'mt-0'
                                } bg-white `}>
                                <Col
                                  md={3}
                                  className="title d-block custom-font-weight">
                                  Prognosis
                                </Col>
                                <Col
                                  md={10}
                                  className=" d-block custom-value-style"
                                  style={{ whiteSpace: 'pre-line' }}>
                                  {prescriptionItem?.prognosis}
                                </Col>
                              </Row>
                            ) : null}
                            {prescriptionItem?.diagnosis ? (
                              <Row
                                className={`table-item ${
                                  prescriptionItem?.prescriptionItems?.length
                                    ? 'mt-2'
                                    : 'mt-0'
                                } bg-white `}>
                                <Col
                                  md={3}
                                  className="title d-block custom-font-weight">
                                  Diagnosis
                                </Col>
                                <Col
                                  md={10}
                                  className=" d-block custom-value-style"
                                  style={{ whiteSpace: 'pre-line' }}>
                                  {prescriptionItem?.diagnosis}
                                </Col>
                              </Row>
                            ) : null}
                            {prescriptionItem?.adviceGiven ? (
                              <Row
                                className={`table-item ${
                                  prescriptionItem?.prescriptionItems?.length
                                    ? 'mt-2'
                                    : 'mt-0'
                                } bg-white `}>
                                <Col
                                  md={3}
                                  className="title d-block custom-font-weight">
                                  Advice Given
                                </Col>
                                <Col
                                  md={10}
                                  className=" d-block c-gray custom-value-style"
                                  style={{ whiteSpace: 'pre-line' }}>
                                  {prescriptionItem?.adviceGiven}
                                </Col>
                              </Row>
                            ) : null}
                            {prescriptionItem?.treatmentNotes ? (
                              <Row
                                className={`table-item ${
                                  prescriptionItem?.prescriptionItems?.length
                                    ? 'mt-2'
                                    : 'mt-0'
                                } bg-white `}>
                                <Col
                                  md={3}
                                  className="title d-block custom-font-weight">
                                  Treatment Notes
                                </Col>
                                <Col
                                  md={10}
                                  className=" d-block c-gray custom-value-style"
                                  style={{ whiteSpace: 'pre-line' }}>
                                  {prescriptionItem?.treatmentNotes}
                                </Col>
                              </Row>
                            ) : null}
                          </Row>
                        ))}
                      </Row>
                    </>
                  ) : (
                    <Col>
                      {translate('prescription_entry.no_prescription_added')}
                    </Col>
                  )}
                </>
              )}
              {IsPermittedAction(
                PERMISSION_ACTIONS.GET_APPOINTMENT_LAB_REPORT,
                authorities,
                permissionList,
              ) && (
                <>
                  <Row>
                    <Col className="subheader-style pt-3 pb-0 mb-0">
                      {translate(
                        'consultation_tab.laboratory_details.laboratory_details',
                      )}
                    </Col>
                  </Row>
                  <hr className="mt-0" />
                  {props?.labRecord.length ? (
                    <>
                      <Row className="prescription-header-border">
                        <Col md={12} className="mt-3">
                          <Card className="custom-card-font border-0">
                            <Row>
                              <Col md={8} className="justify-content-start">
                                {consultationDetails?.header?.businessName}
                                <br />
                                {consultationDetails?.header?.city},
                                {consultationDetails?.header?.state}
                              </Col>
                              <Col md={4}>
                                <Row>
                                  <Col className="text-right">
                                    {translate(
                                      'consultation_tab.consultation_details.pet_name',
                                    )}
                                    <br />{' '}
                                    {translate(
                                      'consultation_tab.consultation_details.pet_type',
                                    )}
                                    <br />
                                    {translate(
                                      'consultation_tab.consultation_details.dob',
                                    )}
                                    <br />
                                  </Col>
                                  <Col>
                                    {consultationDetails?.petDetails?.name}
                                    <br />
                                    {consultationDetails?.petDetails?.petType}
                                    <br />
                                    {consultationDetails?.petDetails?.dob
                                      ? age
                                      : null}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                      <Row className="bottom-container">
                        <Row
                          className="table-item custom-font-weight"
                          style={{ backgroundColor: '#c9dfda' }}>
                          <Col md={1} className="title justify-content-center">
                            {translate('laboratory_entry.serial_number')}
                          </Col>
                          <Col md={2} className="title">
                            {translate('laboratory_entry.test')}
                          </Col>
                          <Col md={2} className="title">
                            {translate('laboratory_entry.reference_range')}
                          </Col>
                          <Col md={1} className="title">
                            {translate('laboratory_entry.units')}
                          </Col>
                          <Col md={2} className="title">
                            {translate('laboratory_entry.methodology')}
                          </Col>
                          <Col md={2} className="title">
                            {translate('laboratory_entry.result')}
                          </Col>
                        </Row>

                        {Object.entries(groupedLabRecords).map(
                          ([date, records]) => (
                            <>
                              <Row className="table-item mt-2 date-container">
                                <span>{date}</span>
                              </Row>
                              {Array.isArray(records) &&
                                records?.map((labItem, index) => (
                                  <Row
                                    key={index}
                                    className="table-item mt-2 bg-white p-3">
                                    <Col
                                      md={1}
                                      className="title justify-content-center d-flex table-text">
                                      <span style={{ marginRight: 5 }}>
                                        {index + 1}
                                      </span>
                                    </Col>
                                    <Col md={2} className="title d-block">
                                      <span className="table-text">
                                        {labItem?.labTest?.name}
                                      </span>
                                    </Col>
                                    <Col md={2} className="title">
                                      <span className="table-text">
                                        {labItem?.referenceRange}
                                      </span>
                                    </Col>
                                    <Col md={1} className="title">
                                      <span className="table-text">
                                        {labItem.unit}
                                      </span>
                                    </Col>
                                    <Col md={2} className="title">
                                      <span className="table-text">
                                        {labItem?.methodology}
                                      </span>
                                    </Col>
                                    <Col md={2} className="title">
                                      <span className="table-text">
                                        {labItem?.results}
                                      </span>
                                    </Col>
                                    <Col md={2} className="title">
                                      <span className="table-text">
                                        {labItem?.attachments.length ? (
                                          <a
                                            target="_blank"
                                            href={labItem?.attachments[0]}>
                                            report
                                            <FontAwesomeIcon
                                              icon={faDownload}
                                              className="ms-1 fa-lg"
                                              color="var(--bs-grey-teal)"
                                            />
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </span>
                                    </Col>
                                  </Row>
                                ))}
                            </>
                          ),
                        )}
                      </Row>
                    </>
                  ) : (
                    <Col>
                      {translate(
                        'laboratory_entry.no_laboratory_details_added',
                      )}
                    </Col>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ videoConsultation, prescription }: IRootState) => ({
  consultationDetails: videoConsultation.consultationDetails,
  prescriptionResponse: prescription.prescription,
  labRecord: prescription.labRecord,
  appointmentDownloadResponse: videoConsultation.appointmentDownloadResponse,
});

const mapDispatchToProps = {
  fetchConsultationDetails,
  getPrescriptionByAppointment,
  getLabRecord,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerAppointmentViewDetails);
