import {
  ALREADY_BOOKED_ERROR_KEY,
  API_URL,
  HTTP_FAILURE_STATUS_CODE,
  SERVICE_NOT_PAID,
  SUBSCRIPTION_NOT_ACTIVE,
  TOAST_TIMEOUT,
} from './../config/constants';
import axios from 'axios';
import { getToken } from '../config/constants';

import { ISlot } from '../model/slots.model';
import { REQUEST, SUCCESS, FAILURE } from '../shared/reducers/action-type.util';
import { toast } from 'react-toastify';
import { translate } from 'react-jhipster';

export const ACTION_TYPES = {
  GET_APPOINTMENTS: 'appointment/GET_APPOINTMENTS',
  GET_YEARS: 'appointment/GET_YEARS',

  SCHEDULE_APPOINTMENT: 'SCHEDULE_APPOINTMENT',
  GET_DEFAULT_SLOTS: 'GET_DEFAULT_SLOTS',
  UPDATE_APPOINTMENT_SCHEDULE: 'UPDATE_APPOINTMENT_SCHEDULE',

  FETCH_SERVICE_PREVIEW_DETAIL: 'FETCH_SERVICE_PREVIEW_DETAIL',

  GET_SLOTS: 'appointment/GET_SLOTS',
  RESCHEDULE_APPOINTMENT: 'appointment/RESCHEDULE_APPOINTMENT',
  FETCH_MAX_BOOKING_PER_SLOT: 'appointment/FETCH_MAX_BOOKING_PER_SLOT',

  UPDATE_APPOINTMENT_STATUS: 'UPDATE_APPOINTMENT_STATUS',
  GET_APPOINTMENT_REPORT: 'GET_APPOINTMENT_REPORT',
  GET_IN_PATIENT_APPOINTMENTS: 'GET_IN_PATIENT_APPOINTMENTS',
  GET_DOWNLOAD_APPOINTMENT_REPORT: 'GET_DOWNLOAD_APPOINTMENT_REPORT',
};

const initialState = {
  loading: false,
  appointments: [] as any,
  slots: [] as ISlot[],
  years: [] as number[],

  scheduleAppointmentResponse: {} as any,
  defaultSlots: [] as any[],
  updateAppointmentScheduleResponse: {} as any,

  servicePreviewDetail: {} as any,
  rescheduleAppointmentResponse: {} as any,
  fetchMaxBookingPerSlotResponse: {} as any,
  appointmentList: [] as any,
  inPatientList: [] as any,
  downloadAppointmentReport: [] as any,
  totalCount: 0,
};

export type AppointmentState = Readonly<typeof initialState>;

export default (
  state: AppointmentState = initialState,
  action,
): AppointmentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_APPOINTMENTS):
    case REQUEST(ACTION_TYPES.GET_SLOTS):
    case REQUEST(ACTION_TYPES.GET_YEARS):
    case REQUEST(ACTION_TYPES.SCHEDULE_APPOINTMENT):
    case REQUEST(ACTION_TYPES.UPDATE_APPOINTMENT_SCHEDULE):
    case REQUEST(ACTION_TYPES.FETCH_SERVICE_PREVIEW_DETAIL):
    case REQUEST(ACTION_TYPES.RESCHEDULE_APPOINTMENT):
    case REQUEST(ACTION_TYPES.FETCH_MAX_BOOKING_PER_SLOT):
    case REQUEST(ACTION_TYPES.UPDATE_APPOINTMENT_STATUS):
    case REQUEST(ACTION_TYPES.GET_IN_PATIENT_APPOINTMENTS):
    case REQUEST(ACTION_TYPES.GET_DOWNLOAD_APPOINTMENT_REPORT):
    case REQUEST(ACTION_TYPES.GET_APPOINTMENT_REPORT): {
      return {
        ...state,
        loading: true,
      };
    }
    case REQUEST(ACTION_TYPES.GET_DEFAULT_SLOTS): {
      return {
        ...state,
        loading: true,
        defaultSlots: [],
      };
    }
    case SUCCESS(ACTION_TYPES.GET_APPOINTMENTS): {
      return {
        ...state,
        appointments: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_SLOTS): {
      return {
        ...state,
        slots: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_YEARS): {
      return {
        ...state,
        years: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.SCHEDULE_APPOINTMENT): {
      toast.success(
        translate('form.service.appointment_schedule.scheduled_msg'),
      );
      return {
        ...state,
        scheduleAppointmentResponse: action.payload,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.UPDATE_APPOINTMENT_STATUS): {
      toast.success(
        translate(
          'form.service.appointment_schedule.appointment_status_updated',
        ),
      );
      return {
        ...state,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_DEFAULT_SLOTS): {
      return {
        ...state,
        defaultSlots: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.UPDATE_APPOINTMENT_SCHEDULE): {
      toast.success(
        translate('form.service.appointment_schedule.schedule_updated_msg'),
      );
      return {
        ...state,
        updateAppointmentScheduleResponse: action.payload,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_MAX_BOOKING_PER_SLOT): {
      return {
        ...state,
        fetchMaxBookingPerSlotResponse: action.payload,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_SERVICE_PREVIEW_DETAIL): {
      return {
        ...state,
        servicePreviewDetail: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_APPOINTMENT_REPORT): {
      return {
        ...state,
        appointmentList: action.payload.data,
        loading: false,
        totalCount: action.payload.headers['x-total-count'],
      };
    }
    case SUCCESS(ACTION_TYPES.RESCHEDULE_APPOINTMENT): {
      toast.success(
        translate('form.service.appointment_schedule.schedule_updated_msg'),
      );
      return {
        ...state,
        rescheduleAppointmentResponse: action.payload,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_IN_PATIENT_APPOINTMENTS): {
      return {
        ...state,
        inPatientList: action.payload.data,
        loading: false,
        totalCount: action.payload.headers['x-total-count'],
      };
    }
    case SUCCESS(ACTION_TYPES.GET_DOWNLOAD_APPOINTMENT_REPORT): {
      return {
        ...state,
        downloadAppointmentReport: action.payload.data,
        loading: false,
      };
    }
    case FAILURE(ACTION_TYPES.RESCHEDULE_APPOINTMENT): {
      if (
        action.payload?.response?.data?.errorKey === ALREADY_BOOKED_ERROR_KEY
      ) {
        toast.error(
          translate('entity.validation.booked_slot_cannot_be_changed'),
        );
      } else {
        toast.error(translate('error.something_went_wrong'));
      }
      {
        return {
          ...state,
          loading: false,
          rescheduleAppointmentResponse: action.payload,
        };
      }
    }
    case FAILURE(ACTION_TYPES.GET_APPOINTMENTS):
    case FAILURE(ACTION_TYPES.GET_SLOTS):
    case FAILURE(ACTION_TYPES.GET_YEARS):
    case FAILURE(ACTION_TYPES.SCHEDULE_APPOINTMENT):
    case FAILURE(ACTION_TYPES.GET_DEFAULT_SLOTS):
    case FAILURE(ACTION_TYPES.FETCH_SERVICE_PREVIEW_DETAIL):
    case FAILURE(ACTION_TYPES.RESCHEDULE_APPOINTMENT):
    case FAILURE(ACTION_TYPES.FETCH_MAX_BOOKING_PER_SLOT):
    case FAILURE(ACTION_TYPES.UPDATE_APPOINTMENT_SCHEDULE): {
      if (
        action.payload?.response?.data?.status === HTTP_FAILURE_STATUS_CODE &&
        (action.payload?.response?.data?.errorKey === SERVICE_NOT_PAID ||
          action.payload?.response?.data?.errorKey === SUBSCRIPTION_NOT_ACTIVE)
      ) {
        toast.error(translate('entity.validation.pay_before_slot_adding'), {
          autoClose: TOAST_TIMEOUT,
        });
      } else {
        toast.error(translate('error.something_went_wrong'));
      }
      return {
        ...state,
        loading: false,
      };
    }
    case FAILURE(ACTION_TYPES.GET_APPOINTMENT_REPORT):
    case FAILURE(ACTION_TYPES.GET_IN_PATIENT_APPOINTMENTS):
    case FAILURE(ACTION_TYPES.GET_DOWNLOAD_APPOINTMENT_REPORT):
    case FAILURE(ACTION_TYPES.UPDATE_APPOINTMENT_STATUS):
      toast.error(translate('error.something_went_wrong'));
      {
        return {
          ...state,
          loading: false,
        };
      }
    default:
      return state;
  }
};

const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

export const getAppointments: (date) => void = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_APPOINTMENTS,
    payload: axios.get(`/data/appointments.json`),
  });
  return result;
};

export const getSlots: (payload) => void = payload => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_SLOTS,
    payload: axios.get(
      `${API_URL}/v1/vendor/service/${payload?.serviceId}/slots?date=${payload?.date}`,
      config,
    ),
  });
  return result;
};

export const getYearList: () => void = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_YEARS,
    payload: axios.get(`/data/years.json`),
  });
  return result;
};

export const scheduleAppointment: (payload) => void =
  payload => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.SCHEDULE_APPOINTMENT,
      payload: axios.post(
        `${API_URL}/v1/vendor/services/${payload?.serviceId}/default-slots`,
        payload?.slots,
        config,
      ),
    });
    return result;
  };

export const getDefaultSlots: (payload) => void = payload => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_DEFAULT_SLOTS,
    payload: axios.get(
      `${API_URL}/v1/vendor/services/${payload?.serviceId}/default-slots`,
      config,
    ),
  });
  return result;
};

export const updateAppointmentSchedule: (payload) => void =
  payload => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.UPDATE_APPOINTMENT_SCHEDULE,
      payload: axios.put(
        `${API_URL}/v1/vendor/services/${payload?.serviceId}/default-slots`,
        payload?.slots,
        config,
      ),
    });
    return result;
  };

export const updateAppointmentStatus: (payload) => void =
  payload => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.UPDATE_APPOINTMENT_STATUS,
      payload: axios.put(`${API_URL}/v1/customer/appointment`, payload, config),
    });
    return result;
  };

export const rescheduleAppointmentSchedule: (payload) => void =
  payload => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.RESCHEDULE_APPOINTMENT,
      payload: axios.put(
        `${API_URL}/v1/vendor/services/${payload?.serviceId}/slots`,
        payload?.slotDetail,
        config,
      ),
    });
    return result;
  };

export const fetchServicePreviewDetail: (payload) => void =
  () => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.FETCH_SERVICE_PREVIEW_DETAIL,
      payload: axios.get(`/data/service-preview.json`),
    });
    return result;
  };

export const getAppointmentList: (payload) => void =
  payload => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.GET_APPOINTMENT_REPORT,
      payload: axios.get(`${API_URL}/v1/vendor/appointment-reports?page=${
        payload?.page
      }&size=${payload?.size}&startDate=${payload?.startDate}&endDate=${
        payload?.endDate
      }${payload.petTypeId ? '&petTypeId=' + payload.petTypeId : ''}${
        payload?.breedId ? '&breedId=' + payload?.breedId : ''
      }${payload?.search ? '&search=' + payload?.search : ''}${
        payload.customerId ? '&customerId=' + payload.customerId : ''
      }${
        payload?.consultationType
          ? '&consultationType=' + payload?.consultationType
          : ''
      }${payload?.type ? '&type=' + payload?.type : ''}${
        payload?.doctorId ? '&doctorId=' + payload?.doctorId : ''
      }${
        payload?.specialityId ? '&specialityId=' + payload?.specialityId : ''
      }${payload?.date ? '&date=' + payload?.date : ''}${
        payload?.status ? '&status=' + payload?.status : ''
      }
      `),
    });
    return result;
  };

export const fetchMaxBookingPerSlot: (payload) => void =
  payload => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.FETCH_MAX_BOOKING_PER_SLOT,
      payload: axios.get(
        `${API_URL}/v1/vendor/services/${payload?.serviceId}/service-default-slots`,
        config,
      ),
    });
    return result;
  };

export const getInPatientAppointments = payload => ({
  type: ACTION_TYPES.GET_IN_PATIENT_APPOINTMENTS,
  payload: axios.get<any>(
    `${API_URL}/v1/vendor/appointments/inpatients?date=${
      payload?.date
    }&page=${payload?.page}&size=${payload?.size}${payload?.doctorId ? '&doctorId=' + payload?.doctorId : ''}${
      payload?.searchKey ? '&search=' + payload?.searchKey : ''
    }`,
  ),
});

export const getDownloadAppointmentReport: (payload) => void =
  payload => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.GET_DOWNLOAD_APPOINTMENT_REPORT,
      payload:
        axios.get(`${API_URL}/v1/vendor/appointment-reports/downloads?startDate=${
          payload?.startDate
        }&endDate=${payload?.endDate}${
          payload.petTypeId ? '&petTypeId=' + payload.petTypeId : ''
        }${payload?.breedId ? '&breedId=' + payload?.breedId : ''}${
          payload?.search ? '&search=' + payload?.search : ''
        }${payload.customerId ? '&customerId=' + payload.customerId : ''}${
          payload?.consultationType
            ? '&consultationType=' + payload?.consultationType
            : ''
        }${payload?.type ? '&type=' + payload?.type : ''}${
          payload?.doctorId ? '&doctorId=' + payload?.doctorId : ''
        }${
          payload?.specialityId ? '&specialityId=' + payload?.specialityId : ''
        }${payload?.date ? '&date=' + payload?.date : ''}${
          payload?.status ? '&status=' + payload?.status : ''
        }
    `),
    });
    return result;
  };
