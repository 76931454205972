import { connect } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router';
import { IRootState } from '../../shared/reducers';
import './style.scss';
import { getServicesMenus } from '../../reducers/plan.reducer';
import PaymentSuccessSvg from '../../svg/Done.png';
import { AppLogo } from '../login/components/app-logo';
import { Translate } from 'react-jhipster';
import { Row, Col } from 'reactstrap';
import { useEffect } from 'react';
import { PAYMENT_SUCCESS_MESSAGE } from '../../config/constants';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

 export interface IPaymentProps {
   isRenewalPaymentSuccessPage: boolean;
 }

export const PaymentSuccess = (props: IPageProps) => {
  const location = useLocation<IPaymentProps>();
  const { isRenewalPaymentSuccessPage } = location.state;

  useEffect(() => {
    if (isRenewalPaymentSuccessPage) {
      PAYMENT_SUCCESS_MESSAGE();
    }
  }, []);
  
  useEffect(() => {
    let timer = setTimeout(() => {
      props.history.push("/landing-page")
    }, 10000)
    return () => {
      clearTimeout(timer);
    }
  },[])

  return (
    <div
      id="payment_success"
      className="background-whole-page"
      style={{ overflow: 'hidden' }}>
      <div className="background">
        <Col>
          <div className="d-flex align-items-center flex-column">
            <img
              src={PaymentSuccessSvg}
              alt=" "
              height="250"
              width="300"
              style={{ marginBottom: -80 }}
            />
            <div style={{ height: '30vh', width: 450, marginTop: 30 }}>
              <AppLogo />
            </div>
            <Row md={2} className="payment-text-container">
              <Translate
                contentKey={
                  !isRenewalPaymentSuccessPage
                    ? 'form.payment.success'
                    : 'form.payment_renewal.success'
                }>
                Payment Success
              </Translate>
            </Row>
          </div>
        </Col>
      </div>
    </div>
  );
};

const mapStateToProps = ({ authentication, plan }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  services: plan.services,
  loading: plan.loading,
});

const mapDispatchToProps = { getServicesMenus };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);
