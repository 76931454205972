import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../shared/reducers';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row, Table } from 'reactstrap';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faRotateLeft,
  faSearch,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { fetchCustomerList } from '../../reducers/user.reducer';
import { DatePicker } from 'antd';
import moment from 'moment-timezone';
import { DateIcon } from '../services/components/icons/date-icon';
import { DATE_TIME_FORMATS } from '../../config/constants';
import { useLocation } from 'react-router';
import Select, {
  components,
  PlaceholderProps,
  OptionProps,
} from 'react-select';
import { getInPatientAppointments } from '../../reducers/appointment.reducer';
import { fetchDoctors } from '../../reducers/video-consultation-reducer';
import EmptyContent from '../../assets/images/empty-content.png';
import { ITEMS_PER_PAGE } from '../../shared/util/pagination.constants';
import Pagination from '@mui/material/Pagination';

const APPOINTMENT_TYPE = 'ALL';

export const InPatientReport = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { inPatientList, loading, totalCount } = useSelector(
    (state: IRootState) => state.appointment,
  );
  const doctors = useSelector(
    (state: IRootState) => state.videoConsultation.doctors,
  );
  const searchParams = new URLSearchParams(location.search);
  const [dateParam, setDateParam] = useState(searchParams.get('date') as any);
  const [selectedDate, setSelectedDate] = useState(
    dateParam
      ? moment(dateParam).format(DATE_TIME_FORMATS.APP_LOCAL_DATE_FORMAT)
      : moment(new Date()).toDate(),
  );
  const [doctorSelect, setDoctorSelect] = useState([]) as any;
  const [doctorId, setDoctorId] = useState() as any;
  const [doctorName, setDoctorName] = useState() as any;
  const [searchKey, setSearchKey] = useState();
  const [encodedSearchKey, setEncodedSearchKey] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);

  useEffect(() => {
    dispatch(
      getInPatientAppointments({
        date: moment(selectedDate).format(
          DATE_TIME_FORMATS.YYYY_MM_DD_WITH_HYPHEN,
        ),
        type: APPOINTMENT_TYPE,
        page: currentPage - 1,
        size: ITEMS_PER_PAGE,
      }),
    );
    dispatch(fetchDoctors());
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    getInPatientAppointmentsApi(1);
  }, [selectedDate, searchKey, doctorId]);

  useEffect(() => {
    getInPatientAppointmentsApi(currentPage);
  }, [currentPage]);

  const getInPatientAppointmentsApi = page => {
    dispatch(
      getInPatientAppointments({
        date: moment(selectedDate).format(
          DATE_TIME_FORMATS.YYYY_MM_DD_WITH_HYPHEN,
        ),
        type: APPOINTMENT_TYPE,
        doctorId,
        searchKey: encodedSearchKey,
        page: page - 1,
        size: ITEMS_PER_PAGE,
      }),
    );
  };

  useEffect(() => {
    if (doctors && doctors.length !== 0) {
      const newDoctorSelect = doctors.map(doctor => ({
        value: doctor?.id,
        label: doctor?.name,
      }));
      setDoctorSelect(newDoctorSelect);
    }
  }, [doctors]);

  useEffect(() => {
    setNumberOfPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
  }, [inPatientList]);

  const searchHandler = event => {
    event.persist();
    setSearchKey(event.target.value);
    const searchValue = encodeURIComponent(event.target.value);
    setEncodedSearchKey(searchValue);
  };

  const onPaginationChange = (e, page) => {
    setCurrentPage(page);
  };

  const selectedDateIsToday =
    moment(selectedDate).format(DATE_TIME_FORMATS.APP_LOCAL_DATE_FORMAT) ===
    moment(new Date()).format(DATE_TIME_FORMATS.APP_LOCAL_DATE_FORMAT);

  return (
    <div>
      <Row className="ps-4 pe-5 py-2" style={{ backgroundColor: '#E5E5E5' }}>
        <Col md="2">
          <h6 className="text-greyTeal mb-0 px-2 py-3">
            {translate('in-patient-report.title')}
          </h6>
        </Col>
      </Row>
      <Row className="mt-3 mx-0 d-flex">
        <Col md={1}>
          <Button
            onClick={() => setSelectedDate(moment().toDate())}
            className={`today-button p-2 px-3 ${
              selectedDateIsToday ? 'today-button-active' : ''
            }`}>
            <p className="m-0">
              {translate('form.service.appointment_reschedule.today_text')}
            </p>
          </Button>
        </Col>
        <Col md={2}>
          <DatePicker
            value={moment(selectedDate)}
            onChange={date => {
              setSelectedDate(moment(date).toDate());
            }}
            format={DATE_TIME_FORMATS.DD_MM_YYYY}
            allowClear={false}
            style={{ width: '100%', borderRadius: '4px' }}
            className="p-2"
          />
        </Col>
        <Col md={2} />
        <Col md={2} />
        <Col md={2}>
          {/* COMMENTED FOR FUTURE PURPOSE */}
          <Select
            className="my-1 text"
            value={
              doctorId && {
                value: doctorId,
                label: doctorName,
              }
            }
            isClearable
            placeholder={translate('in-patient-report.doctor')}
            options={doctorSelect}
            onChange={value => {
              setDoctorId(value?.value);
              setDoctorName(value?.label);
            }}
          />
        </Col>
        <Col md={3}>
          <div className="fa-search position-relative mt-1">
            <input
              className="form-control input-lg search-style"
              style={{ height: '38px',width:'317px' }}
              type="text"
              value={searchKey}
              onChange={searchHandler}
              placeholder="Customer Name/Patient Id/Mobile"></input>
            <FontAwesomeIcon
              icon={faSearch}
              className="position-absolute "
              style={{ top: '12px', left: '7px' }}
            />
          </div>
        </Col>
      </Row>
      {inPatientList?.length ? (
        <>
          <div className="table-responsive shadow border border-1 rounded custom-table ms-4 me-4 my-5 pt-0">
            <Table className="mb-0">
              <thead
                className="border-0 border-bottom border-1"
                style={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: 'white',
                }}>
                <tr>
                  <th className="border-0 ps-4 pe-2 py-3">
                    {translate('in-patient-report.si_no')}
                  </th>
                  <th className="border-0 px-2 py-3">
                    {translate('in-patient-report.patient_id')}
                  </th>
                  <th className="border-0 px-2 py-3">
                    {translate('in-patient-report.pet_name')}
                  </th>
                  <th className="border-0 px-2 py-3">
                    {translate('in-patient-report.pet_parent')}
                  </th>
                  <th className="border-0 px-2 py-3">
                    {translate('in-patient-report.pet_type')}
                  </th>
                  <th className="border-0 px-2 py-3">
                    {translate('in-patient-report.mobile_no')}
                  </th>
                  <th className="border-0 px-2 py-3">
                    {translate('in-patient-report.speciality')}
                  </th>
                  <th className="border-0 px-2 py-3">
                    {translate('in-patient-report.admission_date')}
                  </th>
                  <th className="border-0 px-2 py-3">
                    {translate('in-patient-report.discharge_date')}
                  </th>
                  <th className="border-0 px-2 py-3">
                    {translate('in-patient-report.doctor')}
                  </th>
                  <th className="border-0 px-2 py-3">
                    {translate('in-patient-report.status')}
                  </th>
                  <th />
                </tr>
              </thead>
              {!loading && (
                <tbody className="border-top-0">
                  {inPatientList?.map((inPatient, i) => {
                    const serialNumber =
                      i + 1 + (currentPage - 1) * ITEMS_PER_PAGE;
                    return (
                      <tr
                        className="border-0 border-bottom border-1"
                        key={`entity-${i}`}
                        data-cy="entityTable">
                        <td className="border-0 ps-4 pe-2 py-4">
                          {serialNumber <= totalCount ? serialNumber : ''}
                        </td>
                        <td className="border-0 px-2 py-4">
                          {inPatient?.patientId}
                        </td>
                        <td className="border-0 px-2 py-4">
                          {inPatient?.petName}
                        </td>
                        <td className="border-0 px-2 py-4">
                          {inPatient?.customer}
                        </td>
                        <td className="border-0 px-2 py-4">
                          {inPatient?.petType}
                        </td>
                        <td className="border-0 px-2 py-4">
                          {inPatient?.mobile}
                        </td>
                        <td className="border-0 px-2 py-4">
                          {inPatient.speciality}
                        </td>
                        <td className="border-0 px-2 py-4">
                          {moment(inPatient?.admissionDate).format(
                            'DD-MM-YYYY',
                          )}
                        </td>
                        <td className="border-0 px-2 py-4">
                          {inPatient?.dischargeDate
                            ? moment(inPatient.dischargeDate).format(
                                'DD-MM-YYYY',
                              )
                            : ''}
                        </td>
                        <td className="border-0 px-2 py-4">
                          {inPatient?.doctorName}
                        </td>
                        <td className="border-0 px-2 py-4">
                          {inPatient?.status}
                        </td>
                        <td className="border-0 pa-2 pe-4 py-4">
                          <div>
                            <Button
                              tag={Link}
                              to={`/video-consultation/${inPatient?.id}/consultation-detail`}
                              color="none"
                              size="sm"
                              data-cy="entityDeleteButton">
                              <FontAwesomeIcon
                                className="text-danger"
                                icon={faEye}
                              />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </Table>
          </div>
          {totalCount && inPatientList?.length ? (
            <Pagination
              count={numberOfPages}
              onChange={onPaginationChange}
              variant="outlined"
              shape="rounded"
              className="d-flex justify-content-center mb-4"
            />
          ) : null}
        </>
      ) : (
        <>
          {loading && <FontAwesomeIcon icon={faSpinner} spin />}
          {!loading && (
            <img
              src={EmptyContent}
              style={{
                padding: ' 7% 25% 0% 30%',
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default InPatientReport;
