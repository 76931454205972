import { title } from "process";
import React, { useState } from "react";
import { translate } from "react-jhipster";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { NavLink } from "react-router-dom";
import { Button, Col } from "reactstrap";
import { IMenu, ISubMenu } from "../../../model/user-menu";
import "./style.scss";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

export interface IHeaderProps {
  title?: string;
  showTabMenu?: boolean;
  subMenus?: ISubMenu[];
  selectedMenu?: IMenu;
  selectedSubMenu?: ISubMenu;
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  currentLocale?: string;
  sideDrawerOpen?: boolean;
  children?: any;
}

const SubHeader = (props: IHeaderProps) => {
  const {
    title,
    showTabMenu,
    selectedMenu,
    selectedSubMenu,
    subMenus,
    children,
  } = props;

  return (
    <>
      <div className="sub-header container-fluid">
        <div className="title-row px-lg-3">
          <div className="col-left">
            <h6 className="m-0" >{selectedSubMenu?.name ?? title}</h6>
          </div>
          <div className="col-right">{children}</div>
        </div>
      </div>

      {subMenus && showTabMenu ? (
        <ScrollMenu>
          {subMenus?.map((subMenu, i) => (
            <NavLink key={i} to={subMenu.url}>
              <div className="tab-button-container">
                <div
                  className={`tab-button pt-3 pb-2 ${
                    subMenu.url === selectedSubMenu?.url
                      ? `tab-button-active`
                      : ``
                  }`}
                >
                  <p
                    className={`text-center m-0 tab-button-text ${
                      subMenu.url === selectedSubMenu?.url
                        ? "active-tab-text"
                        : ""
                    }`}
                  >
                    {subMenu.name}
                  </p>
                </div>
              </div>
            </NavLink>
          ))}
        </ScrollMenu>
      ) : null}
    </>
  );
};

export default SubHeader;
