import {
  faCaretDown,
  faDownload,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IAgoraRTCRemoteUser } from 'agora-rtc-react';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import moment from 'moment-timezone';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { setFileData, Translate, translate } from 'react-jhipster';
import 'react-multi-carousel/lib/styles.css';
import 'react-multi-carousel/lib/styles.css';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import {
  Col,
  Input,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { v4 as uuid } from 'uuid';
import {
  AGORA_APP_ID,
  APPOINTMENT_STATUS,
  CUSTOMER_CALL_STATUS,
  DATE_TIME_FORMATS,
  HTTP_OK_RESPONSE,
  PET_WEIGHT_UNIT,
  APP_CUSTOM_FIELD_INPUT_TYPES,
  MEDIA_TYPES,
  CONSULTATION_TYPE,
  HISTORY_LENGTH_FROM_REPORT_SCREEN,
} from '../../config/constants';
import { updateAppointmentStatus } from '../../reducers/appointment.reducer';
import {
  fetchBreedByPetType,
  fetchPetTypeVendor,
} from '../../reducers/master.reducer';
import {
  fetchConsultationDetails,
  initiateVideoCall,
  editPetDetail,
  getSpeciality,
  getQuestionnaire,
  uploadDocumet,
  createQuestionnaire,
  editQuestionnaire,
} from '../../reducers/video-consultation-reducer';
import { IRootState } from '../../shared/reducers';
import Button from '../components/button';
import OnlineVideoCall from '../online-video-call/index';
import PET_DEFAULT_IMAGE from '../vendor-profile-view/svg/default-pet.png';
import PrescriptionDetail from './components/prescription-detail';
import LaboratoryDetail from './components/laboratory-detail';
import Reminder from './components/reminder';
import { MedicalHistory } from './medical-history/medical-history';
import './style.scss';
import VideoCallService from './video-call-service';
import { FaPencilAlt } from 'react-icons/fa';
import { Autocomplete } from '@material-ui/lab';
import { InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Link from '@mui/material/Link';
import { PERMISSION_ACTIONS } from '../../config/permission-constants';
import { IsPermittedAction } from '../../shared/util/permission-utils';
import { toast } from 'react-toastify';
import dotPng from '../../assets/images/dot.png';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import CallConfirmation from '../components/status-confirmation';
import AttachmentViewer from './components/attachment-view';
import CalenderPicker from 'react-datepicker';
import { DateIcon } from '../services/components/icons/date-icon';
import { DatePicker } from 'antd';

const CONSULTATION_DETAILS = 'consultation_details';
const MEDICAL_HISTORY = 'medical_history';
const VIDEO_CALL = 'video_call';
const PRESCRIPTION = 'prescription_details';
const LABORATORY = 'laboratory_details';
const REMINDER = 'reminder';
const SMOOTH_TRANSITION_TIME_IN_MILLISECONDS = 100;
const OFFLINE_STATUSES = [
  CUSTOMER_CALL_STATUS.CUSTOMER_CALL_DECLINED,
  CUSTOMER_CALL_STATUS.CUSTOMER_CALL_MISSED,
];

const inPatient = 'In Patient';

let CONSULTATION_DETAIL_TABS = [] as any[];

// Uncomment when api is accepting other statuses
let CONSULTATION_STATUS = [
  APPOINTMENT_STATUS.COMPLETED,
  APPOINTMENT_STATUS.CANCELLED,

  // APPOINTMENT_STATUS.MISSED,
  // APPOINTMENT_STATUS.OPEN,
  // APPOINTMENT_STATUS.RESCHEDULED,
];
export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export const ConsultationTab = memo((props: IPageProps) => {
  const {
    consultationDetails,
    initiateVideoCallResponse,
    editPetDetailResponse,
  } = props;
  const routeParams = (props.location && props.location.state) || ({} as any);
  const appointmentId = props?.match?.params.id;
  const [petId, setPetId] = useState(0);
  const history = useHistory();
  const [videoCallStart, setVideoCallStart] = useState<boolean>(false);
  const [remoteVideo, setRemoteVideo] = useState<boolean>(true);
  const [remoteAudio, setRemoteAudio] = useState<boolean>(true);
  const [trackState, setTrackState] = useState({ video: true, audio: true });
  const [autoCompleteError, setAutoCompleteError] = useState(false);

  const fetchConsultationDetails = async () => {
    await props.fetchConsultationDetails({ appointmentId });
  };

  const [videoStarted, setVideoStarted] = useState(false);
  const [users, setUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const [ready, setReady] = useState(false);
  const [tracks, setTracks] = useState<any[]>([]);
  const [assignedDoctor, setAssignedDoctor] = useState('');
  const [status, setStatus] = useState('');
  const [age, setAge] = useState('' as any);
  const [isPetEdit, setIsPetEdit] = useState(false);
  const [isConsultationEdit, setIsConsultationEdit] = useState(false);
  const [petParent, setPetParent] = useState('');
  const [petTypeId, setPetTypeId] = useState();
  const [breedId, setBreedId] = useState();
  const [petName, setPetName] = useState('');
  const [weight, setWeight] = useState();
  const [patientId, setPatientId] = useState();
  const [consultationType, setConsultationType] = useState('');
  const [petSex, setPetSex] = useState();
  const [dob, setDob] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [specialityId, setSpecialityId] = useState();
  const [serviceId, setServiceId] = useState(0);
  const [vendorId, setVendorId] = useState(0);
  const genders = ['Male', 'Female'];
  const [validSubmit, setValidSubmit] = useState(false);
  const [entityArray, setEntityArray] = useState([] as any);
  const [isNewQuestionnaire, setIsNewQuestionnaire] = useState(false);
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [fileQuestionnaireId, setFileQuestionnaireId] = useState(0);
  const [appointmentMode, setAppointmentMode] = useState();
  const [fileName, setFileName] = useState([] as any);
  const [attachmentUrl, setAttachmentUrl] = useState([] as any);
  const doctorId = routeParams.doctor?.id;
  const [callStatus, setCallStatus] = useState(
    CUSTOMER_CALL_STATUS.CUSTOMER_IDLE,
  );
  const [questionnairewithValue, setQuestionnairewithValue] = useState(
    [] as any,
  );
  const [appointmentStatus, setAppointmentStatus] = useState('');
  const [historyLength, setHistoryLength] = useState(history?.length);
  const [petTypeError, setPetTypeError] = useState(false);
  const [breedError, setBreedError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [dobError, setDobError] = useState(false);

  const NUMBER_OF_LIMIT_TAGS = 1;
  const updateCallStatus = useCallback(
    (status: string) => {
      setCallStatus(status);
    },
    [setCallStatus],
  );

  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );

  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );

  const uploadDocumentResponse = useSelector(
    (state: IRootState) => state.videoConsultation.uploadDocumentResponse.url,
  );
  {
    !CONSULTATION_DETAIL_TABS.includes(CONSULTATION_DETAILS) &&
      IsPermittedAction(
        PERMISSION_ACTIONS.GET_CUSTOMER_APPOINTMENT,
        authorities,
        permissionList,
      ) &&
      CONSULTATION_DETAIL_TABS.push(CONSULTATION_DETAILS);
  }
  {
    !CONSULTATION_DETAIL_TABS.includes(MEDICAL_HISTORY) &&
      IsPermittedAction(
        PERMISSION_ACTIONS.GET_MEDICAL_HISTORY,
        authorities,
        permissionList,
      ) &&
      CONSULTATION_DETAIL_TABS.push(MEDICAL_HISTORY);
  }
  {
    !CONSULTATION_DETAIL_TABS.includes(PRESCRIPTION) &&
      IsPermittedAction(
        PERMISSION_ACTIONS.GET_CUSTOMER_APPOINTMENT_PRESCRIPTIONS,
        authorities,
        permissionList,
      ) &&
      CONSULTATION_DETAIL_TABS.push(PRESCRIPTION);
  }
  {
    !CONSULTATION_DETAIL_TABS.includes(LABORATORY) &&
      IsPermittedAction(
        PERMISSION_ACTIONS.GET_APPOINTMENT_LAB_REPORT,
        authorities,
        permissionList,
      ) &&
      // appointmentMode === 'OFFLINE' &&
      CONSULTATION_DETAIL_TABS.push(LABORATORY);
  }
  {
    !CONSULTATION_DETAIL_TABS.includes(REMINDER) &&
      IsPermittedAction(
        PERMISSION_ACTIONS.GET_ALL_REMINDER,
        authorities,
        permissionList,
      ) &&
      CONSULTATION_DETAIL_TABS.push(REMINDER);
  }

  const [activeTab, setActiveTab] = useState(CONSULTATION_DETAIL_TABS[0]);

  useEffect(() => {
    if (appointmentMode === 'OFFLINE') {
      CONSULTATION_STATUS = [
        APPOINTMENT_STATUS.COMPLETED,
        APPOINTMENT_STATUS.CANCELLED,
      ];
      if (
        !CONSULTATION_DETAIL_TABS.includes(LABORATORY) &&
        IsPermittedAction(
          PERMISSION_ACTIONS.GET_APPOINTMENT_LAB_REPORT,
          authorities,
          permissionList,
        )
      ) {
        CONSULTATION_DETAIL_TABS.push(LABORATORY);
      }
    } else if (appointmentMode === 'ONLINE') {
      CONSULTATION_STATUS = [APPOINTMENT_STATUS.COMPLETED];
      if (CONSULTATION_DETAIL_TABS.includes(LABORATORY)) {
        CONSULTATION_DETAIL_TABS.pop();
      }
    }
  }, [appointmentMode]);

  const videoService = useMemo(
    () =>
      new VideoCallService({
        doctorId,
        appointmentId,
        updateCallStatus,
      }),
    [appointmentId],
  );

  useEffect(() => {
    if (
      IsPermittedAction(
        PERMISSION_ACTIONS.GET_CUSTOMER_APPOINTMENT,
        authorities,
        permissionList,
      )
    ) {
      fetchConsultationDetails();
    }
    return () => {
      handleCloseVideo();
    };
  }, []);

  useEffect(() => {
    if (Object.keys(editPetDetailResponse)?.length !== 0)
      fetchConsultationDetails();
  }, [editPetDetailResponse]);
  

  const sendConsultationData = async (entityArray, error) => {
    if (!error && entityArray?.length > 0) {
      if (isNewQuestionnaire)
        await props?.createQuestionnaire(entityArray, appointmentId);
      else await props?.editQuestionnaire(entityArray, appointmentId);
    }
    fetchConsultationDetails();
  };

  useEffect(() => {
    let error = checkValidation();
    if (!error && validSubmit) {
      if (isNewQuestionnaire)
        props?.createQuestionnaire(entityArray, appointmentId, specialityId);
      else props?.editQuestionnaire(entityArray, appointmentId, specialityId);
    }
  }, [entityArray]);

  const checkValidation = () => {
    let flag = false;
    questionnairewithValue.forEach((field, index) => {
      if (field.inputType === APP_CUSTOM_FIELD_INPUT_TYPES.MULTI_SELECT) {
        if (field?.value?.length === 0 && field?.isRequired) {
          field.error = true;
          flag = true;
          setAutoCompleteError(true);
        } else {
          field.error = false;
          setAutoCompleteError(false);
        }
      } else if (
        field.inputType === APP_CUSTOM_FIELD_INPUT_TYPES.SINGLE_SELECT
      ) {
        if (field.value?.length === 0 && field?.isRequired) {
          field.error = true;
          flag = true;
        } else {
          field.error = false;
        }
      } else if (field.inputType === APP_CUSTOM_FIELD_INPUT_TYPES.DATE) {
        if (field.value?.length === 0 && field?.isRequired) {
          field.error = true;
          flag = true;
        } else {
          field.error = false;
        }
      }
    });
    return flag;
  };

  useEffect(() => {
    if (specialityId) props.getQuestionnaire(specialityId);
  }, [specialityId]);

  useEffect(() => {
    if (props?.specialities.length && !consultationDetails?.specialityId)
      setSpecialityId(props?.specialities[0]?.id);
  }, [props?.specialities, consultationDetails]);

  const calculateAge = (value) => {
    let dob = moment(value);
    const now = moment();
    const years = now.diff(dob, 'years');
    dob.add(years, 'years');
    const months = now.diff(dob, 'months');
    dob.add(months, 'months');
    const days = now.diff(dob, 'days');
    setAge(`${years}y ${months}m ${days}d`);
  };
  
  useEffect(() => {
    if (OFFLINE_STATUSES.includes(callStatus)) {
      setTimeout(() => {
        handleCloseVideo();
      }, SMOOTH_TRANSITION_TIME_IN_MILLISECONDS);
    }
  }, [callStatus]);

  useEffect(() => {
    if (consultationDetails) {
      setVendorId(consultationDetails?.vendorId);
      setServiceId(consultationDetails?.serviceId);
      setPetId(consultationDetails.petDetails?.petId);
      if (activeTab !== CONSULTATION_DETAIL_TABS[1]) {
        setAssignedDoctor(consultationDetails.header?.doctor);
        setStatus(consultationDetails.header?.status);
        setPetName(consultationDetails.petDetails?.name);
        setPetSex(consultationDetails.petDetails?.sex);
        setPetParent(consultationDetails.petDetails?.ownerName);
        setWeight(consultationDetails.petDetails?.weight);
        setPatientId(consultationDetails.petDetails?.patientId);
        setConsultationType(consultationDetails?.consultationType);
        setPetTypeId(consultationDetails.petDetails?.petTypeId);
        setBreedId(consultationDetails.petDetails?.breedId);
        setDob(consultationDetails?.petDetails?.dob);
        setAppointmentMode(consultationDetails?.type);
        consultationDetails?.specialityId &&
          setSpecialityId(consultationDetails?.specialityId);
      }
      calculateAge(consultationDetails?.petDetails?.dob);
      if (consultationDetails?.questionnaire?.length === 0)
        setIsNewQuestionnaire(true);
      else setIsNewQuestionnaire(false);
    }
  }, [consultationDetails]);

  useEffect(() => {
    if (serviceId && vendorId) {
      props?.getSpeciality(serviceId, vendorId);
      props?.fetchPetTypeVendor(serviceId);
    }
  }, [vendorId, serviceId]);

  useEffect(() => {
    if (petTypeId) props?.fetchBreedByPetType(petTypeId);
  }, [petTypeId]);

  useEffect(() => {
    if (
      activeTab === CONSULTATION_DETAIL_TABS[0] &&
      IsPermittedAction(
        PERMISSION_ACTIONS.GET_CUSTOMER_APPOINTMENT,
        authorities,
        permissionList,
      )
    ) {
      fetchConsultationDetails();
    }
  }, [activeTab]);

  useEffect(() => {
    if (!videoService?.client) {
      return;
    }

    let init = async () => {
      videoService.client.on('user-published', async (user, mediaType) => {
        await videoService.client.subscribe(user, mediaType);
        if (mediaType === MEDIA_TYPES.VIDEO) {
          setUsers(prevUsers => {
            return [...prevUsers, user];
          });
        }
        if (mediaType === MEDIA_TYPES.AUDIO) {
          user.audioTrack?.play();
        }
      });

      videoService.client.on('user-unpublished', (user, type) => {
        if (type === MEDIA_TYPES.AUDIO) {
          user.audioTrack?.stop();
        }
        if (type === MEDIA_TYPES.VIDEO) {
          setUsers(prevUsers => {
            return prevUsers.filter(User => User.uid !== user.uid);
          });
        }
      });

      videoService.client.on('user-left', user => {
        setUsers(prevUsers => {
          return prevUsers.filter(User => User.uid !== user.uid);
        });
        toast.info(translate(`consultation_tab.video_call.client_left`));
        setTimeout(() => {
          handleCloseVideo();
        }, SMOOTH_TRANSITION_TIME_IN_MILLISECONDS);
      });

      videoService.client.on('user-info-updated', (user, info) => {
        console.log('[DEBUG]user-info-updated', user, info);

        switch (info) {
          case 'mute-audio':
            setRemoteAudio(false);
            break;
          case 'unmute-audio':
            setRemoteAudio(true);
            break;
          case 'mute-video':
            setRemoteVideo(false);
            break;
          case 'unmute-video':
            setRemoteVideo(true);
            break;
        }
      });

      if (tracks?.length)
        await videoService.client.publish([tracks[0], tracks[1]]);

      setVideoCallStart(true);
    };

    if (ready && tracks?.length) {
      init();
    }
  }, [ready, tracks]);

  const onChangeAutocompleteValue = (event, value, id) => {
    const stateValue = value?.map(item => {
      return item;
    });
    const questionnaires = [...questionnairewithValue];
    const questionnaire = questionnaires.find(element => element.id === id);
    questionnaire.value = stateValue;
    if (stateValue?.length === 0 && questionnaire.isRequired) {
      questionnaire.error = true;
    } else {
      questionnaire.error = false;
    }
    setQuestionnairewithValue(questionnaires);
  };

  const onSingleSelect = (event, id) => {
    const stateValue = [event?.target?.value];
    const questionnaires = [...questionnairewithValue];
    const questionnaire = questionnaires.find(element => element.id === id);
    questionnaire.value = stateValue;
    if (stateValue?.length === 0 && questionnaire.isRequired) {
      questionnaire.error = true;
    } else {
      questionnaire.error = false;
    }
    setQuestionnairewithValue(questionnaires);
  };

  const onDateChange = (date, id) => {
    const stateValue = [
      moment(date).format(DATE_TIME_FORMATS.YYYY_MM_DD_WITH_HYPHEN),
    ];
    const questionnaires = [...questionnairewithValue];
    const questionnaire = questionnaires.find(element => element.id === id);
    questionnaire.value = stateValue;
    if (stateValue?.length === 0 && questionnaire.isRequired) {
      questionnaire.error = true;
    } else {
      questionnaire.error = false;
    }
    setQuestionnairewithValue(questionnaires);
  };

  useEffect(() => {
    if (props?.initiateVideoCallResponse?.status === HTTP_OK_RESPONSE && appointmentMode === 'ONLINE') {
      setVideoStarted(true);
      setActiveTab(VIDEO_CALL);
    }
  }, [initiateVideoCallResponse]);

  const onClickCallButton = () => {
    console.log('Clicked Call Button');
    const callUUID = uuid();
    videoService.initiateClientMessaging();
    videoService.setCallUID(callUUID);
    videoService.resetClient();
    setRemoteAudio(true);
    setRemoteVideo(true);
    videoService.client
      .join(AGORA_APP_ID, appointmentId, null, null)
      .then(() => {
        console.log('joined channel');
        props.initiateVideoCall({ appointmentId, callUUID });
      })
      .catch((err: any) => {
        console.log(`Could not join channel : ${err}`);
      });
  };

  useEffect(() => {
    if (uploadDocumentResponse) {
      const questionnaires = [...questionnairewithValue];
      const questionnaire = questionnaires.find(
        element => element.id === fileQuestionnaireId,
      );
      const temp = questionnaires.map(element => {
        if (element.id === fileQuestionnaireId) {
          return { ...questionnaire, value: [uploadDocumentResponse] };
        }
        return element;
      });
      setQuestionnairewithValue(temp);
    }
  }, [uploadDocumentResponse]);

  const handleImagePick = (name, id) => event => {
    const questionnaires = [...questionnairewithValue];
    const questionnaire = questionnaires.find(element => element.id === id);
    setFileData(
      event,
      async (contentType, data) => {
        setFileQuestionnaireId(id);
        let formData = new FormData();
        formData.append('fileContentType', event.target.files[0].type);
        formData.append('name', event.target.files[0]?.name?.split('/').pop());
        formData.append('purpose', 'questionnaireFileUpload');
        formData.append('file', event?.target?.files[0]);
        await props?.uploadDocumet(formData);
      },
      isAnImage(event.target.files[0]),
    );
    let tempFileName = fileName?.filter(item => item?.id !== id);
    setFileName([
      ...tempFileName,
      {
        id: id,
        fileName: convertFileName(
          event.target.files[0]?.name?.split('/').pop(),
        ),
      },
    ]);
  };
  const convertFileName = fileName => {
    return fileName?.length > 16 ? fileName.substring(0, 16) + '...' : fileName;
  };
  const fetchImageName = id => {
    return fileName.find(item => item.id === id)?.fileName;
  };

  const isAnImage = file => {
    const fileExtension = file?.name?.split('.').pop();
    if (fileExtension === 'pdf') {
      return false;
    } else {
      return true;
    }
  };

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (event, value) => {
    setAttachmentUrl(value);
    setShowModal(true);
  };

  const handleHideModal = () => {
    setAttachmentUrl('');
    setShowModal(false);
  };

  const toggle = () => {
    setShowModal(!showModal);
  };

  const onPetTypeChange = event => {
    setPetTypeId(event?.target?.value);
    if (event?.target?.value) {
      setPetTypeError(false);
    } else {
      setPetTypeError(true);
    }
  };

  const petTypeErrorChecking = () => {
    if (!petTypeId) {
      setPetTypeError(true);
      return true;
    } else {
      setPetTypeError(false);
      return false;
    }
  };
  const onBreedChange = event => {
    setBreedId(event?.target?.value);
    if (event?.target?.value) {
      setBreedError(false);
    } else {
      setBreedError(true);
    }
  };

  const breedErrorChecking = () => {
    if (!breedId) {
      setBreedError(true);
      return true;
    } else {
      setBreedError(false);
      return false;
    }
  };

  const onGenderChange = event => {
    setPetSex(event?.target?.value);
    if (event?.target?.value) {
      setGenderError(false);
    } else {
      setGenderError(true);
    }
  };

  const genderErrorChecking = () => {
    if (!petSex) {
      setGenderError(true);
      return true;
    } else {
      setGenderError(false);
      return false;
    }
  };

  const onDobChange = date => {
    setDob(moment(date).format('YYYY-MM-DD'));
     if (date) {
      setDobError(false);
    } else {
      setDobError(true);
    }
  };

  const dobErrorChecking = () => {
    if (!dob) {
      setDobError(true);
      return true;
    } else {
      setDobError(false);
      return false;
    }
  }; 

  const renderLabelAndValue = ({ label, value, type = null }) => {
    return (
      <>
        <Col md={6}>
          <p className="label">{label}</p>
        </Col>
        <Col md={6}>
          {type === APP_CUSTOM_FIELD_INPUT_TYPES.CHECK_BOX &&
          value === 'true' ? (
            <p className="value">Yes</p>
          ) : type === APP_CUSTOM_FIELD_INPUT_TYPES.CHECK_BOX &&
            (!value || value === 'false') ? (
            <p className="value">No</p>
          ) : type === APP_CUSTOM_FIELD_INPUT_TYPES.ATTACHMENT && value ? (
            <div>
              <p onClick={event => handleShowModal(event, value)}>
                Attachment &nbsp;
                <FontAwesomeIcon
                  icon={faDownload}
                  color="var(--bs-grey-teal)"
                />
              </p>
            </div>
          ) : type === APP_CUSTOM_FIELD_INPUT_TYPES.DATE && value ? (
            <p>{moment(value).format('DD-MM-yyyy')}</p>
          ) : (
            <p
              className="value"
              style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
              {value}
            </p>
          )}
        </Col>
      </>
    );
  };

  const handleCloseVideo = async () => {
    if (
      callStatus === CUSTOMER_CALL_STATUS.CUSTOMER_CALL_DISCONNECTED ||
      callStatus === CUSTOMER_CALL_STATUS.VENDOR_CALL_DISCONNECTED
    )
      setIsShowConfirmationModal(true);
    await videoService.notifyPeerAboutVideoDisconnection();
    videoService.releaseAllResources();
    // we close the tracks to perform cleanup
    if (tracks?.length) {
      await tracks[0].close();
      await tracks[1].close();
    }
    setVideoStarted(false);
    setActiveTab(CONSULTATION_DETAILS);
    setUsers([]);
    setTracks([]);
    setReady(false);
    setVideoCallStart(false);

    setCallStatus(CUSTOMER_CALL_STATUS.CUSTOMER_IDLE);
    if (videoService.client.channelName) window.location.reload();
  };

  const onChangeStatus = event => {
    if (event?.target?.value) {
      setAppointmentStatus(event?.target?.value);
    }
  };
  const onUpdateStatus = () => {
    props.updateAppointmentStatus({
      id: appointmentId,
      status: appointmentStatus,
    });
    history.push('/video-consultation');
  };

  const handleValidSubmit = async (event, errors, values) => {
    let petTypeErrorValid = petTypeErrorChecking();
    let breedErrorValid = breedErrorChecking();
    let genderErrorValid = genderErrorChecking();
    let dobErrorValid = dobErrorChecking();
    if (
      !petTypeErrorValid &&
      !breedErrorValid &&
      !genderErrorValid &&
      !dobErrorValid
    ) {
      const entity = {
        id: petId,
        petName: petName,
        petTypeId: petTypeId,
        breedId: breedId,
        sex: petSex,
        weight: weight,
        patientId: patientId,
        consultationType: consultationType,
        dob: moment(dob).format(DATE_TIME_FORMATS.YYYY_MM_DD_WITH_HYPHEN),
      };
      props?.editPetDetail(entity);
      setIsPetEdit(false);
    }
  };

  const findDisplayName = str => {
    return str?.split(' ')?.slice(1)?.join(' ');
  };
  const handleConsultationDataSubmit = (event, errors, values) => {
    let tempValues: any[] = [];
    tempValues = Object.entries(values);
    let entity = [] as any;
    if (!errors.length) {
      setValidSubmit(true);
    }
    questionnairewithValue?.forEach((field, index) => {
      if (
        field.inputType !== APP_CUSTOM_FIELD_INPUT_TYPES.MULTI_SELECT &&
        field.inputType !== APP_CUSTOM_FIELD_INPUT_TYPES.SINGLE_SELECT &&
        field.inputType !== APP_CUSTOM_FIELD_INPUT_TYPES.DATE
      ) {
        const displayName = field.displayName;
        let value = tempValues?.find(
          item => findDisplayName(item[0]) === displayName,
        );
        if (field.inputType === APP_CUSTOM_FIELD_INPUT_TYPES.CHECK_BOX) {
          field.value[0] =
            typeof value[1] !== 'undefined'
              ? value[1] === true
                ? 'true'
                : 'false'
              : 'false';
        } else if (
          field.inputType === APP_CUSTOM_FIELD_INPUT_TYPES.ATTACHMENT
        ) {
          field.value[0] = field?.value[0];
        } else {
          field.value[0] = typeof value[1] !== 'undefined' ? value[1] : '';
        }
      }
      if (field.questionnaireId !== 0) {
        if (entity?.length > 0) {
          entity = [
            ...entity,
            {
              inputValues: field?.value,
              questionnaireId: field?.id ?? '',
              displayName: field?.displayName,
              displayOrder: field?.displayOrder,
              inputType: field?.inputType,
            },
          ];
        } else {
          entity = [
            {
              inputValues: field?.value,
              questionnaireId: field?.id ?? '',
              attributeKey: field?.key,
              displayName: field?.displayName,
              displayOrder: field?.displayOrder,
              inputType: field?.inputType,
            },
          ];
        }
      } else {
        if (entity?.length > 0) {
          entity = [
            ...entity,
            {
              inputValues: field?.value,
              questionnaireId: field?.id ?? '',
              attributeKey: field?.key,
              displayName: field?.displayName,
              displayOrder: field?.displayOrder,
              inputType: field?.inputType,
            },
          ];
        } else {
          entity = [
            {
              inputValues: field?.value,
              questionnaireId: field?.id ?? '',
              attributeKey: field?.key,
              displayName: field?.displayName,
              displayOrder: field?.displayOrder,
              inputType: field?.inputType,
            },
          ];
        }
      }
    });
    setEntityArray(entity);
  };

  const setAudioVideoTracks = (videoReady, videoAudioTracks) => {
    setTracks(videoAudioTracks);
    setReady(videoReady);
  };

  const subHeader = headerText => {
    return (
      <div className="header-container pt-4 pb-4">
        <p className="m-0 header-text">{headerText}</p>
      </div>
    );
  };

  const renderCallExceptionButtons = () => {
    return (
      <Col md={12} className="bottom-buttons-container p-3">
        <Row className="align-items-center">
          {!videoStarted &&
            appointmentMode === 'ONLINE' &&
            assignedDoctor !== null && (
              <Col className="m-1 mt-0" md={1}>
                {IsPermittedAction(
                  PERMISSION_ACTIONS.INITIATE_CALL,
                  authorities,
                  permissionList,
                ) && (
                  <Button onClick={onClickCallButton}>
                    <span>{translate('consultation_tab.call_text')}</span>
                  </Button>
                )}
              </Col>
            )}
          {/* <Col className="m-1 mt-0" md={1}>
            Future
            <Button transparent>
              <span>{translate('consultation_tab.exception_text')}</span>
            </Button>
          </Col> */}
          {IsPermittedAction(
            PERMISSION_ACTIONS.UPDATE_CUSTOMER_APPOINTMENT_STATUS,
            authorities,
            permissionList,
          ) && (
            <>
              {consultationType == inPatient ||
              (consultationType != inPatient && assignedDoctor !== null) ? (
                <Col className="m-1 mx-4 d-flex align-items-center">
                  <span style={{ fontSize: 13 }}>
                    {translate('consultation_tab.mark_as')}
                  </span>
                  <div className="d-flex mx-2 align-items-center">
                    <select
                      value={appointmentStatus}
                      onChange={event => onChangeStatus(event)}
                      placeholder="Select"
                      style={{
                        width: '100%',
                      }}>
                      <option value="" selected>
                        {translate('placeholders.select')}
                      </option>
                      {CONSULTATION_STATUS?.map((status, i) => (
                        <option key={i} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                    <Button
                      type="submit"
                      className="mx-3"
                      onClick={onUpdateStatus}
                      disabled={appointmentStatus ? false : true}>
                      <span>{translate('consultation_tab.update_status')}</span>
                    </Button>
                  </div>
                </Col>
              ) : null}
            </>
          )}
        </Row>
      </Col>
    );
  };

  useEffect(() => {
    setIsConsultationEdit(false);
    {
      IsPermittedAction(
        PERMISSION_ACTIONS.GET_CUSTOMER_APPOINTMENT,
        authorities,
        permissionList,
      ) && fetchConsultationDetails();
    }
  }, [props.editQuestionnaireResponse, props.createQuestionnaireResponse]);

  useEffect(() => {
    const questionnaireList = [...props?.questionnaireResponse];
    let tempFileName = [] as any;
    questionnaireList.map(facility => {
      const questionnaire = consultationDetails?.questionnaire?.find(
        element => element?.specialityQuestionnaireId === facility?.id,
      );
      if (questionnaire) {
        facility.fileValue =
          questionnaire?.inputType === 'ATTACHMENT'
            ? questionnaire?.response
            : '';
        if (
          questionnaire?.inputType !== APP_CUSTOM_FIELD_INPUT_TYPES.TEXT_BOX &&
          questionnaire?.inputType !== APP_CUSTOM_FIELD_INPUT_TYPES.TEXT_AREA
        ) {
          facility.value = questionnaire?.response?.split(',');
        } else {
          facility.value = [questionnaire?.response];
        }
        facility.questionnaireId = questionnaire?.id;
      } else {
        facility.fileValue = '';
        facility.value = [];
        facility.questionnaireId = 0;
      }
      return facility;
    });
    setQuestionnairewithValue(questionnaireList);
    questionnaireList?.map(item => {
      if (item?.inputType === 'ATTACHMENT') {
        tempFileName.push({
          id: item?.id,
          fileName: convertFileName(item?.fileValue),
        });
      }
    });
    setFileName(tempFileName);
  }, [props?.questionnaireResponse, consultationDetails?.questionnaire]);

  const renderCustomDatePicker = (date, error) => {
    return (
      <div
        className={`p-3 px-3  ${
          error === true ? 'calender-error-style' : 'calender-style'
        }`}>
        <div className="d-flex justify-content-between">
          <p className="m-0">
            {date
              ? moment(date).format(DATE_TIME_FORMATS.DD_MM_YYYY)
              : 'dd-mm-yyyy'}
          </p>
          <DateIcon />
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        {history && history?.length > 1 && (
          <Button
            className="text-white"
            color="danger"
            size="sm"
            data-cy="entityBackwardButton"
            onClick={() => history.goBack()}>
            <div style={{ color: 'white' }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
          </Button>
        )}
      </div>
      <Col md={12}>
        <div
          className="p-4"
          style={{ marginBottom: '5rem' }}
          id="consultation-tab">
          <ul
            style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
            className="nav nav-pills pt-2 justify-content-start tab-container"
            id="pills-tab"
            role="tablist">
            {(videoStarted
              ? [VIDEO_CALL, ...CONSULTATION_DETAIL_TABS]
              : CONSULTATION_DETAIL_TABS
            ).map((tab, index) => (
              <li key={index} className="nav-item" role="presentation">
                <button
                  className={`nav-link ${tab === activeTab && 'active'}`}
                  onClick={() => setActiveTab(tab)}
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true">
                  {tab === VIDEO_CALL && videoStarted && (
                    <img src={dotPng} className="dot-icon" />
                  )}
                  <span>{translate(`consultation_tab.${tab}.tab_header`)}</span>
                </button>
              </li>
            ))}
          </ul>

          <div className="tab-content" id="pills-tabContent">
            {props.loading ? (
              <div className="text-info my-4" style={{ fontSize: 32 }}>
                <FontAwesomeIcon icon={faSpinner} spin />
              </div>
            ) : (
              <Col md={12}>
                {activeTab === CONSULTATION_DETAILS ? (
                  <Col
                    className="tab-pane consultation-detail-tab pb-3 fade show active"
                    id="consultation-details"
                    role="tabpanel"
                    aria-labelledby="consultation-details-tab">
                    <>
                      <AvForm onValidSubmit={handleValidSubmit}>
                        <Col md={12}>
                          {subHeader(
                            <Row>
                              <Col md={10}>
                                {translate(
                                  'consultation_tab.consultation_details.pet_info',
                                )}
                              </Col>
                              <Col md={2}>
                                {!isPetEdit ? (
                                  historyLength >
                                    HISTORY_LENGTH_FROM_REPORT_SCREEN && (
                                    <FaPencilAlt
                                      className="edit-style-pencil pointer-style"
                                      onClick={() => setIsPetEdit(true)}
                                    />
                                  )
                                ) : (
                                  <Button
                                    className="button--appointmakement px-3"
                                    color="dark"
                                    Type="submit">
                                    Save
                                  </Button>
                                )}
                              </Col>
                            </Row>,
                          )}
                        </Col>
                        {!isPetEdit ? (
                          <Col md={12}>
                            <Row>
                              <Col className="pet-info-left-col pt-3" md={6}>
                                <Row>
                                  {renderLabelAndValue({
                                    label: translate(
                                      'consultation_tab.consultation_details.owner_name',
                                    ),
                                    value:
                                      consultationDetails?.petDetails
                                        ?.ownerName,
                                  })}
                                  {renderLabelAndValue({
                                    label: translate(
                                      'consultation_tab.consultation_details.pet_type',
                                    ),
                                    value:
                                      consultationDetails?.petDetails?.petType,
                                  })}
                                  {renderLabelAndValue({
                                    label: translate(
                                      'consultation_tab.consultation_details.breed',
                                    ),
                                    value:
                                      consultationDetails?.petDetails?.breed,
                                  })}
                                  {renderLabelAndValue({
                                    label: translate(
                                      'consultation_tab.consultation_details.contact_number',
                                    ),
                                    value:
                                      consultationDetails?.petDetails?.mobile,
                                  })}
                                </Row>
                              </Col>
                              <Col md={6}>
                                <Row>
                                  <Col
                                    className="pt-3 pet-info-left-col"
                                    md={8}>
                                    <Row>
                                      {renderLabelAndValue({
                                        label: translate(
                                          'consultation_tab.consultation_details.dob',
                                        ),
                                        value: consultationDetails?.petDetails
                                          ?.sex
                                          ? age
                                          : '',
                                      })}
                                      {renderLabelAndValue({
                                        label: translate(
                                          'consultation_tab.consultation_details.sex',
                                        ),
                                        value:
                                          consultationDetails?.petDetails?.sex,
                                      })}
                                      {renderLabelAndValue({
                                        label: translate(
                                          'consultation_tab.consultation_details.weight',
                                        ),
                                        value: consultationDetails?.petDetails
                                          ?.weight
                                            ? consultationDetails?.petDetails
                                              ?.weight + PET_WEIGHT_UNIT
                                          : '',
                                      })}
                                      {renderLabelAndValue({
                                        label: translate(
                                          'consultation_tab.consultation_details.patient_id',
                                        ),
                                        value: consultationDetails?.petDetails
                                          ?.patientId
                                          ? consultationDetails?.petDetails
                                              ?.patientId
                                          : '',
                                      })}
                                    </Row>
                                  </Col>
                                  <Col md={4}>
                                    <Col className=" pt-4 pb-0 pet-image-col">
                                      {consultationDetails?.petDetails
                                        ?.imageUrl !== null ? (
                                        <Media
                                          object
                                          className="img-responsive center-block pet-image"
                                          width="80%"
                                          src={
                                            consultationDetails?.petDetails
                                              ?.imageUrl
                                          }
                                        />
                                      ) : (
                                        <Media
                                          object
                                          className="img-responsive center-block pet-image"
                                          width="80%"
                                          src={PET_DEFAULT_IMAGE}
                                        />
                                      )}
                                      <p className="m-0 pt-1 text-center text-light">
                                        {consultationDetails?.petDetails?.name}
                                      </p>
                                    </Col>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        ) : (
                          <Row>
                            <Col md={8}>
                              <Row className="p-3">
                                <Col md={6}>
                                  <Label>
                                    {translate(
                                      'consultation_tab.consultation_details.owner_name',
                                    )}{' '}
                                    :
                                  </Label>
                                  <Label>
                                    {consultationDetails?.petDetails?.ownerName}
                                  </Label>
                                </Col>
                                <Col md={6}>
                                  <Label>
                                    {translate(
                                      'consultation_tab.consultation_details.contact_number',
                                    )}{' '}
                                    :
                                  </Label>
                                  <Label>
                                    {consultationDetails?.petDetails?.mobile}
                                  </Label>
                                </Col>
                              </Row>
                              <Row className="p-3">
                                <Col md={6}>
                                  <Label>
                                    {translate(
                                      'consultation_tab.consultation_details.patient_id',
                                    )}{' '}
                                    :
                                  </Label>
                                  <Col md={6}>
                                    <AvField
                                      className="style-text-box style-text-box-pet"
                                      name="patientId"
                                      type="text"
                                      value={patientId}
                                      onChange={event =>
                                        setPatientId(event?.target?.value)
                                      }
                                    />
                                  </Col>
                                </Col>
                                <Col md={6}>
                                  <Label>
                                    {translate(
                                      'consultation_tab.consultation_details.pet_type',
                                    )}{' '}
                                    :
                                  </Label>
                                  <Col md={6}>
                                    <Select
                                      className="style-text-box style-text-box-pet select-value"
                                      variant="outlined"
                                      MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                          vertical: 'bottom',
                                          horizontal: 'left',
                                        },
                                      }}
                                      value={petTypeId}
                                      error={petTypeError ? true : false}
                                      onChange={event => {
                                        onPetTypeChange(event);
                                      }}>
                                      <MenuItem key="" value="">
                                        {translate(
                                          'consultation_tab.consultation_details.select_pet_type',
                                        )}
                                      </MenuItem>
                                      {props?.petTypes?.map((attr, i) => (
                                        <MenuItem key={i} value={attr?.id}>
                                          {attr?.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {petTypeError && (
                                      <span
                                        className="text-danger"
                                        style={{ fontSize: '.8rem' }}>
                                        {translate(
                                          'consultation_tab.consultation_details.please_select_pet_type',
                                        )}
                                      </span>
                                    )}
                                  </Col>
                                </Col>
                              </Row>
                              <Row className="p-3 pt-1">
                                <Col md={6}>
                                  <Label>
                                    {translate(
                                      'consultation_tab.consultation_details.breed',
                                    )}{' '}
                                    :
                                  </Label>
                                  <Col md={6}>
                                    <Select
                                      className="style-text-box style-text-box-pet select-value"
                                      variant="outlined"
                                      MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                          vertical: 'bottom',
                                          horizontal: 'left',
                                        },
                                      }}
                                      value={breedId}
                                      error={breedError ? true : false}
                                      onChange={event => {
                                        onBreedChange(event);
                                      }}>
                                      <MenuItem key="" value="">
                                        {translate(
                                          'consultation_tab.consultation_details.select_breed',
                                        )}
                                      </MenuItem>
                                      {props?.petBreeds?.map((attr, i) => (
                                        <MenuItem key={i} value={attr?.id}>
                                          {attr?.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {breedError && (
                                      <span
                                        className="text-danger"
                                        style={{ fontSize: '.8rem' }}>
                                        {translate(
                                          'consultation_tab.consultation_details.please_select_breed',
                                        )}
                                      </span>
                                    )}
                                  </Col>
                                </Col>
                              </Row>
                              <Row className="p-3 pt-1">
                                <Col md={6}>
                                  <Label>
                                    {translate(
                                      'consultation_tab.consultation_details.dob',
                                    )}{' '}
                                    :
                                  </Label>
                                  <Col md={6}>
                                    <div
                                      className="ant-picker-style d-flex"
                                      style={{ width: '100%' }}>
                                      <DatePicker
                                        defaultValue={
                                          dob ? moment(dob) : undefined
                                        }
                                        onChange={date => onDobChange(date)}
                                        placeholder="dd-mm-yyyy"
                                        className="ant-picker-dropdown dob-datepicker"
                                        format={DATE_TIME_FORMATS.DD_MM_YYYY}
                                        allowClear={false}
                                        style={{
                                          width: '24%',
                                          borderColor: dobError
                                            ? 'red'
                                            : undefined,
                                        }}
                                      />
                                      </div>
                                      <div style={{marginTop:'50px'}}>
                                      {dobError && (
                                        <span
                                          className="text-danger"
                                          style={{ fontSize: '.8rem' }}>
                                          {translate(
                                            'consultation_tab.consultation_details.please_select_a_date',
                                          )}
                                        </span>
                                      )}
                                    </div>
                                  </Col>
                                </Col>
                                <Col md={3}>
                                  <Label>
                                    {translate(
                                      'consultation_tab.consultation_details.sex',
                                    )}{' '}
                                    :
                                  </Label>
                                  <Col md={3}>
                                    <Select
                                      className="style-text-box style-text-box-weight select-value"
                                      variant="outlined"
                                      MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                          vertical: 'bottom',
                                          horizontal: 'left',
                                        },
                                      }}
                                      value={petSex}
                                      error={genderError ? true : false}
                                      onChange={event => {
                                        onGenderChange(event);
                                      }}>
                                      <MenuItem key="" value="">
                                        {translate(
                                          'consultation_tab.consultation_details.select_pet_sex',
                                        )}
                                      </MenuItem>
                                      {genders?.map((attr, i) => (
                                        <MenuItem key={i} value={attr}>
                                          {attr}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {genderError && (
                                      <span
                                        className="text-danger"
                                        style={{ fontSize: '.8rem' }}>
                                        {translate(
                                          'consultation_tab.consultation_details.please_select_pet_sex',
                                        )}
                                      </span>
                                    )}
                                  </Col>
                                </Col>
                                <Col md={3}>
                                  <Label>
                                    {translate(
                                      'consultation_tab.consultation_details.weight',
                                    )}{' '}
                                    :
                                  </Label>
                                  <Col md={3}>
                                    <AvField
                                      className="style-text-box style-text-box-weight"
                                      name="weight"
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: translate(
                                            'consultation_tab.consultation_details.please_enter_pet_weight',
                                          ),
                                        },
                                      }}
                                      type="text"
                                      value={weight}
                                      onChange={event =>
                                        setWeight(event?.target?.value)
                                      }
                                    />
                                  </Col>
                                </Col>
                              </Row>
                            </Col>
                            <Col md={2}>
                              <Col className=" pt-4 pb-0 pet-image-col">
                                {consultationDetails?.petDetails?.imageUrl !==
                                null ? (
                                  <Media
                                    object
                                    className="img-responsive center-block pet-image"
                                    width="80%"
                                    src={
                                      consultationDetails?.petDetails?.imageUrl
                                    }
                                  />
                                ) : (
                                  <Media
                                    object
                                    className="img-responsive center-block pet-image"
                                    width="80%"
                                    src={PET_DEFAULT_IMAGE}
                                  />
                                )}
                              </Col>
                              <Col>
                                <AvField
                                  className="style-text-box style-text-box-pet-name"
                                  name="petName"
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: translate(
                                        'consultation_tab.consultation_details.please_enter_pet_name',
                                      ),
                                    },
                                  }}
                                  type="text"
                                  value={petName}
                                  onChange={event => {
                                    setPetName(event.target.value);
                                  }}
                                />
                              </Col>
                            </Col>
                          </Row>
                        )}
                      </AvForm>
                    </>
                    <AvForm onSubmit={handleConsultationDataSubmit}>
                      <Col md={12}>
                        {subHeader(
                          <Row>
                            <Col md={7}>
                              {translate(
                                'consultation_tab.consultation_details.tab_header',
                              )}
                            </Col>
                            <Col md={3}>
                              {consultationDetails?.type === 'OFFLINE' ? (
                                <Label>
                                  {translate(
                                    'appointment_form.consultation_type',
                                  )}
                                  : &nbsp;
                                  {consultationDetails?.consultationType}
                                </Label>
                              ) : null}
                            </Col>
                            {IsPermittedAction(
                              PERMISSION_ACTIONS.SET_VENDOR_SPECIALITY_QUESTIONNAIRES,
                              authorities,
                              permissionList,
                            ) &&
                              IsPermittedAction(
                                PERMISSION_ACTIONS.UPDATE_VENDOR_SPECIALITY_QUESTIONNAIRES,
                                authorities,
                                permissionList,
                              ) && (
                                <Col md={2}>
                                  {!isConsultationEdit &&
                                    historyLength >
                                      HISTORY_LENGTH_FROM_REPORT_SCREEN && (
                                      <FaPencilAlt
                                        className="edit-style-pencil pointer-style"
                                        onClick={() =>
                                          setIsConsultationEdit(true)
                                        }
                                      />
                                    )}
                                </Col>
                              )}
                          </Row>,
                        )}
                      </Col>
                      {!isConsultationEdit ? (
                        <Col md={12}>
                          <Row>
                            {consultationDetails?.questionnaire?.length > 0 ? (
                              consultationDetails?.questionnaire?.map(
                                (item, index) => {
                                  return (
                                    <>
                                      {item?.displayName === 'Symptoms' &&
                                      item?.inputType ===
                                        APP_CUSTOM_FIELD_INPUT_TYPES.TEXT_BOX ? (
                                        <Col
                                          md={12}
                                          className="pet-info-left-col pt-3">
                                          {' '}
                                          <b>Clinial Examination </b>
                                        </Col>
                                      ) : item?.displayName === 'Skin' &&
                                        item?.inputType ===
                                          APP_CUSTOM_FIELD_INPUT_TYPES.TEXT_BOX ? (
                                        <Col
                                          md={12}
                                          className="pet-info-left-col pt-3">
                                          {' '}
                                          <b>System/Area Involved </b>
                                        </Col>
                                      ) : null}
                                      {item?.response &&
                                      item?.response != -1 ? (
                                        <Col
                                          key={index}
                                          className="pet-info-left-col pt-3"
                                          md={6}>
                                          <Row>
                                            {renderLabelAndValue({
                                              label: item?.displayName,
                                              value:
                                                item?.inputType ===
                                                'MULTI_SELECT'
                                                  ? item?.response?.slice(
                                                      0,
                                                      item?.response?.length -
                                                        1,
                                                    )
                                                  : item?.response,
                                              type: item?.inputType,
                                            })}
                                          </Row>
                                        </Col>
                                      ) : null}
                                    </>
                                  );
                                },
                              )
                            ) : (
                              <Col className="pet-info-left-col pt-3" md={12}>
                                <Row>
                                  {renderLabelAndValue({
                                    label: 'There is no questionnaire added',
                                    value: '',
                                  })}
                                </Row>
                              </Col>
                            )}
                          </Row>
                        </Col>
                      ) : (
                        <Col md={8} className="pet-info-left-col pt-3">
                          <Label className="my-2">Select Specialities</Label>
                          <Row>
                            {props.specialities.map(speciality => {
                              return (
                                <Col md={4} className="my-1">
                                  <Input
                                    type="radio"
                                    checked={specialityId === speciality.id}
                                    onClick={() => {
                                      setSpecialityId(speciality.id);
                                    }}
                                  />
                                  &nbsp;{speciality.name}
                                </Col>
                              );
                            })}
                          </Row>

                          <Row className="d-flex">
                            {props?.questionnaireResponse?.map(
                              (questionnaire, index) => {
                                return (
                                  <>
                                    {questionnaire?.displayName ===
                                      'Symptoms' &&
                                    questionnaire?.inputType ===
                                      APP_CUSTOM_FIELD_INPUT_TYPES.TEXT_BOX ? (
                                      <Col md={12}>
                                        {' '}
                                        <b>Clinial Examination </b>
                                      </Col>
                                    ) : questionnaire?.displayName === 'Skin' &&
                                      questionnaire?.inputType ===
                                        APP_CUSTOM_FIELD_INPUT_TYPES.TEXT_BOX ? (
                                      <Col md={12}>
                                        {' '}
                                        <b>System/Area Involved </b>
                                      </Col>
                                    ) : null}
                                    <Col md={4} key={index} className="my-1">
                                      {questionnaire?.inputType ===
                                      APP_CUSTOM_FIELD_INPUT_TYPES.SINGLE_SELECT ? (
                                        <div className="select-wrapper">
                                          <Label
                                            className={
                                              questionnaire?.error &&
                                              'invalid-label-text'
                                            }>
                                            {questionnaire?.displayName}
                                          </Label>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              className="facility-multi-dropdown"
                                            variant="outlined"
                                            MenuProps={{
                                              getContentAnchorEl: null,
                                              anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                              },
                                            }}
                                            fullWidth
                                            value={
                                              questionnaire?.value?.length
                                                ? questionnaire?.value[0]
                                                : ''
                                            }
                                            displayEmpty
                                            error={
                                              questionnaire?.error
                                                ? true
                                                : false
                                            }
                                            onChange={event => {
                                              onSingleSelect(
                                                event,
                                                questionnaire?.id,
                                              );
                                            }}
                                            style={{
                                              color: !questionnaire?.value
                                                ?.length
                                                ? 'rgba(190,190,190 )'
                                                : 'black',
                                              fontSize: '0.8rem',
                                              fontWeight: 400,
                                            }}>
                                            <MenuItem
                                              value=""
                                              key=""
                                              selected
                                              disabled>
                                              {translate('placeholders.select')}
                                            </MenuItem>
                                            {questionnaire?.attributeValues?.map(
                                              (attr, i) => (
                                                <MenuItem key={i} value={attr}>
                                                  {attr}
                                                </MenuItem>
                                              ),
                                            )}
                                          </Select>
                                          {questionnaire?.error && (
                                            <span
                                              className="text-danger"
                                              style={{ fontSize: '.8rem' }}>
                                              {translate(
                                                'validation_facility_details.fields_error_message',
                                              ) +
                                                questionnaire?.displayName.toLowerCase()}
                                            </span>
                                          )}
                                        </div>
                                      ) : questionnaire?.inputType ===
                                        APP_CUSTOM_FIELD_INPUT_TYPES.CHECK_BOX ? (
                                        <AvField
                                          type="checkbox"
                                          label={questionnaire?.displayName}
                                          value={
                                            questionnaire?.value?.length
                                              ? questionnaire?.value[0] ===
                                                'true'
                                                ? true
                                                : false
                                              : false
                                          }
                                          name={
                                            index +
                                            ' ' +
                                            questionnaire?.displayName
                                          }
                                        />
                                      ) : questionnaire?.inputType ===
                                        APP_CUSTOM_FIELD_INPUT_TYPES.TEXT_BOX ? (
                                        <>
                                          <AvField
                                            value={
                                              questionnaire?.value?.length
                                                ? questionnaire?.value[0]
                                                : ''
                                            }
                                            className="p-3"
                                            label={questionnaire?.displayName}
                                            name={
                                              index +
                                              ' ' +
                                              questionnaire?.displayName
                                            }
                                            validate={{
                                              required: {
                                                value:
                                                  questionnaire?.isRequired,
                                                errorMessage:
                                                  translate(
                                                    'validation_facility_details.fields_error_message',
                                                  ) +
                                                  questionnaire?.displayName.toLowerCase(),
                                              },
                                            }}
                                          />
                                        </>
                                      ) : questionnaire?.inputType ===
                                        APP_CUSTOM_FIELD_INPUT_TYPES.TEXT_AREA ? (
                                        <>
                                          <AvField
                                            value={
                                              questionnaire?.value?.length
                                                ? questionnaire?.value[0]
                                                : ''
                                            }
                                            className="p-3"
                                            label={questionnaire?.displayName}
                                            name={
                                              index +
                                              ' ' +
                                              questionnaire?.displayName
                                            }
                                            type="textarea"
                                            validate={{
                                              required: {
                                                value:
                                                  questionnaire?.isRequired,
                                                errorMessage:
                                                  translate(
                                                    'validation_facility_details.fields_error_message',
                                                  ) +
                                                  questionnaire?.displayName.toLowerCase(),
                                              },
                                            }}
                                          />
                                        </>
                                      ) : questionnaire?.inputType ===
                                        APP_CUSTOM_FIELD_INPUT_TYPES.MULTI_SELECT ? (
                                        <>
                                          <Label
                                            className={
                                              questionnaire?.error &&
                                              'invalid-label-text'
                                            }>
                                            {questionnaire?.displayName}
                                          </Label>
                                          <Autocomplete
                                            multiple
                                            limitTags={NUMBER_OF_LIMIT_TAGS}
                                            className="facility-multi-dropdown"
                                            id={questionnaire?.id}
                                            style={{ fontSize: '.8rem' }}
                                            onChange={(event, value) =>
                                              onChangeAutocompleteValue(
                                                event,
                                                value,
                                                questionnaire?.id,
                                              )
                                            }
                                            options={
                                              Array.isArray(
                                                questionnaire?.attributeValues,
                                              )
                                                ? questionnaire?.attributeValues
                                                : []
                                            }
                                            getOptionLabel={option =>
                                              option ?? ''
                                            }
                                            getOptionSelected={(
                                              option,
                                              value,
                                            ) => option === value}
                                            value={
                                              questionnaire?.value?.map(
                                                (spec, i) => {
                                                  return Array.isArray(
                                                    questionnaire?.attributeValues,
                                                  )
                                                    ? questionnaire?.attributeValues.filter(
                                                        x => x === spec,
                                                      )[0]
                                                    : '';
                                                },
                                              ) ?? []
                                            }
                                            filterSelectedOptions
                                            renderInput={params => (
                                              <TextField
                                                {...params}
                                                variant="outlined"
                                                error={questionnaire?.error}
                                                placeholder={translate(
                                                  'placeholders.select',
                                                )}
                                              />
                                            )}
                                          />
                                          {questionnaire?.error && (
                                            <span
                                              className="text-danger"
                                              style={{ fontSize: '.8rem' }}>
                                              {translate(
                                                'validation_facility_details.fields_error_message',
                                              ) +
                                                questionnaire?.displayName.toLowerCase()}
                                            </span>
                                          )}
                                        </>
                                      ) : questionnaire?.inputType ===
                                        APP_CUSTOM_FIELD_INPUT_TYPES.DATE ? (
                                        <>
                                          <Label>
                                            {questionnaire?.displayName}
                                          </Label>
                                          <div className="ant-picker-style">
                                            <DatePicker
                                              value={
                                                questionnaire?.value?.length
                                                  ? moment(
                                                      questionnaire?.value[0],
                                                    )
                                                  : null
                                              }
                                              onChange={date =>
                                                onDateChange(
                                                  date,
                                                  questionnaire?.id,
                                                )
                                              }
                                              format={
                                                DATE_TIME_FORMATS.DD_MM_YYYY
                                              }
                                              allowClear={false}
                                              style={{
                                                width: '100%',
                                                height: '52px',
                                                borderRadius: '4px',
                                                borderColor:
                                                  questionnaire?.error
                                                    ? 'red'
                                                    : undefined,
                                              }}
                                              placeholder="dd-mm-yyyy"
                                            />
                                          </div>
                                          {questionnaire?.error && (
                                            <span
                                              className="text-danger"
                                              style={{ fontSize: '.8rem' }}>
                                              {translate(
                                                'validation_facility_details.fields_error_message',
                                              ) +
                                                questionnaire?.displayName.toLowerCase()}
                                            </span>
                                          )}
                                        </>
                                      ) : questionnaire?.inputType ===
                                        APP_CUSTOM_FIELD_INPUT_TYPES.ATTACHMENT ? (
                                        <>
                                          <Label>
                                            {questionnaire?.displayName} :
                                          </Label>
                                          <div className="file-drop-area">
                                            <span className="file-msg"></span>
                                            <div className="d-flex attachment-conatiner">
                                              <span className="file-fake-button">
                                                choose File
                                              </span>
                                              <span className="fake-btn-text">
                                                {fetchImageName(
                                                  questionnaire?.id,
                                                )
                                                  ? fetchImageName(
                                                      questionnaire?.id,
                                                    )
                                                  : 'No file chosen'}
                                              </span>
                                            </div>
                                            <input
                                              onChange={handleImagePick(
                                                'image',
                                                questionnaire?.id,
                                              )}
                                              accept="image/*"
                                              id="logoUrl"
                                              className="file-input"
                                              type="file"
                                            />
                                            <input
                                              // onChange={handleImagePick}
                                              accept="image/*"
                                              id="logoUrl"
                                              type="hidden"
                                              name="fileHidden"
                                            />
                                          </div>

                                          {questionnaire?.value?.length ? (
                                            <span className="table-text"></span>
                                          ) : (
                                            ''
                                          )}
                                        </>
                                      ) : null}
                                    </Col>
                                  </>
                                );
                              },
                            )}
                          </Row>
                        </Col>
                      )}
                      <Col md={12}>
                        <Row className="d-flex justify-content-center">
                          {IsPermittedAction(
                            PERMISSION_ACTIONS.SET_VENDOR_SPECIALITY_QUESTIONNAIRES,
                            authorities,
                            permissionList,
                          ) &&
                            IsPermittedAction(
                              PERMISSION_ACTIONS.UPDATE_VENDOR_SPECIALITY_QUESTIONNAIRES,
                              authorities,
                              permissionList,
                            ) && (
                              <Col md={2}>
                                {isConsultationEdit && (
                                  <Button
                                    className="button--appointmakement px-3"
                                    color="dark"
                                    Type="submit">
                                    Save
                                  </Button>
                                )}
                              </Col>
                            )}
                        </Row>
                        ,
                      </Col>
                    </AvForm>
                  </Col>
                ) : activeTab === VIDEO_CALL ? (
                  <Col>
                    <OnlineVideoCall
                      appointmentId={appointmentId}
                      setUsers={setUsers}
                      users={users}
                      handleCloseVideo={handleCloseVideo}
                      doctor={consultationDetails?.header?.doctor}
                      ready={ready}
                      callStatus={callStatus}
                      tracks={tracks}
                      setAudioVideoTracks={setAudioVideoTracks}
                      consultationDetails={consultationDetails.header}
                      setCallStatus={setCallStatus}
                      videoStarted={videoStarted}
                      start={videoCallStart}
                      setStart={setVideoCallStart}
                      remoteAudio={remoteAudio}
                      remoteVideo={remoteVideo}
                      trackState={trackState}
                      setTrackState={setTrackState}
                    />
                  </Col>
                ) : activeTab === PRESCRIPTION ? (
                  <Col>
                    <PrescriptionDetail
                      doctor={assignedDoctor}
                      appointmentId={appointmentId}
                      consultationDetails={consultationDetails.header}
                      consultationType={consultationType}
                      historyLength={historyLength}
                    />
                  </Col>
                ) : activeTab === LABORATORY ? (
                  <Col>
                    <LaboratoryDetail
                      doctor={assignedDoctor}
                      appointmentId={appointmentId}
                      consultationDetails={consultationDetails.header}
                      historyLength={historyLength}
                    />
                  </Col>
                ) : activeTab === MEDICAL_HISTORY ? (
                  <Col>
                    <MedicalHistory
                      petId={petId}
                      appointmentId={appointmentId}
                    />
                  </Col>
                  ) : activeTab === REMINDER ? (
                    <Col>
                      <Reminder  appointmentId={appointmentId}/>
                    </Col>
                ) : (
                  <Col />
                )}
              </Col>
            )}
          </div>
        </div>
        {!videoStarted &&
          status !== APPOINTMENT_STATUS.COMPLETED &&
          status !== APPOINTMENT_STATUS.CANCELLED &&
          renderCallExceptionButtons()}

        <CallConfirmation
          appointmentId={appointmentId}
          isShowConfirmationModal={isShowConfirmationModal}
          setIsShowConfirmationModal={setIsShowConfirmationModal}
          setCallStatus={setCallStatus}
        />

        {showModal && attachmentUrl && (
          <AttachmentViewer
            show={showModal}
            onHide={handleHideModal}
            toggle={toggle}
            title="Attachment"
            pdfFile={attachmentUrl}
          />
        )}
      </Col>
    </>
  );
});

const mapStateToProps = ({
  authentication,
  user,
  videoConsultation,
  master,
}: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loading: user.loading,
  weekDetail: videoConsultation.weekDetail,
  doctors: videoConsultation.doctors,
  appointments: videoConsultation.appointments,
  consultationDetails: videoConsultation.consultationDetails,
  initiateVideoCallResponse: videoConsultation.initiateVideoCallResponse,
  petTypes: master.petTypeVendor,
  petBreeds: master.petBreedPetType,
  editPetDetailResponse: videoConsultation.editPetDetailResponse,
  specialities: videoConsultation.specialities,
  questionnaireResponse: videoConsultation.questionnaireResponse,
  createQuestionnaireResponse: videoConsultation.createQuestionnaireResponse,
  editQuestionnaireResponse: videoConsultation.editQuestionnaireResponse,
});

const mapDispatchToProps = {
  fetchConsultationDetails,
  initiateVideoCall,
  updateAppointmentStatus,
  fetchPetTypeVendor,
  fetchBreedByPetType,
  editPetDetail,
  getSpeciality,
  getQuestionnaire,
  uploadDocumet,
  createQuestionnaire,
  editQuestionnaire,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationTab);
