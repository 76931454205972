import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import {
  BrowserRouter as Router,
  Route as DefaultRoute,
  Switch
} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Container } from 'reactstrap';
import { AUTHORITIES } from '../../../config/constants';
import { hasAnyAuthority } from '../../auth/private-route';
import ErrorBoundary from '../../error/error-boundary';
import { IRootState } from '../../reducers';
import { getSession, logout } from '../../reducers/authentication';
import { setLocale } from '../../reducers/locale';
import Header from '../header/header';
import './styles.scss';

export const DefaultLayout = (props) => {
  const {
    children,
    sideDrawerOpen,
    isAuthenticated,
    backdropClickHandler,
  } = props;

  return (
    !isAuthenticated ?
      <div id="default-layout" className="App" >
        <Container fluid className="p-0">
          <ToastContainer 
           position={toast.POSITION.TOP_RIGHT}
           className='toastify-container'
           toastClassName='toastify-toast'
           autoClose={2000}
           hideProgressBar={false}
           newestOnTop={false}
           closeOnClick
           rtl={false}
           pauseOnFocusLoss
           draggable
           pauseOnHover
          />
          <ErrorBoundary>
            <div className="view-default-routes" >
              {children}
            </div>
          </ErrorBoundary>
        </Container>
      </div> 
      : <Redirect to={{ pathname: '/video-consultation', state: { from: props.location } }} />
  )
};

const mapStateToProps = ({ authentication, locale, user }: IRootState) => ({
  currentLocale: locale?.currentLocale,
  isAuthenticated: authentication?.isAuthenticated,
  isAdmin: hasAnyAuthority(authentication?.account?.authorities, [
    AUTHORITIES.ADMIN,
  ]),
});

const mapDispatchToProps = { getSession, setLocale, logout };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);