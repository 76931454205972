import { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { IRootState } from '../../shared/reducers';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row, Table } from 'reactstrap';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faRotateLeft,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { fetchCustomerList } from '../../reducers/user.reducer';
import './styles.scss';
import Pagination from '@mui/material/Pagination';
import { ITEMS_PER_PAGE } from '../../shared/util/pagination.constants';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { getAppointmentList } from '../../reducers/appointment.reducer';
import { getDownloadAppointmentReport } from '../../reducers/appointment.reducer';
import { useTable } from 'react-table';
import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import {
  makeStyles,
  TextField,
  Tooltip,
  TooltipProps,
} from '@material-ui/core';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import moment from 'moment-timezone';
import {
  fetchPetTypeVendor,
  getSpecialityList,
  getPetBreed,
} from '../../reducers/master.reducer';
import Select, {
  components,
  PlaceholderProps,
  OptionProps,
} from 'react-select';
import { fetchDoctors } from '../../reducers/video-consultation-reducer';
import { getServices } from '../../reducers/plan.reducer';
import {
  CUSTOMER_APPOINTMENT_MODE,
  DATE_TIME_FORMATS,
} from '../../config/constants';
import { CSVLink } from 'react-csv';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';
import { DatePicker } from 'antd';

export interface ICustomerListProps
  extends StateProps,
  DispatchProps,
  RouteComponentProps<{ url: string }> { }

export const AppointmentReport = (props: ICustomerListProps) => {
  const history = useHistory();
  const {
    appointmentList,
    totalCount,
    loading,
    doctors,
    services,
    specialityList,
    petTypes,
    petBreeds,
    customerList,
    downloadAppointmentReport,
  } = props;

  const appointmentListInDateFormat = appointmentList?.map(item => {
    return {
      ...item,
      appointmentDate: moment(item?.appointmentDate).format('DD-MM-YYYY'),
    };
  });

  const [searchForm, setSearchForm] = useState();
  const [encodedSearchKey, setEncodedSearchKey] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  let filteredArray = [] as any;
  const NUMBER_OF_LIMIT_TAGS = 3;
  const [startDate, setStartDate] = useState(moment(new Date())) as any;
  const [endDate, setEndDate] = useState(moment(new Date())) as any;
  const [doctorSelect, setDoctorSelect] = useState([]) as any;
  const [specialitySelect, setSpecialitySelect] = useState([]) as any;
  const [petTypeSelect, setPetTypeSelect] = useState([]) as any;
  const [petBreedSelect, setPetBreedSelect] = useState([]) as any;
  const [customerSelect, setCustomerSelect] = useState([]) as any;
  const [modeSelect, setModeSelect] = useState([]) as any;
  const [filterDoctorName, setFilterDoctorName] = useState() as any;
  const [filterDoctorId, setFilterDoctorId] = useState() as any;
  const [filterSpecialityId, setFilterSpecialityId] = useState() as any;
  const [filterSpecialityName, setFilterSpecialityName] = useState() as any;
  const [filterPetTypeId, setFilterPetTypeId] = useState() as any;
  const [filterPetTypeName, setFilterPetTypeName] = useState() as any;
  const [filterPetBreedId, setFilterPetBreedId] = useState() as any;
  const [filterPetBreedName, setFilterPetBreedName] = useState() as any;
  const [filterConsultationStatus, setFilterConsultationStatus] =
    useState() as any;
  const [filterConsultationStatusLabel, setFilterConsultationStatusLabel] =
    useState() as any;
  const [filterConsultationType, setFilterConsultationType] = useState(
    '',
  ) as any;
  const [filterConsultationTypeLabel, setFilterConsultationTypeLabel] =
    useState() as any;
  const [filterConsultationMode, setFilterConsultationMode] = useState(
    '',
  ) as any;
  const [filterConsultationModeLabel, setFilterConsultationModeLabel] =
    useState() as any;
  const [serviceId, setServiceId] = useState(0) as any;
  const [isDownload, setIsDownload] = useState(false);
  const csvRef = useRef<any>();
  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );
  const CONSULTATION_TYPE = [
    { label: 'In Patient', value: 'In+Patient' },
    { label: 'Out Patient', value: 'Out+Patient' },
    { label: 'Emergency', value: 'Emergency' },
    { label: 'Procedure', value: 'Procedure' },
    { label: 'Surgery', value: 'Surgery' },
  ];

  const CONSULTATION_STATUS = [
    { label: 'Open', value: 'OPEN' },
    { label: 'Completed', value: 'COMPLETED' },
    { label: 'Cancelled', value: 'CANCELLED' },
    { label: 'Missed', value: 'MISSED' },
    { label: 'Pending', value: 'PENDING' },
    { label: 'Declined', value: 'DECLINED' },
    { label: 'Incomplete', value: 'INCOMPLETE' },
  ];

  useEffect(() => {
    setDoctorSelect([]);
    setPetBreedSelect([]);
    setCustomerSelect([]);
    !doctorSelect?.length && props.fetchDoctors();
    props.getServices();
    !petBreedSelect?.length && props.getPetBreed({ page: 0, size: 5 });
    !customerSelect?.length && props.fetchCustomerList({ page: 0, size: 5 });
  }, []);

  useEffect(() => {
    setServiceId(services[0]?.id);
  }, [services]);

  useEffect(() => {
    CUSTOMER_APPOINTMENT_MODE?.length !== 0 &&
      CUSTOMER_APPOINTMENT_MODE?.map(mode => {
        modeSelect.push({
          value: mode,
          label: mode,
        });
      });
  }, [CUSTOMER_APPOINTMENT_MODE]);

  useEffect(() => {
    if (serviceId) {
      props.getSpecialityList({ serviceId });
      props.fetchPetTypeVendor(serviceId);
    }
  }, [serviceId]);

  useEffect(() => {
    specialityList?.length !== 0 &&
      specialitySelect?.length === 0 &&
      specialityList?.map(speciality => {
        specialitySelect.push({
          value: speciality?.id,
          label: speciality?.name,
        });
      });
  }, [specialityList]);

  useEffect(() => {
    petTypes?.length !== 0 &&
      petTypeSelect?.length === 0 &&
      petTypes?.map(petType => {
        petTypeSelect.push({
          value: petType?.id,
          label: petType?.name,
        });
      });
  }, [petTypes]);

  useEffect(() => {
    if (customerList?.length !== 0) {
      setCustomerSelect(
        customerList?.map(customer => {
          return {
            value: customer?.id,
            label: customer?.name,
          };
        }),
      );
    }
  }, [customerList]);

  useEffect(() => {
    if (petBreeds?.length !== 0)
      setPetBreedSelect(
        petBreeds?.map(petBreed => {
          return {
            value: petBreed?.id,
            label: petBreed?.name,
          };
        }),
      );
  }, [petBreeds]);

  useEffect(() => {
    doctors?.length !== 0 &&
      doctorSelect?.length === 0 &&
      doctors?.map(doctor => {
        doctorSelect.push({
          value: doctor?.id,
          label: doctor?.name,
        });
      });
  }, [doctors]);

  useEffect(() => {
    setNumberOfPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
  }, [appointmentList]);

  useEffect(() => {
    setCurrentPage(1);
    appointmentReportApi(1);
  }, [
    filterConsultationMode,
    filterConsultationType,
    filterConsultationStatus,
    filterDoctorId,
    filterPetBreedId,
    filterPetTypeId,
    filterSpecialityId,
    searchForm,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    appointmentReportApi(currentPage);
  }, [currentPage]);

  const appointmentReportApi = page => {
    {
      startDate &&
        endDate &&
        props?.getAppointmentList({
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
          petTypeId: filterPetTypeId,
          breedId: filterPetBreedId,
          specialityId: filterSpecialityId,
          status: filterConsultationStatus,
          doctorId: filterDoctorId,
          consultationType: filterConsultationType,
          type: filterConsultationMode,
          search: encodedSearchKey,
          page: page - 1,
          size: ITEMS_PER_PAGE,
        });
    }
  };

  const Placeholder = (props: PlaceholderProps<any>) => {
    return <components.Placeholder {...props} />;
  };

  const [tooltipClose, setTooltipClose] = useState(true);

  const onPaginationChange = (e, page) => {
    setCurrentPage(page);
  };

  const searchHandler = event => {
    event.persist();
    setSearchForm(event?.target?.value);
    const searchKey = encodeURIComponent(event?.target?.value);
    setEncodedSearchKey(searchKey);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        show: true,
      },
      {
        Header: 'Patient Id',
        accessor: 'patientId',
        show: true,
      },
      {
        Header: 'Speciality',
        accessor: 'speciality',
        show: true,
      },
      {
        Header: 'Date',
        accessor: 'appointmentDate',
        show: true,
      },
      {
        Header: 'Consultation Type',
        accessor: 'consultationType',
        show: true,
      },
      {
        Header: 'Mode',
        accessor: 'type',
        show: true,
      },
      {
        Header: 'Name',
        accessor: 'petName',
        show: true,
      },
      {
        Header: 'Pet Type',
        accessor: 'petType',
        show: true,
      },
      {
        Header: 'Breed',
        accessor: 'breed',
        show: true,
      },
      {
        Header: 'Doctor',
        accessor: 'doctorName',
        show: true,
      },
      {
        Header: 'Customer',
        accessor: 'customer',
        show: true,
      },
      {
        Header: 'Phone',
        accessor: 'customerMobile',
        show: true,
      },
      {
        Header: 'Payment Status',
        accessor: 'paymentStatus',
        show: true,
      },
      {
        Header: 'Paid Amount',
        accessor: 'paidAmount',
        show: true,
      },
      {
        Header: 'Consultation Status',
        accessor: 'status',
        show: true,
      },
    ],
    [],
  );

  const useStyles = makeStyles(theme => ({
    autocomplete: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#FB8886',
        },
        '&:hover fieldset': {
          borderColor: '#FB8886',
        },
      },
      '& .MuiChip-root': {
        fontFamily: 'Poppins',
        backgroundColor: '#51A095',
        color: 'white',
      },
      '& .MuiChip-deleteIcon': {
        color: 'white',
      },
    },
  }));

  const [columnsList, setColumnsList] = useState(columns) as any;
  const [appointmentListColumnFilter, setAppointmentListColumnFilter] =
    useState([]) as any;
  const [selectedColumns, setSelectedColumns] = useState(columns) as any;
  const columnsOption = columns;
  const classes = useStyles();

  useEffect(() => {
    let temp = appointmentListInDateFormat?.map(item => {
      let filterAppoinment = {};
      columnsList?.map((column: any) => {
        filterAppoinment[column?.Header] = item[column?.accessor];
      });
      return filterAppoinment;
    });
    setAppointmentListColumnFilter(temp);
  }, [columnsList, appointmentList]);

  useEffect(() => {
    setColumnsList(selectedColumns);
  }, [selectedColumns]);

  const getDownloadData = () => {
    startDate &&
      endDate &&
      props?.getDownloadAppointmentReport({
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        petTypeId: filterPetTypeId,
        breedId: filterPetBreedId,
        specialityId: filterSpecialityId,
        status: filterConsultationStatus,
        doctorId: filterDoctorId,
        consultationType: filterConsultationType,
        type: filterConsultationMode,
        search: encodedSearchKey,
      });
  };

  useEffect(() => {
    if (isDownload) {
      if (csvRef?.current) {
        csvRef?.current?.link.click();
      }
      setIsDownload(false);
    }
  }, [downloadAppointmentReport]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    getToggleHideAllColumnsProps,
  } = useTable({
    columns: columnsList,
    data:
      appointmentListInDateFormat?.length > 0
        ? appointmentListInDateFormat
        : [],
  });

  const onAppointmentDetailScreen = row => {
    const appointmentId = row?.values?.id;
    window.open(`/video-consultation/${appointmentId}/consultation-detail`);
  };

  const resetFilter = () => {
    setFilterSpecialityId('');
    setFilterSpecialityName('');
    setFilterDoctorId('');
    setFilterDoctorName('');
    setFilterPetTypeId('');
    setFilterPetTypeName();
    setFilterPetBreedId('');
    setFilterPetBreedName('');
    setFilterConsultationStatus('');
    setFilterConsultationStatusLabel('');
    setFilterConsultationType('');
    setFilterConsultationMode('');
  };

  const renderAutoCompleteField = () => {
    return (
      <Autocomplete
        size="small"
        limitTags={NUMBER_OF_LIMIT_TAGS}
        multiple
        id="column-visibility"
        options={columns}
        getOptionLabel={option => option.Header}
        filterSelectedOptions
        disableCloseOnSelect
        value={selectedColumns}
        onChange={(event, newValue) => {
          setSelectedColumns(newValue);
        }}
        renderInput={params => (
          <TextField
            className="z-index-autocomplete"
            {...params}
            variant="outlined"
          />
        )}
        className={classes.autocomplete}
      />
    );
  };

  const optionsWithTooltip = (props: OptionProps<any>) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <Tooltip title={data?.label}>
          <div style={{ width: '100%', height: '100%' }}>
            <span>{data?.label}</span>
          </div>
        </Tooltip>
      </components.Option>
    );
  };

  const onCustomerKeyPress = e => {
    props.fetchCustomerList({
      page: 0,
      size: 5,
      search: e,
    });
  };

  const onCustomerKeyChange = e => {
    props.getSpecialityList({ serviceId, search: e });
  };

  const onBreedsKeyChange = e => {
    props.getPetBreed({ search: e });
  };

  const onDownloadClick = () => {
    setIsDownload(true);
    getDownloadData();
  };

  return (
    <div>
      <Row className="ps-4 pe-5 py-2" style={{ backgroundColor: '#E5E5E5' }}>
        <Col md="10">
          <h6 className="text-greyTeal mb-0 px-2 py-3">
            {translate('appointment-report.head')}
          </h6>
        </Col>
      </Row>
      <Row className="d-flex pt-3 justify-content-between p-3 pb-1">
        <Col md={4}>
          <AvForm>
            <Row className="d-flex justify-content-between">
              <Col md={6}>
                <div style={{ textAlign: 'center' }} className="mb-1">
                  <Label> {translate('appointment-report.start_date')}</Label>
                </div>
                  <DatePicker
                    defaultValue={startDate}
                    onChange={date => {
                      setStartDate(moment(date).format('YYYY-MM-DD'));
                    }}
                    format={DATE_TIME_FORMATS.DD_MM_YYYY}
                    allowClear={false}
                    style={{width:'100%',height:'70%',borderRadius:'4px'}}
                  />
              </Col>
              <Col md={6}>
                <div style={{ textAlign: 'center' }} className="mb-1">
                  <Label> {translate('appointment-report.end_date')}</Label>
                </div>
                <DatePicker
                  defaultValue={endDate}
                  onChange={date => {
                    setEndDate(moment(date).format('YYYY-MM-DD'));
                  }}
                  format={DATE_TIME_FORMATS.DD_MM_YYYY}
                  allowClear={false}
                  style={{width:'100%',height:'70%',borderRadius:'4px'}}
                />
              </Col>
            </Row>
          </AvForm>
        </Col>
        <Col md={2}>
          <div style={{ textAlign: 'center' }}>
            <Label>{translate('appointment-report.filter_speciality')}</Label>
          </div>
          <Tooltip
            title={
              filterSpecialityName && tooltipClose ? filterSpecialityName : ''
            }
            arrow>
            <div>
              <Select
                onMenuOpen={() => {
                  setTooltipClose(false);
                }}
                onMenuClose={() => setTooltipClose(true)}
                className="my-1 text"
                value={
                  filterSpecialityId && {
                    value: filterSpecialityId,
                    label: filterSpecialityName,
                  }
                }
                isClearable
                components={{ Option: optionsWithTooltip, Placeholder }}
                placeholder={'Speciality'}
                options={specialitySelect}
                onChange={value => {
                  setFilterSpecialityId(value?.value);
                  setFilterSpecialityName(value?.label);
                }}
                onInputChange={event => onCustomerKeyChange(event)}
              />
            </div>
          </Tooltip>
        </Col>
        <Col md={2} />
        <Col md={2}>
          <div className="fa-search position-relative pt-4">
            <input
              className="form-control input-lg search-placeholder pt-2"
              type="text"
              value={searchForm}
              onChange={searchHandler}
              placeholder="Customer/Phone/PatientId"></input>
            <FontAwesomeIcon
              icon={faSearch}
              className="position-absolute"
              style={{ top: '38px', left: '5px' }}
            />
          </div>
        </Col>
        <Col
          md={2}
          className="d-flex justify-content-between pt-4"
          style={{ gap: '2px' }}>
          <div>
            <Button color="danger" className="text-light" onClick={resetFilter}>
              <Tooltip title="Clear Filter">
                <FontAwesomeIcon icon={faRotateLeft} />
              </Tooltip>
            </Button>
          </div>
          <div className="d-flex justify-content-between button-up">
            <CSVLink
              ref={csvRef}
              data={downloadAppointmentReport}
              filename={'data.csv'}
            />
            <Button
              onClick={onDownloadClick}
              color="danger"
              className="text-light download-button"
              disabled={appointmentListColumnFilter?.length > 0 ? false : true}>
              <FontAwesomeIcon
                icon={faDownload}
                style={{ paddingTop: '3px' }}
              />{' '}
              &nbsp;
              <div className="download-text">
                {translate('appointment-report.download')}
              </div>
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between p-3">
        <Col md={2}>
          <div style={{ textAlign: 'center' }}>
            <Label>{translate('appointment-report.filter_doctor')}</Label>
          </div>
          <Tooltip
            title={filterDoctorName && tooltipClose ? filterDoctorName : ''}
            arrow>
            <div>
              <Select
                className="my-1 text"
                value={
                  filterDoctorId && {
                    value: filterDoctorId,
                    label: filterDoctorName,
                  }
                }
                isClearable
                components={{ Option: optionsWithTooltip, Placeholder }}
                onMenuOpen={() => {
                  setTooltipClose(false);
                }}
                onMenuClose={() => setTooltipClose(true)}
                placeholder={'Doctor'}
                options={doctorSelect}
                onChange={value => {
                  setFilterDoctorId(value?.value);
                  setFilterDoctorName(value?.label);
                }}
              />
            </div>
          </Tooltip>
        </Col>
        <Col md={2}>
          <div style={{ textAlign: 'center' }}>
            <Label>{translate('appointment-report.filter_status')}</Label>
          </div>
          <Tooltip
            title={
              filterConsultationStatusLabel && tooltipClose
                ? filterConsultationStatusLabel
                : ''
            }
            arrow>
            <div>
              <Select
                className="my-1 text"
                value={
                  filterConsultationStatus && {
                    value: filterConsultationStatus,
                    label: filterConsultationStatusLabel,
                  }
                }
                isClearable
                components={{ Option: optionsWithTooltip, Placeholder }}
                placeholder={'Consultation Status'}
                options={CONSULTATION_STATUS}
                onChange={value => {
                  setFilterConsultationStatus(value?.value);
                  setFilterConsultationStatusLabel(value?.label);
                }}
                onMenuOpen={() => {
                  setTooltipClose(false);
                }}
                onMenuClose={() => setTooltipClose(true)}
                onInputChange={event => onCustomerKeyPress(event)}
              />
            </div>
          </Tooltip>
        </Col>
        <Col md={2}>
          <div style={{ textAlign: 'center' }}>
            <Label>
              {translate('appointment-report.filter_consultation_type')}
            </Label>
          </div>
          <Tooltip
            title={
              filterConsultationTypeLabel && tooltipClose
                ? filterConsultationTypeLabel
                : ''
            }
            arrow>
            <div>
              <Select
                className="my-1 text"
                value={
                  filterConsultationType && {
                    value: filterConsultationType,
                    label: filterConsultationTypeLabel,
                  }
                }
                isClearable
                components={{ Option: optionsWithTooltip, Placeholder }}
                placeholder={'Consultation Type'}
                options={CONSULTATION_TYPE}
                onChange={value => {
                  setFilterConsultationType(value?.value);
                  setFilterConsultationTypeLabel(value?.label);
                }}
                onMenuOpen={() => {
                  setTooltipClose(false);
                }}
                onMenuClose={() => setTooltipClose(true)}
              />
            </div>
          </Tooltip>
        </Col>
        <Col md={2}>
          <div style={{ textAlign: 'center' }}>
            <Label>
              {' '}
              {translate('appointment-report.filter_consultation_mode')}
            </Label>
          </div>
          <Tooltip
            title={
              filterConsultationModeLabel && tooltipClose
                ? filterConsultationModeLabel
                : ''
            }
            arrow>
            <div>
              <Select
                className="my-1 text"
                value={
                  filterConsultationMode && {
                    value: filterConsultationMode,
                    label: filterConsultationModeLabel,
                  }
                }
                isClearable
                components={{ Option: optionsWithTooltip, Placeholder }}
                placeholder={'Consultation Type'}
                options={modeSelect}
                onChange={value => {
                  setFilterConsultationMode(value?.value);
                  setFilterConsultationModeLabel(value?.label);
                }}
                onMenuOpen={() => {
                  setTooltipClose(false);
                }}
                onMenuClose={() => setTooltipClose(true)}
              />
            </div>
          </Tooltip>
        </Col>
        <Col md={2}>
          <div style={{ textAlign: 'center' }}>
            <Label>{translate('appointment-report.filter_pet_type')}</Label>
          </div>
          <Tooltip
            title={filterPetTypeName && tooltipClose ? filterPetTypeName : ''}
            arrow>
            <div>
              <Select
                className="my-1 text"
                value={
                  filterPetTypeId && {
                    value: filterPetTypeId,
                    label: filterPetTypeName,
                  }
                }
                components={{ Option: optionsWithTooltip, Placeholder }}
                placeholder={'Pet Type'}
                isClearable
                options={petTypeSelect}
                onChange={value => {
                  setFilterPetTypeId(value?.value);
                  setFilterPetTypeName(value?.label);
                }}
                onMenuOpen={() => {
                  setTooltipClose(false);
                }}
                onMenuClose={() => setTooltipClose(true)}
              />
            </div>
          </Tooltip>
        </Col>
        <Col md={2}>
          <div style={{ textAlign: 'center' }}>
            <Label>{translate('appointment-report.filter_pet_breed')}</Label>
          </div>
          <Tooltip
            title={filterPetBreedName && tooltipClose ? filterPetBreedName : ''}
            arrow>
            <div>
              <Select
                className="my-1 text"
                value={
                  filterPetBreedId && {
                    value: filterPetBreedId,
                    label: filterPetBreedName,
                  }
                }
                isClearable
                components={{ Option: optionsWithTooltip, Placeholder }}
                placeholder={'Pet Breed'}
                options={petBreedSelect}
                onChange={value => {
                  setFilterPetBreedId(value?.value);
                  setFilterPetBreedName(value?.label);
                }}
                onMenuOpen={() => {
                  setTooltipClose(false);
                }}
                onMenuClose={() => setTooltipClose(true)}
                onInputChange={event => onBreedsKeyChange(event)}
              />
            </div>
          </Tooltip>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center pt-0">
        <Col md={4}></Col>
        <Col md={4} id="appointment-report">
          <div style={{ textAlign: 'center' }}>
            <Label>{translate('appointment-report.column_visibility')}</Label>
          </div>
          {renderAutoCompleteField()}
        </Col>
        <Col md={4}></Col>
      </Row>
      <div className={!appointmentListInDateFormat?.length ? 'd-flex' : ''}>
        {appointmentListInDateFormat?.length ? (
          <>
            <Row className="justify-content-center">
              <Col
                md={11}
                className="table-responsive shadow border border-1 rounded custom-table ms-4 me-4 my-5 ">
                <table className="table-width" {...getTableProps()}>
                  <thead>
                    {headerGroups?.map(headerGroup => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="table-header">
                        <th style={{ whiteSpace: 'nowrap' }}>
                          {translate('appointment-report.sl_no')}
                        </th>
                        {headerGroup.headers?.map(column => (
                          <th
                            {...column.getHeaderProps()}
                            style={{
                              paddingRight: '8px',
                              whiteSpace: 'nowrap',
                            }}>
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows?.map((row, i) => {
                      const serialNumber =
                        currentPage === 1
                          ? i + 1
                          : i + 1 + (currentPage - 1) * ITEMS_PER_PAGE;
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className="table-row-border clickable"
                          onClick={() => onAppointmentDetailScreen(row)}>
                          <td>
                            {serialNumber <= totalCount ? serialNumber : ''}
                          </td>
                          {row.cells?.map(cell => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                style={{
                                  whiteSpace: 'nowrap',
                                  paddingRight: '8px',
                                }}>
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Col>
              <Col md={2}></Col>
            </Row>
            {totalCount && appointmentList?.length ? (
              <Pagination
                count={numberOfPages}
                onChange={onPaginationChange}
                variant="outlined"
                shape="rounded"
                className="d-flex justify-content-center mb-4"
              />
            ) : null}
          </>
        ) : (
          <>
            {loading && <FontAwesomeIcon icon={faSpinner} spin />}
            {!loading && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  fontSize: 18,
                  margin: '7%',
                  marginLeft: '40%',
                  color: 'GrayText',
                }}>
                <Translate contentKey="appointment-report.no_appointment_list">
                  No appointments to list
                </Translate>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  vendorUser,
  user,
  appointment,
  videoConsultation,
  plan,
  master,
}: IRootState) => ({
  appointmentList: appointment.appointmentList,
  totalCount: appointment.totalCount,
  loading: vendorUser.loading,
  doctors: videoConsultation.doctors,
  services: plan.services,
  specialityList: master.specialities,
  petTypes: master.petTypeVendor,
  petBreeds: master.petBreeds,
  customerList: user.customerList,
  downloadAppointmentReport: appointment.downloadAppointmentReport,
});

const mapDispatchToProps = {
  getAppointmentList,
  fetchDoctors,
  getServices,
  getSpecialityList,
  fetchPetTypeVendor,
  getPetBreed,
  fetchCustomerList,
  getDownloadAppointmentReport,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentReport);
