import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Card, Col, Container, Label, Row } from 'reactstrap';
import { preRegister } from '../../reducers/user.reducer';
import { IRootState } from '../../shared/reducers';
import {
  AvForm,
  AvField,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';
import { getPlans } from '../../reducers/plan.reducer';
import './style.scss';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { translate, Translate } from 'react-jhipster';
import leftBgImg from '../../assets/images/left-container-bg.png';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {
  fetchCities,
  fetchCountries,
  fetchStates,
} from '../../reducers/master.reducer';
import {
  HTTP_CREATED_RESPONSE,
  PHONE_NUMBER_LENGTH,
} from '../../config/constants';
import { useHistory } from 'react-router';
import Button from '../components/button';
import { AppLogo } from '../login/components/app-logo';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export const PreRegistration = (props: IPageProps) => {
  const { plans } = props;
  const [selectedPlans, setSelectedPlans] = useState([] as any);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [countries, setCountries] = useState([] as any[]);
  const [states, setStates] = useState([] as any[]);
  const [cities, setCities] = useState([] as any[]);
  const history = useHistory();

  useEffect(() => {
    props.getPlans();
    props?.fetchCountries({});
  }, []);

  useEffect(() => {
    if (Array.isArray(props?.countries)) {
      setCountries(props?.countries);
    }
  }, [props?.countries]);

  useEffect(() => {
    if (Array.isArray(props?.states)) {
      setStates(props?.states);
    }
  }, [props?.states]);

  useEffect(() => {
    if (Array.isArray(props?.cities)) {
      setCities(props?.cities);
    }
  }, [props?.cities]);

  const checkIfSelected = id => {
    if (selectedPlans.find(p => p?.id === id)) {
      return true;
    }
    return false;
  };

  const handleAddService = plan => {
    const plansTemp = JSON.parse(JSON.stringify(selectedPlans));
    const index = plansTemp.findIndex(p => p.id === plan.id);
    index > -1 ? plansTemp.splice(index, 1) : plansTemp.push(plan);
    setSelectedPlans(plansTemp);
  };

  useEffect(() => {
    if (plans.length === 1) {
      setSelectedPlans([plans[0]]);
    }
  }, [plans]);

  const handleValidSubmit = (event, values) => {
    if (!selectedPlans?.length) {
      toast.error(translate('form.validation.min_plan'));
    } else {
      const entity = {
        ...values,
        serviceIds: selectedPlans.map(p => p.id),
      };
      setRegisterLoading(true);
      props.preRegister(entity);
    }
  };

  useEffect(() => {
    if (registerLoading) {
      if (props?.preRegisterResponse.status === HTTP_CREATED_RESPONSE) {
        history.push('/pre-registration-success');
      }
      setRegisterLoading(false);
    }
  }, [props.history, props?.preRegisterResponse]);

  return (
    <Row className="w-100 mx-0" style={{ height: '100vh' }}>
      <Col xs="12" md="4" className="px-0 h-100 sm-h-20">
        <div className="left-container">
          <div className="d-flex flex-column h-100 justify-content-center align-items-center">
            <div className="logo-container">
              <AppLogo />
            </div>
            <h2 className="text-white pr-5">
              <Translate contentKey="pages.vendor_pre_registration">
                Vendor Pre Registration
              </Translate>
            </h2>
          </div>
          <img src={leftBgImg} alt="left-bg"></img>
        </div>
      </Col>
      <Col xs="12" md="8" className="right-container pt-3 pt-sm-4">
        <div className="d-none d-sm-block">
          <h2>
            <Translate contentKey="pages.vendor_pre_registration">
              Vendor Pre Registration
            </Translate>
          </h2>
          <hr />
        </div>
        <AvForm model={{}} onValidSubmit={handleValidSubmit}>
          <Row className="mt-sm-5">
            <Col md="6">
              <Label for="contact">
                <Translate contentKey="form.register.name">
                  name / business name
                </Translate>
                <span className="text-danger">*</span>
              </Label>
              <AvField
                name="businessName"
                id="businessName"
                validate={{
                  required: {
                    errorMessage: translate(
                      'validation_registration.name_error_message',
                    ),
                  },
                }}
              />
            </Col>
            <Col md="6">
              <Label for="contact">
                <Translate contentKey="form.register.contact">
                  contact person
                </Translate>
                <span className="text-danger">*</span>
              </Label>
              <AvField
                name="contactPerson"
                id="contactPerson"
                validate={{
                  required: {
                    errorMessage: translate(
                      'validation_registration.name_error_message',
                    ),
                  },
                }}
              />
            </Col>
            <Col md="6">
              <Label for="mobile">
                <Translate contentKey="form.register.mobile">
                  mobile number
                </Translate>
                <span className="text-danger">*</span>
              </Label>
              <AvField
                name="mobileNo"
                id="mobileNo"
                type="number"
                validate={{
                  required: {
                    errorMessage: translate(
                      'validation_registration.number_error_message',
                    ),
                  },
                  minLength: {
                    value: `${PHONE_NUMBER_LENGTH}`,
                    errorMessage: translate(
                      'validation_registration.invalid_number_error_message',
                    ),
                  },
                  maxLength: {
                    value: `${PHONE_NUMBER_LENGTH}`,
                    errorMessage: translate(
                      'validation_registration.invalid_number_error_message',
                    ),
                  },
                }}
              />
            </Col>
            {/* commented for future */}
            {/* <Col md="6">
                  <Label for="landline">
                    <Translate contentKey="form.register.landline">
                      landline number
                    </Translate>
                  </Label>
                  <AvField
                    name="landline"
                    id="landline"
                    type="number"
                    maxLength="12"
                  />
                </Col> */}
            <Col md="6">
              <Label for="email">
                <Translate contentKey="form.register.email">email id</Translate>
                <span className="text-danger">*</span>
              </Label>
              <AvField
                name="email"
                type="email"
                validate={{
                  required: {
                    errorMessage: translate(
                      'validation_registration.email_error_message',
                    ),
                  },
                  email: {
                    errorMessage: translate(
                      'validation_registration.invalid_email_error_message',
                    ),
                  },
                }}
              />
            </Col>

            <Col md="6">
              <Label for="state">
                <Translate contentKey="form.register.country">
                  Country
                </Translate>
                <span className="text-danger">*</span>
              </Label>
              <AvField
                onChange={({ target }) => {
                  props?.fetchStates({
                    countryId: target?.value,
                  });
                }}
                type="select"
                name="countryId"
                id="countryId"
                validate={{
                  required: {
                    errorMessage: translate(
                      'validation_registration.country_error_message',
                    ),
                  },
                }}>
                <option value="" key="">Select Country</option>
                {countries?.map((country, index) => {
                  return (
                    <option key={index} value={country?.id}>
                      {country?.name}
                    </option>
                  );
                })}
              </AvField>
            </Col>
            <Col md="6">
              <Label for="state">
                <Translate contentKey="form.register.state">state</Translate>
                <span className="text-danger">*</span>
              </Label>
              <AvField
                onChange={({ target }) => {
                  props?.fetchCities({
                    stateId: target?.value,
                  });
                }}
                type="select"
                name="stateId"
                id="stateId"
                validate={{
                  required: {
                    errorMessage: translate(
                      'validation_registration.state_error_message',
                    ),
                  },
                }}>
                <option value="" key="">Select State</option>
                {states?.map((state, index) => {
                  return (
                    <option key={index} value={state?.id}>
                      {state?.name}
                    </option>
                  );
                })}
              </AvField>
            </Col>
            <Col md="6">
              <Label for="city">
                <Translate contentKey="form.register.city">city </Translate>
                <span className="text-danger">*</span>
              </Label>
              <AvField
                type="select"
                name="cityId"
                id="cityId"
                validate={{
                  required: {
                    errorMessage: translate(
                      'validation_registration.city_error_message',
                    ),
                  },
                }}>
                <option value="" key="">Select City</option>
                {cities?.map((city, index) => (
                  <option key={index} value={city?.id}>
                    {city?.name}
                  </option>
                ))}
              </AvField>
            </Col>
          </Row>
          <Row className="services-container">
            <Col lg="12">
              <h2>
                <Translate contentKey="placeholders.select_services">
                  Select services required
                </Translate>
              </h2>
              <hr />
              {!plans.length && (
                <Translate contentKey="placeholders.noServices">
                  No Services Found
                </Translate>
              )}
            </Col>
            {plans.map((plan, index) => (
              <Col lg="6" key={plan.id}>
                <Card
                  className={`${checkIfSelected(plan?.id) ? 'active' : ''}`}
                  onClick={() => handleAddService(plan)}>
                  <h5>
                    <FontAwesomeIcon
                      className={checkIfSelected(plan.id) ? '' : 'text-black'}
                      icon={checkIfSelected(plan.id) ? faCheckCircle : faCircle}
                    />
                  </h5>
                  <img src={plan.iconUrl} alt="plan-icon" />
                  <h6> {plan.name} </h6>
                  {/* commented for future */}
                  {/* {plan?.services?.map((service, i) => (
                        <p key={service.id}> {service.name} </p>
                      ))} */}
                </Card>
              </Col>
            ))}
          </Row>
          <Row className="mb-5 mb-sm-3 mt-2 mt-sm-5">
            <Col>
              <Button color="px-5 submit-btn">
                <Translate contentKey="placeholders.submit">Submit</Translate>
              </Button>
            </Col>
          </Row>
        </AvForm>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({
  authentication,
  user,
  plan,
  master,
}: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loading: user.loading,
  plans: plan.plans,
  preRegisterResponse: user?.preRegisterResponse,
  countries: master?.countries,
  states: master?.states,
  cities: master?.cities,
});

const mapDispatchToProps = {
  preRegister,
  getPlans,
  fetchCountries,
  fetchStates,
  fetchCities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PreRegistration);
