import { AUTH_TOKEN_KEY } from './../shared/reducers/authentication';
import { Storage } from 'react-jhipster';
import { toast } from 'react-toastify';
const config = {
  VERSION: process.env.VERSION,
};

export default config;

export const SERVER_API_URL = process.env.SERVER_API_URL;
export const SOCKET_URL = process.env.REACT_APP_MARS_SOCKET_URL || '';
export const API_URL = process.env.REACT_APP_MARS_SERVER_API_URL;
export const AGORA_APP_ID = '80b3a672d0cd485793f2861fcae325c4';

export const SENTRY_DSN = process.env.REACT_APP_MARS_SENTRY_DSN;

export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ROLE_VENDOR_ADMIN: 'ROLE_VENDOR_ADMIN',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const DATE_TIME_FORMATS = {
  HH_MM_SS_A: 'hh:mm:ss A',
  HH_MM_SS: 'hh:mm:ss',
  HH_MM: 'hh:mm',
  APP_LOCAL_DATE_FORMAT: 'DD/MM/YYYY',
  APP_LOCAL_DATE_TIME_FORMAT: 'YYYY-MM-DDTHH:mm',
  APP_LOCAL_DATE_TIME_FORMAT_Z: 'YYYY-MM-DDTHH:mm Z',
  DAY_NAME_FORMAT: 'dddd',
  DAY_NUMERIC_FORMAT: 'DD',
  APP_LOCAL_DATE_FORMAT_WITH_SPACE: 'DD MMMM YYYY',
  APP_LOCAL_DATE_FORMAT_WITH_COMMA: 'DD MMMM, YYYY',
  YYYY_MM_DD_WITH_HYPHEN: 'YYYY-MM-DD',
  TWENTY_FOUR_HH_MM: 'HH:mm',
  TWELVE_HH_MM_A: 'h:mm a',
  ZERO_HOUR_FORMAT: '00',
  TWENTY_FOUR_HOUR_FORMAT: '24',
  HH_MM_A: 'hh:mm A',
  DD_MM_YYYY:'DD-MM-YYYY'
};

export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
export const COLOR_LIGHT_GRAY = '#CCCCCC';
export const COLOR_WHITE = '#FFFFFF';
export const SM_SCREEN_BREAKPOINT = 992;
export const DAY_NUMBER_FORMAT = 'DD';
export const DAY_TEXT_FORMAT = 'ddd';
export const MONTH_NUMBER_FORMAT = 'MM';
export const INITIAL_TIME = '00:00';
export const TIME_FORMAT_AM = 'AM';
export const TOTAL_APPOINTMENT_SCHEDULE_HOURS = 24;
export const MINUTES_RANGE_PER_HOURS = 30;
export const PHONE_NUMBER_LENGTH = 10;
export const PHONE_NUMBER_VALIDATION_EXPRESSION = '^[0-9]{10}$';
export const OTP_MINIMUM_LENGTH = 6;
export const OTP_MAXIMUM_LENGTH = 6;
export const APPOINTMENT_RESCHEDULE_DAYS_RANGE = 7;
export const DEFAULT_NUMBER_OF_BOOKING_PER_SLOT = 3;
export const DISCOUNT_PERCENTAGE_BASE = 100;
export const PIN_CODE_LENGTH = 6;

export const TIME_TYPES = {
  FROM_TIME: 'fromTime',
  TO_TIME: 'toTime',
};

export const ALREADY_BOOKED_ERROR_KEY = 'already booked';
export const SERVICE_NOT_PAID = 'service-not-paid';
export const SUBSCRIPTION_NOT_ACTIVE = 'subscription_inactive';

export const Colors = {
  white: '#FFFFFF',
  beluga: '#F1F1F1',
  black: '#000',
  greyTeal: '#51A095',
  aquaHaze: '#EAF6F3',
  geraldine: '#FB8886',
  charcoalGrey: '#404040',
  davyGrey: '#565656',
  osloGrey: '#8E8E8E',
  mercury: '#E5E5E5',
  shuttleGrey: '#64676C',
  pageBackground: '#F6F6FC',
  darkRedColor: '#D30A0A',
  green: '#00B050',
  red: '#FF0000',
  yellow: '#FFD700',
  blue: '#0070C0',
};

export const REG_EX = {
  number: '/^[0-9]+$/',
  name: '^[a-zA-Z][a-z A-Z0-9]+$',
};

export const MONTHS = Array.from(Array(12).keys());

export const YEARS = Array.from(Array(20).keys());
export enum MEDICAL_HISTORY_STATUS {
  COMPLETED = 'COMPLETED',
}

export const MEDICINE_TIME = ['After Food', 'Before Food', 'With Food', 'N/A'];

export const UNASSIGNED = 'Unassigned';

export const WEEK_DAYS = [
  {
    day: 1,
    dayText: 'Monday',
    bookingPerSlot: 1,
    workingHours: [],
    isSelected: false,
  },
  {
    day: 2,
    dayText: 'Tuesday',
    bookingPerSlot: 1,
    workingHours: [],
    isSelected: false,
  },
  {
    day: 3,
    dayText: 'Wednesday',
    bookingPerSlot: 1,
    workingHours: [],
    isSelected: false,
  },
  {
    day: 4,
    dayText: 'Thursday',
    bookingPerSlot: 1,
    workingHours: [],
    isSelected: false,
  },
  {
    day: 5,
    dayText: 'Friday',
    bookingPerSlot: 1,
    workingHours: [],
    isSelected: false,
  },
  {
    day: 6,
    dayText: 'Saturday',
    bookingPerSlot: 1,
    workingHours: [],
    isSelected: false,
  },
  {
    day: 7,
    dayText: 'Sunday',
    bookingPerSlot: 1,
    workingHours: [],
    isSelected: false,
  },
];

export const APP_DATA_TYPES = {
  label: 'text',
  image: 'imageUrl',
};

export const APPOINTMENT_SCHEDULE_SHIFT_LABELS = [
  'Morning',
  'Afternoon',
  'Evening',
];

export const SHIFT_TIMES = {
  morning: {
    fromTime: '09:00',
    toTime: '13:00',
  },
  afternoon: {
    fromTime: '13:00',
    toTime: '17:00',
  },
  evening: {
    fromTime: '17:00',
    toTime: '21:00',
  },
};

export const HTTP_OK_RESPONSE = 200;
export const HTTP_CREATED_RESPONSE = 201;
export const HTTP_FAILURE_STATUS_CODE = 400;
export const INVALID_CREDENTIALS_MESSAGE_KEY = 'login.error.unauthorized';

export const VENDOR_SUBSCRIPTION_RENEWAL = 'VENDOR_SUBSCRIPTION_RENEWAL';
export const VENDOR_SUBSCRIPTION = 'VENDOR_SUBSCRIPTION';

export const getToken = () => {
  let userToken = Storage.local.get(AUTH_TOKEN_KEY, '');
  return userToken;
};

export const SLOT_STATUS_LABELS = {
  BOOKED: 'Booked',
  AVAILABLE: 'Available',
  UNAVAILABLE: 'Unavailable',
};

export const APP_CUSTOM_FIELD_INPUT_TYPES = {
  TEXT_BOX: 'TEXT_BOX',
  TEXT_AREA: 'TEXT_AREA',
  SINGLE_SELECT: 'SINGLE_SELECT',
  MULTI_SELECT: 'MULTI_SELECT',
  CHECK_BOX: 'CHECK_BOX',
  ATTACHMENT: 'ATTACHMENT',
  DATE: 'DATE',
};

export const APPOINTMENT_STATUS = {
  OPEN: 'OPEN',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  RESCHEDULED: 'RESCHEDULED',
  MISSED: 'MISSED',
  PENDING: 'PENDING',
  INCOMPLETE: 'INCOMPLETE',
};

export const SERVICE_STATUSES = {
  DRAFT: 'DRAFT',
  REQUEST_FOR_PUBLISH: 'REQUEST_FOR_PUBLISH',
  PUBLISHED: 'PUBLISHED',
  DEACTIVATED: 'DEACTIVATED',
  DEACTIVATED_BY_ADMIN: 'DEACTIVATED BY ADMIN',
};

export const FORM_ACTION_TYPES = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
};

export const PET_WEIGHT_UNIT = 'kg';

export const MEDIA_TYPES = { AUDIO: 'audio', VIDEO: 'video' };

export const filterPets = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'petType',
    label: 'Pet Type',
    children: [
      {
        value: 'dog',
        label: 'Dog',
      },
      {
        value: 'cat',
        label: 'Cat',
      },
      {
        value: 'bird',
        label: 'Bird',
      },
    ],
  },
  {
    value: 'breed',
    label: 'Breed',
  },
];

export const filterAppointment = [
  {
    value: 'petName',
    label: 'Pet Name',
  },
  {
    value: 'petType',
    label: 'Pet Type',
    children: [
      {
        value: 'dog',
        label: 'Dog',
      },
      {
        value: 'cat',
        label: 'Cat',
      },
      {
        value: 'bird',
        label: 'Bird',
      },
    ],
  },
  {
    value: 'breed',
    label: 'Breed',
  },
  {
    value: 'vetName',
    label: 'Vet Name',
  },
  {
    value: 'time',
    label: 'Time',
  },
  {
    value: 'appointmentStatus',
    label: 'Appointment Status',
  },
  {
    value: 'paymentStatus',
    label: 'Payment Status',
  },
];

export const CUSTOMER_PETS_FILTER = ['PetType', 'Breed'];
export const CUSTOMER_APPOINTMENTS_FILTER = [
  'Pet Name',
  'Pet Type',
  'Breed',
  'Doctor Name',
  'Appointment Status',
  'Payment Status',
  'Mode of Appointment',
];
export const PAYMENT_STATUS = ['FREE', 'OPEN', 'PAID', 'REFUND', 'FAILED'];
export const CUSTOMER_APPOINTMENT_STATUS = [
  'OPEN',
  'COMPLETED',
  'CANCELLED',
  'MISSED',
  'PENDING',
  'DECLINED',
  'INCOMPLETE',
  'ALL',
];
export const SESSION_EXPIRE_REDIRECTION_TIMEOUT = 2000;

export const CUSTOMER_CALL_STATUS = {
  CUSTOMER_IDLE: 'CUSTOMER_IDLE',
  CUSTOMER_CALL_NOTIFICATION_RECEIVED: 'CALL_NOTIFICATION_RECEIVED',
  CUSTOMER_CALL_ANSWERED: 'CALL_ANSWERED',
  CUSTOMER_CALL_ON_HOLD: 'CALL_ON_HOLD',
  CUSTOMER_CALL_DISCONNECTED: 'CALL_DISCONNECTED',
  CUSTOMER_CALL_DECLINED: 'CALL_DECLINED',
  CUSTOMER_CALL_MISSED: 'CALL_MISSED',
  VENDOR_CALL_DISCONNECTED: 'VENDOR_CALL_DISCONNECTED',
};

export const RAZORPAY_PAYMENT_TEST_KEY =
  process.env.REACT_APP_MARS_RAZORPAY_KEY;

export const PAYMENT_CURRENCY = 'INR';

export const PAYMENT_NAME = 'NetSta Minds Pvt. Ltd.';

export const PAYMENT_IMAGE = '/peditoh.png';

export const PAYMENT_DESCRIPTION = 'Your Vet-on-video';

export const PAYMENT_METHOD = {
  EMI: 'emi',
  PAYLATER: 'paylater',
  WALLET: 'wallet',
};

export const PAYMENT_AMOUNT_MULTIPLIER = 100;

export const PAYMENT_SUCCESS_MESSAGE = () => {
  return toast.success('Payment Successful');
};

export const MEDICINE_DIRECTION = [
  { value: '0-0-1', label: '0-0-1' },
  { value: '0-1-0', label: '0-1-0' },
  { value: '0-1-1', label: '0-1-1' },
  { value: '1-0-0', label: '1-0-0' },
  { value: '1-0-1', label: '1-0-1' },
  { value: '1-1-0', label: '1-1-0' },
  { value: '1-1-1', label: '1-1-1' },
];

export const CUSTOMER_APPOINTMENT_MODE = ['ONLINE', 'OFFLINE'];

export const CONSULTATION_TYPE = [
  { label: 'In Patient', value: 'In Patient' },
  { label: 'Out Patient', value: 'Out Patient' },
  { label: 'Emergency', value: 'Emergency' },
  { label: 'Surgery', value: 'Surgery' },
  { label: 'Procedure', value: 'Procedure' },
];

export const UN_ASSIGNED_LIST_KEY = -1;

export const FIREBASE_MESSAGING_SENDER_ID = '1081154936850';

export const TOAST_TIMEOUT = 5000;

export const HISTORY_LENGTH_FROM_REPORT_SCREEN = 1;
