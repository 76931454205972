import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Card, Col, Container, Label, Row, CardBody } from 'reactstrap';
import { IRootState } from '../../shared/reducers';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import {
  createFacilitiesAndOthers,
  fetchFacilitiesAndOthers,
  fetchVendorFacilitiesAndOthers,
  updateFacilitiesAndOthers,
} from '../../reducers/user.reducer';
import { ISubMenu } from '../../model/user-menu';
import SubHeader from '../../shared/layout/sub-header';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useWindowDimensions } from '../../shared/util/utility-functions';
import { getServicesMenus } from '../../reducers/plan.reducer';
import {
  APP_CUSTOM_FIELD_INPUT_TYPES,
  DATE_TIME_FORMATS,
  SM_SCREEN_BREAKPOINT,
} from '../../config/constants';
import { translate } from 'react-jhipster';
import { Autocomplete } from '@material-ui/lab';
import { MenuItem, Select, TextField } from '@material-ui/core';
import Button from '../components/button';
import ServicePublishButton from './components/service-publish-button';
import { DatePicker } from 'antd';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import { PERMISSION_ACTIONS } from '../../config/permission-constants';
import { IsPermittedAction } from '../../shared/util/permission-utils';
import { DateIcon } from '../services/components/icons/date-icon';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

const FacilitiesAndOthers = (props: any) => {
  const {
    servicesLinks,
    facilitiesAndOthers,
    vendorFacilitiesAndOthers,
    fetchFacilitiesAndOthers,
    fetchVendorFacilitiesAndOthers,
    updateFacilitiesAndOthers,
    createFacilitiesAndOthers,
    isSuccess,
    getServicesMenus,
    match,
  } = props;
  const [selectedSubMenu, setSubMenu] = useState({} as ISubMenu);
  const [facilitiesAndOthersWithValue, setFacilitiesAndOthersWithValue] =
    useState([] as any);
  const serviceId = props.serviceId;
  const [isNewFacility, setIsNewFacility] = useState(true);
  const [entityArray, setEntityArray] = useState([] as any);
  const { width } = useWindowDimensions();
  const [autoCompleteError, setAutoCompleteError] = useState(false);
  const [validSubmit, setValidSubmit] = useState(false);
  const NUMBER_OF_LIMIT_TAGS = 1;
  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );

  useEffect(() => {
    getServicesMenus();
    fetchFacilitiesAndOthers(serviceId);
    fetchVendorFacilitiesAndOthers(serviceId);
  }, []);

  useEffect(() => {
    vendorFacilitiesAndOthers.length > 0
      ? setIsNewFacility(false)
      : setIsNewFacility(true);
  }, [vendorFacilitiesAndOthers]);

  useEffect(() => {
    const facilityList = [...facilitiesAndOthers];
    facilityList.map(facility => {
      const vendorFacility = vendorFacilitiesAndOthers.find(
        element => element?.facilityId === facility?.id,
      );
      if (vendorFacility) {
        facility.value = vendorFacility?.value?.split(',');
        facility.facilityId = vendorFacility?.id;
      } else {
        facility.value = [];
        facility.facilityId = 0;
      }
      return facility;
    });
    setFacilitiesAndOthersWithValue(facilityList);
  }, [facilitiesAndOthers, vendorFacilitiesAndOthers]);

  useEffect(() => {
    if (isSuccess) {
      fetchVendorFacilitiesAndOthers(serviceId);
    }
  }, [isSuccess]);

  const onChangeSingleSelect = (event, id) => {
    const stateValue = [event?.target?.value];
    const facilities = [...facilitiesAndOthersWithValue];
    const facility = facilities.find(element => element.id === id);
    if (stateValue) {
      facility.value = stateValue;
    }

    if (stateValue.length === 0 && facility.isRequired) {
      facility.error = true;
    } else {
      facility.error = false;
    }
    setFacilitiesAndOthersWithValue(facilities);
  };

  const onDateChange = (date, id) => {
    const stateValue = [
      moment(date).format(DATE_TIME_FORMATS.YYYY_MM_DD_WITH_HYPHEN),
    ];
    const facilities = [...facilitiesAndOthersWithValue];
    const facility = facilities.find(element => element.id === id);
    facility.value = stateValue;
    if (stateValue?.length === 0 && facility.isRequired) {
      facility.error = true;
    } else {
      facility.error = false;
    }
    setFacilitiesAndOthersWithValue(facilities);
  };

  const handleValidSubmit = (event, errors, values) => {
    let entity = [] as any;
    if (!errors.length) {
      setValidSubmit(true);
    }
    facilitiesAndOthersWithValue.forEach((field, index) => {
      if (
        field.inputType !== APP_CUSTOM_FIELD_INPUT_TYPES.MULTI_SELECT &&
        field.inputType !== APP_CUSTOM_FIELD_INPUT_TYPES.SINGLE_SELECT &&
        field.inputType !== APP_CUSTOM_FIELD_INPUT_TYPES.DATE
      ) {
        const displayName = field.displayName;
        if (field.inputType === APP_CUSTOM_FIELD_INPUT_TYPES.CHECK_BOX) {
          field.value[0] = values[displayName] === true ? 'true' : 'false';
        } else {
          field.value[0] = values[displayName];
        }
      }
      if (field.facilityId !== 0) {
        if (entity.length > 0) {
          entity = [
            ...entity,
            {
              value: field.value,
              serviceFacilityId: field.id,
              id: field.facilityId,
            },
          ];
        } else {
          entity = [
            {
              value: field.value,
              serviceFacilityId: field.id,
              id: field.facilityId,
            },
          ];
        }
      } else {
        if (entity.length > 0) {
          entity = [
            ...entity,
            {
              value: field.value,
              serviceFacilityId: field.id,
            },
          ];
        } else {
          entity = [
            {
              value: field.value,
              serviceFacilityId: field.id,
            },
          ];
        }
      }
    });
    setEntityArray(entity);
  };

  useEffect(() => {
    let error = checkValidation();
    if (!error && validSubmit) {
      if (isNewFacility) {
        createFacilitiesAndOthers(entityArray, serviceId);
      } else {
        updateFacilitiesAndOthers(entityArray, serviceId);
      }
      fetchVendorFacilitiesAndOthers(serviceId);
    }
  }, [entityArray]);

  const onChangeAutocompleteValue = (event, value, id) => {
    const stateValue = value?.map(item => {
      return item;
    });
    const facilities = [...facilitiesAndOthersWithValue];
    const facility = facilities.find(element => element.id === id);
    facility.value = stateValue;
    if (stateValue.length === 0 && facility.isRequired) {
      facility.error = true;
    } else {
      facility.error = false;
    }
    setFacilitiesAndOthersWithValue(facilities);
  };

  const checkValidation = () => {
    let flag = false;
    facilitiesAndOthersWithValue.forEach((field, index) => {
      if (field.inputType === APP_CUSTOM_FIELD_INPUT_TYPES.MULTI_SELECT) {
        if (field.value?.length === 0 && field?.isRequired) {
          field.error = true;
          flag = true;
          setAutoCompleteError(true);
        } else {
          field.error = false;
          setAutoCompleteError(false);
        }
      } else if (
        field.inputType === APP_CUSTOM_FIELD_INPUT_TYPES.SINGLE_SELECT
      ) {
        if (field.value?.length === 0 && field?.isRequired) {
          field.error = true;
          flag = true;
        } else {
          field.error = false;
        }
      } else if (field.inputType === APP_CUSTOM_FIELD_INPUT_TYPES.DATE) {
        if (field.value?.length === 0 && field?.isRequired) {
          field.error = true;
          flag = true;
        } else {
          field.error = false;
        }
      }
    });
    return flag;
  };

  const renderCustomDatePicker = (date, error) => {
    return (
      <div
        className={`p-3 px-3  ${
          error === true ? 'calender-error-style' : 'calender-style'
        }`}>
        <div className="d-flex justify-content-between">
          <p className="m-0">
            {date
              ? moment(date).format(DATE_TIME_FORMATS.DD_MM_YYYY)
              : 'dd-mm-yyyy'}
          </p>
          <DateIcon />
        </div>
      </div>
    );
  };

  return (
    <div>
      {facilitiesAndOthersWithValue?.length ? (
        <AvForm onSubmit={handleValidSubmit}>
          <>
            <Container
              fluid
              className="px-0 px-lg-4 p-3 pb-5 mb-2"
              id="facility-detail">
              <Row>
                <Col lg="6">
                  <Row>
                    {facilitiesAndOthersWithValue.map((field, index) => {
                      return (
                        <Col key={index} className="mt-3 mb-3" md="6">
                          {field?.inputType ===
                          APP_CUSTOM_FIELD_INPUT_TYPES.CHECK_BOX ? (
                            <>
                              <div className="d-flex align-items-center">
                                <AvField
                                  type="checkbox"
                                  className="mt-2"
                                  value={
                                    field.value[0] === 'true' ? true : false
                                  }
                                  name={field?.displayName}
                                />
                                <Label
                                  style={{ paddingTop: '2px' }}
                                  for={field?.displayName}>
                                  {field?.displayName}
                                  {field?.isRequired && (
                                    <span className="text-danger">*</span>
                                  )}
                                </Label>
                              </div>
                            </>
                          ) : (
                            <>
                              <Label for={field?.displayName}>
                                {field?.displayName}
                                {field?.isRequired && (
                                  <span className="text-danger">*</span>
                                )}
                              </Label>
                              {field?.inputType ===
                              APP_CUSTOM_FIELD_INPUT_TYPES.TEXT_BOX ? (
                                <>
                                  <AvField
                                    value={field?.value && field?.value[0]}
                                    className="p-3"
                                    name={field?.displayName}
                                    validate={
                                      field?.isRequired && {
                                        required: {
                                          value: field?.isRequired,
                                          errorMessage:
                                            translate(
                                              'validation_facility_details.fields_error_message',
                                            ) +
                                            field?.displayName.toLowerCase(),
                                        },
                                      }
                                    }
                                  />
                                </>
                              ) : field?.inputType ===
                                APP_CUSTOM_FIELD_INPUT_TYPES.TEXT_AREA ? (
                                <>
                                  <AvField
                                    value={field?.value && field?.value[0]}
                                    className="p-3"
                                    name={field?.displayName}
                                    type="textarea"
                                    validate={{
                                      required: {
                                        value: field?.isRequired,
                                        errorMessage:
                                          translate(
                                            'validation_facility_details.fields_error_message',
                                          ) + field?.displayName.toLowerCase(),
                                      },
                                    }}
                                  />
                                </>
                              ) : field?.inputType ===
                                APP_CUSTOM_FIELD_INPUT_TYPES.SINGLE_SELECT ? (
                                <>
                                  <div className="select-wrapper">
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      className="facility-multi-dropdown"
                                      variant="outlined"
                                      fullWidth
                                      MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                          vertical: 'bottom',
                                          horizontal: 'left',
                                        },
                                        PaperProps: {
                                          style: {
                                            maxHeight: '150px',
                                          },
                                        },
                                      }}
                                      value={
                                        field?.value?.length
                                          ? field?.value[0]
                                          : ''
                                      }
                                      onChange={(event, value) => {
                                        onChangeSingleSelect(event, field?.id);
                                      }}
                                      error={field?.error ? true : false}
                                      style={{ fontSize: '0.9rem' }}>
                                      <MenuItem
                                        value=""
                                        key=""
                                        selected
                                        disabled>
                                        Select
                                      </MenuItem>
                                      {field?.attributeValues?.map(
                                        (attr, i) => (
                                          <MenuItem key={i} value={attr}>
                                            {attr}
                                          </MenuItem>
                                        ),
                                      )}
                                    </Select>
                                    {field?.error && (
                                      <span
                                        className="text-danger"
                                        style={{ fontSize: '.8rem' }}>
                                        {translate(
                                          'validation_facility_details.fields_error_message',
                                        ) + field?.displayName.toLowerCase()}
                                      </span>
                                    )}
                                  </div>
                                </>
                              ) : field?.inputType ===
                                APP_CUSTOM_FIELD_INPUT_TYPES.DATE ? (
                                <>
                                  <div className="ant-picker-style">
                                    <DatePicker
                                      value={
                                        field?.value?.length
                                          ? moment(field?.value[0])
                                          : null
                                      }
                                      onChange={date =>
                                        onDateChange(date, field?.id)
                                      }
                                      format={DATE_TIME_FORMATS.DD_MM_YYYY}
                                      allowClear={false}
                                      style={{
                                        width: '100%',
                                        height: '52px',
                                        borderRadius: '4px',
                                        borderColor: field?.error
                                          ? 'red'
                                          : undefined,
                                      }}
                                      placeholder="dd-mm-yyyy"
                                    />
                                  </div>
                                  {field?.error && (
                                    <span
                                      className="text-danger"
                                      style={{ fontSize: '.8rem' }}>
                                      {translate(
                                        'validation_facility_details.fields_error_message',
                                      ) + field?.displayName.toLowerCase()}
                                    </span>
                                  )}
                                </>
                              ) : field?.inputType ===
                                APP_CUSTOM_FIELD_INPUT_TYPES.MULTI_SELECT ? (
                                <>
                                  <Autocomplete
                                    multiple
                                    limitTags={NUMBER_OF_LIMIT_TAGS}
                                    className="facility-multi-dropdown"
                                    id={field?.id}
                                    style={{ fontSize: '.8rem' }}
                                    onChange={(event, value) =>
                                      onChangeAutocompleteValue(
                                        event,
                                        value,
                                        field?.id,
                                      )
                                    }
                                    options={
                                      Array.isArray(field?.attributeValues)
                                        ? field?.attributeValues
                                        : []
                                    }
                                    getOptionLabel={option => option ?? ''}
                                    getOptionSelected={(option, value) =>
                                      option === value
                                    }
                                    value={
                                      field?.value?.map((spec, i) => {
                                        return Array.isArray(
                                          field?.attributeValues,
                                        )
                                          ? field?.attributeValues.filter(
                                              x => x === spec,
                                            )[0]
                                          : '';
                                      }) ?? []
                                    }
                                    filterSelectedOptions
                                    renderInput={params => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        error={field?.error}
                                        placeholder={field?.displayName}
                                      />
                                    )}
                                  />
                                  {field?.error && (
                                    <span
                                      className="text-danger"
                                      style={{ fontSize: '.8rem' }}>
                                      {translate(
                                        'validation_facility_details.fields_error_message',
                                      ) + field?.displayName.toLowerCase()}
                                    </span>
                                  )}
                                </>
                              ) : null}
                            </>
                          )}
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </Container>
            <div className="bottom-button-save-container d-flex justify-content-end">
              {IsPermittedAction(
                PERMISSION_ACTIONS.CREATE_VENDOR_SERVICE_FACILITIES,
                authorities,
                permissionList,
              ) &&
                IsPermittedAction(
                  PERMISSION_ACTIONS.UPDATE_VENDOR_SERVICE_FACILITIES,
                  authorities,
                  permissionList,
                ) && (
                  <Button
                    type="submit"
                    color="danger"
                    className="m-2 text-light button-text mr-5 px-4"
                    size={width > SM_SCREEN_BREAKPOINT ? '' : 'sm'}>
                    {translate('placeholders.save_as_draft')}
                  </Button>
                )}
            </div>
          </>
        </AvForm>
      ) : (
        <div className="text-info my-4" style={{ fontSize: 32 }}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({
  authentication,
  user,
  plan,
  master,
}: IRootState) => ({
  loading: user.loading,
  servicesLinks: plan.servicesLinks,
  facilitiesAndOthers: user.facilitiesAndOthers,
  vendorFacilitiesAndOthers: user.vendorFacilitiesAndOthers,
  isSuccess: user.isSuccess,
});

const mapDispatchToProps = {
  getServicesMenus,
  fetchFacilitiesAndOthers,
  fetchVendorFacilitiesAndOthers,
  updateFacilitiesAndOthers,
  createFacilitiesAndOthers,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FacilitiesAndOthers);
