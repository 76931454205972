import { translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import {
  API_URL,
  INVALID_CREDENTIALS_MESSAGE_KEY,
} from './../../config/constants';
import axios from 'axios';
import { Storage } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from './action-type.util';
import { setLocale } from './locale';
import { getPermissionActions, getRolesPermissions} from '../../reducers/permissions.reducer';

export const ACTION_TYPES = {
  LOGIN: 'authentication/LOGIN',
  GET_SESSION: 'authentication/GET_SESSION',
  LOGOUT: 'authentication/LOGOUT',
  CLEAR_AUTH: 'authentication/CLEAR_AUTH',
  ERROR_MESSAGE: 'authentication/ERROR_MESSAGE',
};

export const AUTH_TOKEN_KEY = 'jhi-authenticationToken';

const initialState = {
  loading: false,
  isAuthenticated: false,
  loginError: false, // Errors returned from server side
  showModalLogin: false,
  account: {} as any,
  errorMessage: null as unknown as string, // Errors returned from server side
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
  idToken: null as unknown as string,
  logoutUrl: null as unknown as string,
  loginResponse: null as any,
};

export type AuthenticationState = Readonly<typeof initialState>;

// Reducer

export default (
  state: AuthenticationState = initialState,
  action: any,
): AuthenticationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.LOGIN):
    case REQUEST(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.LOGIN):
      return {
        ...initialState,
        errorMessage: action.payload,
        showModalLogin: true,
        loginError: true,
      };
    case FAILURE(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        showModalLogin: true,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.LOGIN):
      return {
        ...state,
        loginError: false,
        loginResponse: action?.payload,
      };
    case ACTION_TYPES.LOGOUT:
      return {
        ...initialState,
        showModalLogin: true,
        isAuthenticated: false,
      };
    case SUCCESS(ACTION_TYPES.GET_SESSION): {
      const isAuthenticated =
        action.payload && action.payload.data && action.payload.data.activated;
        localStorage.setItem('account', JSON.stringify(action.payload.data));
      return {
        ...state,
        isAuthenticated,
        loading: false,
        sessionHasBeenFetched: true,
        account: action.payload.data,
      };
    }
    case ACTION_TYPES.GET_SESSION:
      return {
        ...initialState,
        isAuthenticated: true,
      };
    case ACTION_TYPES.ERROR_MESSAGE:
      let authStatus = state.isAuthenticated;
      if (action.message === INVALID_CREDENTIALS_MESSAGE_KEY) {
        authStatus = false;
        toast.error(translate('entity.validation.invalid_credentials'));
      }
      return {
        ...initialState,
        showModalLogin: true,
        redirectMessage: action.message,
        isAuthenticated: authStatus,
      };
    case ACTION_TYPES.CLEAR_AUTH:
      return {
        ...state,
        loading: false,
        showModalLogin: true,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export const displayAuthError = message => ({
  type: ACTION_TYPES.ERROR_MESSAGE,
  message,
});

export const getSession: () => void = () => async (dispatch, getState) => {
  let userToken = Storage.local.get(AUTH_TOKEN_KEY, '');

  if (userToken) {
    dispatch({
      type: ACTION_TYPES.GET_SESSION,
      payload: axios.get(`${API_URL}/account`, {
        headers: { Authorization: `Bearer ${userToken}` },
      }),
    });
  }

  const account = getState()?.authentication?.account;
  if (account && account.langKey) {
    const langKey = Storage.session.get('locale', account?.langKey);
    await dispatch(setLocale(langKey));
  }
};

export const login: (
  username: string,
  password: string,
  rememberMe?: boolean,
) => void =
  (username, password, rememberMe = false) =>
  async (dispatch, getState) => {
    const result = await dispatch({
      type: ACTION_TYPES.LOGIN,
      payload: axios.post(`${API_URL}/authenticate`, {
        username,
        password,
        rememberMe,
      }),
    });
    const bearerToken = result?.value?.data?.id_token;
    if (bearerToken) {
      localStorage.setItem(AUTH_TOKEN_KEY, bearerToken);
      Storage.local.set(AUTH_TOKEN_KEY, bearerToken);
    }

    const permissionTypes = await dispatch(getPermissionActions(bearerToken));
    if (!permissionTypes?.value?.data?.length) return;

    const rolePermissions = await dispatch(getRolesPermissions(bearerToken));
    if (!rolePermissions?.value?.data?.length) return;

    await dispatch(getSession());
  };

export const clearAuthToken = () => {
  if (Storage.local.get(AUTH_TOKEN_KEY)) {
    Storage.local.remove(AUTH_TOKEN_KEY);
  }
  if (Storage.session.get(AUTH_TOKEN_KEY)) {
    Storage.session.remove(AUTH_TOKEN_KEY);
  }
};

export const logout: () => void = () => dispatch => {
  clearAuthToken();
  dispatch({
    type: ACTION_TYPES.LOGOUT,
  });
};

export const clearAuthentication = messageKey => (dispatch, getState) => {
  // For future
  // clearAuthToken();
  dispatch(displayAuthError(messageKey));
  dispatch({
    type: ACTION_TYPES.CLEAR_AUTH,
  });
};
