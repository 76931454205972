import './styles.scss';
import { Button, Col, Navbar, Row } from "reactstrap";
import { useHistory } from "react-router";
import peditohPng from './src/peditoh.png';
import { useMediaQuery } from 'react-responsive'
import { useState } from 'react'


export const PrivacyPolicy = () => {
    const history = useHistory();
    const [toggeleMenu, setToggleMenu] = useState(false);
    const togglenav = () => {
        setToggleMenu(!toggeleMenu)

    }
    const isMobile = useMediaQuery({ maxWidth: 906 })
    return (
        <div>
            <div>
                {isMobile ? <>
                    <Navbar fixed="top" >
                        <Row className='navbar-row '>
                            <div className="row menubtn">
                                <div className="col-10">
                                    <img src={peditohPng} alt='peditoh' onClick={() => { history.push('/landing-page') }} />
                                </div>
                                <div className="col-2">
                                    <Button onClick={togglenav}>menu</Button>
                                </div>
                            </div>
                            <div className={toggeleMenu ? 'list-block' : 'list'} >
                                {toggeleMenu && (
                                    <>
                                        <Col />
                                        <Col ><a href='/terms-condition' >Terms and condition </a></Col>
                                        <Col  ><a href='/privacy-policy'>PeditoH Privacy Policy</a></Col>
                                        <Col ><a href='/landing-page#contact-us'>Contact Us</a></Col>
                                    </>
                                )}
                            </div>
                        </Row>
                    </Navbar></> :
                    <Navbar fixed="top" >
                        <Row className='navbar-row '>
                            <Col xs={4} xxl={4} xl={4} lg={3} md={2} sm={1} className='pl-2'>
                                <img src={peditohPng} alt='peditoh' onClick={() => { history.push('/landing-page') }} />
                            </Col>
                            <Col />
                            <Col></Col>
                            <Col ><a href='/terms-condition'>Terms and conditions</a></Col>
                            <Col  ><a href='/privacy-policy'>PeditoH Privacy Policy</a></Col>
                            <Col ><a href='/landing-page#contact-us'>Contact Us</a></Col>

                        </Row>
                    </Navbar>
                }
                <h2 className="text-center page-header header-style pt-5">PeditoH Privacy Policy </h2>
                <div className=" new-page-container "  >
                    <ol>
                        <li> Your access or use of the Website, transaction on the Website and use of Services (as defined herein
                            below) hosted or
                            managed remotely through the Website, are governed by the following terms and conditions (hereinafter
                            referred to as the
                            Terms of Use”), including the applicable policies which are incorporated herein by way of reference.
                            These Terms of Use
                            constitutes a legal and binding contract between you (hereinafter referred to as “You” or “Your” or the
                            “User”) on one part and
                            PeditoH on the other Part.</li>
                        <li> By accessing, browsing or in any way transacting on the Website, or availing any Services, you signify
                            your agreement to be
                            bound by these Terms of Use. Further, by impliedly or expressly accepting these terms of Use, you also
                            accept and agree to be
                            bound by our policies, including the Privacy Policy, and such other rules, guidelines, policies, terms
                            and conditions as are
                            relevant under the applicable law(s) in India and other jurisdictions for the purposes of accessing,
                            browsing or transacting on
                            the Website, or availing any of the Services, and such rules, guidelines, policies, terms and conditions
                            shall be deemed to be
                            incorporated into, and considered as part and parcel of these terms of use. However, if you navigate
                            away from the website to
                            a third party website, you may be subject to alternative terms and conditions of use and privacy policy,
                            as may be specified on
                            such website. In such event, the terms and conditions of use and privacy policy applicable to that
                            website will govern your use
                            of that website.</li>
                        <li> The arrangement between the Third Party Service Providers, You and Us shall be governed in accordance
                            with these Terms
                            of Use. The Services would be made available to such natural persons who have agreed to use the Website
                            after obtaining due
                            registration, in accordance with the procedure as determined by Us, from time to time, (referred to as
                            “You” or “Your” or
                            “Yourself” or “User”, which terms shall also include natural persons who are accessing the Website
                            merely as visitors). The
                            Services are offered to you through various modes which shall include issue of discount coupons and
                            vouchers that can be
                            redeemed for various goods/ services offered for sale by relevant Third Party Service Providers. To
                            facilitate the relation
                            between you and the Third Party Service Providers through the Website, PeditoH shall send to you
                            (promotional content
                            including but not limited to e mailers, notifications and messages).</li>
                        <li> These Terms of Use constitute an electronic record in terms of the IT Act and rules framed there under,
                            as applicable and
                            amended from time to time. This electronic record is generated by a computer system and does not require
                            any physical or
                            digital signatures.</li>
                        <li> PeditoH makes no warranty that the Services will meet your requirements, or that the Service(s) will
                            be uninterrupted, timely,
                            secure, or error free. This includes loss of data or any service interruption caused by PeditoH
                            employees. PeditoH is not
                            responsible for transmission errors, corruption of data.</li>

                        <li> The Website may not be used for illegal purposes. The Information and Services may not be used for any
                            illegal purpose.
                            You may not access our networks, computers, or the Information and Services in any manner that could
                            damage, disable,
                            overburden, or impair them, or interfere with any other person&#39;s use and enjoyment. You may not
                            attempt to gain unauthorized
                            access to any Information or Services, other accounts, computer systems, or networks connected with the
                            Website, the
                            Information, or Services. You may not use any automated means (such as a scraper) to access the Website,
                            the Information, or
                            Services for any purpose. Such unauthorized access includes, but is not limited to, using another
                            person’s login credentials to
                            access his or her PeditoH profile/ account. Any attempt by any individual or entity to solicit login
                            information of any other user or
                            Medical Expert or to access any such account is an express and direct violation of these Terms of Use
                            and of applicable law(s),
                            including relevant privacy and security laws and laws prohibiting unfair or unethical business
                            practices.</li>
                        <li> Your right to use the Services is not transferable.</li>
                        <li> Editorial Policy for the Website –</li>
                        <p>As part of the Services, PeditoH provides PeditoH Content on the Website or any social media platform
                            such as YouTube,
                            facebook, instagram targeted at general public for informational purposes only and does not
                            constitute professional medical
                            advice, diagnosis, treatment or recommendations of any kind. PeditoH Content is subject to the
                            following rules/ information:
                        </p>
                        <ol type="a">
                            <li> PeditoH Content is original and is relevant to the general public;</li>
                            <li> Topics for PeditoH Content are selected by our board of qualified experts consisting of
                                certified medical experts, medical
                                professionals;</li>
                            <li>Topics for PeditoH Content are chosen on the basis of current health news, drug alerts, new drug
                                launches, latest medical
                                findings published in peer-reviewed medical journals;</li>
                            <li>Editorial board takes into account the latest trending health and wellness topics like seasonal
                                allergies, new vaccines, public</li>
                            awareness trends as well as emerging health and nutrition trends like health benefits;
                            <li> PeditoH maintains principles of fairness, accuracy, objectivity, and responsible, independent
                                reporting;</li>
                            <li> The member of PeditoH has to fully disclose any potential conflict of interest with any of the
                                Third Party Service Providers;</li>
                            <li> PeditoH’s editorial staff holds the responsibility of providing objective, accurate, and
                                balanced accounts of events and issues;</li>
                        </ol>
                        <li> OTHER TERMS</li>
                        <p>Your Profile, Collection, Use, Storage and Transfer of Personal Information:</p>
                        <ol type="i">
                            <li>Your PeditoH profile is created to store record of you or Your Consultations and Your animal’s
                                health information online,
                                including history, health conditions, allergies and medications.</li>
                            <li> Any information provided as part of a web Consultation or obtained from use of the Services by
                                you becomes part of your
                                PeditoH record. You agree to provide accurate information to help us serve you best to our
                                knowledge, to periodically review
                                such information and to update such information as and when necessary. PeditoH reserves the
                                right to maintain, delete or
                                destroy all communications and materials posted or uploaded to the Website according to its
                                internal record retention and/or
                                destruction policies. You might be contacted via email to review the information provided by you
                                for PeditoH’s record or for the
                                Services. Please make sure you provide a valid email-id and you update it as and when needed.</li>
                            <li>For additional information regarding use of information about you, please refer to the Privacy
                                Policy.</li>
                            <li>The terms “personal information” and “sensitive personal data or information” are defined under
                                the Information Technology
                                (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011
                                (the “SPI Rules”), and are
                                reproduced in the Privacy Policy.</li>
                            <ol>
                                <li>The Privacy Policy sets out:</li>
                                <ol type='a'>
                                    <li> The type of information collected from Users, including sensitive personal data
                                        or information;</li>
                                    <li> The purpose, means and modes of usage of such information; and</li>
                                    <li> How and to whom PeditoH will disclose such information.</li>
                                </ol>
                                <li>The Users are expected to read and understand the Privacy Policy, so as to ensure that
                                    he or she has the knowledge of:</li>
                                <ol type='a'>
                                    <li> The fact that the information is being collected;</li>
                                    <li> The purpose for which the information is being collected;</li>
                                    <li> The intended recipients of the information;</li>
                                    <li> The name and address of the agency that is collecting the information and the
                                        agency that will retain the information; and</li>
                                    <li> The various rights available to such Users in respect of such information.</li>
                                </ol>
                                <li> PeditoH shall not be responsible in any manner for the authenticity of the personal
                                    information or sensitive personal data or
                                    information supplied by the Users to PeditoH or any other person acting on behalf of
                                    PeditoH</li>
                                <li>The use of the Website involves every User’s registration information and browsing
                                    history being stored and submitted to the
                                    appropriate authorities. The consent and procedure for such collection and submission is
                                    provided in the Privacy Policy. The
                                    other information collected by PeditoH from Users as part of the registration process is
                                    described in the Privacy Policy. The
                                    consent and revocation procedures in relation to the same are set out in the Privacy
                                    Policy.</li>
                                <li>The Users are responsible for maintaining the confidentiality of the Users’ account
                                    access information and password. The
                                    Users shall be responsible for all uses of the Users’ account and password, whether or
                                    not authorized by the Users. The Users
                                    shall immediately notify PeditoH of any actual or suspected unauthorized use of the
                                    Users’ account or password.</li>
                                <li>If a User provides any information that is untrue, inaccurate, not current or incomplete
                                    (or becomes untrue, inaccurate, not
                                    current or incomplete), or PeditoH has reasonable grounds to suspect that such
                                    information is untrue, inaccurate, not current or
                                    incomplete, PeditoH shall have the right to suspend or terminate such account at its
                                    sole discretion.</li>
                                <li> PeditoH may disclose or transfer User Information (as defined in the Privacy Policy) to
                                    its affiliates in other countries, and
                                    you hereby consent to such transfer. The SPI Rules only permit PeditoH to transfer
                                    sensitive personal data or information
                                    including any information, to any other body corporate or a person in India, or located
                                    in any other country, that ensures the
                                    same level of data protection that is adhered to by PeditoH as provided for under the
                                    SPI Rules, only if such transfer is
                                    necessary for the performance of the lawful contract between PeditoH or any person on
                                    its behalf and the user or where the
                                    User has consented to data transfer.</li>
                                <li>By accepting these Terms of Use and by registering on the Website, You consent to be
                                    contacted by Us and by the Third
                                    Party Service Providers and You further consent to receive emails and messages (SMS)
                                    notifications and information at any
                                    time from Us and from Third Party Service Providers.</li>
                            </ol>
                        </ol>
                    </ol>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
