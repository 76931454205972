import './styles.scss';
import { Button, Col, Navbar, Row } from "reactstrap";
import { useHistory } from "react-router";
import peditohPng from './src/peditoh.png';
import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';

export const TermsAndCondition = () => {
    const history = useHistory();
    const [toggeleMenu, setToggleMenu] = useState(false);
    const togglenav = () => {
        setToggleMenu(!toggeleMenu)

    }
    const isMobile = useMediaQuery({ maxWidth: 906 })
    return (
        <div>
            {isMobile ? <>

                <Navbar fixed="top" >

                    <Row className='navbar-row '>
                        <div className="row menubtn">
                            <div className="col-10">
                                <img src={peditohPng} alt='peditoh' onClick={() => { history.push('/landing-page') }} />
                            </div>
                            <div className="col-2">
                                <Button onClick={togglenav}>menu</Button>
                            </div>
                        </div>

                        <div className={toggeleMenu ? 'list-block' : 'list'} >
                            {toggeleMenu && (
                                <>
                                    <Col />
                                     <Col ><a href='/terms-condition'>Terms and conditions</a></Col>
                                    <Col  ><a href='/privacy-policy'>PeditoH  Policy</a></Col>
                                    <Col ><a href='/landing-page#contact-us'>Contact Us</a></Col>
                                </>
                            )}
                        </div>

                    </Row>
                </Navbar></> :
                <Navbar fixed="top" >
                    <Row className='navbar-row '>
                        <Col xs={4} xxl={4} xl={4} lg={3} md={2} sm={1} className='pl-2'>
                            <img src={peditohPng} alt='peditoh' onClick={() => { history.push('/landing-page') }} />
                        </Col>
                        <Col />
                        <Col></Col>
                        <Col ><a href='/terms-condition'>Terms and conditions</a></Col>
                        <Col  ><a href='/privacy-policy'>PeditoH Privacy Policy</a></Col>
                        <Col ><a href='/landing-page#contact-us'>Contact Us</a></Col>

                    </Row>
                </Navbar>
            }

            <>
                <h2 className="text-center page-header header-style pt-5" >Terms and  conditions </h2>
                <br />
                <div className="new-page-container">

                    <p>
                        Please read these terms of use carefully by accessing or using this internet-based platform, you agree to be
                        bound by the
                        terms described herein and all terms incorporated by reference. If you do not agree to all of these terms,
                        do not use this
                        internet-based platform.
                        The domain name WWW.PeditoH.com, an internet based portal and PeditoH a mobile application, is
                        owned and operated
                        by NetSta Minds Pvt Ltd a company duly incorporated under the provisions of the
                        [Pursuant to sub-section (2) of
                        section 7 and sub-section (1) of section 8 of the Companies Act, 2013 (18 of 2013) and rule 18 of the
                        Companies
                        (Incorporation) Rules, 2014] (hereinafter referred to as PeditoH or “We” or “Our” or “Us” or “Company”). The
                        domain name and
                        the mobile application are collectively referred to as the “Website”.
                    </p>
                    <ol>
                        <li> The data belongs to the customers and is generated by veterinarian. PeditoH will charge the
                            veterinarian as well as
                            customer for the service rented.</li>
                        <li> Customer shall execute agreement with veterinarian to retain data. The veterinarian as well as customer
                            also shall
                            interest. PeditoH is responsible for keeping the data with the veterinarian and customer. PeditoH shall
                            charge for
                            providing the data base in secure and privacy ensured manner. The data will not be sold by any, but
                            shall be used to
                            generate revenue by PeditoH. PeditoH reserves right to access and user of platform for activity which
                            may generate
                            income, as planned from time to time. The vendors will have access only to their own data and shall have
                            access to
                            other data if shared by or with the consent of the customers. The data shall be stored on a shared
                            private cloud as
                            decide by the vendor, upon payment of charges prescribed from time to time. Veterinarian and the
                            customers have
                            the option of storing and accessing the data according to the options offered by the platform, subject
                            to payment
                            stipulated by time to time.</li>
                        <li> Your access or use of the Website, transaction on the Website and use of Services (as defined herein
                            below) hosted
                            or managed remotely through the Website, are governed by the following terms and conditions (hereinafter
                            referred
                            to as the Terms of Use”), including the applicable policies which are incorporated herein by way of
                            reference. These
                            Terms of Use constitutes a legal and binding contract between you (hereinafter referred to as “You” or
                            “Your” or the
                            “User”) on one part and PeditoH on the other Part.</li>

                        <li> PeditoH reserves the right to change or modify these Terms of Use or any policy or guideline of the
                            Website including
                            the Privacy Policy, at any time and in its sole discretion. Any changes or modifications will be
                            effective immediately
                            upon posting the revisions on the Website and you waive any right you may have to receive specific
                            notice of such
                            changes or modifications. Your continued use of the Website will confirm your acceptance of such changes
                            or
                            modifications; therefore, you should frequently review these Terms of Use and applicable policies to
                            understand the
                            terms and conditions that apply to Your use of the Website.</li>
                        <li> As a condition to your use of the Website, You must be 18 (eighteen) years of age or older to use or
                            visit the Website
                            in any manner. By visiting the Website or accepting these Terms of Use, You represent and warrant to
                            PeditoH that
                            you are 18 (eighteen) years of age or older, and that you have the right, authority and capacity to use
                            the Website
                            and agree to and abide by these Terms of Use.</li>
                        <li> These terms of use is published in compliance of, and is governed by the provisions of Indian laws,
                            including but
                            limited to:</li>
                        <li> The Indian Contract Act, 1872 (“Contract Act”);</li>
                        <li> The (Indian) Information Technology Act, 2000 (“IT Act”) and the rules, regulations, guidelines and
                            clarifications
                            framed thereunder, including the (Indian) Information Technology (Reasonable Security Practices and
                            Procedures
                            and Sensitive Personal Information) Rules, 2011, and the (Indian) Information Technology (Intermediaries
                            Guidelines) Rules, 2011 (“IG Guidelines”);</li>
                        <li> PeditoH authorizes you to view and access the content available on the Website solely for the purposes
                            of availing
                            the Services, such as visiting, using, ordering, receiving, delivering and communicating only as per
                            these Terms of
                            Use. The contents on the Website including information, text, graphics, images, logos, button icons,
                            software code,
                            design, and the collection, arrangement and assembly of content, contains Third Party Service Providers’
                            content
                            (“Third Party Content”) as well as in-house content provided by PeditoH including without limitation,
                            text, copy, audio,
                            video, photographs, illustrations, graphics and other visuals (“PeditoH Content”) (collectively,
                            “Content”). The PeditoH
                            Content is the property of PeditoH and is protected under copyright, trademark and other applicable
                            law(s). You shall
                            not modify the PeditoH Content or reproduce, display, publicly perform, distribute, or otherwise use the
                            PeditoH
                            Content in any way for any public or commercial purpose or for personal gain.</li>
                        <li> Compliance with these Terms of use would entitle you to a personal, non-exclusive, non-transferable,
                            limited
                            privilege to access and transact on the Website.</li>
                        <li> These Terms of Use constitute an electronic record in terms of the IT Act and rules framed there under,
                            as applicable
                            and amended from time to time. This electronic record is generated by a computer system and does not
                            require any
                            physical or digital signatures.</li>
                    </ol>
                    <h4> Cancellation policy</h4>
                    <p>Cancellations and refunds will be initiated only under the following instances.</p>
                    <ol>
                        <li>	The user initiates cancellation and refund within the time frame of the confirmed appointment the site has stipulated. The same will be notified during the process. The owners of the site has the authority to change the time frames as being suited to their business policy. This may or may not have a service charge as per the then prevailing policies of the site. The amount paid by the user will be refunded as per the above mentioned business rules.</li>
                        <li> 	If the user wishes to reschedule the appointment within the time frame of the confirmed appointment the site has stipulated, he may do so. This may or may not have a service charge as per the then prevailing policies of the site.</li>
                        <li>	If the consultation has not happened due to any technical or other omissions from the RVs due to unavoidable  reasons for which the customer is not directly responsible, the customer will have the option of proceeding for a full refund or avail a new time slot asper availability and choice.</li>
                    </ol>
                </div>
            </>
        </div>
    );
};

export default TermsAndCondition;
