import './header.scss';

import React, { useEffect, useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import {
  Navbar,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NavLink } from 'react-router-dom';
import LoadingBar from 'react-redux-loading-bar';

import { Home, Brand } from './header-components';
import DrawerToggleButton from '../side-drawer/drawer-toggle-button';
import {
  faSignInAlt,
  faSignOutAlt,
  faUserCircle,
  faAddressCard,
} from '@fortawesome/free-solid-svg-icons';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { getToken } from '../../../config/constants';
import { Redirect, RouteComponentProps } from 'react-router';
import { HeaderAppLogo } from './components/app-logo';
import { PERMISSION_ACTIONS } from '../../../config/permission-constants';
import { IsPermittedAction } from '../../../shared/util/permission-utils';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { TextField } from '@material-ui/core';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  currentLocale: string;
  onLocaleChange: Function;
  sideDrawerOpen: boolean;
  backdropClickHandler: Function;
  logout: Function;
}

export interface IPageProps extends IHeaderProps {}

const Header = (props: IPageProps) => {
  const backdropClickHandler = () => props.backdropClickHandler();
  const backdrop = props.sideDrawerOpen ? (
    <div className="backdrop" onClick={backdropClickHandler} />
  ) : null;

  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    props.onLocaleChange(langKey);
  };

  const { isAuthenticated } = props;

  const { authorities } = useSelector(
    (state: IRootState) => state.authentication.account,
  );
  const account = useSelector(
    (state: IRootState) => state.authentication.account,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );

  return (
    <div id="app-header">
      <LoadingBar className="loading-bar" />
      <Navbar fixed="top" className="shadow-sm bg-light px-2">
        <div className="d-flex align-items-center">
          <HeaderAppLogo />
        </div>

        <UncontrolledDropdown>
          {isAuthenticated ? (
            <>
              <DropdownToggle color="light" className="text-dark">
                <Translate contentKey="global.menu.account.main">
                  account
                </Translate>
                &nbsp;
                <FontAwesomeIcon
                  size="lg"
                  icon={faUserCircle}></FontAwesomeIcon>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem disabled className="user-profile">
                  <div
                    style={{
                      color: 'var(--bs-grey-teal)',
                      // paddingLeft: '8%',
                      paddingRight: '10%',
                    }}>
                    <FontAwesomeIcon
                      size="lg"
                      icon={faUserCircle}></FontAwesomeIcon>
                    &nbsp; &nbsp;{account?.firstName}
                    <br />
                    <div
                      style={{
                        color: 'var(--bs-grey-teal)',
                        paddingLeft: '9%',
                        paddingRight: '30%',
                      }}>
                      &nbsp; &nbsp;{account?.email}
                    </div>
                    &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                    {account?.authoritiesDisplayNames[0]}
                  </div>
                </DropdownItem>
                <hr
                  style={{
                    margin: '0px 10px 0px 10px',
                    color: ' #ced4da',
                  }}
                />
                {IsPermittedAction(
                  PERMISSION_ACTIONS.GET_VENDOR_PROFILE,
                  authorities,
                  permissionList,
                ) && (
                  <NavLink className="text-link" to="/vendor-profile-view">
                    <DropdownItem className="user-profile">
                      <FontAwesomeIcon
                        size="lg"
                        icon={faAddressCard}></FontAwesomeIcon>
                      &nbsp; &nbsp;
                      <Translate contentKey="global.menu.account.profile">
                        profile
                      </Translate>
                    </DropdownItem>
                  </NavLink>
                )}
                <NavLink className="text-link" to="/logout">
                  <DropdownItem className="user-profile">
                    <FontAwesomeIcon
                      size="lg"
                      icon={faSignOutAlt}></FontAwesomeIcon>
                    &nbsp; &nbsp;
                    <Translate contentKey="global.menu.account.logout">
                      logout
                    </Translate>
                  </DropdownItem>
                </NavLink>
              </DropdownMenu>
            </>
          ) : (
            <NavLink to="/login">
              <DropdownItem className="rounded">
                <FontAwesomeIcon icon={faSignInAlt} />
                &nbsp;
                <Translate contentKey="global.menu.account.login">
                  Sign in
                </Translate>
              </DropdownItem>
            </NavLink>
          )}
        </UncontrolledDropdown>
      </Navbar>
      {backdrop}
    </div>
  );
};

export default Header;
