import playStoreSvg from './src/google-play-badge.svg';
import appStoreSvg from './src/app-store-badge.svg';
import petPng from './src/pets.png';
import './styles.scss';
import { Button, Col, Navbar, Row } from "reactstrap";
import { useHistory } from "react-router";
import peditohPng from './src/peditoh.png';
import { width } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { padding } from '@mui/system';
import { useState } from 'react'
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive'

export const LandingPage = () => {
  const [toggeleMenu, setToggleMenu] = useState(false);
  const history = useHistory();
  const togglenav = () => {
    setToggleMenu(!toggeleMenu)

  }

  const isMobile = useMediaQuery({ maxWidth: 906 })

  return (
    <div>
      {isMobile ? <>

        <Navbar fixed="top" >

          <Row className='navbar-row '>
            <div className="row menubtn">
              <div className="col-10">
                <img src={peditohPng} alt='peditoh' onClick={() => { history.push('/landing-page') }} />
              </div>
              <div className="col-2">
                <Button onClick={togglenav}>menu</Button>
              </div>
            </div>

            <div className={toggeleMenu ? 'list-block' : 'list'} >
              {toggeleMenu && (
                <>
                  <Col />
                  <Col ><a href='/terms-condition' >Terms and condition </a></Col>
                  <Col  ><a href='/privacy-policy'>PeditoH  Policy</a></Col>
                  <Col ><a href='#contact-us'>Contact Us</a></Col>
                </>
              )}
            </div>
          </Row>
        </Navbar></> :
        <Navbar fixed="top" >
          <Row className='navbar-row '>
            <Col xs={4} xxl={4} xl={4} lg={3} md={2} sm={1} className='pl-2'>
              <img src={peditohPng} alt='peditoh' onClick={() => { history.push('/landing-page') }} />
            </Col>
            <Col />
            <Col ><a href='/terms-condition'>Terms and conditions</a></Col>
            <Col  ><a href='/privacy-policy'>PeditoH Privacy Policy</a></Col>
            <Col ><a href='#contact-us'>Contact Us</a></Col>
          </Row>
        </Navbar>
      }
      <header className="masthead">
        <div className=" px-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-7">
              <div className="mb-5 mb-lg-0 text-center text-lg-start">
                <h1 className="display-1 lh-1 mb-3 main-header"><b>PeditoH</b></h1>
                <p className="lead fw-normal text-muted mb-5">A futuristic online video consultation platform for
                  the Pet Veterinarian
                  community and Pet Parents, PeditoH is all set to change the outlook around interactive
                  online pet consultation.</p>
                <div className="d-flex flex-column flex-lg-row align-items-center mb-3">
                  <a className="me-lg-3 mb-4 mb-lg-0" href="#!"><img className="app-badge"
                    src={playStoreSvg} alt="playStore" /></a>
                  <a href="#!"><img className="app-badge " src={appStoreSvg} alt="appStore" /></a>
                </div>
                <div className="d-flex flex-column flex-lg-row align-items-center">
                  <Button className="login-button p-2 mr-4" onClick={() => { history.push('/login') }}>Login</Button>
                  <Button className="register-button p-2 m-3" onClick={() => { history.push('/pre-registration') }}>Register Now</Button>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="masthead-device-mockup">
                <img className='w-100' src={petPng} alt="pet" />
              </div>
            </div>
          </div>
        </div>
      </header>
      <footer className="bg-black text-center footer-style py-1">
        <section id="contact-us">
          <div className=" px-5">
            <div className="text-white-50 medium">
              <div className="mb-2 contact-us-text">Contact Us </div>
              <p className="small">NetSta Minds Private Limited<br />
                No: 4/461, 2nd Floor, Suite No:296,<br />
                Valamkottil Towers, Judgemukku,<br />
                Kakkanad, Kochi, Kerala, India.<br />
                Pin: 682021<br />
              </p>
              <div className="mb-2">Phone : 9249955155, 9895098989, 9995868883</div>
              <div className="mb-2">Email :  support@peditoh.com</div>
            </div>
          </div>
        </section>
      </footer>
    </div>
  );
};

export default LandingPage;
