import React, { useEffect } from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import {
  SERVICE_STATUSES,
  SM_SCREEN_BREAKPOINT,
} from '../../../config/constants';
import {
  getVendorService,
  requestToPublish,
} from '../../../reducers/user.reducer';
import { IRootState } from '../../../shared/reducers';
import { useWindowDimensions } from '../../../shared/util/utility-functions';
import Button from '../../components/button';
interface IProps {
  serviceId?: string;
}
export interface IPageProps extends StateProps, DispatchProps, IProps {}

export const ServicePublishButton = (props: IPageProps) => {
  useEffect(() => {
    getVendorService(serviceId);
  }, []);
  const { width } = useWindowDimensions();
  const { requestToPublish, serviceId, getVendorService, service } = props;
  
  const clickHandler= async() => {
    await requestToPublish(serviceId)
    getVendorService(serviceId)
  }
  
  return (
    <Button
      color="danger"
      className="m-1 text-light button-text px-4"
      size={width > SM_SCREEN_BREAKPOINT ? '' : 'sm'}
      disabled={service?.status !== SERVICE_STATUSES.DRAFT}
      onClick={()=> clickHandler()}>
      {translate('placeholders.publish_text')}
    </Button>
  );
};

const mapStateToProps = ({ user }: IRootState) => ({
  service: user.service,
});

const mapDispatchToProps = {
  requestToPublish,
  getVendorService,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServicePublishButton);
