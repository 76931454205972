import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import { Card, Col, Container, Label, Row, CardBody } from 'reactstrap';
import { IRootState } from '../../shared/reducers';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  createSubscriptionDetail,
  updateSubscriptionDetail,
  getSubscriptionDetail,
  getServiceAreaLocation,
  updateVendorSubscription,
  fetchServicePackages,
  fetchServicePaymentPeriods,
  fetchServiceSlots,
  getAdditionalCharges,
} from '../../reducers/user.reducer';
import { IMenu, ISubMenu } from '../../model/user-menu';
import { translate, Translate } from 'react-jhipster';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useWindowDimensions } from '../../shared/util/utility-functions';
import { getServicesMenus } from '../../reducers/plan.reducer';
import {
  DATE_TIME_FORMATS,
  SERVICE_STATUSES,
  SM_SCREEN_BREAKPOINT,
} from '../../config/constants';
import { getTabs } from '../../shared/layout/sub-header/tabs';
import moment from 'moment-timezone';
import Button from '../components/button';
import ServicePublishButton from './components/service-publish-button';
import { toast } from 'react-toastify';
import serviceSubscriptionRenewal from '../registration/service-subscription-renewal';
import { PERMISSION_ACTIONS } from '../../config/permission-constants';
import { IsPermittedAction } from '../../shared/util/permission-utils';
import EmptyContent from '../../assets/images/empty-content.png';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

const SubscriptionDetails = (props: any) => {
  const {
    subscriptionDetails,
    servicesLinks,
    servicePackages,
    servicePaymentPeriods,
    serviceSlots,
    additionalCharges,
  } = props;
  const [selectedSubMenu, setSubMenu] = useState({} as ISubMenu);
  const [subscriptionDetailsId, setSubscriptionDetailsId] = useState(0 as any);
  const [startDate, setStartDate] = useState(0 as any);
  const [expiryDate, setExpiryDate] = useState(0 as any);
  const [plan, setPlan] = useState('' as any);
  const [period, setPeriod] = useState('' as any);
  const [diffDays, setDiffDays] = useState(0 as any);
  let [days] = useState(0 as any);
  const [bookingPerSlot, setBookingPerSlot] = useState(0 as any);
  const [serviceAreaId, setServiceAreaId] = useState(0 as any);
  const [serviceArea, setServiceArea] = useState('' as any);
  const [daysToRenew, setDaysToRenew] = useState(0 as any);
  const [hasPharma, setHasPharma] = useState(false as boolean);
  const [updatedFields, setUpdatedFields] = useState(false as boolean);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [endDate, setEndDate] = useState(new Date());
  const [paymentPeriodId, setPaymentPeriodId] = useState(0 as number);
  const [servicePlanId, setServicePlanId] = useState(0 as number);
  const [servicesId, setServiceId] = useState(0 as number);
  const [servicePackageList, setServicePackageList] = useState([] as any);
  const [servicePaymentPeriodList, setServicePaymentPeriodList] = useState(
    [] as any,
  );
  const [serviceSlotList, setServiceSlotList] = useState([] as any);
  const [paymentPeriodMonths, setPaymentPeriodMonths] = useState(0 as number);
  const [service, setService] = useState<string>('');

  const { width } = useWindowDimensions();
  const [location, setLocation] = useState(0 as any);
  const [serviceAreaLocationResponse, setServiceAreaLocationResponse] =
    useState([] as any[]);
  const [payload] = useState({} as any);
  const serviceId = props.serviceId;
  const history = useHistory();
  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );

  useEffect(() => {
    props.getServicesMenus();
    props.getSubscriptionDetail(serviceId);
  }, [serviceId]);

  useEffect(() => {
    if (serviceAreaId) {
      props.getServiceAreaLocation(serviceAreaId);
    }
  }, [serviceAreaId]);

  useEffect(() => {
    if (props.serviceAreaLocationResponse) {
      setServiceAreaLocationResponse(props.serviceAreaLocationResponse);
    }
  }, [props.serviceAreaLocationResponse]);

  useEffect(() => {
    dateDifference(expiryDate);
  }, [expiryDate]);

  useEffect(() => {
    if (subscriptionDetails) {
      const {
        id,
        daysToRemoval,
        serviceAreaId,
        bookingPerSlot,
        period,
        plan,
        startDate,
        expiryDate,
        serviceArea,
        locationId,
        hasPharma,
        discount,
        totalAmount,
        paymentPeriodId,
        servicePlanId,
        serviceId,
        service,
      } = subscriptionDetails;
      setSubscriptionDetailsId(id);
      setDaysToRenew(daysToRemoval);
      setServiceAreaId(serviceAreaId);
      setServiceArea(serviceArea);
      setBookingPerSlot(bookingPerSlot);
      setPeriod(period);
      setPlan(plan);
      setStartDate(startDate);
      setExpiryDate(expiryDate);
      setLocation(locationId);
      setHasPharma(hasPharma);
      setDiscount(discount);
      setTotalAmount(totalAmount);
      setEndDate(expiryDate);
      setPaymentPeriodId(paymentPeriodId);
      setServicePlanId(servicePlanId);
      setServiceId(serviceId);
      setService(service);
    }
  }, [subscriptionDetails]);

  useEffect(() => {
    if (Array.isArray(servicePackages)) {
      setServicePackageList(servicePackages);
    }
  }, [servicePackages]);

  useEffect(() => {
    if (Array.isArray(servicePaymentPeriods)) {
      setServicePaymentPeriodList(servicePaymentPeriods);
    }
  }, [servicePaymentPeriods]);

  useEffect(() => {
    const noOfMonths = servicePaymentPeriodList.filter(
      x => x.id == paymentPeriodId,
    )[0]?.noOfMonths;
    if (noOfMonths) {
      setPaymentPeriodMonths(noOfMonths);
    }
  }, [servicePaymentPeriodList, paymentPeriodId]);

  useEffect(() => {
    if (Array.isArray(serviceSlots)) {
      setServiceSlotList(serviceSlots);
    }
  }, [serviceSlots]);

  useEffect(() => {
    if (servicesId > 0) {
      props.fetchServicePackages({ serviceId: servicesId });
      props.fetchServicePaymentPeriods({ serviceId: servicesId });
      props.fetchServiceSlots({ serviceId: servicesId });
      setUpdatedFields(true);
      hasPharma && props?.getAdditionalCharges({ serviceId: servicesId });
    }
  }, [servicesId]);

  useEffect(() => {
    if (
      servicePackageList.length &&
      servicePaymentPeriodList.length &&
      serviceSlotList.length
    ) {
      getPlanTotalAmount();
    }
  }, [
    servicePackageList,
    servicePaymentPeriodList,
    serviceSlotList,
    additionalCharges,
  ]);

  const getPlanTotalAmount = () => {
    let total = 0;
    const selectedPackage = servicePackageList.filter(
      x => x.id === servicePlanId,
    )[0];
    const selectedPaymentPeriod = servicePaymentPeriodList.filter(
      x =>
        x.id === parseInt(paymentPeriodId ? paymentPeriodId.toString() : '0'),
    )[0];
    const selectedBookingPerSlot = serviceSlotList.filter(
      x =>
        x.slabFrom <=
          parseInt(bookingPerSlot ? bookingPerSlot?.toString() : '0') &&
        x.slabTo >= parseInt(bookingPerSlot ? bookingPerSlot?.toString() : '0'),
    )[0];
    if (selectedPackage && selectedPaymentPeriod && selectedBookingPerSlot) {
      total =
        (selectedPaymentPeriod?.noOfMonths ?? 0) *
        (selectedPackage?.amount +
          selectedBookingPerSlot?.amount +
          (hasPharma ? parseFloat(additionalCharges?.additionalCharges) : 0));
    }
    setTotalAmount(total);
  };

  const handleValidSubmit = () => {
    if (subscriptionDetailsId > 0) {
      props.updateSubscriptionDetail({});
    } else {
      props.createSubscriptionDetail({});
    }
    const entity = {
      id: subscriptionDetailsId,
      locationId: location,
    };
    const payload = {
      serviceId: serviceId,
      entity,
    };
    props.updateVendorSubscription(payload);
  };

  const checkSubscriptionsLength = () => {
    if (diffDays > 7) {
      return true;
    }
    return false;
  };

  const handleServiceRenew = () => {
    if (totalAmount) {
      history.push({
        pathname: '/service-subscription-renewal',
        state: {
          subscriptionService: { service },
          subscriptionPlan: { plan },
          noOfBookingsPerSlot: { bookingPerSlot },
          paymentPeriod: { period },
          hasPharma: { hasPharma },
          totalAmount: { totalAmount },
          discount: { discount },
          startDate: { startDate },
          endDate: { endDate },
          servicesId: { servicesId },
          servicePlanId: { servicePlanId },
          paymentPeriodId: { paymentPeriodId },
          noOfMonths: { paymentPeriodMonths },
          renewalRemainingDays: diffDays,
        },
      });
    }
  };

  const handleAreaChange = event => {
    setLocation(event?.target?.value);
  };

  const dateDifference = expiryDate => {
    let date = new Date().toISOString();
    days = moment(expiryDate).diff(date, 'days');
    setDiffDays(days);
  };

  return (
    <div>
      {subscriptionDetails?.id ? (
        <AvForm model={subscriptionDetails} onValidSubmit={handleValidSubmit}>
          <Container
            fluid
            className="px-0 px-lg-3 p-3"
            id="subscription-details">
            <Col md="8">
              <Card className="subscription-card">
                <CardBody>
                  <Row className="mt-2 mb-2">
                    <Col md="3">
                      <p className="label">
                        <Translate contentKey="form.service.subscription.subscription_plan">
                          Subscription Plan
                        </Translate>
                      </p>
                    </Col>
                    <Col
                      className="justify-content-space-between d-flex"
                      md="3">
                      <p className="value">{plan}</p>
                    </Col>
                    <Col md="3">
                      <p className="label">
                        <Translate contentKey="form.service.subscription.start_date">
                          Start Date
                        </Translate>
                      </p>
                    </Col>
                    <Col
                      className="justify-content-space-between d-flex"
                      md="3">
                      <p className="value">
                        {moment(startDate).format(
                          DATE_TIME_FORMATS.APP_LOCAL_DATE_FORMAT,
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Col md="3">
                      <p className="label">
                        <Translate contentKey="form.service.subscription.payment_period">
                          payment period
                        </Translate>
                      </p>
                    </Col>
                    <Col
                      className="justify-content-space-between d-flex"
                      md="3">
                      <p className="value">{period}</p>
                    </Col>
                    <Col md="3">
                      <p className="label">
                        <Translate contentKey="form.service.subscription.end_date">
                          End Date
                        </Translate>
                      </p>
                    </Col>
                    <Col
                      className="justify-content-space-between d-flex"
                      md="3">
                      <p className="value">
                        {moment(expiryDate).format(
                          DATE_TIME_FORMATS.APP_LOCAL_DATE_FORMAT,
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Col md="3">
                      <p className="label">
                        <Translate contentKey="form.service.subscription.no_of_booking_per_slot">
                          No Of Bookings Per Slot
                        </Translate>
                      </p>
                    </Col>
                    <Col
                      className="justify-content-space-between d-flex"
                      md="3">
                      <p className="value">{bookingPerSlot}</p>
                    </Col>
                    <Col md="3">
                      <p className="label">
                        <Translate contentKey="form.service.subscription.service_area">
                          Service Area
                        </Translate>
                      </p>
                    </Col>
                    <Col
                      className="justify-content-space-between d-flex"
                      md="3">
                      <p className="value">{serviceArea}</p>
                    </Col>
                  </Row>
                  <Col className="mt-4" lg="12">
                    {diffDays > 0 && !subscriptionDetails.isAlreadyRenewed && (
                      <p className="text-center renew-label">
                        {diffDays}
                        <Translate contentKey="form.service.subscription.days_to_renewal">
                          Days To Renewal
                        </Translate>
                      </p>
                    )}
                    {subscriptionDetails.isAlreadyRenewed && (
                      <p className="text-center early-renew-label">
                        Your subscription has been successfully renewed and will
                        be effective after the current plan
                      </p>
                    )}
                  </Col>
                  <Row className="justify-content-center">
                    {IsPermittedAction(
                      PERMISSION_ACTIONS.RENEW_SUBSCRIPTION,
                      authorities,
                      permissionList,
                    ) && (
                      <Col className="mt-1 " lg="4">
                        {subscriptionDetailsId > 0 &&
                        (subscriptionDetails?.status ===
                          SERVICE_STATUSES.PUBLISHED ||
                          subscriptionDetails?.status ===
                            SERVICE_STATUSES.DEACTIVATED) &&
                        !subscriptionDetails.isAlreadyRenewed ? (
                          <Button
                            color="danger"
                            className="button renew-button px-4"
                            onClick={handleServiceRenew}
                            disabled={checkSubscriptionsLength()}>
                            <span className="button-text">
                              <Translate contentKey="form.service.subscription.renew">
                                Renew
                              </Translate>
                            </span>
                          </Button>
                        ) : null}
                      </Col>
                    )}
                  </Row>
                  <hr />
                  {serviceAreaId !== 1 ? (
                    <Row className="mt-2 mb-2">
                      <Col md="3 mt-2">
                        <p className="label">
                          <Translate contentKey="form.service.subscription.choose">
                            Choose
                          </Translate>
                          <span>{serviceArea}</span>
                        </p>
                      </Col>
                      <Col
                        className="justify-content-space-between d-flex"
                        md="3 mt-2">
                        <select
                          value={location}
                          onChange={event => handleAreaChange(event)}
                          placeholder="Select"
                          className="field-style">
                          <option value="" selected>
                            {translate('placeholders.select')}
                          </option>
                          {serviceAreaLocationResponse?.map(
                            (serviceAreaLocation, index) => {
                              return (
                                <option
                                  key={index}
                                  value={serviceAreaLocation?.id}>
                                  {serviceAreaLocation?.name}
                                </option>
                              );
                            },
                          )}
                        </select>
                      </Col>
                    </Row>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Container>
          <div className="bottom-button-save-container d-flex justify-content-end">
            {IsPermittedAction(
              PERMISSION_ACTIONS.EDIT_VENDOR_SERVICE_SUBSCRIPTIONS,
              authorities,
              permissionList,
            ) && (
              <Button
                type="submit"
                color="danger"
                className="m-2 text-light button-text mr-5 px-4"
                size={width > SM_SCREEN_BREAKPOINT ? '' : 'sm'}>
                {translate('placeholders.save_as_draft')}
              </Button>
            )}
          </div>
        </AvForm>
      ) : (
        <div className="text-info my-4" style={{ fontSize: 32 }}>
          {props.loading && <FontAwesomeIcon icon={faSpinner} spin />}
          {!props.loading && (
            <img
              src={EmptyContent}
              alt="no-data"
              style={{
                padding: ' 7% 25% 0% 25%',
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ authentication, user, plan }: IRootState) => ({
  loading: user.loading,
  servicesLinks: plan.servicesLinks,
  subscriptionDetails: user.subscriptionDetails,
  createSubscriptionDetailResponse: user.createSubscriptionDetailResponse,
  updateSubscriptionDetailResponse: user.updateSubscriptionDetailResponse,
  serviceAreaLocationResponse: user.serviceAreaLocationResponse,
  updateVendorSubscriptionAreaResponse:
    user.updateVendorSubscriptionAreaResponse,
  servicePackages: user?.servicePackages,
  servicePaymentPeriods: user?.servicePaymentPeriods,
  serviceSlots: user?.serviceSlots,
  additionalCharges: user?.additionalChargesResponse,
});

const mapDispatchToProps = {
  getSubscriptionDetail,
  createSubscriptionDetail,
  updateSubscriptionDetail,
  getServiceAreaLocation,
  getServicesMenus,
  updateVendorSubscription,
  fetchServicePackages,
  fetchServicePaymentPeriods,
  fetchServiceSlots,
  getAdditionalCharges,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscriptionDetails);
