import { useState, useEffect } from 'react';
import { Colors } from '../../config/constants';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const getRandomIconColor = () => {
  const bgColor = [
    Colors.greyTeal,
    Colors.charcoalGrey,
    Colors.geraldine,
    Colors.black,
    Colors.darkRedColor,
  ];
  return bgColor[Math.floor(Math.random() * bgColor.length)];
};
