import React, { useEffect, useState } from 'react';
import './style.scss';
import {
  Modal,
  Row,
  Col,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import VideoCallComponent from './components/video-component';
import PrescriptionEntry from '../components/prescription-entry';
import { createMicrophoneAndCameraTracks } from 'agora-rtc-react';
import CallConfirmation from '../components/status-confirmation';
import { APPOINTMENT_STATUS } from '../../config/constants';

const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

const OnlineVideoCall = ({
  appointmentId,
  handleCloseVideo,
  setUsers,
  users,
  doctor,
  ready,
  callStatus,
  tracks,
  setAudioVideoTracks,
  consultationDetails,
  setCallStatus,
  videoStarted,
  start,
  setStart,
  remoteAudio,
  remoteVideo,
  trackState,
  setTrackState,
}) => {
  const [channelName, setChannelName] = useState(appointmentId);
  const [inCall, setInCall] = useState(true);
  const [showPrescriptionPopup, setShowPrescriptionPopup] = useState(false);
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false)
  

  useEffect(() => {
    if (!inCall) handleCloseVideo();
  }, [inCall]);

  const { ready: videoReady, tracks: videoAudioTracks } =
    useMicrophoneAndCameraTracks();

  useEffect(() => {
    if (!ready) {
      setAudioVideoTracks(videoReady, videoAudioTracks);
    } else {
      if (videoAudioTracks?.length) {
        videoAudioTracks[0].close();
        videoAudioTracks[1].close();
      }
    }
  }, [videoAudioTracks]);

  const togglePrescriptionModal = () =>
    setShowPrescriptionPopup(!showPrescriptionPopup);

  return (
    <div id="video-call">
      {inCall && (
        <VideoCallComponent
          handleCloseVideo={handleCloseVideo}
          setInCall={setInCall}
          channelName={channelName}
          setUsers={setUsers}
          users={users}
          ready={ready}
          callStatus={callStatus}
          tracks={tracks}
          onClickEditPrescription={togglePrescriptionModal}
          setCallStatus={setCallStatus}
          videoStarted={videoStarted}
          start={start}
          setStart={setStart}
          remoteAudio={remoteAudio}
          remoteVideo={remoteVideo}
          trackState={trackState}
          setTrackState={setTrackState}
        />
      )}
      <Modal
        className="prescription-entry-modal"
        isOpen={showPrescriptionPopup}
        centered>
        <ModalBody>
          <PrescriptionEntry
            appointmentId={appointmentId}
            onCloseModal={togglePrescriptionModal}
            doctor={doctor}
            consultationDetails={consultationDetails}
            isShowConfirmationModal={isShowConfirmationModal}
            setIsShowConfirmationModal={setIsShowConfirmationModal}
          />
        </ModalBody>
      </Modal>
      {callStatus !== APPOINTMENT_STATUS.COMPLETED && <CallConfirmation 
            appointmentId={appointmentId}
            isShowConfirmationModal={isShowConfirmationModal}
            setIsShowConfirmationModal={setIsShowConfirmationModal}
            setCallStatus={setCallStatus}
          />}
    </div>
  );
};

export default OnlineVideoCall;
