import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import SubHeader from '../../shared/layout/sub-header';
import { IRootState } from '../../shared/reducers';
import 'react-multi-carousel/lib/styles.css';
import './style.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
  fetchMaxBookingPerSlot,
  getAppointments,
  getDefaultSlots,
  getSlots,
  scheduleAppointment,
  updateAppointmentSchedule,
} from '../../reducers/appointment.reducer';
import { getServicesMenus } from '../../reducers/plan.reducer';
import { IMenu, ISubMenu } from '../../model/user-menu';
import {
  APPOINTMENT_SCHEDULE_SHIFT_LABELS,
  HTTP_OK_RESPONSE,
  SM_SCREEN_BREAKPOINT,
  DATE_TIME_FORMATS,
  WEEK_DAYS,
  DEFAULT_NUMBER_OF_BOOKING_PER_SLOT,
  TIME_TYPES,
  FORM_ACTION_TYPES,
  INITIAL_TIME,
  SHIFT_TIMES,
  Colors,
  TOAST_TIMEOUT,
} from '../../config/constants';
import moment from 'moment-timezone';
import { translate } from 'react-jhipster';
import { getTabs } from '../../shared/layout/sub-header/tabs';
import { toast } from 'react-toastify';
import { useWindowDimensions } from '../../shared/util/utility-functions';
import Button from '../components/button';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { convertToTwentyFourFormat } from '../../shared/util/date-utils';
import { IoMdAdd } from 'react-icons/io';
import { AiOutlineDelete } from 'react-icons/ai';
import { TimePickerWidget } from './timepicker-widget';
import { IsPermittedAction } from '../../shared/util/permission-utils';
import { PERMISSION_ACTIONS } from '../../config/permission-constants';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export const AppointmentSchedule = (props: any) => {
  const { servicesLinks, defaultSlots, fetchMaxBookingPerSlotResponse } = props;
  const [selectedMenu, setMenu] = useState({} as IMenu);
  const [selectedSubMenu, setSubMenu] = useState({} as ISubMenu);
  const [timeValidationMsg, setTimeValidationMsg] = useState('');
  const [selectedTime, setSelectedTime] = useState({
    fromTime: INITIAL_TIME,
    toTime: INITIAL_TIME,
    shiftLabel: '',
  });
  const [formLoading, setFormLoading] = useState(false);
  const [scheduleDetail, setScheduleDetail] = useState(WEEK_DAYS);
  const selectedDay = scheduleDetail.filter(x => x.isSelected)[0];
  const serviceId = props.serviceId;
  const [fetchMaxBookingPerSlotLoading, setFetchMaxBookingPerSlotLoading] =
    useState(false);
  const [defaultNoOfBookingPerSlot, setDefaultNoOfBookingPerSlot] = useState(
    DEFAULT_NUMBER_OF_BOOKING_PER_SLOT,
  );
  const [multiSelectedDays, setMultiSelectedDays] = useState([] as any[]);

  const { HH_MM_SS, TWENTY_FOUR_HH_MM, TWELVE_HH_MM_A } = DATE_TIME_FORMATS;

  useEffect(() => {
    setFetchMaxBookingPerSlotLoading(true);
  }, []);

  useEffect(() => {
    props.fetchMaxBookingPerSlot({ serviceId });
  }, [serviceId]);

  useEffect(() => {
    if (fetchMaxBookingPerSlotLoading) {
      if (fetchMaxBookingPerSlotResponse.status === HTTP_OK_RESPONSE) {
        let scheduleDetails = [...scheduleDetail];
        const maxBookingPerSlot =
          fetchMaxBookingPerSlotResponse?.data?.maxBookingPerSlot;
        scheduleDetails.map(item => {
          item.bookingPerSlot = maxBookingPerSlot ?? 1;
          return item;
        });
        setScheduleDetail(scheduleDetails);
      }
    }
    if (fetchMaxBookingPerSlotResponse?.data?.maxBookingPerSlot)
      setDefaultNoOfBookingPerSlot(
        fetchMaxBookingPerSlotResponse?.data?.maxBookingPerSlot,
      );
  }, [fetchMaxBookingPerSlotResponse]);

  useEffect(() => {
    if (serviceId) {
      props.getServicesMenus(serviceId);
      props.getDefaultSlots({ serviceId });
    }
  }, [serviceId]);

  useEffect(() => {
    if (Array.isArray(defaultSlots) && defaultSlots[0] !== null) {
      let scheduleList: any[] = [];
      scheduleDetail.forEach((item, i) => {
        item?.workingHours.map((hour: any) => {
          hour.isDeleted = hour.isDeleted === null ? false : hour.isDeleted;
          hour.fromTime = convertToTwentyFourFormat(hour?.fromTime);
          hour.toTime = convertToTwentyFourFormat(hour?.toTime);
          return hour;
        });
        scheduleList.push({
          ...scheduleDetail[i],
          ...defaultSlots
            .map((slot, index) => {
              if (
                slot.workingHours.filter((x: any) => !x?.isDeleted)?.length &&
                scheduleDetail.every(x => !x?.isSelected) &&
                index === 0
              ) {
                slot.isSelected = true;
              }
              slot?.workingHours.map(hr => {
                hr.fromTime = convertToTwentyFourFormat(hr?.fromTime);
                hr.toTime = convertToTwentyFourFormat(hr?.toTime);
                return hr;
              });
              return slot;
            })
            .find(itmInner => itmInner?.day === scheduleDetail[i]?.day),
        });
        return item;
      });
      setScheduleDetail(scheduleList);
    } else {
      WEEK_DAYS.forEach(item => {
        item.isSelected = false;
        item.workingHours = [];
        return item;
      });
      setScheduleDetail(WEEK_DAYS);
      setMultiSelectedDays([]);
    }
  }, [defaultSlots]);

  useEffect(() => {
    if (servicesLinks?.length) {
      servicesLinks?.forEach((menu, i) => {
        if (window.location.pathname.includes(menu?.url ?? '')) {
          setSubMenu(menu);
        }
      });
    }
  }, [servicesLinks]);

  const addWorkingHoursBasedOnMultiSelect = (day, type) => {
    let scheduleList = [...scheduleDetail];
    let multiSelectedDayList: any = [...multiSelectedDays];
    let checkDataExistingItem = scheduleList.filter(x =>
      x.workingHours?.some((x: any) => !x.isDeleted && !x?.id),
    )[0];
    let index = scheduleList.findIndex(x => x.day === day);
    if (type === FORM_ACTION_TYPES.ADD) {
      scheduleList.forEach(item => {
        item.isSelected = false;
        return item;
      });
      scheduleList[index].isSelected = true;
      scheduleList[index].workingHours =
        checkDataExistingItem?.workingHours ?? [];
      scheduleList[index].bookingPerSlot =
        (checkDataExistingItem?.bookingPerSlot ??
          scheduleList[index].bookingPerSlot) ||
        0;
    } else {
      scheduleList[index].isSelected = false;
      scheduleList[index].workingHours = [];
      multiSelectedDayList.map(item => {
        let existIndex = scheduleList.findIndex(x => x.day === item);
        if (existIndex !== -1 && existIndex !== index) {
          scheduleList[existIndex].isSelected = true;
        } else {
          scheduleList[existIndex].isSelected = false;
        }
        return item;
      });
    }
    setScheduleDetail(scheduleList);
  };

  const onChangeNoOfBookingPerSlot = (event, selectedDay) => {
    let scheduleList = [...scheduleDetail];
    if (
      multiSelectedDays?.length &&
      multiSelectedDays.some(x => x === selectedDay?.day)
    ) {
      multiSelectedDays.forEach(item => {
        let findExistDay = scheduleList.find(x => x.day === item);
        if (findExistDay) {
          findExistDay.bookingPerSlot = event.target.value;
        }
        return item;
      });
    } else {
      scheduleList.forEach(item => {
        if (item.day === selectedDay?.day) {
          item.bookingPerSlot = event.target.value;
        }
        return item;
      });
    }
    setScheduleDetail(scheduleList);
  };

  const onChangeDay = schedule => {
    let workingHours =
      schedule.workingHours.filter((x: any) => !x.isDeleted && x?.id) ?? [];
    if (workingHours?.length === 0) {
      let multiSelectedDayList: any = [...multiSelectedDays];
      let findExistIndex = multiSelectedDayList.findIndex(
        x => x === schedule?.day,
      );
      if (findExistIndex !== -1) {
        multiSelectedDayList.splice(findExistIndex, 1);
        addWorkingHoursBasedOnMultiSelect(
          schedule?.day,
          FORM_ACTION_TYPES.REMOVE,
        );
      } else {
        multiSelectedDayList.push(schedule?.day);
        addWorkingHoursBasedOnMultiSelect(schedule?.day, FORM_ACTION_TYPES.ADD);
      }
      setMultiSelectedDays(multiSelectedDayList);
    } else {
      setMultiSelectedDays([]);
      let scheduleList = [...scheduleDetail];
      scheduleList.forEach(item => {
        if (item.day === schedule.day) {
          item.isSelected = true;
        } else {
          item.isSelected = false;
        }
        return item;
      });
      setScheduleDetail(scheduleList);
    }
  };

  const onChangeTime = ({
    time,
    selectedDay,
    hour,
    timeType,
    isNew,
    index,
  }) => {
    setIsInvalidPeriod(false);
    if (!timeAlreadyExistInRange({ time, timeType, index })) {
      if (!isNew) {
        let scheduleList = [...scheduleDetail];
        scheduleList = scheduleList.map((item: any) => {
          let hourIndex = item.workingHours.indexOf(hour);
          if (multiSelectedDays?.length) {
            multiSelectedDays.forEach((item, index) => {
              let existItem = scheduleList.find(x => x.day === item) as any;
              if (existItem && hourIndex !== -1) {
                existItem.workingHours[hourIndex][timeType] = time;
              }
              return item;
            });
          } else if (item.day === selectedDay?.day) {
            item.workingHours[hourIndex][timeType] = time;
          }
          return item;
        });

        setScheduleDetail(scheduleList);
      } else {
        setSelectedTime(prevState => ({
          ...prevState,
          [timeType]: time,
        }));
      }
    } else {
      toast.error(translate('entity.validation.appointment.time_period'), {
        autoClose: TOAST_TIMEOUT,
      });
    }
  };

  const timeIntervalValidator = (
    time,
    fromTime,
    toTime,
    updatingWorkingHour,
    timeType,
    widgetFromTime,
    widgetToTime,
  ) => {
    const convertedFromTime = moment(fromTime, HH_MM_SS);
    const convertedToTime = moment(toTime, HH_MM_SS);
    if (
      widgetFromTime.isBetween(convertedFromTime, convertedToTime) ||
      widgetToTime.isBetween(convertedFromTime, convertedToTime)
    ) {
      return true;
    } else if (
      !widgetFromTime.isSameOrBefore(convertedFromTime) &&
      !widgetFromTime.isSameOrAfter(convertedToTime)
    ) {
      return true;
    } else if (
      !widgetToTime.isSameOrBefore(convertedToTime) &&
      !widgetToTime.isSameOrAfter(convertedFromTime)
    ) {
      return true;
    } else if (widgetToTime.isSame(widgetFromTime)) {
      return true;
    } else if (
      convertedFromTime.isBetween(widgetFromTime, widgetToTime) &&
      convertedToTime.isBetween(widgetFromTime, widgetToTime)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [isInvalidPeriod, setIsInvalidPeriod] = useState(false);
  const resetError = () => {
    setIsInvalidPeriod(false);
  };
  const timeAlreadyExistInRange = ({ time, timeType, index }) => {
    const workingHours = selectedDay?.workingHours.filter(
      (x: any, i) => i !== index && !x.isDeleted && x.fromTime && x.toTime,
    );
    const updatingWorkingHour = selectedDay?.workingHours.filter(
      (x, i) => i === index,
    )[0] as any;
    let isExist = false;
    const widgetFromTime = moment(
      `${
        timeType === TIME_TYPES.FROM_TIME && time
          ? time
          : updatingWorkingHour?.fromTime
      }`,
      HH_MM_SS,
    );
    const widgetToTime = moment(
      `${
        timeType === TIME_TYPES.TO_TIME && time
          ? time
          : updatingWorkingHour?.toTime
      }`,
      HH_MM_SS,
    );
    if (
      widgetToTime.isBefore(widgetFromTime) ||
      widgetFromTime.isAfter(widgetToTime)
    ) {
      isExist = true;
      setIsInvalidPeriod(true);
      return isExist;
    }

    if (workingHours?.length) {
      const value = workingHours.map((day: any) =>
        timeIntervalValidator(
          time,
          day.fromTime,
          day.toTime,
          updatingWorkingHour,
          timeType,
          widgetFromTime,
          widgetToTime,
        ),
      );
      isExist = value.reduce((a, b) => a || b);
    }
    setIsInvalidPeriod(true);
    return isExist;
  };
  const [addWorkingHoursIsSuccess, setAddWorkingHoursIsSuccess] =
    useState(true);
  const onAddWorkingHours = selectedDay => {
    setAddWorkingHoursIsSuccess(false);
    let scheduleList = [...scheduleDetail];
    scheduleList = scheduleList.map((item: any, index) => {
      const shiftLabel = APPOINTMENT_SCHEDULE_SHIFT_LABELS.filter(
        label =>
          item.workingHours.findIndex(x => x.shiftLabel === label) === -1,
      )[0];
      const morningTime = shiftLabel === APPOINTMENT_SCHEDULE_SHIFT_LABELS[0];
      const afternoonTime = shiftLabel === APPOINTMENT_SCHEDULE_SHIFT_LABELS[1];
      const eveningTime = shiftLabel === APPOINTMENT_SCHEDULE_SHIFT_LABELS[2];
      const initialFromTime = morningTime
        ? SHIFT_TIMES.morning.fromTime
        : afternoonTime
        ? SHIFT_TIMES.afternoon.fromTime
        : eveningTime
        ? SHIFT_TIMES.evening.fromTime
        : '';
      const initialToTime = morningTime
        ? SHIFT_TIMES.morning.toTime
        : afternoonTime
        ? SHIFT_TIMES.afternoon.toTime
        : eveningTime
        ? SHIFT_TIMES.evening.toTime
        : '';
      if (shiftLabel) {
        if (multiSelectedDays?.length) {
          let checkDayExist =
            multiSelectedDays.findIndex(x => x === item.day) !== -1;
          let findExistingIndex = item.workingHours.findIndex(
            x =>
              x.fromTime === initialFromTime &&
              x.toTime === initialToTime &&
              x.shiftLabel === shiftLabel &&
              !x.isDeleted,
          );
          if (checkDayExist && findExistingIndex === -1) {
            item.workingHours.push({
              fromTime: initialFromTime,
              toTime: initialToTime,
              shiftLabel: shiftLabel,
              isDeleted: false,
            });
            setAddWorkingHoursIsSuccess(true);
          }
        } else if (item.day === selectedDay?.day) {
          item.workingHours.push({
            fromTime: initialFromTime,
            toTime: initialToTime,
            shiftLabel,
            isDeleted: false,
          });
          setAddWorkingHoursIsSuccess(true);
        }
      }
      return item;
    });
    setScheduleDetail(scheduleList);
  };

  const onRemoveWorkingHour = (event, selectedDay, hour) => {
    event.preventDefault();
    let scheduleList = [...scheduleDetail];
    scheduleList = scheduleList.map((item: any) => {
      if (multiSelectedDays?.length) {
        const checkDayExist =
          multiSelectedDays.findIndex(x => x === item.day) !== -1;
        if (checkDayExist) {
          const hourIndex = item.workingHours.indexOf(hour);
          if (item.workingHours[hourIndex]?.id) {
            item.workingHours[hourIndex].isDeleted = true;
          } else {
            item.workingHours.splice(hourIndex, 1);
          }
        }
      } else {
        if (item.day === selectedDay?.day) {
          let hourIndex = item.workingHours.indexOf(hour);
          if (item.workingHours[hourIndex]?.id) {
            item.workingHours[hourIndex].isDeleted = true;
          } else {
            item.workingHours.splice(hourIndex, 1);
          }
        }
      }
      return item;
    });
    setScheduleDetail(scheduleList);
  };

  const shiftLabelValidator = (label: string) => {
    return (
      selectedDay?.workingHours?.filter(
        (hour: any) => !hour.isDeleted && hour.shiftLabel === label,
      ).length === 0
    );
  };
  const onChangeShift = ({ event, selectedDay, hour, isNew }) => {
    event.preventDefault();
    if (shiftLabelValidator(event.target.value)) {
      if (!isNew) {
        let scheduleList = [...scheduleDetail];
        scheduleList = scheduleList.map((item: any) => {
          let hourIndex = item.workingHours.indexOf(hour);
          if (multiSelectedDays?.length) {
            multiSelectedDays.forEach((item, index) => {
              let existItem = scheduleList.find(x => x.day === item) as any;
              if (existItem && hourIndex !== -1) {
                existItem.workingHours[hourIndex].shiftLabel =
                  event.target.value;
              }
              return item;
            });
          } else if (item.day === selectedDay?.day) {
            item.workingHours[hourIndex].shiftLabel = event.target.value;
          }
          return item;
        });
        setScheduleDetail(scheduleList);
      } else {
        setSelectedTime(prevState => ({
          ...prevState,
          shiftLabel: event.target.value,
        }));
      }
    } else toast.error(translate('entity.validation.appointment.shift_label'));
  };

  const checkBookingSlot = () => {
    const getWorkingHoursLength = workingHours =>
      workingHours?.filter((x: any) => !x.isDeleted && x.id).length;
    let checkBookingSlot =
      scheduleDetail?.filter(
        x => getWorkingHoursLength(x.workingHours) > 0 && !x.bookingPerSlot,
      )?.length > 0;
    return checkBookingSlot;
  };

  const [isRequestComplete, setIsRequestedComplete] = useState(true);
  const onPressSave = () => {
    setAddWorkingHoursIsSuccess(true);
    const isEdit =
      scheduleDetail.filter(
        x =>
          x.workingHours?.length > 0 &&
          x.workingHours.filter((hour: any) => hour?.id),
      ).length > 0;
    if (!checkBookingSlot()) {
      setFormLoading(true);
      scheduleDetail.forEach(sch => {
        sch.workingHours.forEach((hour: any) => {
          hour.isDeleted = hour.isDeleted ?? false;
          return hour;
        });
        return sch;
      });
      if (isEdit) {
        props.updateAppointmentSchedule({
          serviceId,
          slots: scheduleDetail,
        });
      } else {
        props.scheduleAppointment({
          serviceId,
          slots: scheduleDetail,
        });
      }
      setIsRequestedComplete(false);
    }
  };

  useEffect(() => {
    if (formLoading) {
      if (
        props?.updateAppointmentScheduleResponse.status === HTTP_OK_RESPONSE
      ) {
        setMultiSelectedDays([]);
        props.getDefaultSlots({ serviceId });
        setFormLoading(false);
      }
      setIsRequestedComplete(true);
    }
  }, [props?.updateAppointmentScheduleResponse]);
  const { width } = useWindowDimensions();
  const getRemoveAlreadySelectedShiftLabels = () => {
    return APPOINTMENT_SCHEDULE_SHIFT_LABELS.filter(label => {
      return (
        selectedDay?.workingHours?.filter(
          (hour: any) => !hour.isDeleted && hour.shiftLabel === label,
        ).length === 0
      );
    });
  };

  const dayCard = (schedule, workingHoursExist) => {
    let checkExistInDayList =
      multiSelectedDays.findIndex(x => x === schedule?.day) !== -1;

    return (
      <div
        onClick={() => onChangeDay(schedule)}
        className={`justify-content-center d-flex day-button 
        ${
          workingHoursExist
            ? selectedDay?.day === schedule?.day
              ? 'selected-day-card-with-hours'
              : 'day-button-with-hour'
            : checkExistInDayList
            ? 'selected-day-card-without-hour'
            : 'day-button-without-hour'
        }`}>
        <p className="text-center day-text mb-0 pt-3 pb-3 pr-3 pl-3">
          {schedule.dayText}
        </p>
      </div>
    );
  };

  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );

  const validateEditAndCreatePermission = () => {
    return (
      IsPermittedAction(
        PERMISSION_ACTIONS.CREATE_VENDOR_SERVICE_DEFAULT_SLOT,
        authorities,
        permissionList,
      ) &&
      IsPermittedAction(
        PERMISSION_ACTIONS.UPDATE_VENDOR_SERVICE_DEFAULT_SLOT,
        authorities,
        permissionList,
      )
    );
  };

  const renderScheduleForm = () => {
    return (
      <Row className="justify-content-center">
        <Col className="m-0" md={6}></Col>
        {validateEditAndCreatePermission() && (
          <Col className="m-0 add-time-button-col" md={1}>
            <Label className="label"></Label>
            <div
              onClick={() => onAddWorkingHours(selectedDay)}
              className="add-time-button align-items-center d-flex justify-content-center pt-2 pb-2">
              {selectedDay?.workingHours.filter((x: any) => !x.isDeleted)
                .length >= 0 ? (
                !addWorkingHoursIsSuccess ? (
                  <label className="ps-1 pe-1 add-time-error-handler">
                    {translate(
                      'form.service.appointment_schedule.add_shift_error_handler',
                    )}
                  </label>
                ) : (
                  <IoMdAdd className="text-light" />
                )
              ) : (
                <small className="text-light">
                  {translate('form.service.appointment_schedule.add_shift')}
                </small>
              )}
            </div>
          </Col>
        )}
      </Row>
    );
  };

  const renderAppointmentItem = (hour, index) => {
    return (
      <Row className="justify-content-center">
        <Col className="m-0" md={2}>
          <Label className="label">
            {translate('form.service.appointment_schedule.shift_label')}
          </Label>
          <div key={index} className="form-group">
            <select
              value={hour.shiftLabel}
              onChange={e =>
                onChangeShift({
                  event: e,
                  selectedDay,
                  hour,
                  isNew: false,
                })
              }
              style={{
                width: '100%',
              }}>
              <option value={0} disabled>
                {translate('appointment_form.select_service')}
              </option>
              {APPOINTMENT_SCHEDULE_SHIFT_LABELS?.map((shift, i) => (
                <option key={i} value={shift}>
                  {shift}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col className="m-0" md={2}>
          <Label className="label">
            {translate('form.service.appointment_schedule.from_text')}
          </Label>

          <div className="form-group">
            <TimePickerWidget
              resetError={resetError}
              time={
                hour?.fromTime
                  ? moment(hour?.fromTime, TWENTY_FOUR_HH_MM)
                  : undefined
              }
              selectedDay={selectedDay}
              hour={hour}
              timeType={TIME_TYPES.FROM_TIME}
              isNew={false}
              key={index}
              index={index}
              onChangeTime={onChangeTime}
              error={isInvalidPeriod}
            />
          </div>
          {timeValidationMsg && (
            <p className="text-danger">{timeValidationMsg}</p>
          )}
        </Col>
        <Col className="m-0" md={2}>
          <Label className="label">
            {translate('form.service.appointment_schedule.to_text')}
          </Label>
          <div className="form-group">
            <TimePickerWidget
              resetError={resetError}
              time={
                hour?.toTime
                  ? moment(hour?.toTime, TWENTY_FOUR_HH_MM)
                  : undefined
              }
              selectedDay={selectedDay}
              hour={hour}
              timeType={TIME_TYPES.TO_TIME}
              isNew={false}
              key={index}
              index={index}
              onChangeTime={onChangeTime}
              error={isInvalidPeriod}
            />
          </div>
        </Col>

        {validateEditAndCreatePermission() && (
          <Col className="m-0 add-time-button-col" md={1}>
            <Label className="label"></Label>
            <div
              onClick={e => onRemoveWorkingHour(e, selectedDay, hour)}
              className="close-button align-items-center d-flex justify-content-center pt-2 pb-2">
              <p className="text-danger mb-0 pt-2">
                <AiOutlineDelete size={20} color={Colors.geraldine} />
              </p>
            </div>
          </Col>
        )}
      </Row>
    );
  };

  return (
    <div>
      <Container
        fluid
        className="px-0 px-lg-3 p-0 pb-5 mb-2"
        id="schedule-details">
        <Row className="justify-content-center px-3 my-2">
          <Col lg={4}></Col>
          <Col
            lg={4}
            className="d-flex justify-content-center align-items-center">
            <p className="text-center header m-0">
              {translate('form.service.appointment_schedule.days_header')}
            </p>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-end"
            lg={4}></Col>
        </Row>

        <Col>
          <Row>
            {scheduleDetail.map((schedule, index) => {
              let workingHoursExist =
                schedule?.workingHours?.some(
                  (x: any) => !x.isDeleted && x.id,
                ) ?? false;
              return (
                <Col className="m-2" key={index}>
                  {dayCard(schedule, workingHoursExist)}
                </Col>
              );
            })}
          </Row>
        </Col>
        <hr />
        <Row>
          <Col lg={4} />
          <Col lg={4}>
            <p className="text-center header">
              {translate('form.service.appointment_schedule.times_header')}
            </p>
            <p className="duration-text">
              {translate('form.service.appointment_schedule.slot_duration_msg')}
            </p>
          </Col>
          <Col lg={4} />
        </Row>

        {selectedDay?.workingHours
          .filter((x: any) => !x.isDeleted)
          .map((hour: any, index) => {
            return (
              <Col key={index}>
                {renderAppointmentItem(hour, index)}
                <hr />
              </Col>
            );
          })}
        <Col>
          {getRemoveAlreadySelectedShiftLabels().length > 0 &&
            renderScheduleForm()}
        </Col>

        <Col>
          <Row className="justify-content-center">
            <Col md={2} />
            <Col className="m-2" md={2}>
              <Label className="label text-center m-2">
                {translate('form.service.subscription.no_of_booking_per_slot')}
              </Label>
              <select
                value={selectedDay?.bookingPerSlot ?? '1'}
                onChange={event =>
                  onChangeNoOfBookingPerSlot(event, selectedDay)
                }
                placeholder="Select"
                style={{
                  width: '100%',
                }}>
                <option value="" selected>
                  {translate('placeholders.select')}
                </option>
                {Array.from({ length: defaultNoOfBookingPerSlot })?.map(
                  (item, index) => (
                    <option key={index}>{index + 1}</option>
                  ),
                )}
              </select>
            </Col>
            <Col md={3} />
          </Row>
        </Col>
      </Container>
      <div className="bottom-button-save-container d-flex justify-content-end">
        {validateEditAndCreatePermission() && (
          <Button
            type="submit"
            color="danger"
            className="m-1 text-light button-text px-4"
            size={width > SM_SCREEN_BREAKPOINT ? '' : 'sm'}
            onClick={onPressSave}
            disabled={!isRequestComplete}>
            {translate('placeholders.save')}
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  authentication,
  locale,
  user,
  appointment,
  plan,
}: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loading: user.loading,
  appointments: appointment.appointments,
  slots: appointment.slots,
  servicesLinks: plan.servicesLinks,
  scheduleAppointmentResponse: appointment.scheduleAppointmentResponse,
  defaultSlots: appointment.defaultSlots,
  updateAppointmentScheduleResponse:
    appointment.updateAppointmentScheduleResponse,
  fetchMaxBookingPerSlotResponse: appointment?.fetchMaxBookingPerSlotResponse,
});

const mapDispatchToProps = {
  getAppointments,
  getSlots,
  getServicesMenus,
  scheduleAppointment,
  getDefaultSlots,
  updateAppointmentSchedule,
  fetchMaxBookingPerSlot,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppointmentSchedule);
