import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, ModalBody, Button } from 'reactstrap';
import { getPrescriptionByAppointment } from '../../../reducers/prescription.reducer';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { FaPen } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { translate } from 'react-jhipster';
import { PERMISSION_ACTIONS } from '../../../config/permission-constants';
import { IsPermittedAction } from '../../../shared/util/permission-utils';
import PrescriptionEntry from '../../components/prescription-entry';
import {
  APPOINTMENT_STATUS,
  Colors,
  HISTORY_LENGTH_FROM_REPORT_SCREEN,
  UNASSIGNED,
} from '../../../config/constants';
import CallConfirmation from '../../components/status-confirmation';
import moment from 'moment-timezone';

const inPatient = 'In Patient';

const PrescriptionDetail = ({
  appointmentId,
  doctor,
  consultationDetails,
  consultationType,
  historyLength,
}) => {
  const dispatch = useDispatch();
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [callStatus, setCallStatus] = useState('');
  const [prescription, setPrescription] = useState([]) as any;
  const [prescriptionId, setPrescriptionId] = useState('');
  const [isAddPrescription, setIsAddPrescription] = useState(false);

  const loading = useSelector(
    (state: IRootState) => state.prescription.loading,
  );
  const prescriptionResponse = useSelector(
    (state: IRootState) => state.prescription.prescription,
  );

  const account = useSelector(
    (state: IRootState) => state.authentication.account,
  );

  const [showPrescriptionPopup, setShowPrescriptionPopup] = useState(false);

  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );

  useEffect(() => {
    if (prescriptionResponse?.prescriptions?.length)
      setPrescription(prescriptionResponse?.prescriptions);
  }, [prescriptionResponse]);

  const togglePrescriptionModal = () =>
    setShowPrescriptionPopup(!showPrescriptionPopup);

  useEffect(() => {
    dispatch(getPrescriptionByAppointment(appointmentId));
  }, []);

  const renderTextWithTitle = (title, value) => (
    <Row className="table-item mt-2 bg-white">
      <span className="title">{title}</span>
      <span
        className="table-text description-text mt-2 "
        style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
        {value ?? ''}
      </span>
    </Row>
  );

  const onEditClick = id => {
    setPrescriptionId(id);
    setIsAddPrescription(false);
    togglePrescriptionModal();
  };

  const onAddPrescription = () => {
    setIsAddPrescription(true);
    togglePrescriptionModal();
  };

  return (
    <div id="prescription-detail">
      {loading ? (
        <div
          className="text-info my-4 d-flex justify-content-center"
          style={{ fontSize: 32 }}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      ) : (
        <Row className="d-flex">
          <Col
            className={`main-container ${prescriptionResponse?.prescriptions?.length ? '' : 'mw-100'
              }`}>
            {prescriptionResponse?.prescriptions?.length ? (
              <>
                <Col className="prescription-container">
                  <Row
                    className="header-container"
                    style={{ backgroundColor: 'var(--bs-light-cyan)' }}>
                    {consultationType != inPatient ? <Col className="p-0">
                      <h5 className="header-texts">{doctor ?? UNASSIGNED}</h5>
                      <h5 className="header-texts">
                        {consultationDetails?.qualification ?? ''}
                      </h5>
                    </Col> : null}
                    {consultationType == inPatient ? (
                      <Col className="d-flex align-items-end flex-column">
                        {IsPermittedAction(
                          PERMISSION_ACTIONS.CREATE_APPOINTMENT_PRESCRIPTION,
                          authorities,
                          permissionList,
                        ) && (
                            <>
                              {historyLength >
                                HISTORY_LENGTH_FROM_REPORT_SCREEN && (
                                  <Button
                                    onClick={onAddPrescription}
                                    className="submit-btn add-prescription-inpatient text-white">
                                    {translate(
                                      'prescription_entry.add_prescription',
                                    )}
                                  </Button>
                                )}
                            </>
                          )}
                      </Col>
                    ) : null}
                  </Row>

                  <Row
                    className="bottom-container justify-content-center"
                    style={{ backgroundColor: 'white' }}>
                    {prescription?.map((prescriptionItem, index) => (
                      <Row key={index} className="prescription-item-container">
                        <div className="d-flex justify-content-between pb-3">
                          <div>
                            <div style={{ fontSize: '14px', fontWeight: 600 }}>
                              {moment(prescriptionItem?.createdDate).format(
                                'DD-MM-YYYY h:mm A',
                              )}
                            </div>
                            {consultationType == inPatient ? (
                              <>
                                <div style={{ paddingTop: '15px' }}>{prescriptionItem?.doctorName}</div>
                                <div >{prescriptionItem?.qualifications}</div>
                              </>) : null}
                          </div>
                          {prescription?.length &&
                            IsPermittedAction(
                              PERMISSION_ACTIONS.UPDATE_APPOINTMENT_PRISCRIPTION,
                              authorities,
                              permissionList,
                            ) && (
                              <>
                                {historyLength >
                                  HISTORY_LENGTH_FROM_REPORT_SCREEN && account?.id === prescriptionItem?.doctorId && (
                                    <div className="w-auto">
                                      <div
                                        onClick={() =>
                                          onEditClick(prescriptionItem?.id)
                                        }
                                        style={{ cursor: 'pointer' }}>
                                        <FaPen />
                                      </div>
                                    </div>
                                  )}
                              </>
                            )}
                        </div>
                        {prescriptionItem?.prescriptionItems?.length ? (
                          <Row
                            className="table-item"
                            style={{
                              fontWeight: 600,
                              backgroundColor: '#c9dfda',
                            }}>
                            <Col
                              md={1}
                              className="title justify-content-center">
                              {translate('prescription_entry.serial_number')}
                            </Col>
                            <Col md={4} className="title">
                              {translate('prescription_entry.medicine_name')}
                            </Col>
                            <Col md={2} className="title">
                              {translate('prescription_entry.time')}
                            </Col>
                            <Col md={2} className="title">
                              {translate('prescription_entry.duration')}
                            </Col>
                            <Col md={3} className="title">
                              {translate('prescription_entry.direction')}
                            </Col>
                          </Row>
                        ) : null}

                        {prescriptionItem?.prescriptionItems?.map(
                          (item, index) => (
                            <Row
                              key={index}
                              className="table-item mt-2 bg-white p-3">
                              <Col
                                md={1}
                                className="title justify-content-center d-flex table-text">
                                <span style={{ marginRight: 5 }}>
                                  {index + 1}
                                </span>
                              </Col>
                              <Col md={4} className="title d-block">
                                <span className="table-text">
                                  {item?.medicineName}
                                </span>
                              </Col>
                              <Col md={2} className="title">
                                <span className="table-text">
                                  {item?.dosage}
                                </span>
                              </Col>
                              <Col md={2} className="title">
                                {item?.durationInDays ? (
                                  <span className="table-text">
                                    {item?.durationInDays}{' '}
                                    {translate('prescription_entry.days')}
                                  </span>
                                ) : null}
                              </Col>
                              <Col md={3} className="title">
                                <span className="table-text">
                                  {item?.directions}
                                </span>
                              </Col>
                            </Row>
                          ),
                        )}

                        {prescriptionItem?.prognosis
                          ? renderTextWithTitle(
                            translate('prescription_entry.prognosis'),
                            prescriptionItem?.prognosis,
                          )
                          : null}

                        {prescriptionItem?.diagnosis
                          ? renderTextWithTitle(
                            translate('prescription_entry.diagnosis'),
                            prescriptionItem?.diagnosis,
                          )
                          : null}

                        {prescriptionItem?.adviceGiven
                          ? renderTextWithTitle(
                            translate('prescription_entry.advice_given'),
                            prescriptionItem?.adviceGiven,
                          )
                          : null}
                        {prescriptionItem?.treatmentNotes
                          ? renderTextWithTitle(
                            translate('prescription_entry.treatment_notes'),
                            prescriptionItem?.treatmentNotes,
                          )
                          : null}
                      </Row>
                    ))}
                  </Row>
                </Col>
              </>
            ) : (
              <>
                {consultationType === inPatient || (consultationType !== inPatient && consultationDetails?.doctor) ? (
                  <>
                    <h4 className="mt-5 text-muted empty-list-text">
                      {translate('prescription_entry.no_prescription')}
                    </h4>
                    <Row className="d-flex justify-content-center p-0 m-0">
                      {IsPermittedAction(
                        PERMISSION_ACTIONS.CREATE_APPOINTMENT_PRESCRIPTION,
                        authorities,
                        permissionList,
                      ) && (
                          <>
                            {historyLength >
                              HISTORY_LENGTH_FROM_REPORT_SCREEN && (
                                <Button
                                  onClick={togglePrescriptionModal}
                                  color={Colors.white}
                                  className="submit-btn add-prescription-button">
                                  {translate('prescription_entry.add_prescription')}
                                </Button>
                              )}
                          </>
                        )}
                    </Row>
                  </>
                ) : (
                  <h2 className="mt-5 text-muted empty-list-text">
                    {translate(
                      'prescription_entry.unable_add_prescription_unassigned',
                    )}
                  </h2>
                )}
              </>
            )}
          </Col>
        </Row>
      )}

      <Modal
        className="prescription-entry-modal"
        isOpen={showPrescriptionPopup}
        centered>
        <ModalBody>
          <PrescriptionEntry
            appointmentId={appointmentId}
            prescriptionsId={prescriptionId}
            isAddPrescription={isAddPrescription}
            onCloseModal={togglePrescriptionModal}
            doctor={doctor}
            consultationDetails={consultationDetails}
            isShowConfirmationModal={isShowConfirmationModal}
            setIsShowConfirmationModal={setIsShowConfirmationModal}
            consultationType={consultationType}
          />
        </ModalBody>
      </Modal>
      {callStatus !== APPOINTMENT_STATUS.COMPLETED && (
        <CallConfirmation
          appointmentId={appointmentId}
          isShowConfirmationModal={isShowConfirmationModal}
          setIsShowConfirmationModal={setIsShowConfirmationModal}
          setCallStatus={setCallStatus}
        />
      )}
    </div>
  );
};

export default PrescriptionDetail;
