import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { getPharmacyCharge, getPlans } from '../../reducers/plan.reducer';
import {
  fetchServicePackages,
  fetchServicePaymentPeriods,
  fetchServiceSlots,
  getPreRegisteredUser,
  postRegister,
  setRegisteredUser,
  updatePostRegistrationState,
} from '../../reducers/user.reducer';
import { IRootState } from '../../shared/reducers';
import './style.scss';
import { deleteIcon } from './components/delete-icon';
import Button from '../components/button';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export const ServiceSubscriptionRenewal = (props: IPageProps) => {
  const { postRegistrationDetails, pharmacyCharge } = props;

  const location = useLocation<any>();
  const rightContentWidth = useRef(null as any);
  const [subscriptionPlan, setSubscriptionPlan] = useState(null as any);
  const [noOfBookingsPerSlot, setNoOfBookingsPerSlot] = useState(0 as number);
  const [paymentPeriod, setPaymentPeriod] = useState(null as any);
  const [hasPharma, setHasPharma] = useState(false as boolean);
  const [netAmount, setNetAmount] = useState(0 as number);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [paymentPeriodId, setPaymentPeriodId] = useState(0 as number);
  const [servicePlanId, setServicePlanId] = useState(0 as number);
  const [servicesId, setServiceId] = useState(0 as number);
  const [paymentPeriodMonths, setPaymentPeriodMonths] = useState(0 as number);
  const [subscriptionService, setSubscriptionService] = useState(null as any);
  const [renewalRemainingDays, setRenewalRemainingDays] = useState<number>(0);

  useEffect(() => {
    setSubscriptionService(location.state.subscriptionService.service);
    setSubscriptionPlan(location.state.subscriptionPlan.plan);
    setPaymentPeriod(location.state.paymentPeriod.period);
    setNoOfBookingsPerSlot(location.state.noOfBookingsPerSlot.bookingPerSlot);
    setNetAmount(location.state.totalAmount.totalAmount);
    setHasPharma(location.state.hasPharma.hasPharma);
    setStartDate(location?.state.startDate?.startDate.split(/[a-zA-Z\\s]/)[0]);
    setEndDate(location.state.endDate.endDate.split(/[a-zA-Z\\s]/)[0]);
    setServiceId(location.state.servicesId.servicesId);
    setServicePlanId(location.state.servicePlanId.servicePlanId);
    setPaymentPeriodId(location.state.paymentPeriodId.paymentPeriodId);
    setPaymentPeriodMonths(location.state.noOfMonths?.paymentPeriodMonths);
    setRenewalRemainingDays(location.state?.renewalRemainingDays)
  }, [location]);

  useEffect(() => {
    props.getPlans();
  }, []);

  const removePlan = plan => {
    let filteredSubscriptions = postRegistrationDetails?.subscriptions?.filter(
      (x, i) => x.id !== plan?.id,
    );
    postRegistrationDetails.subscriptions = filteredSubscriptions;
    props.updatePostRegistrationState(postRegistrationDetails);
  };

  const handleNextClick = () => {
    props.history.push({
      pathname: '/confirm-renew',
      state: {
        subscriptionPlan: subscriptionPlan,
        noOfBookingsPerSlot: noOfBookingsPerSlot,
        paymentPeriod: paymentPeriod,
        netAmount: netAmount,
        hasPharma: hasPharma,
        startDate: startDate,
        endDate: endDate,
        servicesId: servicesId,
        servicePlanId: servicePlanId,
        paymentPeriodId: paymentPeriodId,
        paymentPeriodMonths: paymentPeriodMonths,
        renewalRemainingDays: renewalRemainingDays
      },
    });
  };

  const renderAmountDetail = () => {
    return (
      <Col className="rate-details pb-3">
        <div className="footer">
          <div className="entry p-4 px-5 pb-0 mt-5 mb-5">
            <p className="m-0 checkout-rate-label">
              <Translate contentKey="placeholders.subscription_rate">
                Subscription rate
              </Translate>
            </p>
            <h6 className="inter"> ₹ {netAmount}</h6>
          </div>

          <div className="entry total p-4 px-5 ">
            <p className="m-0 checkout-rate-label inter">
              <Translate contentKey="placeholders.total">total</Translate>
            </p>
            <h6 className="inter"> ₹ {netAmount} </h6>
          </div>
          <div className="p-4 px-5  checkout-button-container">
            <Button
              className="p-3 w-100 px-4"
              color="danger"
              onClick={handleNextClick}>
              <Translate contentKey="placeholders.renew_now">
                Renew Now
              </Translate>
            </Button>
          </div>
        </div>
      </Col>
    );
  };

  const renderLeftContent = () => {
    return (
      <Col className="p-0 left-content-container" lg={4}>
        <Col className="m-4 mb-0 flex-grow-0 text-light">
          <p className="select-service-label m-0 mt-3 mb-3">
            <b>
              <Translate contentKey="placeholders.selected_services">
                Selected services
              </Translate>
            </b>
          </p>
          <p className="m-0 text-light services-selected-text">
            {postRegistrationDetails?.subscriptions?.length}{' '}
            <Translate contentKey="placeholders.services_selected">
              services selected
            </Translate>
          </p>
        </Col>

        {/* Added Services */}
        <Col className="added-services-container">
          <div className="m-4">
            <Card className="mt-3 mb-3 pt-3 pb-3 selected-service-card">
              <CardBody>
                <Col md={12}>
                  <Row>
                    <Col md={10}>
                      <p
                        className="text-dark service-name m-0"
                        style={{ fontSize: '18px', fontWeight: 'bold' }}>
                        {subscriptionService}
                      </p>
                    </Col>
                    <Col md={2} className="d-flex justify-content-end">
                      <p
                        className="text-dark plan-added-amount m-0 inter "
                        style={{ fontSize: '18px', fontWeight: 'bold' }}>
                        ₹{netAmount}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </CardBody>
            </Card>
          </div>
        </Col>
        {renderAmountDetail()}
      </Col>
    );
  };

  const renderAddServiceModal = () => {
    return (
      <div
        ref={el => {
          if (!el) return;
          rightContentWidth.current = el.getBoundingClientRect().width - 5;
        }}
        className="right-content-container col-lg-8">
        <div className="m-5  ">
          <Row className="mt-5" style={{ fontSize: '30px', color: '#51A095' }}>
            <p className="subscrptn-plan-title m-0 mt-5 mb-3 ">
              <b>
                <Translate contentKey="placeholders.subscription_plan_details">
                  Subscription Plan Details
                </Translate>
              </b>
            </p>
          </Row>

          <Row className="payment-selection mt-5 px-4">
            <Col lg="4">
              <p className="m-0 label">
                <Translate contentKey="placeholders.subscription_plan">
                  Subscription plan
                </Translate>
                &nbsp;
              </p>
              <div className="form-group">
                <input
                  className="form-control mb-2 p-3"
                  type="text"
                  value={location.state?.subscriptionPlan?.plan}
                  placeholder="Name"
                />
              </div>
            </Col>
          </Row>

          <Row className="bookings-selection mt-5 px-4 ">
            <Col lg="4">
              <p className="m-0 label">
                <Translate contentKey="placeholders.booking_per_slot">
                  No of booking per slot
                </Translate>
                &nbsp;
              </p>
              <div className="form-group">
                <input
                  className="form-control mb-2 p-3"
                  type="text"
                  value={noOfBookingsPerSlot}
                  placeholder="Name"
                />
              </div>
            </Col>
          </Row>
          <Row className="payment-selection mt-5 px-4">
            <Col lg="4">
              <p className="m-0 label">
                <Translate contentKey="placeholders.payment_period">
                  Payment period
                </Translate>
                &nbsp;
              </p>
              <div className="form-group">
                <input
                  className="form-control p-3"
                  type="text"
                  value={location.state.paymentPeriod.period}
                  placeholder="Name"
                />
              </div>
            </Col>

            {location.state.hasPharma ? (
              <Col className="p-0 px-5">
                <p className="m-0 mt-4 pharmacy-services-required-text">
                  <FontAwesomeIcon
                    size="lg"
                    icon={location.state.hasPharma ? faCheckSquare : faSquare}
                  />
                  &nbsp; &nbsp;
                  <Translate contentKey="placeholders.pharmacy_service_required">
                    Pharmacy service required
                  </Translate>
                </p>
                <p className="m-0 pharmacy-services-required-text">
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  <span className="label additional-charge-text inter">
                    {`${translate(`placeholders.additional_charges`)} ₹${
                      pharmacyCharge.additionalCharges
                    }`}
                  </span>
                </p>
              </Col>
            ) : null}
          </Row>
          <Row className="px-4">
            <Col>
              <p className="m-0">
                <span className="label">
                  {translate('placeholders.total_amount')}
                </span>
                &nbsp; &nbsp; &nbsp;
                <span className="total-amount inter">₹ {netAmount}</span>
              </p>
            </Col>
          </Row>
          <Row className="px-4"></Row>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Container id="service-subscription" fluid className="p-0">
        <Row>
          {renderLeftContent()}
          {renderAddServiceModal()}
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ authentication, user, plan }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  userMenus: user.menus,
  user: user.user,
  loading: user.loading,
  plans: plan.plans,
  servicePackages: user?.servicePackages,
  serviceSlots: user?.serviceSlots,
  servicePaymentPeriods: user?.servicePaymentPeriods,
  postRegistrationDetails: user.postRegistrationDetails,
  postRegisterResponse: user.postRegisterResponse,
  pharmacyCharge: plan?.pharmacyCharge,
});

const mapDispatchToProps = {
  getPlans,
  getPharmacyCharge,
  setRegisteredUser,
  getPreRegisteredUser,
  fetchServiceSlots,
  fetchServicePackages,
  fetchServicePaymentPeriods,
  updatePostRegistrationState,
  postRegister,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceSubscriptionRenewal);
