import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import SubHeader from '../../shared/layout/sub-header';
import { IRootState } from '../../shared/reducers';
import 'react-multi-carousel/lib/styles.css';
import './style.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useWindowDimensions } from '../../shared/util/utility-functions';
import {
  fetchMaxBookingPerSlot,
  getAppointments,
  getSlots,
  rescheduleAppointmentSchedule,
} from '../../reducers/appointment.reducer';
import { getServicesMenus } from '../../reducers/plan.reducer';
import { IMenu, ISubMenu } from '../../model/user-menu';
import {
  APPOINTMENT_RESCHEDULE_DAYS_RANGE,
  APPOINTMENT_SCHEDULE_SHIFT_LABELS,
  DATE_TIME_FORMATS,
  DEFAULT_NUMBER_OF_BOOKING_PER_SLOT,
  HTTP_OK_RESPONSE,
  SLOT_STATUS_LABELS,
  SM_SCREEN_BREAKPOINT,
} from '../../config/constants';
import { getTabs } from '../../shared/layout/sub-header/tabs';
import Button from '@restart/ui/esm/Button';
import moment from 'moment-timezone';
import {
  daysAddedDate,
  getDaysBetweenDates,
} from '../../shared/util/date-utils';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { translate, Translate } from 'react-jhipster';
import { NavItem } from 'react-bootstrap';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { DatePicker } from 'antd';

import 'react-datepicker/dist/react-datepicker.css';
import { ResetIcon } from './components/icons/reset-icon';
import { DateIcon } from './components/icons/date-icon';
import SaveButton from '../components/button';
import ServicePublishButton from './components/service-publish-button';
import { IsPermittedAction } from '../../shared/util/permission-utils';
import { PERMISSION_ACTIONS } from '../../config/permission-constants';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export const AppointmentReSchedule = (props: any) => {
  const {
    userMenus,
    appointments,
    slots,
    servicesLinks,
    fetchMaxBookingPerSlotResponse,
  } = props;
  const [selectedMenu, setMenu] = useState({} as IMenu);
  const [selectedSubMenu, setSubMenu] = useState({} as ISubMenu);
  const [fetchMaxBookingPerSlotLoading, setFetchMaxBookingPerSlotLoading] =
    useState(false);
  const serviceId = props.serviceId;

  useEffect(() => {
    props.getServicesMenus(serviceId);
  }, [serviceId]);

  const [defaultNoOfBookingPerSlot, setDefaultNoOfBookingPerSlot] = useState(
    DEFAULT_NUMBER_OF_BOOKING_PER_SLOT,
  );
  const [selectedInitialDate, setSelectedInitialDate] = useState(
    moment(new Date()).format('YYYY-MM-DD'),
  );
  const [selectedDates, setSelectedDates] = useState([] as any[]);
  const [selectedDate, setSelectedDate] = useState(moment(new Date()));
  const { height, width } = useWindowDimensions();
  const [activeTab, setActiveTab] = useState(
    APPOINTMENT_SCHEDULE_SHIFT_LABELS[0],
  );

  const [selectedSlotDetail, setSelectedSlotDetail] = useState({
    totalNoOfBookingPerSlot: defaultNoOfBookingPerSlot,
    slots: [],
  } as any);

  const [rescheduleLoading, setRescheduleLoading] = useState(false);

  const onClickTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    const initialDates = getDaysBetweenDates(
      moment(selectedInitialDate),
      daysAddedDate(
        moment(selectedInitialDate),
        APPOINTMENT_RESCHEDULE_DAYS_RANGE,
      ),
    );
    setSelectedDates(initialDates);
    if (initialDates.length) {
      setSelectedDate(moment(initialDates[0]));
    }
  }, [selectedInitialDate]);

  const onPressDay = date => {
    setSelectedDate(moment(date));
  };

  useEffect(() => {
    fetchSlots();
  }, [selectedDate]);

  const fetchSlots = () => {
    props.getSlots({
      date: moment(selectedDate).format(
        DATE_TIME_FORMATS.YYYY_MM_DD_WITH_HYPHEN,
      ),
      serviceId,
    });
  };

  useEffect(() => {
    setFetchMaxBookingPerSlotLoading(true);
    props.fetchMaxBookingPerSlot({ serviceId });
  }, []);

  useEffect(() => {
    if (fetchMaxBookingPerSlotLoading) {
      if (fetchMaxBookingPerSlotResponse.status === HTTP_OK_RESPONSE) {
        setDefaultNoOfBookingPerSlot(
          fetchMaxBookingPerSlotResponse.data?.maxBookingPerSlot,
        );
      }
    }
  }, [fetchMaxBookingPerSlotResponse]);

  useEffect(() => {
    if (fetchMaxBookingPerSlotResponse.data?.maxBookingPerSlot) {
      setSelectedSlotDetail({
        ...selectedSlotDetail,
        totalNoOfBookingPerSlot:
          fetchMaxBookingPerSlotResponse.data?.maxBookingPerSlot,
      });
    }
  }, [fetchMaxBookingPerSlotResponse.data?.maxBookingPerSlot]);

  const renderSlotStatusBoxWithText = status => {
    return (
      <Col
        key={status}
        className="d-flex align-items-center justify-content-end"
        lg={2}>
        <Row>
          <Col className="d-flex justify-content-center align-items-center p-0">
            <div
              className={`slot-status-box bg-greyTeal ${
                status === SLOT_STATUS_LABELS.UNAVAILABLE
                  ? `slot-unavailable-status`
                  : status === SLOT_STATUS_LABELS.BOOKED
                  ? `slot-booked-status`
                  : ''
              } `}
            />
          </Col>
          &nbsp; &nbsp;
          <Col className="p-0">
            <span className="label">{status}</span>
          </Col>
        </Row>
      </Col>
    );
  };

  const onClickSlotCard = slot => {
    let selectedSlots = [...selectedSlotDetail.slots];
    let selectedSlotIndex = selectedSlots.findIndex(x => x?.id === slot?.id);
    if (selectedSlotIndex === -1) {
      selectedSlots.push(slot);
    } else {
      selectedSlots.splice(selectedSlotIndex, 1);
    }
    setSelectedSlotDetail({
      ...selectedSlotDetail,
      slots: selectedSlots,
    });
  };

  const renderSlotCard = (slot, index) => {
    const convertTimeFormat = time => {
      const ZERO_TIME_FORMAT_WITH_COL = `${DATE_TIME_FORMATS.ZERO_HOUR_FORMAT}:`;
      const TWENTY_FOUR_HOUR_FORMAT_WITH_COL = `${DATE_TIME_FORMATS.TWENTY_FOUR_HOUR_FORMAT}:`;
      return moment(time, DATE_TIME_FORMATS.HH_MM_SS)
        .format(DATE_TIME_FORMATS.TWENTY_FOUR_HH_MM)
        .replace(ZERO_TIME_FORMAT_WITH_COL, TWENTY_FOUR_HOUR_FORMAT_WITH_COL);
    };

    const isSlotSelected =
      selectedSlotDetail.slots.findIndex(x => x.id === slot?.id) !== -1;
    const numberOfUnavailableSlots =
      defaultNoOfBookingPerSlot - slot?.totalNoOfBooking;

    return (
      <Col key={index} className="p-2" md={3} lg={3}>
        <Card
          {...((slot?.availableNoOfBooking > 0 ||
            slot?.totalNoOfBooking < defaultNoOfBookingPerSlot) && {
            onClick: () => onClickSlotCard(slot),
          })}
          className={`${isSlotSelected ? `slot-selected-status` : ''}`}>
          <CardHeader className="text-center label">{`${convertTimeFormat(
            slot?.fromTime,
          )} - ${convertTimeFormat(slot?.toTime)}`}</CardHeader>
          <CardBody>
            <Col md={12} lg={12}>
              <Row>
                {Array.from({ length: slot?.totalNoOfBooking }).map(
                  (item, index) => {
                    const slotAvailable =
                      index + 1 <= slot?.availableNoOfBooking;
                    return (
                      <Col
                        key={index}
                        className="p-2 d-flex justify-content-center"
                        md={4}
                        lg={4}>
                        <div
                          className={`slot-status-box status-box-large  ${
                            !slotAvailable
                              ? 'status-box-disabled'
                              : 'bg-greyTeal'
                          }`}
                        />
                      </Col>
                    );
                  },
                )}
                {Array.from({ length: numberOfUnavailableSlots }).map(
                  (item, index) => {
                    return (
                      <Col
                        key={index}
                        className="p-2 d-flex justify-content-center"
                        md={4}
                        lg={4}>
                        <div
                          className={`slot-status-box status-box-large slot-unavailable-status`}
                        />
                      </Col>
                    );
                  },
                )}
              </Row>
            </Col>
          </CardBody>
        </Card>
      </Col>
    );
  };

  const onChangeBookingPerSlot = value => {
    if (value <= defaultNoOfBookingPerSlot && value >= 0) {
      setSelectedSlotDetail({
        ...selectedSlotDetail,
        totalNoOfBookingPerSlot: value,
      });
    }
  };

  const clearSelectedSlots = () => {
    setSelectedSlotDetail({
      ...selectedSlotDetail,
      slots: [],
    });
  };

  const onClickUpdate = () => {
    if (selectedSlotDetail?.slots.length > 0) {
      let slotDetail = {
        ...selectedSlotDetail,
        slotIds: selectedSlotDetail?.slots.map(x => x.id),
      };
      setRescheduleLoading(true);
      let payload = {
        serviceId,
        slotDetail: slotDetail,
      };
      props.rescheduleAppointmentSchedule(payload);
    } else {
      toast.error(translate('entity.validation.select_min_slot'));
    }
  };

  useEffect(() => {
    if (props?.rescheduleAppointmentResponse?.status === HTTP_OK_RESPONSE) {
      if (rescheduleLoading) {
        fetchSlots();
        clearSelectedSlots();
        setRescheduleLoading(false);
      }
    } else {
      setRescheduleLoading(false);
    }
  }, [props?.rescheduleAppointmentResponse]);

  const renderSelectedDateRange = () => {
    return selectedDates.map((date, index) => {
      const isSelected =
        moment(date).format(DATE_TIME_FORMATS?.APP_LOCAL_DATE_FORMAT) ===
        moment(selectedDate).format(DATE_TIME_FORMATS?.APP_LOCAL_DATE_FORMAT);

      return (
        <Button
          key={index}
          onClick={() => onPressDay(date)}
          className={`day-button p-3 px-4 m-2 mt-3 mb-3 ${
            isSelected ? 'day-active' : ''
          }`}>
          <p className="m-0 day-number">
            {moment(date).format(DATE_TIME_FORMATS?.DAY_NUMERIC_FORMAT)}
          </p>
          <p className="m-0 day-text">
            {moment(date).format(DATE_TIME_FORMATS?.DAY_NAME_FORMAT)}
          </p>
        </Button>
      );
    });
  };

  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );

  const renderCustomDatePicker = () => {
    return (
      <div
        className={`today-button custom-button p-2 px-3 ${
          !selectedDateIsToday ? 'today-button-active' : ''
        }`}>
        <div className="d-flex justify-content-between">
          <p className="m-0 today-text">
            {!selectedDateIsToday
              ? moment(selectedInitialDate).format(
                  DATE_TIME_FORMATS.APP_LOCAL_DATE_FORMAT,
                )
              : translate('form.service.appointment_reschedule.custom_text')}
          </p>
          <DateIcon />
        </div>
      </div>
    );
  };

  const renderCurrentDateWithStatuses = () => {
    return (
      <Col className="px-5 p-2" lg={12}>
        <Row>
          <Col lg={6}>
            <p className="m-0 text-greyTeal label">
              {moment(selectedDate).format(DATE_TIME_FORMATS.DAY_NAME_FORMAT)}
            </p>
            <p className="m-0 text-greyTeal label">
              {moment(selectedDate).format(
                DATE_TIME_FORMATS.APP_LOCAL_DATE_FORMAT_WITH_SPACE,
              )}
            </p>
          </Col>
          {Object.keys(SLOT_STATUS_LABELS).map(status => {
            return renderSlotStatusBoxWithText(SLOT_STATUS_LABELS[status]);
          })}
        </Row>
      </Col>
    );
  };

  const renderShiftLabelsWithReset = () => {
    return (
      <Row className="justify-content-center">
        <Col lg={3}></Col>
        <Col className="my-3" lg={6}>
          <Nav fill tabs>
            {APPOINTMENT_SCHEDULE_SHIFT_LABELS.map((label, index) => {
              return (
                <NavItem key={index}>
                  <NavLink
                    className={`${classNames({
                      active: activeTab === label,
                    })} m-0`}
                    onClick={() => {
                      onClickTab(label);
                    }}>
                    {label}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
        </Col>
        <Col
          className="d-flex align-items-center justify-content-end my-2"
          lg={3}>
          {/* For future
             <ResetIcon onClick={clearSelectedSlots} /> 
          */}
        </Col>
      </Row>
    );
  };

  const renderBookingPerSlotChanger = () => {
    return (
      <div className="slot-count buttons_added">
        <Button
          onClick={() =>
            onChangeBookingPerSlot(
              selectedSlotDetail?.totalNoOfBookingPerSlot - 1,
            )
          }
          type="button"
          value="-"
          className={
            selectedSlotDetail?.totalNoOfBookingPerSlot <= 0
              ? 'minus-disabled px-3 '
              : 'minus px-3'
          }
          disabled={selectedSlotDetail?.totalNoOfBookingPerSlot <= 0}>
          -
        </Button>
        <input
          disabled
          onChange={event => onChangeBookingPerSlot(event?.target?.value)}
          type="number"
          min={1}
          max={defaultNoOfBookingPerSlot}
          name="quantity"
          value={selectedSlotDetail?.totalNoOfBookingPerSlot}
          title="Qty"
          className="input-text qty text"
          size={4}
          pattern=""
        />
        <Button
          onClick={() =>
            onChangeBookingPerSlot(
              selectedSlotDetail?.totalNoOfBookingPerSlot + 1,
            )
          }
          disabled={
            selectedSlotDetail?.totalNoOfBookingPerSlot >=
            defaultNoOfBookingPerSlot
          }
          type="button"
          value="+"
          className={
            selectedSlotDetail?.totalNoOfBookingPerSlot >=
            defaultNoOfBookingPerSlot
              ? 'plus-disabled px-3 '
              : 'plus px-3'
          }>
          +
        </Button>
      </div>
    );
  };

  const renderTabContent = () => {
    return (
      <TabContent activeTab={activeTab}>
        {Array.isArray(slots) &&
          slots?.map((item, index) => {
            return (
              <TabPane key={index} tabId={item?.shiftLabel}>
                <Col>
                  <Row>
                    {item.slots.map((slot, index) => {
                      return renderSlotCard(slot, index);
                    })}
                  </Row>
                </Col>
              </TabPane>
            );
          })}
      </TabContent>
    );
  };

  const selectedDateIsToday =
    moment(selectedInitialDate).format(
      DATE_TIME_FORMATS.APP_LOCAL_DATE_FORMAT,
    ) === moment(new Date()).format(DATE_TIME_FORMATS.APP_LOCAL_DATE_FORMAT);

  return (
    <div>
      <Container id="reschedule-detail" fluid>
        <Col className="top-content" lg={12}>
          <Row className="py-3 px-2 pb-5 mb-2">
            <Col className="m-1" lg={1}>
              <Button
                onClick={() => setSelectedInitialDate(moment().format('YYYY-MM-DD'))}
                className={`today-button p-2 px-3 ${
                  selectedDateIsToday ? 'today-button-active' : ''
                }`}>
                <p className="m-0">
                  {translate('form.service.appointment_reschedule.today_text')}
                </p>
              </Button>
            </Col>
            <Col className="m-1" lg={2}>
              <DatePicker
                value={moment(selectedInitialDate)}
                onChange={date => setSelectedInitialDate(moment(date).format('YYYY-MM-DD'))}
                format={DATE_TIME_FORMATS.DD_MM_YYYY}
                allowClear={false}
                style={{ height: '39px' }}
              />
            </Col>
          </Row>
          <Col lg={12}>
            <ScrollMenu>{renderSelectedDateRange()}</ScrollMenu>
          </Col>
        </Col>
        <Col className="bottom-content mt-1 mb-5">
          <Row className="m-0">
            <Col className="p-0" lg={8}>
              {renderCurrentDateWithStatuses()}
              <Col className="bg-light px-5 slot-tab-col" lg={12}>
                {renderShiftLabelsWithReset()}
                {renderTabContent()}
              </Col>
            </Col>

            <Col className="p-0 py-2" lg={4}>
              <Col className="py-2">
                <p className="text-center default-booking-slot-text">
                  {translate(
                    'form.service.appointment_reschedule.default_no_of_booking_per_slot',
                  )}
                </p>
                <p className="text-center default-slot m-0">
                  {defaultNoOfBookingPerSlot}
                </p>
              </Col>
              <hr />
              <Col className="py-2">
                <p className="text-center default-booking-slot-text">
                  {translate(
                    'form.service.appointment_reschedule.time_format_text',
                  )}
                </p>
                <p className="text-center default-slot m-0">
                  {translate('form.service.appointment_reschedule.24_h')}
                </p>
              </Col>
              <hr />
              <Col className="py-2">
                <p className="text-center default-booking-slot-text">
                  {translate(
                    'form.service.appointment_reschedule.specific_no_of_booking_per_slot',
                  )}
                </p>
                <Row>
                  <Col lg={4} />
                  <Col
                    className="d-flex align-items-center justify-content-center"
                    lg={4}>
                    {renderBookingPerSlotChanger()}
                  </Col>
                  <Col lg={4} />
                </Row>
              </Col>
              <Col className="d-flex justify-content-center align-items-center m-4">
                {IsPermittedAction(
                  PERMISSION_ACTIONS.RESCHEDULE_SLOTS,
                  authorities,
                  permissionList,
                ) && (
                  <Button
                    onClick={onClickUpdate}
                    color="danger"
                    className="px-5 btn btn-danger">
                    <span className="text-light label">
                      <Translate contentKey="form.service.appointment_reschedule.update_text">
                        Update
                      </Translate>
                    </span>
                  </Button>
                )}
              </Col>
              <Col>
                <p className="duration-text">
                  {translate(
                    'form.service.appointment_schedule.slot_duration_msg',
                  )}
                </p>
              </Col>
            </Col>
          </Row>
        </Col>
      </Container>
    </div>
  );
};

const mapStateToProps = ({
  authentication,
  locale,
  user,
  appointment,
  plan,
}: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loading: user.loading,
  appointments: appointment.appointments,
  slots: appointment.slots,
  servicesLinks: plan.servicesLinks,
  rescheduleAppointmentResponse: appointment?.rescheduleAppointmentResponse,
  fetchMaxBookingPerSlotResponse: appointment?.fetchMaxBookingPerSlotResponse,
});

const mapDispatchToProps = {
  getAppointments,
  getSlots,
  getServicesMenus,
  rescheduleAppointmentSchedule,
  fetchMaxBookingPerSlot,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppointmentReSchedule);
