import {
  faChevronDown,
  faChevronUp,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "./style.scss";

export interface IComponentProps {
  total: number;
  selected: number;
  labels: string[];
  className?: string;
}
export const Steps = (props: IComponentProps) => {
  return (
    <Row className={props.className}>
      <Col>
        <div className="steps steps-dark py-4">
          {props.labels.map((label, index) => (
            <a
              key={index}
              className={`step-item ${index < props.selected ? "active" : ""
                }  current`}
            >
              <div className="step-label">{index + 1}</div>
              <div className="step-progress">
                <span className="step-count" />
              </div>
            </a>
          ))}
        </div>
      </Col>
    </Row>
  );
};
export default Steps;
