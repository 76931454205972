import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { IRootState } from '../../shared/reducers';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import { fetchCustomerList } from '../../reducers/user.reducer';
import { PERMISSION_ACTIONS } from '../../config/permission-constants';
import { IsPermittedAction } from '../../shared/util/permission-utils';
import './styles.scss';
import Pagination from '@mui/material/Pagination';
import { ITEMS_PER_PAGE } from '../../shared/util/pagination.constants';
import EmptyContent from '../../assets/images/empty-content.png';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export interface ICustomerListProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ url: string }> {}

export const CustomerList = (props: ICustomerListProps) => {
  const { customerList, totalCount, loading } = props;
  const [searchForm, setSearchForm] = useState();
  const [encodedSearchKey, setEncodedSearchKey] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );

  useEffect(() => {
    props.fetchCustomerList({
      page: currentPage - 1,
      size: ITEMS_PER_PAGE,
    });
  }, []);

  useEffect(() => {
    setNumberOfPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
  }, [customerList]);

  useEffect(() => { 
    customerListApi(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    customerListApi(1)
  }, [searchForm]);

  const customerListApi=(page)=>{
    props.fetchCustomerList({
      search: encodedSearchKey,
      page:  page-1,
      size: ITEMS_PER_PAGE,
    });
  }


  const onPaginationChange = (e, page) => {
    setCurrentPage(page);
  };

  const searchHandler = event => {
    event.persist();
    setSearchForm(event.target.value);
    const searchKey = encodeURIComponent(event?.target?.value);
    setEncodedSearchKey(searchKey);
  };

  return (
    <div>
      <Row className="ps-4 pe-5 py-2" style={{ backgroundColor: '#E5E5E5' }}>
        <Col md="10">
          <h6 className="text-greyTeal mb-0 px-2 py-3">
            {translate('form.customer.head')}
          </h6>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mb-0 mt-4">
        <Col md={3} className="col-margin">
          <div className="fa-search position-relative ">
            <input
              className="form-control input-lg search-style"
              type="text"
              value={searchForm}
              onChange={searchHandler}
              placeholder="Name/Mobile/Patient Id"></input>
            <FontAwesomeIcon
              icon={faSearch}
              className="position-absolute "
              style={{ top: '7px', left: '7px' }}
            />
          </div>
        </Col>
      </Row>
      <div className={!customerList?.length ? 'd-flex' : ''}>
        {customerList?.length ? (
          <>
            <Row className="justify-content-center">
              <Col
                md={11}
                className="table-responsive shadow border border-1 rounded custom-table ms-4 me-4 my-5 pt-2 ">
                <Table responsive className="mb-0">
                  <thead className="border-0 border-bottom border-1">
                    <tr>
                      <th className="border-0 ps-4 pe-2 py-3">
                        {translate('form.customer.mobile')}
                      </th>
                      <th className="border-0 px-2 py-3">
                        {translate('form.customer.name')}
                      </th>
                      <th className="border-0 px-2 py-3">
                        {translate('form.customer.email')}
                      </th>
                      <th className="border-0 px-2 py-3">
                        {translate('customer_tab.customer_pets.patient_id')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="border-top-0">
                    {customerList?.map((customer, i) => (
                      <tr
                        className="border-0 border-bottom border-1"
                        key={`entity-${i}`}
                        data-cy="entityTable">
                        <td className="border-0 px-2 py-4">
                          {customer.mobile}
                        </td>
                        <td className="border-0 ps-4 pe-2 py-4">
                          {customer.name}
                        </td>
                        <td className="border-0 px-2 py-4">{customer.email}</td>
                        <td className="border-0 pa-2 pe-4 py-4">
                          {customer?.patientIds}{' '}
                        </td>
                        <td>
                          <div>
                            {/* For future */}
                            {/* <Button
                        className="me-1"
                        tag={Link}
                        to={`/user-list/${vendorUser.id}/detail`}
                        color="none"
                        size="sm"
                        data-cy="entityEditButton">
                        <FontAwesomeIcon
                          className="text-primary"
                          size="lg"
                          icon={faEye}
                        />
                      </Button> */}
                            {(IsPermittedAction(
                              PERMISSION_ACTIONS.GET_ALL_VENDOR_CUSTOMERS_PETS,
                              authorities,
                              permissionList,
                            ) ||
                              IsPermittedAction(
                                PERMISSION_ACTIONS.GET_SERVICE_APPOINTMENTS_BY_CUSTOMER_ID,
                                authorities,
                                permissionList,
                              )) && (
                              <Button
                                tag={Link}
                                to={`/customer/${customer.id}`}
                                color="none"
                                size="sm"
                                data-cy="entityDeleteButton">
                                <FontAwesomeIcon
                                  className="text-danger"
                                  icon={faEye}
                                />
                              </Button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
              <Col md={2}></Col>
            </Row>
            {totalCount && customerList?.length ? (
              <Pagination
                count={numberOfPages}
                onChange={onPaginationChange}
                variant="outlined"
                shape="rounded"
                className="d-flex justify-content-center mb-4"
              />
            ) : null}
          </>
        ) : (
          <>
            {loading && <FontAwesomeIcon icon={faSpinner} spin />}
            {!loading && (
              <img
                src={EmptyContent}
                style={{
                  padding: ' 7% 25% 0% 25%',
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ vendorUser, user }: IRootState) => ({
  customerList: user.customerList,
  totalCount: user.totalCount,
  loading: vendorUser.loading,
});

const mapDispatchToProps = {
  fetchCustomerList,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
