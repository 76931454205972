import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Col, Container, Label, Row, Media, Card, CardBody } from 'reactstrap';
import SubHeader from '../../shared/layout/sub-header';
import { IRootState } from '../../shared/reducers';
import 'react-multi-carousel/lib/styles.css';
import './style.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useWindowDimensions } from '../../shared/util/utility-functions';
import {
  getAppointments,
  getDefaultSlots,
  getSlots,
  scheduleAppointment,
  updateAppointmentSchedule,
} from '../../reducers/appointment.reducer';
import {
  getServiceDetails,
  fetchBankDetails,
  getUserBusinessInfo,
  getSubscriptionDetail,
} from '../../reducers/user.reducer';
import { getServicesMenus } from '../../reducers/plan.reducer';
import { ISlot } from '../../model/slots.model';
import { IMenu, ISubMenu } from '../../model/user-menu';
import { Colors, SM_SCREEN_BREAKPOINT } from '../../config/constants';
import { Translate, translate } from 'react-jhipster';
import {
  getLanguageList,
  getPetTypes,
  getSpecialityList,
  fetchState,
  fetchCity,
} from '../../reducers/master.reducer';
import Button from '../components/button';
import ServicePublishButton from './components/service-publish-button';
import PROFILE_DEFAULT_IMAGE from '../vendor-profile-view/svg/default-profile.png';
import { PERMISSION_ACTIONS } from '../../config/permission-constants';
import { IsPermittedAction } from '../../shared/util/permission-utils';
import Tooltip from '@mui/material/Tooltip';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

 const ServicePreview = (props: any) => {
  const { servicesLinks, servicePreviewDetail } = props;
  const [selectedMenu, setMenu] = useState({} as IMenu);
  const [selectedSubMenu, setSubMenu] = useState({} as ISubMenu);
  const [previewDetail, setPreviewDetail] = useState(null as any);
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedPetTypes, setSelectedPetTypes] = useState([]);
  const [pharmaDetailsShow, setPharmaDetailsShow] = useState(false);
  const [state, setState] = useState({} as any);
  const [city, setCity] = useState({} as any);
  const serviceId = props.serviceId;
  const [serviceArea, setServiceArea] = useState('' as any);
  const [countLanguages, setCountLanguages] = useState(0);
  const [serviceDetail, setServiceDetail] = useState({
    id: null,
    numberOfVets: 0,
    workingHoursFrom: '',
    workingHoursTo: '',
    languages: [],
    drugLicenceNo: '',
    pharmaGstNo: '',
    consultationCharges: 0,
    consultationChargeOffline: 0,
    petTypes: [],
    specialities: [],
    vendorServiceSpecialities: [],
    vendorServicePetTypes: [],
    vendorServiceLanguages: [],
    vendorServicePetTypesIcons: []
  });

  const [bankDetail, setBankDetail] = useState({
    id: null,
    bankName: '',
    accountNumber: '',
    beneficiaryName: '',
    ifscCode: '',
    panNumber: '',
    isApplyAll: false,
  } as any);

  const [businessInfoDetail, setBusinessInfoDetail] = useState({
    id: null,
    businessName: '',
    registrationNumber: '',
    inceptionYear: 0,
    gstNumber: '',
    mobile: '',
    email: '',
    imageContentType: '',
    imageData: '',
    stateId: 0,
    cityId: 0,
  } as any);

  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );

  const formatWithComma = dataArray => {
    return [
      dataArray?.map((element, index) => (
        <span>
          {element}
          {index !== dataArray?.length - 1 ? ', ' : ''}
        </span>
      )),
    ];
  };

  useEffect(() => {
    if (props.serviceDetails?.hasPharma === true) {
      setPharmaDetailsShow(true);
    }
  }, [props.serviceDetails]);

  useEffect(() => {
    setServiceDetail({
      id: null,
      numberOfVets: 0,
      workingHoursFrom: '',
      workingHoursTo: '',
      languages: [],
      drugLicenceNo: '',
      pharmaGstNo: '',
      consultationCharges: 0,
      consultationChargeOffline: 0,
      petTypes: [],
      specialities: [],
      vendorServiceSpecialities: [],
      vendorServicePetTypes: [],
      vendorServicePetTypesIcons: [],
      vendorServiceLanguages: [],
    });
    props.getPetTypes({});
    props.getLanguageList({});
    props.getSpecialityList({ serviceId });
    props.getServicesMenus();
    props.getServiceDetails({ serviceId });
    props.fetchBankDetails({ serviceId });
    props.getUserBusinessInfo({ serviceId });
    props.getSubscriptionDetail(serviceId);
  }, [serviceId]);

  useEffect(() => {
    if (props.subscriptionDetails) {
      const { serviceArea } = props.subscriptionDetails;
      setServiceArea(serviceArea);
    }
  }, [props.subscriptionDetails]);

  useEffect(() => {
    if (props.serviceDetails?.hasPharma === true) {
      setPharmaDetailsShow(true);
    }
  }, [props.serviceDetails]);

  useEffect(() => {
    if (servicePreviewDetail) {
      setPreviewDetail(servicePreviewDetail);
    }
  }, [servicePreviewDetail]);

  useEffect(() => {
    if (props.serviceDetails) {
      setSelectedSpecialities(props.serviceDetails?.specialities);
      setSelectedLanguages(props.serviceDetails?.languages);
      setSelectedPetTypes(props.serviceDetails?.petTypes);
      setServiceDetail(props.serviceDetails);
    }
  }, [props.serviceDetails]);

  useEffect(() => {
    if (props.fetchBankDetailsResponse) {
      setBankDetail(props.fetchBankDetailsResponse?.data);
    }
  }, [props.fetchBankDetailsResponse]);

  useEffect(() => {
    if (props.userBusinessInfo) {
      setBusinessInfoDetail(props.userBusinessInfo);
    }
  }, [props.userBusinessInfo]);

  useEffect(() => {
    if (businessInfoDetail.stateId && businessInfoDetail.cityId) {
      props?.fetchState(businessInfoDetail?.stateId);
      props?.fetchCity(businessInfoDetail?.cityId);
    }
  }, [businessInfoDetail]);

  const { height, width } = useWindowDimensions();
  return previewDetail ? (
    <div>
      <Container fluid className="px-0 px-lg-3" id="service-preview">
        <Row md={12} className="mb-3">
          <Col md={4}>
            <div className="mt-3">
              <div className="preview-card m-2">
                <div className="card-head">
                  <p className="card-head-text">
                    {translate('form.service.bank_detail.service_details')}
                  </p>
                </div>
                <div className="p-4">
                  <Row>
                    <Col md={6}>
                      <p>
                        <Translate contentKey="form.service.details.total_vets_available">
                          Total vets Available
                        </Translate>
                      </p>
                    </Col>
                    <Col md={6}>
                      <p className="text-lg-end">
                        {serviceDetail?.numberOfVets}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p>
                        <Translate contentKey="form.service.details.working_hours">
                          working Hours
                        </Translate>
                      </p>
                    </Col>
                    <Col md={6}>
                      <p className="text-lg-end">
                        {serviceDetail?.workingHoursFrom}-
                        {serviceDetail?.workingHoursTo}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p>
                        <Translate contentKey="form.service.details.specialities">
                          Specialities
                        </Translate>
                      </p>
                    </Col>
                    <Col md={6}>
                      <p className="text-lg-end">
                        {formatWithComma(
                          serviceDetail?.vendorServiceSpecialities,
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p>
                        <Translate contentKey="form.service.details.language">
                          Language
                        </Translate>
                      </p>
                    </Col>
                    <Col md={6}>
                      <p className="text-lg-end">
                        {formatWithComma(serviceDetail?.vendorServiceLanguages)}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p>
                        <Translate contentKey="form.service.details.pet_types_consulted">
                          Pet Types Consulted
                        </Translate>
                      </p>
                    </Col>
                    <Col md={6}>
                      <p className="text-lg-end">
                        {formatWithComma(serviceDetail?.vendorServicePetTypes)}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p>
                        <Translate contentKey="form.service.details.consultation_charges">
                         Online Consultation Charges
                        </Translate>
                      </p>
                    </Col>
                    <Col md={6}>
                      <p className="text-lg-end">
                        {serviceDetail?.consultationCharges}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <p>
                        <Translate contentKey="form.service.details.offline_consultation_charges">
                          Offline Consultation Charges
                        </Translate>
                      </p>
                    </Col>
                    <Col md={6}>
                      <p className="text-lg-end">
                        {serviceDetail?.consultationChargeOffline}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p>
                        <Translate contentKey="form.service.subscription.service_area">
                          Service Area
                        </Translate>
                      </p>
                    </Col>
                    <Col md={6}>
                      <p className="text-lg-end">{serviceArea}</p>
                    </Col>
                  </Row>
                  {pharmaDetailsShow && (
                    <>
                      <div>
                        <Row className="sub-header-pharma ">
                          <Col md={6}>
                            <p className="mb-1">
                              <Translate contentKey="form.service.details.pharma-details">
                                Pharma Details
                              </Translate>
                            </p>
                          </Col>
                        </Row>
                        <hr className="mt-0" />
                        <Row>
                          <Col md={6}>
                            <p>
                              <Translate contentKey="form.service.details.drug_licence_no">
                                Drug Licence Number
                              </Translate>
                            </p>
                          </Col>
                          <Col md={6}>
                            <p className="text-lg-end">
                              {serviceDetail?.drugLicenceNo}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <p>
                              <Translate contentKey="form.service.details.gst_no_of_pharmacy">
                                GST no of pharmacy
                              </Translate>
                            </p>
                          </Col>
                          <Col md={6}>
                            <p className="text-lg-end">
                              {serviceDetail?.pharmaGstNo}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <Row md={9}>
              <div className="mt-2">
                <div className="preview-card m-2">
                  <div className="card-head">
                    <p className="card-head-text">
                      {translate('form.service.bank_detail.bank_details')}
                    </p>
                  </div>
                  <div className="p-4">
                    <Row>
                      <Col md={6}>
                        <p>
                          {translate('form.service.bank_detail.bank_name_text')}
                        </p>
                      </Col>
                      <Col md={6}>
                        <p className="text-lg-end">{bankDetail?.bankName}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <p>
                          {translate(
                            'form.service.bank_detail.account_name_text',
                          )}
                        </p>
                      </Col>
                      <Col md={6}>
                        <p className="text-lg-end">
                          {bankDetail?.beneficiaryName}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <p>{translate('form.service.bank_detail.ifsc_code')}</p>
                      </Col>
                      <Col md={6}>
                        <p className="text-lg-end">{bankDetail?.ifscCode}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <p>
                          {translate('form.service.bank_detail.account_number')}
                        </p>
                      </Col>
                      <Col md={6}>
                        <p className="text-lg-end">
                          {bankDetail?.accountNumber}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <p>
                          {translate('form.service.bank_detail.pan_number')}
                        </p>
                      </Col>
                      <Col md={6}>
                        <p className="text-lg-end">{bankDetail?.panNumber}</p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Row>
            <Row md={9}>
              <p className="business-card-hint mt-3 mb-1">
                {translate('form.service.preview.business_card_hint')}
              </p>
              <Card>
                <CardBody className="p-0 preview-card">
                  <Row className="p-2 mb-0 p-3">
                    <Col className="d-flex align-items-center" md={3}>
                      {businessInfoDetail?.imageUrl !== null ? (
                        <Media
                          object
                          className="rounded-circle img-responsive center-block"
                          width="100%"
                          src={businessInfoDetail?.imageUrl}
                        />
                      ) : (
                        <Media
                          object
                          className="rounded-circle img-responsive center-block"
                          width="100%"
                          src={PROFILE_DEFAULT_IMAGE}
                        />
                      )}
                    </Col>
                    <Col>
                      <Col md={12}>
                        <p className="business-card-head">
                          {businessInfoDetail?.businessName}
                        </p>
                      </Col>
                      <Col>
                        <Row>
                          <Col md={8}>
                            <p className="label-danger">
                            {serviceDetail?.vendorServiceSpecialities?.[0]}
                              <>
                                {serviceDetail?.vendorServiceSpecialities?.length > 1
                                  ? ` +${
                                      serviceDetail?.vendorServiceSpecialities
                                        ?.length - 1
                                    }`
                                  : ''}
                              </>
                            </p>
                            <p className="doctor-name">
                            Speaks&nbsp;{serviceDetail?.vendorServiceLanguages?.[0]}
                              <>
                                {serviceDetail?.vendorServiceLanguages?.length > 1
                                  ? ` +${ serviceDetail?.vendorServiceLanguages
                                        ?.length - 1 }`
                                  : ''}
                              </>
                            </p>
                            <p className=" location-style">
                              {props.city.name}
                            </p>
                            {/* <p className="doctor-name">
                            <img src={serviceDetail?.vendorServicePetTypesIcons[0]} alt="pet"/>
                              {serviceDetail?.vendorServicePetTypes
                                ?.slice(1, 2)
                                ?.map((pet, index) => {
                                  return <span>,{pet}</span>;
                                })}
                              <>
                                {serviceDetail?.vendorServicePetTypes?.length > 2
                                  ? ` +${ serviceDetail?.petTypes
                                        ?.length - 2 }`
                                  : ''}
                              </>
                          </p> */}
                          <Row>
                            {serviceDetail?.vendorServicePetTypesIcons?.slice(0,3).map((pet, index) =>
                            <Col xs={3}>
                            <Media
                          object
                          className="rounded-circle img-responsive center-block"
                          width="100%"
                          height="80%"
                          src={pet}
                        />
                            </Col>
                            )}
                            <Col xs={3}>
                            {serviceDetail?.vendorServicePetTypesIcons?.length > 3 && 
                             `+ ${serviceDetail?.vendorServicePetTypesIcons?.length - 3}`
                            }
                            </Col>
                          </Row>
                          </Col>
                          <Col md={4}>
                            <Row>
                              <Col md={4}>
                                <svg
                                  width="18"
                                  height="12"
                                  viewBox="0 0 18 12"
                                  fill="none">
                                  <path
                                    d="M12 2V10H2V2H12ZM13 0H1C0.45 0 0 0.45 0 1V11C0 11.55 0.45 12 1 12H13C13.55 12 14 11.55 14 11V7.5L18 11.5V0.5L14 4.5V1C14 0.45 13.55 0 13 0Z"
                                    fill="#73BFB1"
                                  />
                                </svg>
                              </Col>
                              <Col
                                className="justify-content-start align-items-end d-flex"
                                md={8}>
                                <p className="amount">
                                  <span className="inter">
                                    ₹{serviceDetail?.consultationCharges}
                                  </span>
                                </p>
                              </Col>
                            </Row>
                            <Col md={12}>
                              <div className="book-button pr-4 pl-4">
                                <p className="text-center book-text">
                                  {translate('form.service.preview.book_text')}
                                </p>
                              </div>
                            </Col>
                          </Col>
                        </Row>
                      </Col>
                    </Col>
                  </Row>
                  <hr className="dashed-line m-0" />
                  <p className="text-center slots-avail-text pt-2 pb-2 m-0">
                    {translate('form.service.preview.slots_available_text')}
                  </p>
                </CardBody>
              </Card>
            </Row>
          </Col>
          <Col md={4}>
            <Row md={9}>
              <div className="mt-2 mr-2">
                <div className="preview-card m-2">
                  <div className="card-head">
                    <p className="card-head-text">
                      {translate(
                        'form.service.bank_detail.business_information',
                      )}
                    </p>
                  </div>
                  <div className="p-4">
                    <Row>
                      <Col md={6}>
                        <p>
                          <Translate contentKey="form.service.business.displayName">
                            displayName
                          </Translate>
                        </p>
                      </Col>
                      <Col md={6}>
                        <p className="text-lg-end">
                          {businessInfoDetail?.businessName}
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          {translate(
                            'form.service.preview.business_registration_number',
                          )}{' '}
                        </p>
                      </Col>
                      <Col md={6}>
                        <p className="text-lg-end">
                          {businessInfoDetail?.registrationNumber}
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          {translate('form.service.preview.inception_year')}
                        </p>
                      </Col>
                      <Col md={6}>
                        <p className="text-lg-end">
                          {businessInfoDetail?.inceptionYear}
                        </p>
                      </Col>
                      <Col md={6}>
                        <p> {translate('form.service.preview.gst_number')}</p>
                      </Col>
                      <Col md={6}>
                        <p className="text-lg-end">
                          {businessInfoDetail?.gstNumber}
                        </p>
                      </Col>

                      <Col md={6}>
                        <p>
                          {' '}
                          {translate('form.service.preview.contact_number')}
                        </p>
                      </Col>
                      <Col md={6}>
                        <p className="text-lg-end">
                          {businessInfoDetail?.mobile}
                        </p>
                      </Col>

                      <Col md={6}>
                        <p> {translate('form.service.preview.email_id')}</p>
                      </Col>
                      <Col md={6}>
                      <Tooltip title= {businessInfoDetail?.email} >
                        <p className="text-lg-end text-mail-overflow">
                          
                          {businessInfoDetail?.email}
                        </p>
                        </Tooltip>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  ) : null;
};

const mapStateToProps = ({
  authentication,
  locale,
  user,
  appointment,
  plan,
  master,
}: IRootState) => ({
  serviceDetails: user.serviceDetails,
  isAuthenticated: authentication.isAuthenticated,
  specialities: master.specialities,
  languages: master.languages,
  petTypes: master.petTypes,
  subscriptionDetails: user.subscriptionDetails,
  loading: user.loading,
  appointments: appointment.appointments,
  slots: appointment.slots,
  servicesLinks: plan.servicesLinks,
  servicePreviewDetail: appointment.servicePreviewDetail,
  fetchBankDetailsResponse: user.fetchBankDetailsResponse,
  userBusinessInfo: user.userBusinessInfo,
  state: master?.state,
  city: master?.city,
});

const mapDispatchToProps = {
  getAppointments,
  getSubscriptionDetail,
  getSlots,
  getServicesMenus,
  getSpecialityList,
  getLanguageList,
  getPetTypes,
  getServiceDetails,
  getUserBusinessInfo,
  fetchBankDetails,
  fetchCity,
  fetchState,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ServicePreview);
