import { Button,Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,} from 'reactstrap';
import { translate } from 'react-jhipster';
import { useDispatch, useSelector } from 'react-redux';
import { APPOINTMENT_STATUS, Colors } from '../../config/constants';
import { updateAppointmentStatus } from '../../reducers/appointment.reducer';
import { fetchConsultationDetails } from '../../reducers/video-consultation-reducer';
import { IRootState } from '../../shared/reducers';
import { useEffect } from 'react';

const CallConfirmation = ({ appointmentId, isShowConfirmationModal , setIsShowConfirmationModal,setCallStatus }) => {
  const dispatch = useDispatch();
  const consultationDetails = useSelector(
    (state: IRootState) => state.videoConsultation.consultationDetails,
  );

  const updateCustomerAppointmentStatus = status => {
    if (status) {
      dispatch(updateAppointmentStatus({
        id: appointmentId,
        status,
      }));
      dispatch(fetchConsultationDetails({ appointmentId }))
      setIsShowConfirmationModal(false);
    }
  };

  useEffect(() => {
    setCallStatus(consultationDetails?.header?.status)
},[consultationDetails])


  return (
    <Modal isOpen={isShowConfirmationModal}>
    <ModalHeader>
      {translate('video_consultation.update_call_status')}
    </ModalHeader>
    <ModalBody>
      {translate('video_consultation.call_status_confirm_message')}
    </ModalBody>
    <ModalFooter>
      <Button
        style={{
          background: 'var(--bs-geraldine)',
          border: 'none',
          paddingLeft: '20px'
        }}
        onClick={() =>
          updateCustomerAppointmentStatus(APPOINTMENT_STATUS.COMPLETED)
        }>
        Yes &nbsp;
      </Button>
      <Button
        style={{
          background: 'var(--bs-geraldine)',
          border: 'none',
          paddingLeft: '20px'
        }}
        onClick={() =>
          updateCustomerAppointmentStatus(APPOINTMENT_STATUS.INCOMPLETE)
        }>
        No &nbsp;
      </Button>
    </ModalFooter>
  </Modal>
  );
};

export default CallConfirmation;
