import { API_URL } from './../config/constants';
import axios from 'axios';
import { REQUEST, SUCCESS, FAILURE } from '../shared/reducers/action-type.util';

export const ACTION_TYPES = {
  GET_ROLE_PERMISSIONS: 'permissions/GET_ROLE_PERMISSIONS',
  GET_PERMISSION_ACTIONS: 'permissions/GET_PERMISSION_ACTIONS',
};

const initialState = {
  rolePermissions: [],
  roleActions: [],
};

export type PermissionState = Readonly<typeof initialState>;

export default (
  state: PermissionState = initialState,
  action,
): PermissionState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_ROLE_PERMISSIONS):
    case REQUEST(ACTION_TYPES.GET_PERMISSION_ACTIONS): {
      return {
        ...state,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_ROLE_PERMISSIONS): {
      const permissions = action.payload?.data ?? [];
      const roleActions = state.roleActions as any;
      let rolePermissions = permissions.map(item => {
        const allowedActions = [] as any;
        JSON.parse(item.allowedActionIds).forEach(id => {
          if (roleActions[id]) {
            const actionCode = roleActions[id].actionCode as any;
            if (actionCode.split('#')[1])
              allowedActions.push(actionCode.split('#')[1]);
          }
        });
        item.allowedActions = allowedActions;
        return item;
      });
      return {
        ...state,
        rolePermissions,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_PERMISSION_ACTIONS): {
      let roleActions = action.payload?.data;
      if (roleActions?.length) {
        roleActions = roleActions.reduce((a, b) => {
          a[b.id] = b;
          return a;
        }, {});
      } else {
        roleActions = {};
      }

      return {
        ...state,
        roleActions,
      };
    }
    case FAILURE(ACTION_TYPES.GET_ROLE_PERMISSIONS):
    case FAILURE(ACTION_TYPES.GET_PERMISSION_ACTIONS): {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export const getRolesPermissions: any = token => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_ROLE_PERMISSIONS,
    payload: axios.get(`${API_URL}/user-roles/permissions?roleType=VENDOR`, { headers:{ 'Authorization': `Bearer ${token}` }})
  });
  return result;
};

export const getPermissionActions: any = token => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_PERMISSION_ACTIONS,
    payload: axios.get(`${API_URL}/actions`, { headers:{ 'Authorization': `Bearer ${token}` }})
  });
  return result;
};
