import { useEffect, useState } from 'react';
import PageNotFound from './shared/error/page-not-found';
import ErrorBoundaryRoute from './shared/error/error-boundary-route';
import Login from './modules/login';
import Home from './modules/home';
import ServiceSubscription from './modules/registration/service-subscription';
import ServiceSubscriptionRenewal from './modules/registration/service-subscription-renewal';
import ConfirmRenewal from './modules/registration/confirm-renewal';
import PreRegistration from './modules/registration/pre-registration';
import PostRegistration from './modules/registration/post-registration';
import ConfirmRegistration from './modules/registration/confirm-registration';
import Services from './modules/services/service-list';
import AppointmentReschedule from './modules/services/appointment-reschedule';
import BusinessInfoUpdate from './modules/services/business-info-update';
import SubscriptionDetails from './modules/services/subscription-details';
import VendorProfileView from './modules/vendor-profile-view/vendor-profile-view';
import PaymentSuccess from './modules/payment/payment-success';
import OtpValidation from './modules/otp/otp-validation';
import VendorProfileEdit from './modules/vendor-profile-edit/vendor-profile-edit';
import ServiceDetails from './modules/services/service-details';
import AppointmentSchedule from './modules/services/appointment-schedule';
import ServicePreview from './modules/services/service-preview';
import BankDetails from './modules/services/bank-details';
import FacilitiesAndOthers from './modules/services/facilities-and-others';
import OnlineVideoConsultation from './modules/video-consultation/online-video-consultation';
import NewPassword from './modules/new-password';
import Logout from './modules/logout';
import consultationTab from './modules/consultation-tab';
import PreRegistrationSuccess from './modules/pre-registration-success/pre-registration-success';
import VendorUserList from './modules/users/vendor-user';
import vendorUserUpdate from './modules/users/vendor-user-update';
import ForgotPassword from './modules/forgot-password/forgot-password';
import {
  BrowserRouter as Router,
  Route as DefaultRoute,
  Switch,
} from 'react-router-dom';
import AppLayout from './shared/layout/app-layout';
import DefaultLayout from './shared/layout/default-layout';
import { Redirect } from 'react-router';
import CustomerList from './modules/customers/customer-list';
import customerTab, { CustomerTab } from './modules/customers/customer-tab';
import CustomerAppointmentViewDetails from './modules/customers/customer-appointments-view-details';
import AppointmentManagmentTab from './modules/appointment-managment/appointment-managment-tab';
import ServiceTab from './modules/service-tab/service-tab';
import LandingPage from './modules/landing';
import PrivacyPolicy from './modules/landing/privacy-policy';
import TermsAndConditon from './modules/landing/terms-and-condition';
import AppointmentReport from './modules/appointment-report/appointment-report';
import InPatientReport from './modules/in-patient-report.tsx/in-patient';

const Routes = props => {
  const { isAuthenticated } = props;
  const Route = ({
    component: Component,
    layout: Layout = DefaultLayout,
    ...rest
  }) => {
    return (
      <DefaultRoute
        {...rest}
        render={renderProps => {
          return (
            <Layout {...renderProps} {...props}>
              <Component {...renderProps} />
            </Layout>
          );
        }}
      />
    );
  };

  return (
    <Router>
      <Switch>
        <DefaultRoute
          exact
          path="/"
          render={() => {
            return isAuthenticated ? (
              <Redirect to="/video-consultation" />
            ) : (
              <Redirect to="/landing-page" />
            );
          }}
        />
        <Route exact path="/login" component={Login} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms-condition" component={TermsAndConditon} />
        <Route exact path="/pre-registration" component={PreRegistration} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/logout" component={Logout} layout={AppLayout} />
        <Route path="/account/password/:id" component={NewPassword} />
        <Route
          exact
          path="/service-subscription"
          component={ServiceSubscription}
        />
        <Route exact path="/post-registration" component={PostRegistration} />
        <Route
          exact
          path="/confirm-registration"
          component={ConfirmRegistration}
        />
        <Route exact path="/payment-success" component={PaymentSuccess} />
        <Route
          exact
          path="/pre-registration-success"
          component={PreRegistrationSuccess}
        />
        <Route exact path="/registration/otp/:id" component={OtpValidation} />
        <Route exact path="/otp-validation/:id" component={OtpValidation} />
        <Route exact path="/" component={Home} layout={AppLayout} />
        <Route
          exact
          path="/service-subscription-renewal"
          component={ServiceSubscriptionRenewal}
          layout={AppLayout}
        />
        <Route
          exact
          path="/confirm-renew"
          component={ConfirmRenewal}
          layout={AppLayout}
        />
        <Route
          exact
          path="/renew-success"
          component={PaymentSuccess}
          layout={AppLayout}
        />
        <Route
          exact
          path="/video-consultation/:id/consultation-detail"
          component={consultationTab}
          layout={AppLayout}
        />
        <Route
          path="/404"
          {...(isAuthenticated && {
            layout: AppLayout,
          })}
          component={PageNotFound}
        />

        {isAuthenticated ? (
          <>
            <Route
              exact
              path="/services"
              component={Services}
              layout={AppLayout}
            />
            <Route
              exact
              path="/services/:id/service-tab"
              component={ServiceTab}
              layout={AppLayout}
            />
            <Route
              exact
              path="/appointment-management"
              component={AppointmentManagmentTab}
              layout={AppLayout}
            />
            <Route
              path="/video-consultation"
              component={OnlineVideoConsultation}
              layout={AppLayout}
            />
            <Route
              exact
              path="/customer"
              component={CustomerList}
              layout={AppLayout}
            />
            <Route
              exact
              path="/customer/:id"
              component={CustomerTab}
              layout={AppLayout}
            />
            <Route
              exact
              path="/user-list"
              component={VendorUserList}
              layout={AppLayout}
            />
            <Route
              exact
              path="/user-list/:id/edit"
              component={vendorUserUpdate}
              layout={AppLayout}
            />
            <Route
              exact
              path="/vendor-profile-view"
              component={VendorProfileView}
              layout={AppLayout}
            />
            <Route
              exact
              path="/user-list/new"
              component={vendorUserUpdate}
              layout={AppLayout}
            />
            <Route
              exact
              path="/vendor-profile-edit"
              component={VendorProfileEdit}
              layout={AppLayout}
            />
            <Route
              exact
              path="/services/:id/appointment-reschedule"
              component={AppointmentReschedule}
              layout={AppLayout}
            />
            <Route
              exacts
              path="/services/:id/business-information"
              component={BusinessInfoUpdate}
              layout={AppLayout}
            />
            <Route
              exact
              path="/services/:id/service-subscription"
              component={ServiceSubscription}
              layout={AppLayout}
            />
            <Route
              path="/services/:id/appointment-schedule"
              component={AppointmentSchedule}
              layout={AppLayout}
            />
            <Route
              path="/services/:id/service-preview"
              component={ServicePreview}
              layout={AppLayout}
            />
            <Route
              exact
              path="/services/:id/subscription-details"
              component={SubscriptionDetails}
              layout={AppLayout}
            />
            <Route
              exact
              path="/services/:id/service-details"
              component={ServiceDetails}
              layout={AppLayout}
            />
            <Route
              exact
              path="/services/:id/bank-details"
              component={BankDetails}
              layout={AppLayout}
            />
            <Route
              exact
              path="/services/:id/facilities-and-others"
              component={FacilitiesAndOthers}
              layout={AppLayout}
            />
            <Route
              exact
              path="/customer/:id/pets"
              component={CustomerTab}
              layout={AppLayout}
            />
            <Route
              exact
              path="/customer/:id/appointment-details"
              component={CustomerTab}
              layout={AppLayout}
            />
            <Route
              exact
              path="/customer/appointment-details/:id"
              component={CustomerAppointmentViewDetails}
              layout={AppLayout}
            />
            <Route
              exact
              path="/appointment-report"
              component={AppointmentReport}
              layout={AppLayout}
            />
            <Route
              exact
              path="/in-patient"
              component={InPatientReport}
              layout={AppLayout}
            />
          </>
        ) : (
          <Route exact path="/landing-page" component={LandingPage} />
        )}

        {isAuthenticated ? (
          <Redirect from="/" to="/404" />
        ) : (
          <Redirect from="*" to="/" />
        )}
      </Switch>
    </Router>
  );
};

export default Routes;
