import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Col, Input, Row, Label } from 'reactstrap';
import { IRootState } from '../../shared/reducers';
import { updateAppointmentStatus } from '../../reducers/appointment.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { translate } from 'react-jhipster';
import AppointmentSchedule from '../services/appointment-schedule';
import { ClientConfig } from 'agora-rtc-react';
import AppointmentReSchedule from '../services/appointment-reschedule';
import  BusinessInfoUpdate  from '../services/business-info-update';
import  ServiceDetails  from '../services/service-details';
import  ServicePreview  from '../services/service-preview';
import BankDetails from '../services/bank-details';
import FacilitiesAndOthers from '../services/facilities-and-others';
import { getServices } from '../../reducers/plan.reducer';
import SubscriptionDetails from '../services/subscription-details';
import ServicePublishButton from '../services/components/service-publish-button';
import { PERMISSION_ACTIONS } from '../../config/permission-constants';
import { IsPermittedAction } from '../../shared/util/permission-utils';

const BUSINESS_INFORMATION = 'business_information';
const SERVICE_DETAILS = 'service_details';
const BANK_DETAILS = 'bank_details';
const FACITLITY_AND_OTHERS = 'facility_and_others';
const SUBSCRIPTION_DETAILS = 'subscription_details';
const PREVIEW = 'preview';

const SERVICE_TABS = [] as any[];

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export const ServiceTab = (props: any) => {
  const { services } = props;

  const [serviceId] = useState(props.match.params.id);
  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );

  {(!SERVICE_TABS.includes(BUSINESS_INFORMATION) && IsPermittedAction(PERMISSION_ACTIONS.GET_VENDOR_SERVICE_BUSINESS_INFO,authorities,permissionList)) && SERVICE_TABS.push(BUSINESS_INFORMATION)}
  {(!SERVICE_TABS.includes(SERVICE_DETAILS) && IsPermittedAction(PERMISSION_ACTIONS.GET_VENDOR_SERVICES,authorities,permissionList)) && SERVICE_TABS.push(SERVICE_DETAILS)}
  {(!SERVICE_TABS.includes(BANK_DETAILS) && IsPermittedAction(PERMISSION_ACTIONS.GET_VENDOR_SERVICE_BANK_ACCOUNT_DETAILS,authorities,permissionList)) && SERVICE_TABS.push(BANK_DETAILS)}
  {(!SERVICE_TABS.includes(FACITLITY_AND_OTHERS) && IsPermittedAction(PERMISSION_ACTIONS.GET_VENDOR_SERVICE_FACILITIES,authorities,permissionList)) && SERVICE_TABS.push(FACITLITY_AND_OTHERS)}
  {(!SERVICE_TABS.includes(SUBSCRIPTION_DETAILS) && IsPermittedAction(PERMISSION_ACTIONS.GET_VENDOR_SERVICE_SUBSCRIPTIONS,authorities,permissionList)) && SERVICE_TABS.push(SUBSCRIPTION_DETAILS)}
  {(!SERVICE_TABS.includes(PREVIEW) && IsPermittedAction(PERMISSION_ACTIONS.GET_VENDOR_SERVICE_BUSINESS_INFO,authorities,permissionList) && IsPermittedAction(PERMISSION_ACTIONS.GET_VENDOR_SERVICE_BANK_ACCOUNT_DETAILS,authorities,permissionList)&& IsPermittedAction(PERMISSION_ACTIONS.GET_VENDOR_SERVICE_SUBSCRIPTIONS,authorities,permissionList))  && SERVICE_TABS.push(PREVIEW)}

  const [activeTab, setActiveTab] = useState(SERVICE_TABS[0]);
  
  useEffect(() => {
    props?.getServices();
  }, []);

  return (
    <div>
      <Row className="ps-4 pe-5 py-2" style={{ backgroundColor: '#E5E5E5' }}>
        <Col md="10">
          <h6 className="text-greyTeal mb-0 px-2 py-3">
             {activeTab? translate(`service_tab.${activeTab}.tab_header`):''}
          </h6>
        
          
        </Col>
        {(activeTab === PREVIEW && IsPermittedAction(PERMISSION_ACTIONS.EDIT_VENDOR_SERVICE_STATUS,authorities,permissionList))? (
        <Col md="2" className="py-1 d-flex justify-content-center align-items-center">
        <div>
          <ServicePublishButton serviceId={serviceId} />
        </div>
        </Col>
        ):("")}
      </Row>
      <Row>
        <Col md={12}>
          <div className="me-4 ms-4" id="consultation-tab">
            <ul  
            style={{paddingTop: '1rem',paddingBottom:'1rem'}}
              className="nav nav-pills justify-content-start tab-container"
              id="pills-tab"
              role="tablist">
              {SERVICE_TABS.map((tab, index) => (
                <li key={index}  className="nav-item" role="presentation">
                  <button
                    className={`nav-link mt-0 ${
                      tab === activeTab && 'active'
                    } tab-font`}
                    onClick={() => setActiveTab(tab)}
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true">
                    <span>
                      {translate(`service_tab.${tab}.tab_header`)}
                    </span>
                  </button>
                </li>
                
              ))}
            </ul>
            <div className="tab-content" id="pills-tabContent">
              {props.loading && !serviceId ? (
                <div className="text-info my-2" style={{ fontSize: 32 }}>
                  <FontAwesomeIcon icon={faSpinner} spin />
                </div>
              ) : (
                <Col md={12}>
                  { activeTab === BUSINESS_INFORMATION ? (
                    <BusinessInfoUpdate
                        serviceId={serviceId}
                        className="mb-2"
                    />
                   ) : activeTab === BANK_DETAILS ? (
                    <BankDetails
                        serviceId={serviceId}
                        className="mb-2"
                    />
                  ) : activeTab === SERVICE_DETAILS ? (
                    <Col>
                      <ServiceDetails
                        serviceId={serviceId}
                        className="mb-2"
                      />
                    </Col>
                  ) : activeTab === FACITLITY_AND_OTHERS ? (
                    <Col>
                      <FacilitiesAndOthers
                        serviceId={serviceId}
                        className="mb-2"
                      />
                    </Col>
                  ) : activeTab === SUBSCRIPTION_DETAILS ? (
                      <Col>
                        <SubscriptionDetails
                        serviceId={serviceId}
                        className="mb-2"
                      />
                      </Col>
                  ) : activeTab === PREVIEW ? (
                      <Col>
                        <ServicePreview
                        serviceId={serviceId}
                        className="mb-2"
                      />
                      </Col>
                  ): (
                      <Col> </Col>
                  )
                }
                </Col>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({
  authentication,
  user,
  videoConsultation,
  plan,
}: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  userMenus: user.menus,
  loading: user.loading,
  weekDetail: videoConsultation.weekDetail,
  doctors: videoConsultation.doctors,
  appointments: videoConsultation.appointments,
  consultationDetails: videoConsultation.consultationDetails,
  initiateVideoCallResponse: videoConsultation.initiateVideoCallResponse,
  services: plan.services,
});

const mapDispatchToProps = {
  updateAppointmentStatus,
  getServices,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceTab);
