import videoConsultation, { VideoConsultationState } from './../../reducers/video-consultation-reducer';
import master, { MasterState } from './../../reducers/master.reducer';
import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import authentication, { AuthenticationState } from './authentication';
import locale, { LocaleState } from './locale';
import user, { UserState } from '../../reducers/user.reducer';
import appointment, { AppointmentState } from '../../reducers/appointment.reducer';
import plan, { PlanState } from '../../reducers/plan.reducer';
import prescription, { PrescriptionState } from '../../reducers/prescription.reducer';
import vendorUser, { VendorState } from '../../reducers/vendor-user.reducer';
import permissions, { PermissionState } from '../../reducers/permissions.reducer';

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly user: UserState;
  readonly appointment: AppointmentState;
  readonly plan: PlanState;
  readonly prescription: PrescriptionState;
  readonly locale: LocaleState;
  readonly loadingBar: any;
  readonly master: MasterState;
  readonly vendorUser: VendorState;
  readonly videoConsultation: VideoConsultationState;
  readonly permissions: PermissionState;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  locale,
  appointment,
  plan,
  prescription,
  loadingBar,
  user,
  master,
  vendorUser,
  videoConsultation,
  permissions
});

export default rootReducer;
