import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Col, Container, Label, Row } from 'reactstrap';
import { IRootState } from '../../shared/reducers';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  createBusinessInfo,
  getUserBusinessInfo,
  updateBusinessInfo,
  updateBusinessInfoState,
  requestToPublish,
} from '../../reducers/user.reducer';
import { ISubMenu } from '../../model/user-menu';
import SubHeader from '../../shared/layout/sub-header';
import { setFileData, translate, Translate } from 'react-jhipster';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useWindowDimensions } from '../../shared/util/utility-functions';
import { getServicesMenus } from '../../reducers/plan.reducer';
import { getYearList } from '../../reducers/appointment.reducer';
import { defaultValue, IImageBlob } from '../../model/image.model';
import { SM_SCREEN_BREAKPOINT } from '../../config/constants';
import { PHONE_NUMBER_LENGTH } from '../../config/constants';
import { toast } from 'react-toastify';
import Button from '../components/button';
import ReactCopyToClipboardUI from 'react-copy-to-clipboard-ui';
import { resizeImage } from '../../utils/resize-image';
import { PERMISSION_ACTIONS } from '../../config/permission-constants';
import { IsPermittedAction } from '../../shared/util/permission-utils';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import { useDebounceEffect } from '../../utils/useDebounceEffect';
import { canvasPreview } from '../../utils/canvasPreview';
import 'react-image-crop/dist/ReactCrop.css'


const DEFAULT_INCEPTION_YEAR = '';
const INCEPTION_YEAR_LENGTH = 4;

export interface IPageProps
  extends StateProps,
  DispatchProps,
  RouteComponentProps<{ id: string }> { }
const BusinessInfoUpdate = (props: any) => {
  const { userBusinessInfo, servicesLinks, loading, requestToPublish } = props;
  const [selectedSubMenu, setSubMenu] = useState({} as ISubMenu);
  const { width } = useWindowDimensions();
  const [formLoading, setFormLoading] = useState(false);
  const [logo, setLogoImage] = useState(defaultValue as IImageBlob);
  const [businessInfoDetail, setBusinessInfoDetail] = useState({
    id: null,
    businessName: '',
    registrationNumber: '',
    inceptionYear: `${DEFAULT_INCEPTION_YEAR}`,
    gstNumber: '',
    mobile: '',
    email: '',
    imageContentType: '',
    imageData: '',
    aboutUs: '',
  } as any);
  const serviceId = props.serviceId;
  const copyToClipboardRef = useRef(null);
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(10 / 10)
  const [image, setImage] = useState({} as any)
  const [crop, setCrop] = useState<Crop | undefined>({
    unit: '%', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50
  })

  let copyToClipboardBtn = document.querySelector('.copy-to-clipboard-container button');
  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );

  useEffect(() => {
    copyToClipboardBtn?.setAttribute('type', 'button')
  }, [copyToClipboardBtn])

  useEffect(() => {
    setFormLoading(true);
    props.getUserBusinessInfo({ serviceId });
    props.getServicesMenus();
    props.getYearList();
  }, [serviceId]);

  const setImageBlob = (imageData, contentType) => {
    const value = { imageData: imageData, contentType };
    setLogoImage(value);
  };

  useEffect(() => {
    if (formLoading) {
      if (userBusinessInfo) {
        setBusinessInfoDetail(userBusinessInfo);
      }
      setFormLoading(false);
    }
  }, [userBusinessInfo]);

  const onHandleValidSubmit = (event, values) => {
    setFormLoading(true);
    if (!businessInfoDetail?.imageUrl && !logo.imageData) {
      toast.error(translate('form.validation.select_logo_toast'));
      return;
    } else {
      let payload = {
        ...businessInfoDetail,
        imageData: logo.imageData,
        imageContentType: logo.contentType,
      };
      if((imgSrc && completedCrop) || (!imgSrc && !completedCrop)) {
        if (businessInfoDetail?.id) {
          props.updateBusinessInfo(serviceId, payload);
          setImgSrc('')
          setCompletedCrop(null)
        } else {
          props.createBusinessInfo(serviceId, payload);
          setImgSrc('')
          setCompletedCrop(null)
        }
      }
      else {
        toast.error('Select the Crop Position before saving');
      }
    }
  };

  const renderAttachIcon = () => {
    return (
      <svg width="11" height="21" viewBox="0 0 11 21" fill="none">
        <path
          d="M8.95247 5.37227V15.9139C8.95247 17.9398 7.31164 19.5806 5.28581 19.5806C3.25997 19.5806 1.61914 17.9398 1.61914 15.9139V4.45561C1.61914 3.19061 2.64581 2.16394 3.91081 2.16394C5.17581 2.16394 6.20247 3.19061 6.20247 4.45561V14.0806C6.20247 14.5848 5.78997 14.9973 5.28581 14.9973C4.78164 14.9973 4.36914 14.5848 4.36914 14.0806V5.37227H2.99414V14.0806C2.99414 15.3456 4.02081 16.3723 5.28581 16.3723C6.55081 16.3723 7.57747 15.3456 7.57747 14.0806V4.45561C7.57747 2.42977 5.93664 0.78894 3.91081 0.78894C1.88497 0.78894 0.244141 2.42977 0.244141 4.45561V15.9139C0.244141 18.7006 2.49914 20.9556 5.28581 20.9556C8.07247 20.9556 10.3275 18.7006 10.3275 15.9139V5.37227H8.95247Z"
          fill="white"
        />
      </svg>
    );
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    setImage(e)
    if (aspect) {
      const { width, height } = e.currentTarget
    }
  }

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
          (resized, resizedContentType) =>
            setImageBlob(resized, resizedContentType),
        )
      }
    },
    100,
    [completedCrop, scale, rotate],

  )

  return (
    <div>
      {loading ? (
        <div className="text-info my-4" style={{ fontSize: 32 }}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      ) : (
        <AvForm model={businessInfoDetail} onValidSubmit={onHandleValidSubmit}>
          <Container
            fluid
            className="px-0 px-lg-4 p-3 pb-5 mb-2"
            id="business-info-update">
            <Row>
              <Col lg="6">
                <Row className="mt-4">
                  <Col md="6">
                    <Label for="businessName">
                      <Translate contentKey="form.service.business.displayName">
                        displayName
                      </Translate>
                      <span className="text-danger">*</span>
                    </Label>
                    <AvField
                      value={businessInfoDetail?.businessName}
                      onChange={({ target }) =>
                        setBusinessInfoDetail({
                          ...businessInfoDetail,
                          businessName: target?.value,
                        })
                      }
                      className="p-3"
                      name="businessName"
                      id="businessName"
                      validate={{
                        required: {
                          errorMessage: translate(
                            'validation_business_info.name_error_message',
                          ),
                        },
                      }}
                    />
                  </Col>
                  <Col md="6">
                    <Label for="registrationNumber">
                      <Translate contentKey="form.service.business.registrationNumber">
                        registrationNumber
                      </Translate>
                    </Label>
                    <AvField
                      value={businessInfoDetail?.registrationNumber}
                      onChange={({ target }) =>
                        setBusinessInfoDetail({
                          ...businessInfoDetail,
                          registrationNumber: target?.value,
                        })
                      }
                      className="p-3"
                      name="registrationNumber"
                      id="registrationNumber"
                    />
                  </Col>
                  <Col md="6" className="mt-3">
                    <Label for="inceptionYear">
                      <Translate contentKey="form.service.business.inceptionYear">
                        inceptionYear
                      </Translate>
                      <span className="text-danger">*</span>
                    </Label>
                    <AvField
                      value={`${businessInfoDetail?.inceptionYear}`}
                      onChange={({ target }) =>
                        setBusinessInfoDetail({
                          ...businessInfoDetail,
                          inceptionYear: target?.value,
                        })
                      }
                      className="p-3"
                      name="inceptionYear"
                      id="inceptionYear"
                      type="number"
                      validate={{
                        required: {
                          errorMessage: translate(
                            'validation_business_info.inception_error_message',
                          ),
                        },
                        minLength: {
                          value: INCEPTION_YEAR_LENGTH,
                          errorMessage: translate(
                            'validation_business_info.invalid_inception_error_message',
                          ),
                        },
                        maxLength: {
                          value: INCEPTION_YEAR_LENGTH,
                          errorMessage: translate(
                            'validation_business_info.invalid_inception_error_message',
                          ),
                        },
                      }}
                    />
                    {/* commented for future */}
                    {/* <AvField
                      type="select"
                      name="inceptionYear"
                      id="inceptionYear"
                      required
                    >
                      <option value="">
                        {translate("entity.action.select")}{" "}
                        {translate("form.service.business.year")}
                      </option>
                      {years.map((year) => (
                        <option value={year} key={year}>
                          {" "}
                          {year}{" "}
                        </option>
                      ))}
                    </AvField> */}
                  </Col>
                  <Col md="6" className="mt-3">
                    <Label for="gstNumber">
                      <Translate contentKey="form.service.business.gstNumber">
                        gstNumber
                      </Translate>
                    </Label>
                    <AvField
                      value={businessInfoDetail?.gstNumber}
                      onChange={({ target }) =>
                        setBusinessInfoDetail({
                          ...businessInfoDetail,
                          gstNumber: target?.value,
                        })
                      }
                      className="p-3"
                      name="gstNumber"
                      id="gstNumber"
                    />
                  </Col>
                  <Col md="6" className="mt-3">
                    <Label for="logoUrl">
                      <Translate contentKey="form.service.business.logoUrl">
                        logoUrl
                      </Translate>
                      <span className="text-danger">*</span>
                      &nbsp;
                      <Translate contentKey="form.service.business.imageSize">
                        logoUrl
                      </Translate>
                    </Label>
                    <br />
                    <div className="file-drop-area">
                      <span className="file-msg">
                        {businessInfoDetail?.imageUrl ??
                          'Pick logo or drag and drop logo here'}
                      </span>
                      <span className="fake-btn">{renderAttachIcon()}</span>
                      <input
                        onChange={onSelectFile}
                        accept="image/*"
                        id="logoUrl"
                        className="file-input"
                        type="file"
                      />
                      <input
                        // onChange={handleImagePick}
                        accept="image/*"
                        id="logoUrl"
                        type="hidden"
                        name="fileHidden"
                      />
                    </div>
                    {imgSrc ? (
                      <>
                        <ReactCrop
                          crop={crop}
                          onChange={(_, percentCrop) => setCrop(percentCrop)}
                          onComplete={(c) => setCompletedCrop(c)}
                          aspect={aspect}
                        >
                          <img
                            ref={imgRef}
                            alt="Crop me"
                            src={imgSrc}
                            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                            onLoad={onImageLoad}
                          />
                        </ReactCrop>
                        <div>
                          {!!completedCrop && (
                            <canvas
                              ref={previewCanvasRef}
                              style={{
                                border: '1px solid black',
                                objectFit: 'contain',
                                width: completedCrop.width,
                                height: completedCrop.height,
                              }}
                            />
                          )}
                        </div>
                      </>) : (


                      <div className="logo-container">
                        {logo?.imageData ? (
                          <>
                            <img
                              alt=""
                              src={`data:${logo.contentType};base64,${logo.imageData}`}
                              className="logo-image"
                            />
                          </>
                        ) : (
                          businessInfoDetail?.imageUrl && (
                            <img
                              src={businessInfoDetail?.imageUrl}
                              className="logo-image"
                            />
                          )
                        )}
                      </div>)}
                  </Col>
                  <Col md="6" className="mt-3">
                    <Label for="aboutUs">
                      <Translate contentKey="form.service.business.aboutUs">
                        About Us
                      </Translate>
                      <span className="text-danger">*</span>
                    </Label>
                    <AvField
                      className="p-4"
                      name="aboutUs"
                      id="aboutUs"
                      type="textarea"
                      value={businessInfoDetail?.aboutUs}
                      style={{ fontSize: '0.8rem' }}
                      onChange={({ target }) =>
                        setBusinessInfoDetail({
                          ...businessInfoDetail,
                          aboutUs: target?.value,
                        })
                      }
                      validate={{
                        required: {
                          errorMessage: translate(
                            'validation_business_info.about_us_error_message',
                          ),
                        },
                      }}
                    />
                    <br />
                    <div>
                      {businessInfoDetail.referralLink ?
                        <>
                          <Col md="6">
                            <Label for="referralLink">
                              <Translate contentKey="form.service.business.referralLink">
                                Referral Link
                              </Translate>
                            </Label>
                            <div ref={copyToClipboardRef} className='copy-to-clipboard-container'> <ReactCopyToClipboardUI>{`${businessInfoDetail.referralLink} `}</ReactCopyToClipboardUI> </div>
                          </Col>
                        </> : null}

                    </div>
                  </Col>
                  <Col xs="12" className="mt-5">
                    <h6 className="business-contact-info-text">
                      <Translate contentKey="form.service.business.headingContactInfo">
                        Business Contact Information
                      </Translate>
                    </h6>
                    <hr style={{ width: '83rem' }} />
                  </Col>
                  <Col md="6" className="mt-3">
                    <Label for="mobile">
                      <Translate contentKey="form.service.business.contactNumber">
                        contactNumber
                      </Translate>
                      <span className="text-danger">*</span>
                    </Label>
                    <AvField
                      value={businessInfoDetail?.mobile}
                      onChange={({ target }) =>
                        setBusinessInfoDetail({
                          ...businessInfoDetail,
                          mobile: target?.value,
                        })
                      }
                      className="p-3"
                      name="mobile"
                      id="mobile"
                      type="number"
                      validate={{
                        required: {
                          errorMessage: translate(
                            'validation_business_info.contact_number_error_message',
                          ),
                        },
                        minLength: {
                          value: `${PHONE_NUMBER_LENGTH}`,
                          errorMessage: translate(
                            'validation_business_info.invalid_number_error_message',
                          ),
                        },
                        maxLength: {
                          value: `${PHONE_NUMBER_LENGTH}`,
                          errorMessage: translate(
                            'validation_business_info.invalid_number_error_message',
                          ),
                        },
                      }}
                    />
                  </Col>
                  <Col md="6" className="mt-3">
                    <Label for="email">
                      <Translate contentKey="form.service.business.email">
                        email
                      </Translate>
                      <span className="text-danger">*</span>
                    </Label>
                    <AvField
                      value={businessInfoDetail?.email}
                      onChange={({ target }) =>
                        setBusinessInfoDetail({
                          ...businessInfoDetail,
                          email: target?.value,
                        })
                      }
                      className="p-3"
                      name="email"
                      id="email"
                      type="text"
                      validate={{
                        required: {
                          errorMessage: translate(
                            'validation_business_info.email_error_message',
                          ),
                        },
                        email: {
                          errorMessage: translate(
                            'validation_business_info.invalid_email_error_message',
                          ),
                        },
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <div className="bottom-button-save-container d-flex justify-content-end">
            {(IsPermittedAction(PERMISSION_ACTIONS.CREATE_VENDOR_SERVICE_BUSINESS_INFO, authorities, permissionList) && (IsPermittedAction(PERMISSION_ACTIONS.UPDATE_VENDOR_SERVICE_BUSINESS_INFO, authorities, permissionList))) && <Button
              type="submit"
              color="danger"
              className="m-2 text-light button-text mr-5 px-5"
              size={width > SM_SCREEN_BREAKPOINT ? '' : 'sm'}>
              {translate('placeholders.save_as_draft')}
            </Button>}
          </div>
        </AvForm>
      )}
    </div>
  );
};

const mapStateToProps = ({
  authentication,
  user,
  plan,
  appointment,
}: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  userBusinessInfo: user.userBusinessInfo,
  loading: user.loading,
  servicesLinks: plan.servicesLinks,
  years: appointment.years,
  subscriptionDetails: user?.subscriptionDetails,
  updateResponse: user?.updateBusinessInfoResponse,
  createResponse: user?.createBusinessInfoResponse,
});

const mapDispatchToProps = {
  getUserBusinessInfo,
  getServicesMenus,
  getYearList,
  createBusinessInfo,
  updateBusinessInfo,
  updateBusinessInfoState,
  requestToPublish,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BusinessInfoUpdate);
