import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Col, Container, Label, Row } from 'reactstrap';
import { IRootState } from '../../shared/reducers';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  fetchBankDetails,
  createBankDetail,
  updateBankDetail,
} from '../../reducers/user.reducer';
import { IMenu, ISubMenu } from '../../model/user-menu';
import SubHeader from '../../shared/layout/sub-header';
import { translate } from 'react-jhipster';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useWindowDimensions } from '../../shared/util/utility-functions';
import { getServicesMenus } from '../../reducers/plan.reducer';
import {
  HTTP_CREATED_RESPONSE,
  HTTP_OK_RESPONSE,
  SM_SCREEN_BREAKPOINT,
} from '../../config/constants';
import { getSpecialityList } from '../../reducers/master.reducer';
import Button from '../components/button';
import ServicePublishButton from './components/service-publish-button';
import { PERMISSION_ACTIONS } from '../../config/permission-constants';
import { IsPermittedAction } from '../../shared/util/permission-utils';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

const BankDetails = (props: any) => {
  const { servicesLinks, subscriptionDetails, loading } = props;
  const [selectedSubMenu, setSubMenu] = useState({} as ISubMenu);
  const { width } = useWindowDimensions();
  const [formLoading, setFormLoading] = useState(false);
  const [bankDetail, setBankDetail] = useState({
    id: null,
    bankName: '',
    accountNumber: '',
    beneficiaryName: '',
    ifscCode: '',
    panNumber: '',
    isApplyAll: false,
  } as any);

  const serviceId = props.serviceId;
  const [confirmAccountNumber, setConfirmAccountNumber] = useState(0);
  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );


  useEffect(() => {
    fetchBankDetail();
    props.getServicesMenus();
  }, []);

  useEffect(() => {
    if (formLoading) {
      if (props.fetchBankDetailsResponse?.status === HTTP_OK_RESPONSE) {
        setBankDetail(props.fetchBankDetailsResponse?.data);
        setConfirmAccountNumber(
          props.fetchBankDetailsResponse?.data?.accountNumber,
        );
      }
      setFormLoading(false);
    }
  }, [props.fetchBankDetailsResponse]);

  const fetchBankDetail = () => {
    setFormLoading(true);
    props.fetchBankDetails({ serviceId });
  };

  useEffect(() => {
    if (formLoading) {
      if (props.createBankDetailResponse?.status === HTTP_CREATED_RESPONSE) {
        fetchBankDetail();
      }
      setFormLoading(false);
    }
  }, [props.createBankDetailResponse]);

  useEffect(() => {
    if (formLoading) {
      if (props.updateBankDetailResponse?.status === HTTP_OK_RESPONSE) {
        fetchBankDetail();
      }
      setFormLoading(false);
    }
  }, [props.updateBankDetailResponse]);

  const handleValidSubmit = (event, values) => {
    setFormLoading(true);
    if (bankDetail?.id) {
      props.updateBankDetail(serviceId, bankDetail);
    } else {
      props.createBankDetail(serviceId, bankDetail);
    }
  };

  return (
    <div>
      {formLoading ? (
        <div className="text-info my-4" style={{ fontSize: 32 }}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      ) : (
        <AvForm model={bankDetail} onValidSubmit={handleValidSubmit}>
          <Container fluid className="px-0 px-lg-4 p-3 pb-5 mb-2" id="bank-details">
            <Row>
              <Col lg="6">
                <Row className="mt-3">
                  <Col className="mt-3 mb-3" md="6">
                    <Label className="mb-1" for="bankName">
                      {translate('form.service.bank_detail.bank_name_text')}
                      <span className="text-danger">*</span>
                    </Label>
                    <AvField
                      value={bankDetail?.bankName}
                      onChange={({ target }) =>
                        setBankDetail({
                          ...bankDetail,
                          bankName: target?.value,
                        })
                      }
                      className="p-3"
                      name="bankName"
                      id="bankName"
                      validate={{
                        required: {
                          errorMessage: translate(
                            'validation_bank_details.bank_name_error_message',
                          ),
                        },
                      }}
                    />
                  </Col>
                  <Col className="mt-3 mb-3" md="6">
                    <Label className="mb-1" for="beneficiaryName">
                      {translate('form.service.bank_detail.account_name_text')}
                      <span className="text-danger">*</span>
                    </Label>
                    <AvField
                      value={bankDetail?.beneficiaryName}
                      onChange={({ target }) =>
                        setBankDetail({
                          ...bankDetail,
                          beneficiaryName: target?.value,
                        })
                      }
                      className="p-3"
                      name="beneficiaryName"
                      id="beneficiaryName"
                      validate={{
                        required: {
                          errorMessage: translate(
                            'validation_bank_details.holder_name_error_message',
                          ),
                        },
                      }}
                    />
                  </Col>
                  <Col className="mt-3 mb-3" md="6">
                    <Label className="mb-1" for="accountNumber">
                      {translate('form.service.bank_detail.account_number')}
                      <span className="text-danger">*</span>
                    </Label>
                    <AvField
                      value={bankDetail?.accountNumber}
                      onChange={({ target }) =>
                        setBankDetail({
                          ...bankDetail,
                          accountNumber: target?.value,
                        })
                      }
                      className="p-3"
                      name="accountNumber"
                      id="accountNumber"
                      validate={{
                        required: {
                          errorMessage: translate(
                            'validation_bank_details.account_number_error_message',
                          ),
                        },
                      }}
                    />
                  </Col>
                  <Col className="mt-3 mb-3" md="6">
                    <Label className="mb-1" for="accountNumber">
                      {translate(
                        'form.service.bank_detail.confirm_account_number',
                      )}
                      <span className="text-danger">*</span>
                    </Label>
                    <AvField
                      value={confirmAccountNumber}
                      onChange={({ target }) =>
                        setConfirmAccountNumber(target?.value)
                      }
                      className="p-3"
                      name="confirmAccountNumber"
                      id="confirmAccountNumber"
                      validate={{
                        required: {
                          errorMessage: translate(
                            'validation_bank_details.account_number_error_message',
                          ),
                        },
                        validate: v =>
                          v === bankDetail?.accountNumber ||
                          translate(
                            'form.service.bank_detail.confirm_account_number_match',
                          ),
                      }}
                    />
                  </Col>
                  <Col className="mt-3 mb-3" md="6">
                    <Label className="mb-1" for="ifscCode">
                      {translate('form.service.bank_detail.ifsc_code')}
                      <span className="text-danger">*</span>
                    </Label>
                    <AvField
                      value={bankDetail?.ifscCode}
                      onChange={({ target }) =>
                        setBankDetail({
                          ...bankDetail,
                          ifscCode: target?.value,
                        })
                      }
                      className="p-3"
                      name="ifscCode"
                      id="ifscCode"
                      validate={{
                        required: {
                          errorMessage: translate(
                            'validation_bank_details.ifsc_error_message',
                          ),
                        },
                      }}
                    />
                  </Col>
                  <Col className="mt-3 mb-3" md="6">
                    <Label className="mb-1" for="panNumber">
                      {translate('form.service.bank_detail.pan_number')}
                      <span className="text-danger">*</span>
                    </Label>
                    <AvField
                      value={bankDetail?.panNumber}
                      onChange={({ target }) =>
                        setBankDetail({
                          ...bankDetail,
                          panNumber: target?.value,
                        })
                      }
                      className="p-3"
                      name="panNumber"
                      id="panNumber"
                      validate={{
                        required: {
                          errorMessage: translate(
                            'validation_bank_details.pan_error_message',
                          ),
                        },
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    {/* For Future
                     <Label for="isApplyAll"></Label>
                    <AvField
                      value={bankDetail?.isApplyAll}
                      onChange={({ target }) =>
                        setBankDetail({
                          ...bankDetail,
                          isApplyAll: !bankDetail?.isApplyAll,
                        })
                      }
                      type="checkbox"
                      name="isApplyAll"
                      id="isApplyAll"
                      label={translate(
                        'form.service.bank_detail.apply_all_services',
                      )}
                    /> */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <div className="bottom-button-save-container d-flex justify-content-end">
            {(IsPermittedAction(PERMISSION_ACTIONS.CREATE_VENDOR_SERVICE_BANK_DETAILS,authorities,permissionList)&& (IsPermittedAction(PERMISSION_ACTIONS.EDIT_VENDOR_SERVICE_BANK_DETAILS,authorities,permissionList))) &&<Button
              type="submit"
              color="danger"
              className="m-2 text-light button-text mr-5 px-4"
              size={width > SM_SCREEN_BREAKPOINT ? '' : 'sm'}>
              {translate('placeholders.save_as_draft')}
            </Button>}
          </div>
        </AvForm>
      )}
    </div>
  );
};

const mapStateToProps = ({
  authentication,
  user,
  plan,
  master,
}: IRootState) => ({
  loading: user.loading,
  servicesLinks: plan.servicesLinks,
  specialities: master.specialities,
  subscriptionDetails: user.subscriptionDetails,
  createBankDetailResponse: user.createBankDetailResponse,
  updateBankDetailResponse: user.updateBankDetailResponse,
  fetchBankDetailsResponse: user.fetchBankDetailsResponse,
});

const mapDispatchToProps = {
  createBankDetail,
  updateBankDetail,
  getServicesMenus,
  getSpecialityList,
  fetchBankDetails,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BankDetails);
