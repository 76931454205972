import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import pdfjsWorker from 'react-pdf/dist/esm/pdf.worker.entry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  Col,
  Modal,
  ModalBody,
  Button,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import { ModalTitle } from 'react-bootstrap';
import { GrClose } from 'react-icons/gr';
import {
  faMinus,
  faPlus,
  faRotateLeft,
} from '@fortawesome/free-solid-svg-icons';

const AttachmentViewer = props => {
  const fileExtension = props?.pdfFile?.split('.').pop();

  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [isRotate, setIsRotate] = useState(false);
  const [origin, setOrigin] = useState('top left');

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleZoomIn = () => {
    setScale(scale + 0.1);
    setOrigin('top left');
  };

  const handleZoomOut = () => {
    setScale(scale - 0.1);
    setOrigin('top left');
  };

  // commented for future
  // const handleRotate = e => {
  //   setRotate(rotate + 90);
  //   setIsRotate(true);
  //   setOrigin('center center');
  // };
  // useEffect(() => {
  //   if (rotate == 360) {
  //     setRotate(0);
  //   }
  // }, [rotate]);

  return (
    <Modal
      isOpen={props?.show}
      toggle={props?.toggle}
      size={fileExtension === 'pdf' ? 'lg' : 'xl'}>
      <ModalHeader>
        <ModalTitle>{props?.title}</ModalTitle>
      </ModalHeader>
      <ModalBody className="modal-content">
        <div style={{ width: '100%' }}>
          <div className="d-flex justify-content-center">
            <Button onClick={handleZoomIn} className="zoom-rotate-button">
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            <Button onClick={handleZoomOut} className="zoom-rotate-button">
              <FontAwesomeIcon icon={faMinus} />
            </Button>
            {/* commented for future */}
            {/* <Button
              onClick={e => handleRotate(e)}
              className="zoom-rotate-button">
              <FontAwesomeIcon icon={faRotateLeft} />
            </Button> */}
          </div>
          {fileExtension === 'pdf' ? (
            <div className="m-4 overflow-scroll img-style">
              <Document
                file={props?.pdfFile}
                onLoadSuccess={onDocumentLoadSuccess}
                options={{ workerSrc: pdfjsWorker }}>
                {Array.from(new Array(numPages), (el, index) => (
                  <div
                    style={{
                      transform: `scale(${scale}) rotate(${rotate}deg)`,
                      transformOrigin: isRotate ? origin : 'top left',
                      height: '100%',
                      width: '100%',
                    }}>
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      scale={scale}
                      rotate={rotate}
                    />
                  </div>
                ))}
              </Document>
            </div>
          ) : (
            <div className="m-4 img-style">
              <img
                src={props?.pdfFile}
                alt=""
                style={{
                  transform: `scale(${scale}) rotate(${rotate}deg)`,
                  transformOrigin: isRotate ? origin : 'top left',
                  height: '100%',
                  width: '100%',
                }}
              />
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          style={{
            background: 'var(--bs-geraldine)',
            border: 'none',
            paddingLeft: '20px',
          }}
          onClick={props.onHide}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AttachmentViewer;
