import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import { Button, Col, Row } from 'reactstrap';
import { IRootState } from '../../shared/reducers';
import 'react-multi-carousel/lib/styles.css';
import './styles.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { updateAppointmentStatus } from '../../reducers/appointment.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { translate } from 'react-jhipster';
import CustomerPets from './customer-pets';
import { ClientConfig } from 'agora-rtc-react';
import CustomerAppointmentDetails from './customer-appointment-details';
import { PERMISSION_ACTIONS } from '../../config/permission-constants';
import { IsPermittedAction } from '../../shared/util/permission-utils';
import 'antd/dist/antd.css';
import { faArrowLeft, faBackward } from '@fortawesome/free-solid-svg-icons';


const CUSTOMER_PETS = 'customer_pets';
const CUSTOMER_APPOINTMENT_DETAILS = 'customer_appointment_details';

export const CUSTOMER_TABS = [] as any[];

export interface IPageProps
  extends StateProps,
  DispatchProps,
  RouteComponentProps<{ id: string }> { }

// the create methods in the wrapper return a hook
// the create method should be called outside the parent component
// this hook can be used the get the client/stream in any component
const config: ClientConfig = {
  mode: 'rtc',
  codec: 'vp8',
};

export const CustomerTab = (props: IPageProps) => {
  const customerId = props?.match?.params?.id;
  const [filter, setFilter] = useState({});
  const history = useHistory();
  const callFilter = () => { };
  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );
  
    {(!CUSTOMER_TABS.includes(CUSTOMER_PETS) && IsPermittedAction(PERMISSION_ACTIONS.GET_ALL_VENDOR_CUSTOMERS_PETS,authorities,permissionList)) && CUSTOMER_TABS.push(CUSTOMER_PETS)}
    {(!CUSTOMER_TABS.includes(CUSTOMER_APPOINTMENT_DETAILS) && IsPermittedAction(PERMISSION_ACTIONS.GET_SERVICE_APPOINTMENTS_BY_CUSTOMER_ID,authorities,permissionList)) && CUSTOMER_TABS.push(CUSTOMER_APPOINTMENT_DETAILS)}
    
    const [activeTab, setActiveTab] = useState(
      (props.location.state as any)?.tabId ?? CUSTOMER_TABS[0],
    );

  return (
    <>
      <div>
        <Button
          className='text-white'
          color="danger"
          size="sm"
          data-cy="entityBackwardButton"
          onClick={() =>
            history.push('/customer')
          }>
          <div style={{ color: 'white' }}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
        </Button>
      </div>
      <Col md={12}>
        <Row>
          <Col md={12}>
            <div
              className="p-4"
              style={{ marginBottom: '5rem' }}
              id="consultation-tab">
              <ul
                className="nav nav-pills pt-2 justify-content-start tab-container"
                id="pills-tab"
                role="tablist">
                {CUSTOMER_TABS.map((tab, index) => (
                  <li key={index} className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${tab === activeTab && 'active'
                        } tab-font`}
                      onClick={() => setActiveTab(tab)}
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true">
                      <span>{translate(`customer_tab.${tab}.tab_header`)}</span>
                    </button>
                  </li>
                ))}
              </ul>
              <div className="tab-content" id="pills-tabContent">
                {props.loading ? (
                  <div className="text-info my-4" style={{ fontSize: 32 }}>
                    <FontAwesomeIcon icon={faSpinner} spin />
                  </div>
                ) : (
                  <Col md={12}>
                    {activeTab === CUSTOMER_PETS ? (
                      <CustomerPets customerId={customerId} />
                    ) : activeTab === CUSTOMER_APPOINTMENT_DETAILS ? (
                      <Col>
                        <CustomerAppointmentDetails customerId={customerId} />
                      </Col>
                    ) : (
                      <Col />
                    )}
                  </Col>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </>
  );
};

const mapStateToProps = ({
  authentication,
  user,
  videoConsultation,
}: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  userMenus: user.menus,
  loading: user.loading,
  weekDetail: videoConsultation.weekDetail,
  doctors: videoConsultation.doctors,
  appointments: videoConsultation.appointments,
  consultationDetails: videoConsultation.consultationDetails,
  initiateVideoCallResponse: videoConsultation.initiateVideoCallResponse,
});

const mapDispatchToProps = {
  updateAppointmentStatus,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTab);
