import moment from "moment";

import { DATE_TIME_FORMATS } from "../../config/constants";
///commented for future
const { APP_LOCAL_DATE_TIME_FORMAT, HH_MM_SS, TWENTY_FOUR_HH_MM } =
  DATE_TIME_FORMATS;
// export const convertDateTimeFromServer = date => (date ? moment(date).format(APP_LOCAL_DATE_TIME_FORMAT) : null);

// export const convertDateTimeToServer = date => (date ? moment(date, APP_LOCAL_DATE_TIME_FORMAT_Z).toDate() : null);

export const displayDefaultDateTime = () =>
  moment().startOf("day").format(APP_LOCAL_DATE_TIME_FORMAT);

const today = moment(new Date());
export const daysAddedDate = (day = today, daysToAdd = 0) =>
  moment(day, APP_LOCAL_DATE_TIME_FORMAT).add(daysToAdd - 1, "days");

export const getDaysBetweenDates = (startDate = today, endDate = today) => {
  var now = startDate.clone(),
    dates: any[] = [];
  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format(APP_LOCAL_DATE_TIME_FORMAT));
    now.add(1, "days");
  }
  return dates;
};

export const convertToTwentyFourFormat = time => {
  return moment(time, HH_MM_SS).format(TWENTY_FOUR_HH_MM);
};
