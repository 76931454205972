import React, { useLayoutEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";

import { IRootState } from "../../shared/reducers";
import { logout } from "../../shared/reducers/authentication";

export interface ILogoutProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {
  idToken: string;
  logoutUrl: string;
}
const LOGOUT_TIMEOUT = 1500;
export const Logout = (props: ILogoutProps) => {
  useLayoutEffect(() => {
    props.logout();
    setTimeout(() => {
      props.history.push("/landing-page");
    }, LOGOUT_TIMEOUT);
  });

  return (
    <div className="p-5">
      <div className="alert alert-info">
        <h4>Logged out successfully!</h4>
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  logoutUrl: storeState.authentication.logoutUrl,
  idToken: storeState.authentication.idToken,
});

const mapDispatchToProps = { logout };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
