import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { IRootState } from '../../shared/reducers';
import {
  Col,
  Container,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import SubHeader from '../../shared/layout/sub-header';
import './styles.scss';
import {
  createVendorUser,
  fetchVendorUser,
  updateVendorUser,
  reset,
  deactivateVendorUser,
} from '../../reducers/vendor-user.reducer';
import {
  fetchDesignations,
  fetchQualifications,
  fetchVendorUserRoles,
} from '../../reducers/master.reducer';
import { getServices } from '../../reducers/plan.reducer';
import { translate } from 'react-jhipster';
import { MenuItem, Select, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../components/button';
import { PHONE_NUMBER_VALIDATION_EXPRESSION } from '../../config/constants';
import { YEARS, MONTHS } from '../../config/constants';
import { months } from 'moment';
import { PERMISSION_ACTIONS } from '../../config/permission-constants';
import { IsPermittedAction } from '../../shared/util/permission-utils';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

export const CreateVendorUser = (props: IPageProps) => {
  const {
    vendorUser,
    fetchVendorUser,
    createVendorUser,
    updateVendorUser,
    deactivateVendorUser,
    getServices,
    fetchQualifications,
    fetchDesignations,
    fetchVendorUserRoles,
    reset,
    updateSuccess,
    loading,
    services,
    designations,
    qualifications,
    roles,
  } = props;

  const [isNew, setIsNew] = useState(
    !props.match.params || !props.match.params.id,
  );
  const [selectedServices, setSelectedServices] = useState([] as any[]);
  const [serviceError, setServiceError] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([] as any[]);
  const [selectedQualification, setSelectedQualification] = useState(
    [] as any[],
  );
  const [roleError, setRoleError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [experienceInMonth, setExperienceInMonth] = useState(0);
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [designation, setDesignation] = useState(null);
  const [designationId, setDesignationId] = useState(null);
  const [designationError, setDesignationError] = useState(false);

  const toggle = () => setModalOpen(!modalOpen);
  const authorities = useSelector(
    (state: IRootState) => state.authentication.account.authorities,
  );
  const permissionList = useSelector(
    (state: IRootState) => state.permissions?.rolePermissions,
  );

  useEffect(() => {
    getServices();
    fetchQualifications();
    fetchDesignations();
    fetchVendorUserRoles();
    if (isNew) {
      reset();
      setSelectedServices([]);
      setSelectedRoles([]);
      setSelectedQualification([]);
      setDesignationId(null);
    } else {
      fetchVendorUser(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (vendorUser?.serviceIds !== undefined) {
      if (vendorUser?.serviceIds.length && !isNew) {
        setSelectedServices([...vendorUser.serviceIds]);
      }
    }
    if (vendorUser?.roles !== undefined) {
      if (vendorUser?.roles.length && !isNew) {
        setSelectedRoles([...vendorUser.roles]);
      }
    }
    if (vendorUser?.qualificationIds) {
      if (!isNew) {
        setSelectedQualification([...vendorUser.qualificationIds]);
      }
    }
    if (vendorUser?.designationId) {
      setDesignationId(vendorUser?.designationId);
    }
  }, [vendorUser]);

  const handleClose = () => {
    props.history.push('/user-list');
  };

  const deactivateModalClose = () => {
    setModalOpen(false);
  };

  const deactivateModalConfirm = () => {
    deactivateVendorUser(props.match.params.id);
    setModalOpen(false);
    props.history.push('/user-list');
  };

  const deactivateModal = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    if (isNew) {
      reset();
      setDesignationId(null);
    } else {
      fetchVendorUser(props.match.params.id);
    }
    fetchQualifications();
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (vendorUser.experienceInMonth) {
      setExperienceInMonth(vendorUser.experienceInMonth);
      calculateYearMonth();
    }
  }, [vendorUser]);

  const handleSubmit = (event, errors, values) => {
    let serviceErrorValid = serviceErrorChecking();
    let roleErrorValid = roleErrorChecking();
    let designationErrorValid = designationErrorChecking();
    if (
      errors.length === 0 &&
      !serviceErrorValid &&
      !roleErrorValid &&
      !designationErrorValid
    ) {
      const entity = {
        ...vendorUser,
        ...values,
        experienceInMonth,
        designationId,
        serviceIds: [...selectedServices],
        roles: [...selectedRoles],
        qualificationIds: [...selectedQualification],
      };

      if (isNew) {
        const newEntity = {
          ...entity,
        };
        createVendorUser(newEntity);
      } else {
        updateVendorUser(entity);
      }
    }
  };

  const serviceErrorChecking = () => {
    if (selectedServices?.length <= 0) {
      setServiceError(true);
      return true;
    } else {
      setServiceError(false);
      return false;
    }
  };

  const roleErrorChecking = () => {
    if (selectedRoles?.length <= 0) {
      setRoleError(true);
      return true;
    } else {
      setRoleError(false);
      return false;
    }
  };

  const designationErrorChecking = () => {
    if (!designationId) {
      setDesignationError(true);
      return true;
    } else {
      setDesignationError(false);
      return false;
    }
  };

  const calculateExperienceInMonthForMonth = monthFromSelect => {
    setMonth(monthFromSelect);
    setExperienceInMonth(Number(monthFromSelect) + Number(year) * 12);
  };

  const calculateExperienceInMonthForYear = yearFromSelect => {
    setYear(yearFromSelect);
    setExperienceInMonth(Number(month) + Number(yearFromSelect) * 12);
  };

  const calculateYearMonth = () => {
    setYear(experienceInMonth / 12);
    setMonth(experienceInMonth % 12);
  };

  const onChangeAutocompleteValue = (event, value, state, setState, isRole) => {
    let stateValue = state;
    stateValue = value?.map(item => {
      if (isRole) return item?.name;
      else return item?.id;
    });
    setState(stateValue);
    if (isRole) event.target.id.startsWith('roles') && setRoleError(false);
    else event.target.id.startsWith('service') && setServiceError(false);
  };

  const onDesignationChange = (event, id) => {
    setDesignationId(event.target.value);
    setDesignationError(false);
  };

  return loading ? (
    <p>Loading...</p>
  ) : (
    <AvForm model={isNew ? {} : vendorUser} onSubmit={handleSubmit}>
      <SubHeader
        title={
          isNew
            ? translate('form.create_vendor_user.create_users')
            : translate('form.create_vendor_user.view_users')
        }>
        <Button
          transparent
          tag={Link}
          id="cancel-save"
          to="/user-list"
          className="px-4"
          replace>
          <span className="d-none d-md-inline">
            {translate('entity.action.back')}
          </span>
        </Button>
        &nbsp;
        {IsPermittedAction(
          PERMISSION_ACTIONS.UPDATE_VENDOR_USERS,
          authorities,
          permissionList,
        ) && (
          <Button className="confirm-button p-2 px-4">
            <p className="m-0">
              {translate('form.create_vendor_user.confirm')}
            </p>
          </Button>
        )}
      </SubHeader>

      <Container fluid className="px-0 px-lg-3" id="create-vendor-user">
        <Row>
          <Col lg="8">
            <Row>
              <Col className="mt-2 mb-2" md="6">
                <Label for="name">
                  <span>{translate('form.create_vendor_user.name')}</span>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  className="p-3"
                  name="name"
                  id="name"
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_create_user.name_error_message',
                      ),
                    },
                  }}
                />
              </Col>
              <Col className="mt-2 mb-2" md="6">
                <Label for="email">
                  <span>{translate('form.create_vendor_user.email')}</span>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  className="p-3"
                  name="email"
                  id="email"
                  readOnly={isNew ? false : true}
                  validate={{
                    required: {
                      errorMessage: translate(
                        'validation_create_user.email_error_message',
                      ),
                    },
                  }}
                />
              </Col>
              <Col className="mt-2 mb-2" md="6">
                <Label for="serviceId">
                  <span>{translate('form.create_vendor_user.service')}</span>
                  <span className="text-danger">*</span>
                </Label>
                <Autocomplete
                  multiple
                  id="services"
                  className="user-multi-dropdown"
                  onChange={(event, value) =>
                    onChangeAutocompleteValue(
                      event,
                      value,
                      selectedServices,
                      setSelectedServices,
                      false,
                    )
                  }
                  onBlur={serviceErrorChecking}
                  options={Array.isArray(services) ? services : []}
                  getOptionLabel={option => option?.serviceName ?? ''}
                  getOptionSelected={(option, value) =>
                    option?.serviceName === value?.serviceName
                  }
                  defaultValue={
                    selectedServices?.map(spec => {
                      return {
                        id: spec,
                        name: Array.isArray(services)
                          ? services.filter(x => x.id === spec)[0]?.serviceName
                          : '',
                      };
                    }) ?? []
                  }
                  value={
                    selectedServices?.map(spec => {
                      return {
                        id: spec,
                        serviceName: Array.isArray(services)
                          ? services.filter(x => x.id === spec)[0]?.serviceName
                          : '',
                      };
                    }) ?? []
                  }
                  filterSelectedOptions
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      error={serviceError}
                      placeholder={translate('form.create_vendor_user.service')}
                    />
                  )}
                />
                {serviceError && (
                  <span className="text-danger" style={{ fontSize: '.8rem' }}>
                    {translate('validation_create_user.service_error_message')}
                  </span>
                )}
              </Col>
              <Col className="mt-2 mb-2" md="6">
                <Label for="mobile">
                  <span>{translate('form.create_vendor_user.phone')}</span>
                  <span className="text-danger">*</span>
                </Label>
                <AvField
                  className="p-3 pb-4"
                  name="mobile"
                  id="mobile"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: translate(
                        'validation_create_user.number_error_message',
                      ),
                    },
                    pattern: {
                      value: PHONE_NUMBER_VALIDATION_EXPRESSION,
                    },
                  }}
                />
              </Col>
              <Col className="mt-2 mb-2" md="6">
                <Label for="designation">
                  <span>
                    {translate('form.create_vendor_user.designation')}
                  </span>
                  <span className="text-danger">*</span>
                </Label>
                <div className="select-wrapper">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className="designation-drop-down"
                    variant="outlined"
                    fullWidth
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      PaperProps: {
                        style: {
                          maxHeight: '150px',
                        },
                      },
                    }}
                    value={designationId ? designationId : null}
                    error={designationError ? true : false}
                    onChange={event => {
                      onDesignationChange(event, designationId);
                    }}>
                    {designations?.map((attr, i) => (
                      <MenuItem key={i} value={attr?.id}>
                        {attr?.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {designationError && (
                    <span className="text-danger" style={{ fontSize: '.8rem' }}>
                      {translate(
                        'validation_create_user.designation_error_message',
                      )}
                    </span>
                  )}
                </div>
              </Col>
              <Col className="mt-2 mb-2" md="6">
                <Label for="qualificationId">
                  <span>
                    {translate('form.create_vendor_user.qualification')}
                  </span>
                </Label>
                <Autocomplete
                  multiple
                  id="qualifications"
                  className="user-multi-dropdown"
                  onChange={(event, value) =>
                    onChangeAutocompleteValue(
                      event,
                      value,
                      selectedQualification,
                      setSelectedQualification,
                      false,
                    )
                  }
                  options={Array.isArray(qualifications) ? qualifications : []}
                  getOptionLabel={option => option?.name ?? ''}
                  getOptionSelected={(option, value) =>
                    option?.name === value?.name
                  }
                  defaultValue={
                    selectedQualification?.map(spec => {
                      return {
                        id: spec,
                        name: Array.isArray(qualifications)
                          ? qualifications.filter(x => x.id === spec)[0]?.name
                          : '',
                      };
                    }) ?? []
                  }
                  value={
                    selectedQualification?.map(spec => {
                      return {
                        id: spec,
                        name: Array.isArray(qualifications)
                          ? qualifications.filter(x => x.id === spec)[0]?.name
                          : '',
                      };
                    }) ?? []
                  }
                  filterSelectedOptions
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={translate(
                        'form.create_vendor_user.qualification',
                      )}
                    />
                  )}
                />
              </Col>
              <Col className="mt-2 mb-2" md="6">
                <Label for="registrationNumber">
                  <span>
                    {translate('form.create_vendor_user.registration_number')}
                  </span>
                </Label>
                <AvField
                  className="p-3"
                  name="registrationNumber"
                  id="registrationNumber"
                />
              </Col>
              <Col className="mt-2 mb-2" md="6">
                <Label for="roleId">
                  <span>{translate('form.create_vendor_user.user_role')}</span>
                  <span className="text-danger">*</span>
                </Label>
                <Autocomplete
                  multiple
                  id="roles"
                  className="user-multi-dropdown"
                  onChange={(event, value) =>
                    onChangeAutocompleteValue(
                      event,
                      value,
                      selectedRoles,
                      setSelectedRoles,
                      true,
                    )
                  }
                  onBlur={roleErrorChecking}
                  options={Array.isArray(roles) ? roles : []}
                  getOptionLabel={option => option?.displayName ?? ''}
                  getOptionSelected={(option, value) =>
                    option?.displayName === value?.displayName
                  }
                  defaultValue={
                    selectedRoles?.map(spec => {
                      return {
                        name: spec,
                        displayName: Array.isArray(roles)
                          ? roles.filter(x => x.name === spec)[0]?.displayName
                          : '',
                      };
                    }) ?? []
                  }
                  value={
                    selectedRoles?.map(spec => {
                      return {
                        name: spec,
                        displayName: Array.isArray(roles)
                          ? roles.filter(x => x.name === spec)[0]?.displayName
                          : '',
                      };
                    }) ?? []
                  }
                  filterSelectedOptions
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      error={roleError}
                      placeholder={translate('form.create_vendor_user.role')}
                    />
                  )}
                />
                {roleError && (
                  <span className="text-danger" style={{ fontSize: '.8rem' }}>
                    {translate('validation_create_user.role_error_message')}
                  </span>
                )}
              </Col>
              <Col className="mt-2 mb-2" md="6">
                <Label for="experience">
                  <span>{translate('form.create_vendor_user.experience')}</span>
                  <span className="text-danger"></span>
                </Label>
                <AvForm onSubmit={calculateExperienceInMonthForMonth}>
                  <Row style={{ alignItems: 'center' }}>
                    <Col md="4">
                      <select
                        value={Math.floor(year)}
                        placeholder="Select"
                        onChange={event =>
                          calculateExperienceInMonthForYear(
                            event?.target?.value,
                          )
                        }
                        style={{
                          width: '100%',
                        }}>
                        <option value={0} disabled>
                          {translate('entity.action.select')}
                        </option>
                        {YEARS?.map((year, i) => (
                          <option key={i} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                      {/* <AvField
                        type="select"
                        name={'year'}
                        placeholder="year"
                        value={Math.floor(year)}
                        className="drop-down-container p-3"
                        onChange={event =>
                          calculateExperienceInMonthForYear(
                            event?.target?.value,
                          )
                        }
                        required>
                        <option value="" disabled selected hidden>
                          {translate('entity.action.select')}
                        </option>
                        {YEARS?.map((year, i) => (
                          <option key={i} value={year}>
                            {year}
                          </option>
                        ))}
                      </AvField> */}
                    </Col>
                    <Col md="2">
                      <span style={{ fontSize: '12px' }}>
                        {translate('form.create_vendor_user.year')}
                      </span>
                    </Col>
                    <Col md="4">
                      <select
                        value={month}
                        placeholder="Select"
                        onChange={event =>
                          calculateExperienceInMonthForMonth(
                            event?.target?.value,
                          )
                        }
                        style={{
                          width: '100%',
                        }}>
                        <option value={0} disabled>
                          {translate('entity.action.select')}
                        </option>
                        {MONTHS?.map((month, i) => (
                          <option key={i} value={month}>
                            {month}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md="2">
                      <span style={{ fontSize: '12px' }}>
                        {translate('form.create_vendor_user.month')}
                      </span>
                    </Col>
                  </Row>
                </AvForm>
              </Col>
              <Col className="mt-2 mb-2" md="6">
                {isNew ? (
                  ''
                ) : (
                  <>
                    <Label for="experience"></Label>
                    <Row>
                      <Col>
                        {IsPermittedAction(
                          PERMISSION_ACTIONS.DELETE_VENDOR_USERS,
                          authorities,
                          permissionList,
                        ) && (
                          <Button
                            className="deactivate-user-button p-2 px-4"
                            onClick={deactivateModal}>
                            <p className="m-0">
                              {translate(
                                'form.create_vendor_user.deactivate_button_text',
                              )}
                            </p>
                          </Button>
                        )}
                      </Col>
                      <Col> </Col>
                    </Row>
                  </>
                )}
              </Col>
              <Col className="mt-2 mb-2" md="6">
                <Label for="isChiefVet"></Label>
                <AvField
                  type="checkbox"
                  name="isChiefVet"
                  id="isChiefVet"
                  label={translate('form.create_vendor_user.set_as_chief_vet')}
                />
              </Col>
              <Col className="mt-2 mb-2" md="6"></Col>
              <Col className="mt-2 mb-2" md="6">
                <Label for="isDefaultPrescriptionLetterHead"></Label>
                <AvField
                  type="checkbox"
                  name="isDefaultPrescriptionLetterHead"
                  id="isDefaultPrescriptionLetterHead"
                  label={translate(
                    'form.create_vendor_user.set_as_letter_head',
                  )}
                />
              </Col>
              <Col className="mt-2 mb-2" md="4"></Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {modalOpen ? (
        <Modal isOpen={modalOpen} toggle={toggle}>
          <ModalHeader>
            {translate('form.create_vendor_user.deactivation_modal_header')}
          </ModalHeader>
          <ModalBody>
            {translate('form.create_vendor_user.deactivation_confirm_message')}
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              className="px-4"
              onClick={deactivateModalClose}>
              <FontAwesomeIcon icon="ban" />
              {translate('entity.action.cancel')}
              &nbsp;
            </Button>
            <Button
              className="confirm-button p-2 px-4"
              color="dark"
              onClick={deactivateModalConfirm}>
              <FontAwesomeIcon icon="trash" />
              {translate('entity.action.deactivate')}
              &nbsp;
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </AvForm>
  );
};

const mapStateToProps = ({
  authentication,
  vendorUser,
  master,
  plan,
}: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  vendorUser: vendorUser.vendorUser,
  updateSuccess: vendorUser.updateSuccess,
  roles: master.userRoles,
  loading: vendorUser.loading,
  updateVendorUserResponse: vendorUser.updateVendorUserResponse,
  createVendorUserResponse: vendorUser.createVendorUserResponse,
  services: plan.services,
  qualifications: master.qualifications,
  designations: master.designations,
  userRoles: master.userRoles,
});

const mapDispatchToProps = {
  fetchVendorUser,
  updateVendorUser,
  createVendorUser,
  getServices,
  fetchQualifications,
  fetchDesignations,
  fetchVendorUserRoles,
  reset,
  deactivateVendorUser,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CreateVendorUser);
